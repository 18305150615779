import React from "react"
import styled from "react-emotion"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StandardSingleColumn } from "../shared/Containers"

import {
  breakpoints,
  colors,
  palette,
  fontFamilies,
  fontSizes,
  spaces,
  radius,
} from "../../utils/presets"

export const TabsNav = styled(StandardSingleColumn.withComponent(`nav`))`
  display: flex;
  flex-direction: column;
  margin-bottom: -1px;
  width: 100%;

  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: row;
  }
`

export const TabsRoot = styled(`ul`)`
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  justify-content: center;
`

export const TabRoot = styled(`li`)`
  align-items: center;
  border: 1px solid ${colors.standardLine};
  border-radius: ${radius.default} ${radius.default} 0 0;
  color: ${palette.purple[500]};
  display: inline-flex;
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${fontSizes.m};
  font-weight: bold;
  height: 3.5rem;
  margin: 0 ${spaces[`2xs`]};
  padding: ${spaces[`3xs`]} ${spaces.l} 0;
  position: relative;
  z-index: 0;

  a {
    align-items: center;
    display: flex;
    text-decoration: none;
    &:active {
      color: inherit;
    }
  }

  &.active {
    background: ${colors.secondaryBackground};
    border-bottom-color: ${colors.secondaryBackground};
    color: ${palette.grey[900]};
    z-index: 2;

    :before,
    :after {
      border: 1px solid ${colors.standardLine};
      bottom: -1px;
      content: "";
      height: 6px;
      position: absolute;
      width: 6px;
    }

    :before {
      border-bottom-right-radius: 6px;
      border-width: 0 1px 1px 0;
      box-shadow: 2px 2px 0 #fff;
      left: -6px;
    }
    :after {
      border-bottom-left-radius: 6px;
      border-width: 0 0 1px 1px;
      box-shadow: -2px 2px 0 #fff;
      right: -6px;
    }
  }
`

export const Tabs = ({ children, className }) => (
  <TabsRoot className={className}>{children}</TabsRoot>
)

Tabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export const Tab = ({ children, className, to, onClick, ...rest }) => (
  <TabRoot className={className} {...rest}>
    {to ? (
      <Link to={to} onClick={onClick}>
        {children}
      </Link>
    ) : (
      children
    )}
  </TabRoot>
)

Tabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
}
