import React from "react"
import qs from "query-string"
import PropTypes from "prop-types"
import { Redirect } from "@reach/router"

export default class AuthRedirect extends React.Component {
  async componentDidMount() {
    const searchQuery = this.props.location.search

    const query = qs.parse(searchQuery)

    localStorage.setItem(`gatsby:token`, query.token)
    localStorage.setItem(`gatsby:userid`, query.id)
  }

  render() {
    const searchQuery = this.props.location.search

    const query = qs.parse(searchQuery)

    const { token, loginRedirectUrl, redirectToLeadCapture, new_user } = query

    if (token) {
      if (redirectToLeadCapture === `true`) {
        return <Redirect to="/dashboard/welcome?loggedin=1" noThrow />
      }

      if (loginRedirectUrl) {
        const {
          location: { origin },
        } = this.props
        const newLoginRedirectUrl = new URL(`${origin}${loginRedirectUrl}`)
        const { search, pathname } = newLoginRedirectUrl
        const redirectQuery = qs.parse(search)
        redirectQuery.loggedin = `1`
        if (new_user) {
          redirectQuery.new_user = `1`
        }
        const stringifiedQuery = qs.stringify(redirectQuery)
        return <Redirect to={`${pathname}?${stringifiedQuery}`} noThrow />
      }

      return (
        <Redirect
          to={`/dashboard/sites?loggedin=1${new_user ? `&new_user=1` : ``}`}
          noThrow
        />
      )
    } else {
      return <Redirect to="/dashboard/login" noThrow />
    }
  }
}

AuthRedirect.propTypes = {
  location: PropTypes.object,
}
