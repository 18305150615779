export const fontFamilies = {
  headerFontFamily: [
    `Futura PT`,
    `-apple-system`,
    `BlinkMacSystemFont`,
    `Segoe UI`,
    `Roboto`,
    `Oxygen`,
    `Ubuntu`,
    `Cantarell`,
    `Fira Sans`,
    `Droid Sans`,
    `Helvetica Neue`,
    `Arial`,
    `sans-serif`,
  ].join(),
  bodyFontFamily: [
    `-apple-system`,
    `BlinkMacSystemFont`,
    `Segoe UI`,
    `Roboto`,
    `Helvetica Neue`,
    `Arial`,
    `Noto Sans`,
    `sans-serif`,
    `Apple Color Emoji`,
    `Segoe UI Emoji`,
    `Segoe UI Symbol`,
    `Noto Color Emoji !important`,
  ].join(),
  monospaceFontFamily: [
    `SFMono-Regular`,
    `Menlo`,
    `Monaco`,
    `Consolas`,
    `Liberation Mono`,
    `Courier New`,
    `monospace`,
  ].join(),
}

export const fontSizes = {
  "7xl": `4.5rem`,
  "6xl": `3.75rem`,
  "5xl": `3rem`,
  "4xl": `2.625rem`,
  "3xl": `2rem`,
  "2xl": `1.75rem`,
  xl: `1.5rem`,
  l: `1.25rem`,
  m: `1.125rem`,
  s: `1rem`,
  xs: `0.875rem`,
  "2xs": `0.75rem`,
  "3xs": `0.6875rem`,
  "4xs": `0.5rem`,
}
