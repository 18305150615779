import React from "react"
import styled from "react-emotion"

import {
  ZeroStatePicture,
  ZeroStateHeader,
  ZeroStateText,
  ZeroStateRoot,
} from "../../shared/ZeroState"
import { siteDetails as text } from "../../../locales/default.json"

const ZeroBuildsRoot = styled(ZeroStateRoot)`
  min-height: calc(100vh - 3.75rem - 11rem);
`

const ZeroBuildsImage = styled(ZeroStatePicture)`
  max-width: 100%;
`

const ZeroBuildsHeader = styled(ZeroStateHeader)`
  margin-bottom: 0;
`

const ZeroBuildsText = styled(ZeroStateText)``

const ZeroBuilds = () => (
  <ZeroBuildsRoot>
    <ZeroBuildsImage />
    <ZeroBuildsHeader>{text.zeroBuildsTitle}</ZeroBuildsHeader>
    <ZeroBuildsText>{text.zeroBuildsText}</ZeroBuildsText>
  </ZeroBuildsRoot>
)

export default ZeroBuilds
