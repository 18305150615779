import React from "react"
import PropTypes from "prop-types"
import { compose, graphql } from "react-apollo"
import { navigate } from "gatsby"

import ErrorModal from "../../shared/ErrorModal"
import Loading from "../../shared/Loading"
import { REPOSITORY_QUERY, SITE_DETAILS_QUERY } from "./graphql"
import {
  SITE_INTEGRATIONS_QUERY,
  ENV_VARS_QUERY,
} from "../../../graphql/queries"
import SetupSiteForm from "./SetupSiteForm"

const SetupSite = ({
  siteId,
  repositoryQuery,
  siteDetailsQuery,
  cancel,
  envVarsQuery,
  siteIntegrationsQuery,
}) => {
  const {
    error: siteDetailsError,
    loading: siteDetailsLoading,
    siteDetails,
  } = siteDetailsQuery
  const {
    loading: siteIntegrationsLoading,
    error: siteIntegrationsError,
  } = siteIntegrationsQuery
  const { loading: repositoryLoading, error: repositoryError } = repositoryQuery
  const {
    error: envVarsError,
    loading: envVarsLoading,
    environmentVariablesForSite,
  } = envVarsQuery
  if (
    siteDetailsError ||
    repositoryError ||
    envVarsError ||
    siteIntegrationsError
  ) {
    navigate(`/dashboard/sites/create`)
    return <ErrorModal errMsg={`An internal error occurred`} />
  }

  if (
    siteDetailsLoading ||
    envVarsLoading ||
    repositoryLoading ||
    siteIntegrationsLoading
  ) {
    return (
      <Loading
        delay={1000}
        message="loading site data ..."
        fullPageHeight={false}
      />
    )
  }
  const { repositoryFromSite } = repositoryQuery
  const { branch } = siteDetails
  const { cmsIntegrationsForSite } = siteIntegrationsQuery

  return (
    <SetupSiteForm
      siteId={siteId}
      cancel={cancel}
      repositoryFromSite={repositoryFromSite}
      branch={branch}
      cmsIntegrationsForSite={cmsIntegrationsForSite}
      environmentVariablesForSite={environmentVariablesForSite}
      envVarsQuery={envVarsQuery}
    />
  )
}

SetupSite.propTypes = {
  siteId: PropTypes.string,
  cancel: PropTypes.func,
}

export default compose(
  graphql(REPOSITORY_QUERY, {
    name: `repositoryQuery`,
    options: props => {
      return {
        name: `repository`,
        variables: { siteId: props.siteId },
      }
    },
  }),
  graphql(SITE_DETAILS_QUERY, {
    name: `siteDetailsQuery`,
    options: props => {
      return {
        name: `siteDetails`,
        variables: { id: props.siteId },
        fetchPolicy: `cache-and-network`,
      }
    },
  }),
  graphql(ENV_VARS_QUERY, {
    name: `envVarsQuery`,
    options: props => {
      return {
        variables: {
          id: props.siteId,
        },
      }
    },
  }),
  graphql(SITE_INTEGRATIONS_QUERY, {
    name: `siteIntegrationsQuery`,
    options: props => {
      return {
        variables: {
          siteId: props.siteId,
          connected: true,
        },
      }
    },
  })
)(SetupSite)
