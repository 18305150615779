import React from "react"
import styled from "react-emotion"

import { NarrowSingleColumn } from "../shared/Containers"
import {
  EmphaticHeading as BaseEmphaticHeading,
  EmphaticLede,
} from "../shared/Typography"
import { createSite as text } from "../../locales/default.json"

const HeaderRoot = NarrowSingleColumn.withComponent(`header`)

const Heading = styled(BaseEmphaticHeading)`
  text-align: center;
`

const Intro = styled(EmphaticLede)`
  text-align: center;
`

const Header = () => (
  <HeaderRoot>
    <Heading>{text.title}</Heading>
    <Intro>{text.intro}</Intro>
  </HeaderRoot>
)

export default Header
