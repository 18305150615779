import React, { useContext } from "react"
import styled, { keyframes } from "react-emotion"
import PropTypes from "prop-types"
import { MdHelpOutline, MdDone } from "react-icons/md"

import { ModalContext } from "../../Modal"
import { ToastContext } from "../../shared/Toast"
import {
  fontSizes,
  palette,
  colors,
  spaces,
  breakpoints,
} from "../../../utils/presets"
import { Heading, Text } from "../../shared/Typography"
import { SettingsCard } from "../../shared/Settings/SettingsCard"
import { SecondaryButton } from "../../shared/Buttons"
import { DataContainer } from "../../shared/CmsIntegration/IntegrationCard"
import EditContentfulIntegration from "../../shared/ContentfulModal/EditContentfulIntegration"
import { cmsIntegrations as text } from "../../../locales/default.json"

const varEntry = keyframes`
   to {
     opacity: 1;
     transform: translateY(0);
   }
 `

const Table = styled(`table`)`
  animation: ${varEntry} 0.5s ease forwards;
  margin-bottom: 0;
  opacity: 0;
  transform: translateY(${spaces.s});
  width: 100%;
`

const TableBody = styled(`tbody`)``

const TableRow = styled(`tr`)`
  border-style: hidden;
`

const TableHeader = styled(`th`)`
  color: ${palette.grey[700]};
  font-size: ${fontSizes[`2xs`]};
  font-weight: normal;
  padding: ${spaces.xs} 0 ${spaces.xs} ${spaces.xs} !important;
  text-align: center !important;
  vertical-align: middle;

  span {
    display: block;
  }
`

const Cell = styled(`td`)`
  padding: 0;
  width: 25%;
  :not(:first-of-type) {
    text-align: center !important;
    vertical-align: middle;
  }

  img {
    display: none;
    @media (min-width: ${breakpoints.tablet}px) {
      display: block;
      max-height: 1.25rem;
      margin: 0;
    }
  }

  ${Text} {
    display: block;
    margin: 0;
    @media (min-width: ${breakpoints.tablet}px) {
      display: none;
    }
  }
`

const Checked = styled(MdDone)`
  stroke: ${palette.green[700]};
  stroke-width: 2;
`

const Unchecked = styled(Checked)`
  stroke: ${palette.grey[200]};
`

const featureList = [`envVars`, `webhooks`, `extension`]

const IntegrationsData = ({
  siteIntegrations,
  showFullModal,
  siteId,
  orgSiteName,
  showToast,
}) => (
  <Table>
    <TableBody>
      <TableRow>
        <TableHeader />
        <TableHeader>
          Environment <span>variables</span>
        </TableHeader>
        <TableHeader>
          Preview <span>Webhook</span>
        </TableHeader>
        <TableHeader>
          Preview <span>Extension</span>
        </TableHeader>
        <TableHeader />
      </TableRow>
      {siteIntegrations.map(({ name, logoUrl, features }) => (
        <TableRow key={name}>
          <Cell>
            <Text>{name}</Text>
            <img src={logoUrl} alt={name} />
          </Cell>
          {featureList.map(feature => (
            <Cell key={feature}>
              {features[feature] ? <Checked /> : <Unchecked />}
            </Cell>
          ))}
          <Cell>
            <SecondaryButton
              type="button"
              onClick={() =>
                showFullModal({
                  Component: EditContentfulIntegration,
                  props: {
                    siteId,
                    orgSiteName,
                    name,
                    showToast,
                  },
                })
              }
            >
              {text.edit}
            </SecondaryButton>
          </Cell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
)

const Title = styled(Heading)`
  align-items: center;
  color: ${palette.grey[800]};
  display: flex;
  font-size: ${fontSizes.l};
  margin-right: ${spaces[`2xl`]};

  svg {
    margin-left: ${spaces.xs};
    fill: ${colors.gatsby};
  }
`

const Icon = styled(MdHelpOutline)`
  height: 1rem;
  width: 1rem;
`

const IntegrationsCard = ({ siteIntegrations, siteId, orgSiteName }) => {
  const { showFullModal } = useContext(ModalContext)
  const { showToast } = useContext(ToastContext)
  return (
    <SettingsCard>
      <Title>
        Integrations{` `}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${window.location.origin}/docs/`}
          aria-label="Integrations docs"
        >
          <Icon />
        </a>
      </Title>
      <DataContainer>
        <IntegrationsData
          siteIntegrations={siteIntegrations}
          showFullModal={showFullModal}
          siteId={siteId}
          orgSiteName={orgSiteName}
          showToast={showToast}
        />
      </DataContainer>
    </SettingsCard>
  )
}

IntegrationsCard.propTypes = {
  siteIntegrations: PropTypes.array,
}

export default IntegrationsCard
