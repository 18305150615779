import React, { useContext } from "react"
import gql from "graphql-tag"
import { compose, graphql } from "react-apollo"
import PropTypes from "prop-types"

import Loading from "../Loading"
import { PERMISSIONS_SITE_FRAGMENT } from "../../../permissions/fragments"
import BranchList from "./BranchList"
import { withState } from "../../../utils/container-life/withState"
import { ModalContext } from "../../Modal"
import ErrorModal, { DefaultError } from "../ErrorModal"

const SITE_DETAILS_QUERY = gql`
  query siteDetailsQuery($id: UUID!) {
    siteDetails(id: $id) {
      id
      organizationId
      name
      previewUrl
      branch
      previewStatus
      permissions {
        ...sitePermissionsFragment
      }
    }
  }

  ${PERMISSIONS_SITE_FRAGMENT}
`

const REPO_BRANCH_QUERY = gql`
  query RepoBranchQuery(
    $searchText: String
    $siteId: UUID
    $nameWithOwner: String
    $endCursor: String
  ) {
    branchesForRepository(
      searchText: $searchText
      siteId: $siteId
      nameWithOwner: $nameWithOwner
      endCursor: $endCursor
    ) {
      branches @connection(key: "branches")
      pageInfo {
        lastId
        hasNextPage
      }
    }
  }
`

const UPDATE_BRANCH_MUTATION = gql`
  mutation updateSiteBranch($id: UUID!, $branchName: String!) {
    updateSiteBranch(id: $id, branchName: $branchName) {
      success
      message
      errorType
    }
  }
`

const BranchListContainer = ({
  siteId,
  siteBranch,
  repoBranchListQuery,
  updateBranchMutation,
  updateBranchState,
  input,
  setInput,
  disabled = false,
  uiSource,
}) => {
  const { showModal } = useContext(ModalContext)
  const { loading, error, fetchMore } = repoBranchListQuery

  if (error) return <ErrorModal errMsg={error.message} />

  const branches =
    (repoBranchListQuery &&
      repoBranchListQuery.branchesForRepository &&
      repoBranchListQuery.branchesForRepository.branches) ||
    []
  const pageInfo =
    (repoBranchListQuery &&
      repoBranchListQuery.branchesForRepository &&
      repoBranchListQuery.branchesForRepository.pageInfo) ||
    {}

  const onScroll = e => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom) handleScroll()
  }

  const handleScroll = () => {
    if (pageInfo && !pageInfo.hasNextPage) {
      return
    }

    return fetchMore({
      variables: {
        siteId,
        pagination: {
          endCursor: pageInfo.lastId,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult
        }

        previousResult.branchesForRepository.branches = [
          ...previousResult.branchesForRepository.branches,
          ...fetchMoreResult.branchesForRepository.branches,
        ]

        previousResult.branchesForRepository.pageInfo =
          fetchMoreResult.branchesForRepository.pageInfo

        return previousResult
      },
    })
  }

  const updateBranch = branch => {
    if (updateBranchState) {
      return updateBranchState(branch)
    }

    updateBranchMutation({
      variables: {
        id: siteId,
        branchName: branch,
      },
      update: cache => {
        const siteDetailsFromCache = cache.readQuery({
          query: SITE_DETAILS_QUERY,
          variables: {
            id: siteId,
          },
        })

        siteDetailsFromCache.siteDetails.branch = branch

        cache.writeQuery({
          query: SITE_DETAILS_QUERY,
          variables: {
            id: siteId,
          },
          data: siteDetailsFromCache,
        })
      },
    })
      .then(({ data }) => {
        const { success, message } = data
        if (!success && message) {
          showModal({
            Component: DefaultError,
            props: { errMsg: message },
          })
        }
      })
      .catch(err => {
        showModal({
          Component: DefaultError,
          props: { errMsg: err.message },
        })
      })
  }

  return (
    <BranchList
      selectedBranch={siteBranch}
      branches={branches}
      loadingCondition={loading && branches.length === 0}
      loadingComponent={
        <Loading fullPageHeight={false} message={`loading... `} />
      }
      input={input}
      setInput={val => setInput(val)}
      onScroll={onScroll}
      updateBranch={branch => {
        updateBranch(branch)
      }}
      disabled={disabled}
      siteId={siteId}
      uiSource={uiSource}
    />
  )
}

BranchListContainer.propTypes = {
  siteId: PropTypes.string,
  siteBranch: PropTypes.string,
  input: PropTypes.string,
  setInput: PropTypes.func,
  disabled: PropTypes.bool,
}

export default compose(
  withState(`input`, ``),
  graphql(REPO_BRANCH_QUERY, {
    name: `repoBranchListQuery`,
    options: props => {
      return {
        variables: {
          nameWithOwner: props.nameWithOwner,
          searchText: props.input,
          siteId: props.siteId,
        },
      }
    },
  }),
  graphql(UPDATE_BRANCH_MUTATION, {
    name: `updateBranchMutation`,
  })
)(BranchListContainer)
