import React from "react"
import { ApolloFragment } from "apollo-fragment-react"
import styled, { keyframes } from "react-emotion"
import { logout } from "../../auth"

import { MdArrowDropDown, MdPowerSettingsNew } from "react-icons/md"
import BookOpenPageVariant from "../../assets/BookOpenPageVariant"

import {
  Menu,
  MenuList,
  MenuButton as BaseMenuButton,
  MenuItem,
  MenuLink,
} from "@reach/menu-button"

import { header as text } from "../../locales/default.json"
import { palette, fontSizes, spaces } from "../../utils/presets"

const userDropdownFragment = `
  fragment userDropdownFields on User {
    id
    name
    email
    avatarUrl
  }
`

const MenuButtonPositioner = styled(`div`)`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  transform: translateX(${spaces.xs});
`

const menuButtonEntry = keyframes`
   to {
     opacity: 1;
   }
 `

const MenuButton = styled(BaseMenuButton)`
  animation: ${menuButtonEntry} 0.5s ease forwards;
  align-items: center;
  background: none;
  border: 0;
  color: ${palette.grey[900]};
  cursor: pointer;
  display: flex;
  line-height: 1;
  opacity: 0;
  padding: ${spaces[`2xs`]};
  padding-left: ${spaces.s};
  position: relative;
  white-space: nowrap;

  svg {
    flex-shrink: 0;
    height: ${spaces.m};
    width: ${spaces.m};
  }
`

const Avatar = styled(`span`)`
  background: ${palette.grey[100]};
  border-radius: 50%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3) inset;
  display: block;
  flex-shrink: 0;
  height: 32px;
  margin: 0 ${spaces[`3xs`]} 0 ${spaces.xs};
  overflow: hidden;
  width: 32px;
`

const Name = styled(`span`)`
  color: ${palette.grey[800]};
  font-size: ${fontSizes.xs};
  text-align: right;
`

const MenuLabel = styled(`div`)`
  color: ${palette.grey[600]};
  display: flex;
  flex-direction: column;
  font-size: ${fontSizes.xs};
  padding: ${spaces.s} ${spaces.m};
  position: relative;
`

const Email = styled(`span`)`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const UserMenu = props => (
  <ApolloFragment
    id={localStorage.getItem(`gatsby:userid`)}
    fragment={userDropdownFragment}
  >
    {({ data }) => {
      const { name, email, avatarUrl } = data
      return (
        <Menu>
          {name && (
            <MenuButtonPositioner data-cy="navbar-menu-button">
              <MenuButton data-testid="user-menu-button">
                <Name data-cy="signed-in-user-name">{name}</Name>
                <Avatar data-cy="signed-in-user-avatar">
                  {avatarUrl && <img src={avatarUrl} alt={`user avatar`} />}
                </Avatar>
                <MdArrowDropDown />
              </MenuButton>
            </MenuButtonPositioner>
          )}

          <MenuList data-testid="user-menu-list">
            <MenuLabel>
              <small>{text.loggedInAs}</small>
              <Email data-cy="signed-in-user-email">{email}</Email>
            </MenuLabel>
            {/* <DropdownItem to="/dashboard/account">
                  <MdSettings />
                  {text.userSettings}
                </DropdownItem> */}
            {/* TODO: Update link when we have docs with integration list */}
            <MenuLink
              data-cy="menu-dropdown-documentation-link"
              component={`a`}
              target={`_blank`}
              href={`${window.location.origin}/docs`}
            >
              <BookOpenPageVariant /> {text.documentation}
            </MenuLink>
            <MenuLink
              component={`a`}
              href="mailto:support@gatsbyjs.com"
              data-cy="menu-dropdown-email-support"
            >
              <small>{text.needHelp}</small>
              <span>support@gatsbyjs.com</span>
            </MenuLink>
            <MenuItem onSelect={logout} data-cy="navbar-logout-menu-button">
              <MdPowerSettingsNew />
              {text.signOut}
            </MenuItem>
          </MenuList>
        </Menu>
      )
    }}
  </ApolloFragment>
)

export default UserMenu
