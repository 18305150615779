import React from "react"
import { PropTypes } from "prop-types"
import { Link } from "gatsby"
import styled from "react-emotion"

import { palette, breakpoints, fontSizes } from "../../utils/presets"
import { billing as billingText } from "../../locales/default.json"
import { Text } from "../shared/Typography"

const FooterRoot = styled(`div`)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const FooterMessage = styled(Text)`
  color: ${palette.purple[600]};
  font-size: ${fontSizes.xs};
  text-align: right;
  width: 100%;

  a {
    font-size: inherit;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    width: 45%;
  }
`

export const Footer = ({ text }) => (
  <FooterRoot>
    <FooterMessage>
      {text}
      <Link to="/terms-and-conditions">{billingText.termsAndConditions}</Link>
    </FooterMessage>
  </FooterRoot>
)

Footer.propTypes = {
  text: PropTypes.string,
}

export default Footer
