import React from "react"

export default () => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0H12V8H0V0ZM6 2C7.10667 2 8 2.89333 8 4C8 5.10667 7.10667 6 6 6C4.89333 6 4 5.10667 4 4C4 2.89333 4.89333 2 6 2ZM2.66667 1.33333C2.66667 2.06667 2.06667 2.66667 1.33333 2.66667V5.33333C2.06667 5.33333 2.66667 5.93333 2.66667 6.66667H9.33333C9.33333 5.93333 9.93333 5.33333 10.6667 5.33333V2.66667C9.93333 2.66667 9.33333 2.06667 9.33333 1.33333H2.66667Z"
      fill="#B7B5BD"
    />
  </svg>
)
