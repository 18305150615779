import React from "react"
import styled from "react-emotion"

import { Subheading } from "../../../shared/Typography"
import { Header, Message } from "../../../shared/Settings/SettingsCard"
import { paymentHistory as historyText } from "../../../../locales/default.json"
import {
  breakpoints,
  spaces,
  palette,
  fontSizes,
} from "../../../../utils/presets"

const Contact = styled(Message)`
  color: ${palette.grey[800]};
  margin: 0;

  @media (min-width: ${breakpoints.desktop}px) {
    margin: 0;
  }
`

const Sales = styled(`a`)`
  color: ${palette.blue[400]};
  text-decoration: none;
`

const HistoryHeader = styled(Header)`
  margin: ${spaces.xl} 0 ${spaces.l} 0;
  position: relative;

  :before {
    display: none;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    :before {
      content: "";
      display: block;
      border-top: 1px solid ${palette.grey[200]};
      height: 1px;
      position: absolute;
      left: 10rem;
      right: 6rem;
    }
  }

  ${Subheading} {
    margin: 0;
  }
`

const ComingSoon = styled(Message)`
  background: ${palette.purple[100]};
  border-radius: 43.3985px;
  color: ${palette.purple[500]};
  font-size: ${fontSizes[`3xs`]};
  margin: 0;
  padding: ${spaces[`3xs`]} ${spaces.xs};
  text-transform: uppercase;

  @media (min-width: ${breakpoints.desktop}px) {
    margin: 0;
  }
`

const HistoryRoot = styled(`section`)`
  padding: 0 ${spaces.l} ${spaces.l} ${spaces.l};

  @media (min-width: ${breakpoints.desktop}px) {
    padding: 0 ${spaces[`2xl`]} ${spaces.xl} ${spaces[`2xl`]};
  }
`

const PaymentHistory = () => (
  <HistoryRoot>
    <HistoryHeader>
      <Subheading>{historyText.paymentHistory}</Subheading>
      <ComingSoon>{historyText.comingSoon}</ComingSoon>
    </HistoryHeader>
    <Contact>{historyText.download}</Contact>
    <Contact>
      <strong>
        {historyText.contact}
        {` `}
        <Sales href="mailto:sales@gatsbyjs.com">sales@gatsbyjs.com</Sales>
        {` `}
        {historyText.receipts}
      </strong>
    </Contact>
  </HistoryRoot>
)

export default PaymentHistory
