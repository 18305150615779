import styled from "react-emotion"

import { Card } from "../../shared/Card"
import { Heading, Text } from "../../shared/Typography"
import { breakpoints, fontSizes, palette, spaces } from "../../../utils/presets"

export const Header = styled(`header`)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: ${breakpoints.tablet}px) {
    flex-wrap: none;
  }
`

export const Title = styled(Heading)`
  font-size: ${fontSizes.l};
  color: ${props =>
    props.theme && palette[props.theme]
      ? `${palette[props.theme][800]}`
      : `${palette.grey[800]}`};
  margin: ${spaces.xs} 0 ${spaces.s};
  align-items: center;
  display: flex;

  @media (min-width: ${breakpoints.desktop}px) {
    margin: 0;
  }
`

export const Message = styled(Text)`
  color: ${palette.grey[500]};
  font-size: ${fontSizes.xs};
  line-height: 1.3125rem;
  letter-spacing: 0.015rem;
  margin: ${spaces.m} ${spaces.m} ${spaces.m} 0;

  @media (min-width: ${breakpoints.desktop}px) {
    margin: ${spaces.l} 15rem ${spaces.l} 0;
  }
`

export const SettingsCard = styled(Card)`
  margin-bottom: ${spaces.m};
  padding: ${spaces.m} ${spaces.l};

  :last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    margin-bottom: ${spaces.l};
    padding: ${spaces.xl} ${spaces[`2xl`]};
  }
`
