import styled from "react-emotion"

import { FieldAbstraction } from "./FieldAbstractions"
import { fontSizes, palette, radius, spaces } from "../../../utils/presets"

export const TextInput = styled(`input`)`
  border: 1px solid ${palette.grey[300]};
  border-radius: ${radius.default};
  color: ${palette.grey[900]};
  font-size: ${fontSizes.s};
  height: 2.25rem;
  padding: 0 ${spaces.s};
  width: 100%;

  :focus {
    border-color: ${palette.purple[400]};
    box-shadow: 0 0 0 3px ${palette.purple[200]};
    outline: 0;
    transition: box-shadow 0.15s ease-in-out;
  }
`

export const TextInputBlock = FieldAbstraction({ Component: TextInput })
