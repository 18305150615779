import React from "react"

export default ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8.88889 8.88889H7.11111V3.55556H8.88889V8.88889ZM8 12.7111C7.36 12.7111 6.84444 12.1956 6.84444 11.5556C6.84444 10.9156 7.36 10.4 8 10.4C8.64 10.4 9.15556 10.9156 9.15556 11.5556C9.15556 12.1956 8.64 12.7111 8 12.7111ZM11.3156 0H4.68444L0 4.68444V11.3156L4.68444 16H11.3156L16 11.3156V4.68444L11.3156 0Z"
      fill="#EC1818"
    />
  </svg>
)
