import React from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"

import { MdArrowDropDown, MdDone } from "react-icons/md"
import {
  Menu,
  MenuList,
  MenuButton as BaseMenuButton,
  MenuItem,
} from "@reach/menu-button"
import { fontSizes, palette, radius } from "../../../utils/presets"

import { createSite as text } from "../../../locales/default.json"
import { useTracker } from "../../../analytics"

const MenuButton = styled(BaseMenuButton)`
  align-items: center;
  background: ${palette.white};
  border: 1px solid ${palette.grey[300]};
  border-radius: ${radius.default};
  color: ${palette.grey[900]};
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-size: ${fontSizes.s};
  height: 2.25rem;
  justify-content: space-between;
  list-style: none;
  margin-left: 0;
  min-width: 7rem;
  position: relative;
  text-decoration: none;
  :focus {
    border-color: ${palette.purple[400]};
    box-shadow: 0 0 0 3px ${palette.purple[200]};
    outline: 0;
    transition: box-shadow 0.15s ease-in-out;
  }
`

const ListItems = ({
  chooseAllOrgs,
  sourceOrganizations,
  selectedOrg,
  changeOrg,
  trackButtonClicked,
}) => (
  <MenuList className="sourceOrgsMenu" data-testid="source-orgs-list">
    {[
      <MenuItem
        onSelect={() => {
          trackButtonClicked(`Source organization: All`, {
            uiSource: `Create site and pick repo`,
          })
          chooseAllOrgs()
        }}
        key="all"
      >
        All
      </MenuItem>,
      ...sourceOrganizations.map(org =>
        selectedOrg !== org.name ? (
          <MenuItem
            key={org.name}
            onSelect={() => {
              trackButtonClicked(`Source organization`, {
                uiSource: `Create site and pick repo`,
              })
              changeOrg(org.name)
            }}
          >
            {org.name}
          </MenuItem>
        ) : (
          <MenuItem onSelect={() => {}} className="active" key={org.name}>
            <MdDone /> {org.name}
          </MenuItem>
        )
      ),
    ]}
  </MenuList>
)

const SingleItem = ({ org, changeOrg, trackButtonClicked }) => (
  <MenuList className="sourceOrgsMenu" data-testid="source-orgs-list">
    <MenuItem
      className="active"
      key={org.id}
      onSelect={() => {
        trackButtonClicked(`Source organization`, {
          uiSource: `Create site and pick repo`,
        })
        changeOrg(org.name)
      }}
    >
      <MdDone /> {org.name}
    </MenuItem>
    <MenuItem onSelect={() => {}} className="inactive">
      {text.noOtherOrgs}
    </MenuItem>
  </MenuList>
)

const OrganizationList = ({
  selectedOrg,
  sourceOrganizations,
  changeOrg,
  chooseAllOrgs,
}) => {
  const { trackButtonClicked } = useTracker()
  return (
    <Menu>
      {!!sourceOrganizations.length && (
        <MenuButton data-testid="source-orgs-button">
          {selectedOrg ? selectedOrg : `All`}
          <MdArrowDropDown />
        </MenuButton>
      )}

      {sourceOrganizations.length === 1 && (
        <SingleItem
          org={sourceOrganizations[0]}
          changeOrg={changeOrg}
          trackButtonClicked={trackButtonClicked}
        />
      )}

      {sourceOrganizations.length > 1 && (
        <ListItems
          chooseAllOrgs={chooseAllOrgs}
          sourceOrganizations={sourceOrganizations}
          selectedOrg={selectedOrg}
          changeOrg={changeOrg}
          trackButtonClicked={trackButtonClicked}
        />
      )}
    </Menu>
  )
}

OrganizationList.propTypes = {
  selectedOrg: PropTypes.string,
  sourceOrganizations: PropTypes.array,
  changeOrg: PropTypes.func,
  chooseAllOrgs: PropTypes.func,
}

export default OrganizationList
