import React, { Fragment, useContext } from "react"
import styled from "react-emotion"
import { graphql } from "react-apollo"
import gql from "graphql-tag"
import PropTypes from "prop-types"
import { MdAdd, MdHelpOutline } from "react-icons/md"
import ContributorsForm from "../../../shared/ContributorsForm/ContributorsForm"

import { ToastContext } from "../../../shared/Toast"
import { breakpoints, colors, spaces } from "../../../../utils/presets"
import { siteMembers as text } from "../../../../locales/default.json"
import Loading from "../../../shared/Loading"
import ContributorDetails from "../../../shared/Settings/ContributorDetails"
import {
  SettingsCard,
  Header,
  Title,
  Message,
} from "../../../shared/Settings/SettingsCard"
import { SecondaryButton } from "../../../shared/Buttons"
import { Heading } from "../../../shared/Typography"
import {
  ModalContext,
  ModalSecondaryHeader,
  ModalBody,
  CloseButton,
} from "../../../Modal"
import { DefaultError } from "../../../shared/ErrorModal"
import { SitePermissions } from "../../../../permissions"
import Paywall from "../../../shared/PaymentInformation/Paywall"
import { useTracker } from "../../../../analytics"

import { SITE_CONTRIBUTORS_QUERY } from "../queries"

const INVITE_CONTRIBUTORS_MUTATION = gql`
  mutation inviteContributorsToResource(
    $id: UUID!
    $emails: [String!]!
    $resourceType: CloudResourceType!
  ) {
    inviteContributorsToResource(
      id: $id
      emails: $emails
      resourceType: $resourceType
    ) {
      id
      name
      email
      avatarUrl
      contributorRole
    }
  }
`

const DocumentationLink = styled(`a`)`
  align-items: center;
  color: ${colors.gatsby};
  display: flex;
  padding-top: ${spaces.m};
  svg {
    display: none;
    margin-left: ${spaces[`2xs`]};
  }
  @media (min-width: ${breakpoints.phablet}px) {
    svg {
      display: block;
    }
  }
`

let InviteContributor = ({
  closeModal,
  siteId,
  showToast,
  inviteContributorsMutation,
}) => {
  const { showModal } = useContext(ModalContext)
  const { trackButtonClicked } = useTracker()

  return (
    <Fragment>
      <ModalSecondaryHeader>
        <Heading>{text.inviteContributors}</Heading>
        <CloseButton onClick={closeModal} aria-label="Close modal" />
      </ModalSecondaryHeader>
      <ModalBody>
        <ContributorsForm
          submitCallback={emails => {
            trackButtonClicked(text.inviteContributors, {
              siteId,
              uiSource: `Site settings`,
            })
            inviteContributorsMutation({
              variables: {
                id: siteId,
                emails: emails,
                resourceType: `SITE`,
              },
              update: (cache, data) => {
                const contributorsFromCache = cache.readQuery({
                  query: SITE_CONTRIBUTORS_QUERY,
                  variables: {
                    id: siteId,
                  },
                })
                const {
                  data: { inviteContributorsToResource },
                } = data

                contributorsFromCache.contributorsBySite = contributorsFromCache.contributorsBySite.concat(
                  inviteContributorsToResource
                )

                cache.writeQuery({
                  query: SITE_CONTRIBUTORS_QUERY,
                  variables: {
                    id: siteId,
                  },
                  data: contributorsFromCache,
                })
              },
            })
              .then(() => {
                closeModal()
                showToast(
                  `<strong>${text.contributorInvitationSent}</strong> ${
                    text.successfully
                  }`
                )
              })
              .catch(err =>
                showModal({
                  Component: DefaultError,
                  props: { errMsg: err.message },
                })
              )
          }}
          cancelCallback={closeModal}
        />
      </ModalBody>
    </Fragment>
  )
}

InviteContributor.propTypes = {
  closeModal: PropTypes.func,
  siteId: PropTypes.string,
  emails: PropTypes.array,
  resourceType: PropTypes.string,
}

InviteContributor = graphql(INVITE_CONTRIBUTORS_MUTATION, {
  name: `inviteContributorsMutation`,
})(InviteContributor)

const SiteContributors = ({
  siteId,
  organizationId,
  permissionToRead,
  permissionToInvite,
  permissionToDelete,
  siteContributorsQuery,
}) => {
  const { showToast } = useContext(ToastContext)
  const { showModal } = useContext(ModalContext)
  const { loading, error, contributorsBySite } = siteContributorsQuery

  if (loading)
    return (
      <Loading
        delay={1000}
        message="loading your site members list..."
        fullPageHeight={false}
      />
    )
  if (error) return <p>Error: {error.message}</p>

  return (
    <SettingsCard>
      <Header>
        <Title>{text.siteMembers}</Title>
        <SitePermissions id={siteId} resource="contributor" action="invite">
          <Paywall
            id={organizationId}
            onSuccess={() => {
              showModal({
                Component: InviteContributor,
                props: {
                  siteId,
                  mode: `action`,
                  showToast,
                },
              })
            }}
          >
            {({ onClick }) => (
              <SecondaryButton
                onClick={onClick}
                data-cy="invite-contributors-button"
              >
                {text.inviteContributors} <MdAdd />
              </SecondaryButton>
            )}
          </Paywall>
        </SitePermissions>
      </Header>

      <Message>
        {permissionToRead
          ? `${text.siteMembersText}`
          : `${text.siteMembersNoPermission}`}
        {/* TODO: Update link when we have docs with role and permissions not cms specific */}
        <DocumentationLink
          target="_blank"
          href={`${
            window.location.origin
          }/docs/preview/getting-started-with-gatsby-preview-and-contentful/#role-and-permissioning-structure`}
        >
          {text.siteMembersLink} <MdHelpOutline />
        </DocumentationLink>
      </Message>

      <SitePermissions id={siteId} resource="contributor" action="read">
        <ContributorDetails
          contributors={contributorsBySite}
          showModal={showModal}
          resourceId={siteId}
          permissionToInvite={permissionToInvite}
          permissionToDelete={permissionToDelete}
          showToast={showToast}
          resourceType="SITE"
        />
      </SitePermissions>
    </SettingsCard>
  )
}

SiteContributors.propTypes = {
  siteId: PropTypes.string.isRequired,
  permissionToInvite: PropTypes.bool,
  permissionToRead: PropTypes.bool,
  permissionToDelete: PropTypes.bool,
}

export default graphql(SITE_CONTRIBUTORS_QUERY, {
  name: `siteContributorsQuery`,
  options: props => {
    return {
      name: `siteContributors`,
      variables: { id: props.siteId },
      fetchPolicy: `cache-and-network`,
    }
  },
})(SiteContributors)
