import React from "react"

export default ({ className }) => (
  <svg
    className={className}
    width="71"
    height="95"
    viewBox="0 0 71 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <circle
        opacity="0.5"
        cx="59.0029"
        cy="28.0005"
        r="11.001"
        fill="#FFDF37"
      />
      <circle
        opacity="0.25"
        cx="59.001"
        cy="0.000965118"
        r="11.001"
        fill="#BC027F"
      />
      <circle
        opacity="0.5"
        r="10.9998"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 32.0003 84.0007)"
        fill="#59C156"
      />
      <circle
        r="11.001"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 32.001 0.000987045)"
        fill="#CCFFFC"
      />
      <circle
        opacity="0.25"
        r="11.001"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 4.00292 28.0005)"
        fill="#FB8400"
      />
    </g>
  </svg>
)
