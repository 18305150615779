import React, { Fragment } from "react"
import styled from "react-emotion"
import { PropTypes } from "prop-types"

import { palette, fontSizes } from "../../utils/presets"
import { Text } from "./Typography"

const Details = styled(Text)`
  color: ${palette.grey[500]};
  font-size: ${fontSizes.xs};
  font-weight: normal;
  margin: 0;
  padding: 0;
`

const Important = styled(`span`)`
  color: ${palette.grey[900]};
  font-weight: bold;
`

/* This should be refactored but for now it'll do */
const PlanFeatures = ({ feature, index }) => {
  let italic = false
  return (
    <Details key={index}>
      {feature.map((word, idx) => {
        italic = word.includes(`(`) || italic ? true : false
        return idx === 0 ||
          !isNaN(word) ||
          word === `to` ||
          word === `Unlimited` ? (
          <Important key={idx}>
            {word}
            {` `}
          </Important>
        ) : italic ? (
          <i key={idx}>
            {word}
            {` `}
          </i>
        ) : (
          <Fragment key={idx}>
            {word}
            {` `}
          </Fragment>
        )
      })}
    </Details>
  )
}

PlanFeatures.propTypes = {
  firstWord: PropTypes.string,
  feature: PropTypes.array,
  index: PropTypes.number,
}

export default PlanFeatures
