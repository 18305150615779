import React from "react"

export default ({ height = 16, width = 45 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 512 104"
    version="1.1"
    preserveAspectRatio="xMidYMid"
  >
    <g>
      <polygon
        fill="#F37368"
        points="381.461756 36.9858357 381.461756 101.81983 360.575637 101.81983 360.575637 26.8328612"
      />
      <path
        d="M85.8651558,89.7813031 L101.529745,103.415297 L167.524079,69.0402266 L160.707082,52.6504249 L85.8651558,89.7813031 Z M360.575637,48.0090652 L413.66119,20.4509915 L404.668555,4.93144476 L360.575637,26.8328612 L360.575637,48.0090652 Z"
        fill="#F7A199"
      />
      <path
        d="M211.18187,31.9093484 L211.18187,101.81983 L191.166006,101.81983 L191.166006,2.17563739 L211.18187,31.9093484 Z M85.8651558,89.7813031 L101.529745,103.415297 L131.698584,27.9932011 L121.980737,2.17563739 L85.8651558,89.7813031 Z"
        fill="#F37368"
      />
      <path
        d="M121.980737,2.17563739 L142.576771,2.17563739 L180.722946,101.81983 L159.546742,101.81983 L121.980737,2.17563739 Z M214.227762,2.17563739 L258.175637,70.2005666 L258.175637,101.81983 L191.166006,2.17563739 L214.227762,2.17563739 Z M295.886686,2.17563739 L316.627762,2.17563739 L316.627762,101.81983 L295.886686,101.81983 L295.886686,2.17563739 Z M360.575637,20.4509915 L328.666289,20.4509915 L328.666289,2.17563739 L403.073088,2.17563739 L413.66119,20.4509915 L381.461756,20.4509915 L360.575637,20.4509915 Z"
        fill="#F04939"
      />
      <polyline
        fill="#F7A199"
        points="475.014164 63.9637394 475.014164 101.81983 454.41813 101.81983 454.41813 63.9637394"
      />
      <polygon
        fill="#F04939"
        points="489.228329 2.17563739 454.41813 63.9637394 475.014164 63.9637394 511.129745 2.17563739"
      />
      <polygon
        fill="#F37368"
        points="454.41813 63.9637394 418.447592 2.17563739 441.074221 2.17563739 465.296317 44.5280453"
      />
      <path
        d="M8.55750708,13.7790368 C8.55750708,27.5580737 17.1150142,35.8254958 34.2300283,40.1767705 L52.3603399,44.3830028 C68.6050992,48.1541076 78.4679887,57.4368272 78.4679887,72.5212465 C78.6130312,79.0481586 76.4373938,85.4300283 72.5212465,90.6515581 C72.5212465,75.5671388 64.6889518,67.4447592 45.9784703,62.5133144 L28.1382436,58.4521246 C13.7790368,55.2611898 2.75580737,47.5739377 2.75580737,31.184136 C2.75580737,24.9473088 4.78640227,18.7104816 8.55750708,13.7790368"
        fill="#F04939"
      />
      <polygon
        fill="#F37368"
        points="258.175637 65.1240793 258.175637 2.17563739 278.191501 2.17563739 278.191501 101.81983 258.175637 101.81983"
      />
      <path
        d="M61.3529745,68.7501416 C69.0402266,73.6815864 72.5212465,80.6436261 72.5212465,90.6515581 C65.9943343,98.9189802 54.8260623,103.415297 41.6271955,103.415297 C19.4356941,103.415297 3.62606232,92.392068 0.290084986,73.3915014 L21.6113314,73.3915014 C24.3671388,82.094051 31.6192635,86.1552408 41.482153,86.1552408 C53.2305949,86.3002833 61.207932,79.9184136 61.3529745,68.7501416 M8.55750708,13.6339943 C14.7943343,5.51161473 25.5274788,0.580169972 38.5813031,0.580169972 C61.3529745,0.580169972 74.4067989,12.6186969 77.7427762,29.4436261 L57.1467422,29.4436261 C54.8260623,22.7716714 49.1694051,17.5501416 38.8713881,17.5501416 C27.7031161,17.6951841 20.1609065,24.0770538 19.725779,34.2300283 C12.2517705,30.1081799 8.2674221,22.0464589 8.55750708,13.6339943 Z"
        fill="#F37368"
      />
    </g>
  </svg>
)
