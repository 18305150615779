import React, { useEffect, useContext, Fragment } from "react"
import PropTypes from "prop-types"
import qs from "query-string"
import { Location } from "@reach/router"
import { navigate } from "gatsby"

import { MdArrowForward, MdCheck } from "react-icons/md"

import {
  ModalPrimaryHeader,
  ModalPrimaryIcon,
  ModalBody,
  ModalMessage,
  ModalAction,
  ModalContext,
  ConfirmButton,
  ModalEmphasizedText,
} from "../Modal"
import ErrorIcon from "../../assets/ErrorIcon"
import { loginErrors as text } from "../../locales/default.json"
import { useTracker } from "../../analytics"
import { Heading, Text } from "../shared/Typography"

const getHeadingLineTag = (query, idx, text) => {
  if (
    (query === `invitation-error` && idx === 0) ||
    (query === `invitation-mismatch` && idx === 1)
  ) {
    return <strong>{text}</strong>
  }

  return text
}

const ErrorTemplate = ({ query, locales, closeModal }) => {
  const { heading, message, label } = locales

  return (
    <Fragment>
      <ModalPrimaryHeader>
        <ModalPrimaryIcon>
          <ErrorIcon />
        </ModalPrimaryIcon>
        {heading &&
          heading.map((item, idx) => (
            <Heading key={idx}>{getHeadingLineTag(query, idx, item)}</Heading>
          ))}
      </ModalPrimaryHeader>
      <ModalBody>
        <ModalMessage>
          <ModalEmphasizedText>{message[0]}</ModalEmphasizedText>
          <Text>{message[1]}</Text>
        </ModalMessage>
        <ModalAction>
          <ConfirmButton onClick={closeModal}>
            {label}
            {` `}
            {query === `invitation-mismatch` ? <MdArrowForward /> : <MdCheck />}
          </ConfirmButton>
        </ModalAction>
      </ModalBody>
    </Fragment>
  )
}

ErrorTemplate.propTypes = {
  query: PropTypes.string,
  locales: PropTypes.object,
  closeModal: PropTypes.func,
}

const InvitationTokenError = ({ closeModal }) => (
  <ErrorTemplate
    query={`invitation-error`}
    locales={text[`invitationError`]}
    closeModal={() => {
      closeModal()
      navigate(`/dashboard/login/`)
    }}
  />
)

const InvitationRequired = () => (
  <ErrorTemplate
    query={`invitation-required`}
    locales={text[`invitationRequired`]}
    closeModal={() => navigate(`/preview/`)}
  />
)

InvitationTokenError.propTypes = {
  closeModal: PropTypes.func,
}

const InvitationMismatchError = () => (
  <ErrorTemplate
    query={`invitation-mismatch`}
    locales={text[`invitationMismatch`]}
    closeModal={() => navigate(`/preview/`)}
  />
)

const InvitationAcceptedError = ({ closeModal }) => (
  <ErrorTemplate
    query={`invitation_accepted`}
    locales={text[`invitationAccepted`]}
    closeModal={() => {
      closeModal()
      navigate(`/dashboard/login/`)
    }}
  />
)

const InvitationExpiredError = ({ closeModal }) => (
  <ErrorTemplate
    query={`invitation_expired`}
    locales={text[`invitationExpired`]}
    closeModal={() => {
      closeModal()
      navigate(`/dashboard/login/`)
    }}
  />
)

const PreviewAccessError = ({ closeModal }) => (
  <ErrorTemplate
    query={`preview-access`}
    locales={text[`previewAccess`]}
    closeModal={() => {
      closeModal()
    }}
  />
)

const UserBlocked = ({ closeModal }) => (
  <ErrorTemplate
    query={`blocked`}
    locales={text[`blocked`]}
    closeModal={() => {
      closeModal()
      navigate(`/dashboard/login/`)
    }}
  />
)

const OAuthError = ({ closeModal }) => (
  <ErrorTemplate
    query={`oauth-error`}
    locales={text[`oauthError`]}
    closeModal={() => {
      closeModal()
      navigate(`/dashboard/login/`)
    }}
  />
)

OAuthError.propTypes = {
  closeModal: PropTypes.func,
}

const ErrorMessage = props => {
  const { trackPageViewed } = useTracker()

  useEffect(() => {
    const { queryString, showModal } = props
    const query = qs.parse(queryString)
    let Component
    switch (query.error) {
      case `invitation-error`:
        Component = InvitationTokenError
        break
      case `invitation-mismatch`:
        Component = InvitationMismatchError
        break
      case `oauth-error`:
        Component = OAuthError
        break
      case `invitation-required`:
        Component = InvitationRequired
        break
      case `blocked`:
        Component = UserBlocked
        break
      case `invitation_expired`:
        Component = InvitationExpiredError
        break
      case `invitation_accepted`:
        Component = InvitationAcceptedError
        break
      case `preview-access`:
        Component = PreviewAccessError
        break
      default:
        Component = null
    }

    if (Component) {
      trackPageViewed(`Login error: ${query.error}`)
      showModal({
        Component,
      })
    }
  }, [])

  return null
}

ErrorMessage.propTypes = {
  queryString: PropTypes.string,
  showModal: PropTypes.func,
}

const LoginErrors = props => {
  const { showModal } = useContext(ModalContext)

  return (
    <Location>
      {({ location }) => (
        <ErrorMessage showModal={showModal} queryString={location.search} />
      )}
    </Location>
  )
}

export default LoginErrors
