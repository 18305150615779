import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import gql from "graphql-tag"
import { Mutation } from "react-apollo"
import { Redirect, navigate } from "@reach/router"
import Loading from "../components/shared/Loading"
import ErrorModal from "./shared/ErrorModal"
import { ModalContext } from "./Modal/Modal"
import { recordFirstTimeVisitor } from "../utils/helpers/fullstory"

const ACCEPT_INVITATION = gql`
  mutation acceptInvitation($invitationId: UUID!) {
    acceptInvitation(invitationId: $invitationId) {
      success
      message
    }
  }
`

function InvitationRedirect({ mutate, invitationId }) {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const { showModal } = useContext(ModalContext)

  function showError({ message }) {
    setError(true)
    return showModal({
      Component: ErrorModal,
      props: {
        errMsg: message,
        onClose: () => navigate(`/dashboard/sites`),
      },
    })
  }

  function acceptInvite() {
    mutate({
      variables: {
        invitationId,
      },
    })
      .then(({ data }) => {
        const resultData = data.acceptInvitation

        if (resultData.success) {
          return setSuccess(true)
        }

        return showError({ message: resultData && resultData.message })
      })
      .catch(e => showError({ message: e.message }))
  }

  useEffect(() => acceptInvite(), [])

  if (error) {
    return null
  }

  if (success) {
    recordFirstTimeVisitor()
    return <Redirect to="/dashboard/sites" noThrow />
  }

  return <Loading delay={1000} message="Accepting Invitation ..." />
}

InvitationRedirect.propTypes = {
  invitationId: PropTypes.string.isRequired,
  mutate: PropTypes.func,
}

export default function RedirectWrapped(props) {
  return (
    <Mutation mutation={ACCEPT_INVITATION}>
      {mutate => <InvitationRedirect mutate={mutate} {...props} />}
    </Mutation>
  )
}
