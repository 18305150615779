import React, { Fragment } from "react"
import styled from "react-emotion"
import PropTypes from "prop-types"

import { palette } from "../../../utils/presets"
import { Text } from "../Typography"
import { envVars as text } from "../../../locales/default.json"
import { Table, TableBody, TableRow, TableHeader } from "../Table"

// typography.js strikes again (re: the overrides in this file)

const Key = styled(`td`)`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 30%;
`

const Value = styled(`td`)`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70%;
`

const NoEnvVarsText = styled(Text)`
  color: ${palette.grey[500]};
  margin: 0;
`

const EnvVarsData = ({ envVars, origin }) =>
  envVars.length > 0 ? (
    <Table>
      <TableBody>
        <Fragment>
          <TableRow>
            <TableHeader>Key</TableHeader>
            <TableHeader>Value</TableHeader>
          </TableRow>
          {envVars.map(({ key, truncatedValue }) => (
            <TableRow key={key}>
              <Key data-cy={`env-var-key-` + key}>{key}</Key>
              <Value data-cy={`env-var-value-` + key}>{truncatedValue}</Value>
            </TableRow>
          ))}
        </Fragment>
      </TableBody>
    </Table>
  ) : (
    origin === `settings` && (
      <NoEnvVarsText data-cy="no-env-vars-message">
        {text.noEnvVars}
      </NoEnvVarsText>
    )
  )

EnvVarsData.propTypes = {
  envVars: PropTypes.array.isRequired,
  origin: PropTypes.string,
}

export default EnvVarsData
