import React, { Fragment } from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"

import { Text } from "../../shared/Typography"

const FeatureText = styled(Text)`
  margin: 0;
`

const Basic = styled(`span`)`
  font-weight: normal;
`

const Features = ({ feature }) => (
  <FeatureText>
    {feature.map((word, idx) =>
      !isNaN(word) || word === `Unlimited` || idx === 0 || word === `to` ? (
        <Fragment key={idx}>
          {word}
          {` `}
        </Fragment>
      ) : (
        <Basic key={idx}>
          {word}
          {` `}
        </Basic>
      )
    )}
  </FeatureText>
)

Features.propTypes = {
  feature: PropTypes.array,
}

export default Features
