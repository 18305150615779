import React, { useEffect, Fragment } from "react"
import { PropTypes } from "prop-types"
import { compose, graphql } from "react-apollo"
import styled from "react-emotion"
import { navigate } from "gatsby"

import { dimensions, palette, spaces, breakpoints } from "../../utils/presets"
import Loading from "../shared/Loading"
import { EmphaticHeading } from "../shared/Typography"
import { billing as text } from "../../locales/default.json"
import { ModalConsumer } from "../Modal"
import PaymentDots from "../../assets/PaymentDots"
import { ORGANIZATION_DETAILS, AVAILABLE_PLANS } from "./queries"
import { useTracker } from "../../analytics"
import Subscribe from "./Subscribe"
import Resubscribe from "./Resubscribe"
import { NarrowSingleColumn } from "../shared/Containers"
import Cards from "./Cards"
import { withState } from "../../utils/container-life/withState"
import { setOrgEnvVars } from "../../utils/helpers/fullstory"

const BillingRoot = styled(`div`)`
  bottom: 0;
  min-height: calc(100vh - ${dimensions.siteHeader.height});
  position: relative;
`

const Title = styled(EmphaticHeading)`
  text-align: center;

  @media (min-width: ${breakpoints.desktop}px) {
    padding: ${spaces.l} 0;
  }
`

const Organization = styled(`span`)`
  color: ${palette.purple[500]};
`

const Dots = styled(`div`)`
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;

  @media (min-width: ${breakpoints.desktop}px) {
    display: block;
  }
`

const Content = styled(NarrowSingleColumn)`
  position: relative;
  margin: 0 auto;
  max-width: 1024px;
  padding-bottom: ${spaces.m};
  @media (min-width: ${breakpoints.desktop}px) {
    padding-bottom: ${spaces[`4xl`]};
  }
`

let BillingForm = function BillingForm({
  data,
  interval,
  setInterval,
  billing,
  organizationId,
  organizationName,
}) {
  const { availableSubscriptionPlans, error, loading } = data

  if (error) {
    return <p>{error}</p>
  }

  if (loading && !data.availableSubscriptionPlans) {
    return <Loading delay={1000} message="loading plan & payment details..." />
  }

  // Hardcoded since we only have one plan for beta, will change after
  const plan = availableSubscriptionPlans[0]

  return (
    <ModalConsumer>
      {({ showModal }) => (
        <Fragment>
          <Cards
            availableSubscriptionPlans={availableSubscriptionPlans}
            planInterval={interval}
            setInterval={val => setInterval(val)}
          />
          {billing && billing.creditCard ? (
            <Resubscribe
              billing={billing}
              organizationId={organizationId}
              name={organizationName}
              plan={plan}
            />
          ) : (
            <Subscribe
              availableSubscriptionPlans={availableSubscriptionPlans}
              organizationId={organizationId}
              showModal={showModal}
              plan={plan}
              name={organizationName}
            />
          )}
        </Fragment>
      )}
    </ModalConsumer>
  )
}

BillingForm = compose(
  withState(`interval`, `ANNUAL`),
  graphql(AVAILABLE_PLANS, {
    options: props => {
      return {
        variables: {
          organizationId: props.organizationId,
          interval: props.interval,
        },
      }
    },
  })
)(BillingForm)

const Billing = ({ organizationId, organizationDetailsQuery }) => {
  const { trackPageViewed } = useTracker()

  useEffect(() => {
    trackPageViewed(`Add Payment Information`)
  }, [])

  if (organizationDetailsQuery.error) {
    navigate(`/dashboard/organization/sites/${organizationId}`)
    return null
  }

  if (organizationDetailsQuery.loading) {
    return <Loading delay={1000} message="loading organization details.." />
  }

  const {
    name,
    billing,
    permissions: {
      role,
      billing: { create },
    },
  } = organizationDetailsQuery.organizationDetails

  if (!create) {
    navigate(`/dashboard/organization/sites/${organizationId}`)
    return null
  }

  if (![`CANCELED`, `TRIALING`, `UNPAID`].includes(billing && billing.status)) {
    navigate(`/dashboard/organization/sites/${organizationId}`)
    return null
  }

  if (!organizationId) {
    return null
  }

  // Call env vars API from fullstory
  setOrgEnvVars(
    name,
    billing.plan.name,
    billing.status,
    billing.trialDaysRemaining,
    role
  )

  return (
    <BillingRoot>
      <NarrowSingleColumn>
        <Title data-cy="emphatic-heading">
          {billing && billing.creditCard
            ? `${text.resubscribeHeader}`
            : `${text.subscribeHeader}`}
          {` `}
          <Organization>{name}</Organization>
        </Title>
      </NarrowSingleColumn>
      <Dots>
        <PaymentDots />
      </Dots>
      <Content>
        <BillingForm
          billing={billing}
          organizationId={organizationId}
          organizationName={name}
        />
      </Content>
    </BillingRoot>
  )
}

Billing.propTypes = {
  organizationId: PropTypes.string,
  interval: PropTypes.string,
}

export default graphql(ORGANIZATION_DETAILS, {
  name: `organizationDetailsQuery`,
  options: props => {
    return {
      name: `organizationDetails`,
      variables: { id: props.organizationId },
    }
  },
})(Billing)
