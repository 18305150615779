import React from "react"
import styled, { keyframes } from "react-emotion"
import { Heading, Text } from "./Typography"
import { PrimaryButton } from "./Buttons"
import { MdAdd } from "react-icons/md"
import DatoCMS from "../../assets/DatoCMS"
import { useTracker } from "../../analytics/index.js"
import NoSites from "../../assets/NoSites"
import ContentfulLogo from "../../assets/ContentfulLogo"
import Sanity from "../../assets/Sanity"
import GitHub from "../../assets/GitHub"
import { StandardSingleColumn } from "./Containers"
import {
  breakpoints,
  colors,
  dimensions,
  fontSizes,
  palette,
  spaces,
} from "../../utils/presets"
import { sites as text } from "../../locales/default.json"
import { recordCreateSite } from "../../utils/helpers/fullstory"

const fadeIn = keyframes`
   to {
     opacity: 1;
   }
   from {
     opacity: 0;
   }
 `

export const ZeroStateRoot = styled(StandardSingleColumn)`
  align-items: center;
  animation: ${fadeIn} 0.2s 0.2s ease-in forwards;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - ${dimensions.siteHeader.height});
  opacity: 0;
  padding: 0 ${spaces.l};

  &.inTab {
    min-height: calc(100vh - ${dimensions.siteHeader.height} - 11rem);
  }
`

export const ZeroStatePicture = styled(NoSites)`
  max-width: 70%;
`

export const ZeroStateHeader = styled(Heading)`
  color: ${colors.gatsby};
  font-size: ${fontSizes.l};
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
  max-width: 14rem;

  @media (min-width: ${breakpoints.phablet}px) {
    max-width: none;
  }
`

const AddIcon = styled(MdAdd)`
  margin: auto auto auto 12px;
  margin-left: 12px;
`

export const ZeroStateText = styled(Text)`
  color: ${palette.grey[600]};
  font-size: ${fontSizes.xs};
  margin-bottom: 0.75rem;
  max-width: 19rem;
  text-align: center;
`

const IntegrationContainer = styled(`div`)`
  display: flex;
  justify-content: center;

  > * {
    margin: 0 ${spaces.xs};
  }
`

export const Label = styled(`span`)`
  display: inline;

  &.desktop {
    display: none;
  }

  @media (min-width: ${breakpoints.mobile}px) {
    display: none;

    &.desktop {
      display: inline;
    }
  }
`

const CreateSiteButton = styled(PrimaryButton)`
  font-size: ${fontSizes.l};
  padding-right: 1rem;

  @media (min-width: ${breakpoints.phablet}px) {
    font-size: ${fontSizes.xl};
  }
`

export const ZeroSites = ({ pagePosition = `inTab` }) => {
  const { trackButtonClicked } = useTracker()
  return (
    <ZeroStateRoot className={pagePosition === `inTab` ? `inTab` : ``}>
      <ZeroStatePicture />
      <ZeroStateHeader>{text.zeroSitesHeader}</ZeroStateHeader>
      <CreateSiteButton
        size={`XL`}
        to={`/dashboard/sites/create`}
        onClick={() => {
          recordCreateSite()
          trackButtonClicked(text.createSiteButton)
        }}
        data-cy="create-site-button"
      >
        <Label>{text.createSiteShortButton}</Label>
        <Label className={`desktop`}>{text.createSiteButton}</Label>
        <AddIcon />
      </CreateSiteButton>
      <ZeroStateText>{text.zeroSitesText}</ZeroStateText>
      <IntegrationContainer>
        <ContentfulLogo />
        <Sanity />
        <DatoCMS width={18} />
        <GitHub />
      </IntegrationContainer>
    </ZeroStateRoot>
  )
}
