import React from "react"
import PropTypes from "prop-types"
import { MdDeleteForever, MdMailOutline } from "react-icons/md"
import { Menu, MenuList, MenuItem } from "@reach/menu-button"
import MenuButton from "./MenuButton"
import {
  ReinviteContributorModal,
  RevokeContributorInviteModal,
} from "../Modals"
import { manageContributors as text } from "../../../../locales/default.json"

const InviteeDropdown = ({
  contributor,
  showModal,
  resourceType,
  showToast,
  resourceId,
}) => (
  <Menu>
    <MenuButton
      aria-label={`${text.manageContributor} ${contributor.name}`}
      data-testid="invitee-button"
    />
    <MenuList className="manageInvitee" data-testid="invitee-list">
      <MenuItem
        onSelect={() => {
          showModal({
            Component: ReinviteContributorModal,
            props: {
              mode: `retake`,
              contributorId: contributor.id,
              email: contributor.email,
              resourceType,
              showToast,
            },
          })
        }}
      >
        <MdMailOutline />
        {text.resendInvite}
      </MenuItem>
      <MenuItem
        className="warn"
        onSelect={() => {
          showModal({
            Component: RevokeContributorInviteModal,
            props: {
              mode: `warning`,
              resourceId,
              contributorId: contributor.id,
              email: contributor.email,
              resourceType,
              showToast,
            },
          })
        }}
      >
        <MdDeleteForever />
        {text.revokeInvite}
      </MenuItem>
    </MenuList>
  </Menu>
)

InviteeDropdown.propTypes = {
  contributor: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  resourceType: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
}

export default InviteeDropdown
