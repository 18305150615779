import React from "react"
import styled from "react-emotion"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import { MdArrowForward } from "react-icons/md"
import { breakpoints, palette, spaces, fontSizes } from "../../../utils/presets"
import { Text } from "../Typography"

export const notificationModes = {
  default: {
    brdCol: palette.purple[600],
    msgCol: palette.grey[900],
    bgCol: palette.purple[50],
  },
  success: {
    brdCol: palette.green[500],
    msgCol: palette.green[500],
    bgCol: palette.green[50],
  },
  warning: {
    brdCol: palette.yellow[400],
    msgCol: palette.grey[900],
    bgCol: palette.yellow[50],
  },
  error: {
    brdCol: palette.red[600],
    msgCol: palette.red[700],
    bgCol: palette.red[50],
  },
}

export const Message = styled(`div`)`
  align-items: center;
  display: flex;

  ${Text} {
    font-size: ${fontSizes.xs};
    margin: 0;
    font-weight: bold;
    color: ${props => notificationModes[props.mode].msgCol};

    span {
      font-weight: normal;
    }
  }

  svg {
    margin-right: ${spaces.s};
    fill: ${props => notificationModes[props.mode].brdCol};
  }
`

export const ActionLink = styled(Link)`
  align-items: center;
  brdcol: ${palette.purple[500]};
  display: flex;
  font-size: ${fontSizes.xs};
  text-decoration: none;
  align-self: flex-end;

  svg {
    fill: ${palette.purple[500]};
    margin-left: ${spaces[`2xs`]};
  }
`

const NotificationRoot = styled(`div`)`
  align-items: stretch;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: row;
    align-items: center;
  }
`

const Notification = props => {
  const {
    icon: Icon,
    mode = `default`,
    message,
    showLink = true,
    linkTo,
    linkOnClick,
    linkLabel,
  } = props

  return (
    <NotificationRoot mode={mode}>
      <Message mode={mode} data-cy="notification-message">
        {Icon && <Icon />}
        <Text dangerouslySetInnerHTML={{ __html: message }} />
      </Message>
      {showLink && linkLabel && (
        <ActionLink
          to={linkTo}
          onClick={linkOnClick}
          data-cy="notification-message-link"
        >
          {linkLabel} <MdArrowForward />
        </ActionLink>
      )}
    </NotificationRoot>
  )
}

Notification.propTypes = {
  mode: PropTypes.oneOf([`default`, `success`, `warning`, `error`]),
  message: PropTypes.any.isRequired,
  icon: PropTypes.func,
  showLink: PropTypes.bool,
  linkLabel: PropTypes.string,
  linkTo: PropTypes.string,
  linkOnClick: PropTypes.func,
  className: PropTypes.string,
}

export default Notification
