import React from "react"
import { DialogOverlay } from "@reach/dialog"
import styled, { keyframes } from "react-emotion"
import { breakpoints } from "../../utils/presets"

const getBackgroundForMode = mode => {
  switch (mode) {
    case `action`:
    case `panel`:
    case `retake`:
      return `rgba(102, 51, 153, 0.75)`
    case `success`:
      return `rgba(55, 182, 53, 0.75)`
    case `error`:
      return `rgba(184, 0, 0, 0.75)`
    default:
      return `rgba(184, 0, 0, 0.75)`
  }
}

const overlayEntry = keyframes`
  100% {
     opacity: 1;
  }
`

const ModalOverlay = ({ isOpen, mode = `error`, onDismiss, children }) => {
  const ModalOverlayRoot = styled(DialogOverlay)`
    align-items: center;
    animation: ${overlayEntry} 0.75s ease forwards;
    background: ${getBackgroundForMode(mode)};
    display: flex;
    justify-content: ${mode === `panel` ? `flex-end` : `center`};
    left: 0;
    min-height: 100vh;
    opacity: 0;
    right: 0;
    top: 0;
    z-index: 10000;

    @media (min-width: ${breakpoints.desktop}px) and (min-height: 30rem) {
      position: fixed;
    }
  `

  return (
    <ModalOverlayRoot
      isOpen={isOpen}
      className={mode}
      onDismiss={onDismiss}
      data-testid="modal-overlay"
    >
      {children}
    </ModalOverlayRoot>
  )
}

export default ModalOverlay
