import styled from "react-emotion"
import { palette, spaces } from "../../utils/presets"

export const Card = styled(`div`)`
  background: ${palette.white};
  /* Elevated */
  box-shadow: 0px 1px 2px rgba(46, 41, 51, 0.08),
    0px 2px 4px rgba(71, 63, 79, 0.08);
  border-radius: ${spaces[`2xs`]};
  padding: ${spaces.m};
`
