import React from "react"
import { Link } from "gatsby"
import styled from "react-emotion"

import { EmphaticHeading, Subheading, Text } from "../shared/Typography"
import { fontSizes, palette, spaces } from "../../utils/presets"
import { isAuthenticated } from "../../auth"
import { notFound as text } from "../../locales/default.json"

const NotFoundRoot = styled(`div`)`
  margin: 10vw;
  padding: 0 ${spaces.xl};
`

const Subtitle = styled(Subheading)`
  font-size: ${fontSizes[`3xl`]};
  font-weight: 100;
  margin: 0 0 ${spaces.xl} 0;
  max-width: 420px;
`

const Content = styled(Text)`
  padding-bottom: ${spaces.m};
`

const HomeLink = styled(Link)`
  text-decoration: none;
`

const Mail = styled(`a`)`
  color: ${palette.blue[400]};
  text-decoration: none;
`

const NotFound = () => (
  <NotFoundRoot>
    <EmphaticHeading>{text.notFound}</EmphaticHeading>
    <Subtitle>
      <em>{text.sorry}</em> 😔{text.notFind}
    </Subtitle>
    <Content>
      <strong>{text.needHelp}</strong>
      <br />
      {text.email}
      {` `}
      <Mail href="mailto:sales@gatsbyjs.com">support@gatsbyjs.com</Mail>
      {` `}
      {text.help}
    </Content>
    <HomeLink to={isAuthenticated() ? `/dashboard/sites` : `/dashboard/login`}>
      {text.backToHomepage}
    </HomeLink>
  </NotFoundRoot>
)

export default NotFound
