import React, { Fragment } from "react"
import gql from "graphql-tag"
import { graphql, compose, withApollo } from "react-apollo"
import { format } from "date-fns"
import styled, { keyframes } from "react-emotion"

import {
  MdRefresh,
  MdCheck,
  MdDoNotDisturb,
  MdTimer,
  MdDateRange,
} from "react-icons/md"
import { GoGitBranch } from "react-icons/go"

import { StandardSingleColumn, NarrowSingleColumn } from "./shared/Containers"
import BaseLogs from "./SiteDetails/Overview/Logs"
import Badge from "./shared/Badge"
import Loading from "./shared/Loading"
import {
  breakpoints,
  fontSizes,
  fontFamilies,
  palette,
  radius,
  spaces,
} from "../utils/presets"
import {
  EmphaticHeading as BaseEmphaticHeading,
  Text as BaseText,
} from "./shared/Typography"
import { ViewUrl as BaseViewUrl } from "./SiteDetails/Overview/BuildDisplay"

const buildByIdQuery = gql`
  query buildById($buildId: UUID!) {
    buildById(buildId: $buildId) {
      id
      siteId
      buildStatus
      branch
      siteName
      createdAt
      endedAt
    }
  }
`

const BUILD_STATUS_LABELS = {
  SUCCESS: { text: `completed`, mode: `green`, icon: MdCheck },
  ERROR: { text: `failed`, mode: `red`, icon: MdDoNotDisturb },
  TIMED_OUT: { text: `timed out`, mode: `red`, icon: MdTimer },
  BUILDING: { text: `in progress`, mode: `purple`, icon: MdRefresh },
}

const TopWrapper = styled(NarrowSingleColumn)`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: ${spaces[`4xl`]}};
`

const Heading = styled(`header`)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${spaces.l};

  @media (min-width: ${breakpoints.desktop}px) {
    flex-direction: row;
    align-items: flex-end;
  }
`

const EmphaticHeading = styled(BaseEmphaticHeading)`
  font-size: ${fontSizes[`2xl`]};
  letter-spacing: -0.02em;

  span {
    display: block;
    font-weight: normal;
    font-size: ${fontSizes.xl};
    margin-bottom: ${spaces[`2xs`]};
  }

  @media (min-width: ${breakpoints.desktop}px) {
    font-size: ${fontSizes[`3xl`]};
  }
`

const rotating = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const BuildStatus = styled(Badge)`
  align-items: center;
  display: flex;
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${fontSizes.l};
  margin-left: ${spaces.s};
  margin-top: ${spaces.s};
  text-transform: uppercase;

  svg {
    animation: ${props =>
      props.mode === `purple` ? `${rotating} 1s linear infinite` : ``};
    margin-left: ${spaces.xs};
    transform: rotate(0);
  }

  @media (min-width: ${breakpoints.desktop}px) {
    margin-top: 0;
  }
`

const Text = styled(BaseText)`
  align-items: center;
  display: flex;
  font-weight: bold;
  margin: 0;

  span {
    font-weight: normal;
    margin-right: ${spaces.xs};

    svg {
      color: ${palette.purple[500]};
      vertical-align: text-top;
      margin-right: ${spaces[`2xs`]};
    }
  }
`

const ViewUrl = styled(BaseViewUrl)`
  font-size: ${fontSizes.l};
  margin-bottom: ${spaces.m};
`

const LogsWrapper = styled(StandardSingleColumn)`
  padding: ${spaces[`l`]};

  @media (min-width: ${breakpoints.desktop}px) {
    padding: ${spaces[`l`]} ${spaces[`2xl`]};
  }
`

const Logs = styled(BaseLogs)`
  height: auto;
  min-height: ${spaces[`5xl`]};

  & > div {
    border-radius: 0 0 ${radius.default} ${radius.default};
  }
  header {
    background: ${palette.grey[900]};
    border-radius: ${radius.default} ${radius.default} 0 0;
    border-bottom: 1px solid ${palette.grey[700]};
    color: ${palette.grey[50]};
    font-size: ${fontSizes.m};
    padding: ${spaces.s} ${spaces.l};
  }
`

function BuildLogs({ client, siteId, buildId, build, loading }) {
  const statusText = build ? BUILD_STATUS_LABELS[build.buildStatus].text : ``
  const statusMode = build ? BUILD_STATUS_LABELS[build.buildStatus].mode : ``
  const StatusIcon = build ? BUILD_STATUS_LABELS[build.buildStatus].icon : null

  return build ? (
    <Fragment>
      <TopWrapper>
        <Heading>
          <EmphaticHeading>
            <span>Build for</span> {build.siteName}
            {` `}
          </EmphaticHeading>
          <BuildStatus mode={statusMode}>
            {statusText} <StatusIcon />
          </BuildStatus>
        </Heading>
        {build.buildStatus === `SUCCESS` && <ViewUrl buildId={buildId} />}
        <Text>
          <span>
            <GoGitBranch /> branch:
          </span>

          {build.branch}
        </Text>
        {[`SUCCESS`, `ERROR`].includes(build.buildStatus) ? (
          <Text>
            <span>
              <MdDateRange />
              Ended:
            </span>
            {` `}
            {format(new Date(build.endedAt), `MM/dd/yyyy - HH:mm:ss aa`)}
          </Text>
        ) : (
          <Text>
            <span>
              <MdDateRange />
              Started:
            </span>
            {` `}
            {format(new Date(build.createdAt), `MM/dd/yyyy - HH:mm:ss aa`)}
          </Text>
        )}
      </TopWrapper>
      <LogsWrapper>
        <Logs
          header="Build logs"
          client={client}
          siteId={build.siteId}
          id={buildId}
        />
      </LogsWrapper>
    </Fragment>
  ) : (
    <Loading delay={1000} />
  )
}

export default compose(
  withApollo,
  graphql(buildByIdQuery, {
    options: ({ buildId }) => {
      return {
        variables: {
          buildId,
        },
      }
    },
    props: ({ data, ...rest }) => {
      return {
        loading: data.loading,
        error: data.error,
        build: data && data.buildById,
        ...rest,
      }
    },
  })
)(BuildLogs)
