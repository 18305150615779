import React from "react"
import styled from "react-emotion"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { MdArrowBack } from "react-icons/md"
import { Heading } from "../shared/Typography"
import {
  palette,
  spaces,
  colors,
  breakpoints,
  fontSizes,
} from "../../utils/presets"

const BackNavigationRoot = styled(`li`)`
  align-items: center;
  display: flex;
  padding: 0 ${spaces.l} 0 0;
  margin: ${spaces.l} 0 ${spaces.m} ${spaces[`2xs`]};
  justify-content: center;

  @media (min-width: ${breakpoints.tablet}px) {
    margin: 0;
  }
`

const BackNavigationIcon = styled(MdArrowBack)`
  fill: ${colors.lilac};
`

const BackNavigationTitle = styled(Heading)`
  color: ${palette.black};
  font-size: ${fontSizes.xl};
  padding: 0 0 0 ${spaces.xs};

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 0 0 0 1.25rem;
  }
`

const BackNavigationLink = styled(Link)`
  display: flex;
  align-items: center;
`

const BackNavigation = ({ title, to }) => (
  <BackNavigationRoot>
    <BackNavigationLink to={to} aria-label={`Back to previous page`}>
      <BackNavigationIcon />
    </BackNavigationLink>
    <BackNavigationTitle data-cy="back-navigation-title">
      {title}
    </BackNavigationTitle>
  </BackNavigationRoot>
)

BackNavigation.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
}

export default BackNavigation
