import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import qs from "query-string"

import { MdArrowForward } from "react-icons/md"
import { isAuthenticated, login } from "../../auth"
import { Heading, SignInButton, Layout } from "./Layout"
import { login as text } from "../../locales/default.json"
import { useTracker } from "../../analytics"

const Login = ({ location }) => {
  const { trackPageViewed, trackButtonClicked } = useTracker()

  useEffect(() => {
    const query = qs.parse(location.search) || {}

    const { invitationId, type } = query

    if (invitationId) {
      trackPageViewed(`Invitation Log In Page`)
    } else {
      trackPageViewed(`Log In Page`)
    }

    if (isAuthenticated()) {
      if (invitationId && type) {
        navigate(`/dashboard/invitations/${invitationId}`)
        return () => {}
      }

      navigate(`/dashboard/sites`)

      return () => {}
    }
  }, [])

  return (
    <Layout>
      <Heading>
        <h3>{text.welcome}</h3>
        <h1>
          <span>{text.gatsby}</span>
          <span>{text.preview}</span>
        </h1>
      </Heading>
      <SignInButton
        size={`XL`}
        onClick={() => {
          trackButtonClicked(text.signInButton, {
            uiSource: `Dashboard Login`,
          })
          login(location.search)
        }}
        data-cy="sign-in-button"
      >
        {text.signInButton} <MdArrowForward />
      </SignInButton>
    </Layout>
  )
}

Login.propTypes = {
  location: PropTypes.object,
}

export default Login
