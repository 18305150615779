import React, { useEffect } from "react"
import gql from "graphql-tag"
import { ApolloConsumer } from "react-apollo"
import styled from "react-emotion"

import { MdErrorOutline, MdArrowForward } from "react-icons/md"

import { Heading, Text } from "../../shared/Typography"
import { siteDetails as text } from "../../../locales/default.json"

import BuildDisplay from "./BuildDisplay"
import { installGithubApplication } from "../../../auth"
import {
  breakpoints,
  fontSizes,
  palette,
  spaces,
  dimensions,
} from "../../../utils/presets"
import { PrimaryButton } from "../../shared/Buttons"
import ZeroBuilds from "./ZeroBuilds"
import useSubscription from "../../../graphql/subscribe"

export const BUILDS_QUERY = gql`
  query buildsForSite($siteId: UUID!, $pagination: CursorPagination) {
    buildsForSite(siteId: $siteId, pagination: $pagination) {
      builds {
        id
        siteId
        buildType
        buildStatus
        createdAt
        endedAt
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

const BuildList = styled(`div`)`
  flex-grow: 1;
  ${Heading} {
    font-size: ${fontSizes.l};
    padding: ${spaces.l};
    color: ${palette.grey[600]};
    position: relative;
    @media (min-width: ${breakpoints.desktop}px) {
      padding: ${spaces.l} 0 ${spaces.l}
        calc(${dimensions.buildList.indentation} + ${spaces.l});
      :before {
        content: "";
        width: 1px;
        height: 100%;
        background: ${palette.grey[300]};
        position: absolute;
        top: 0;
        left: calc(${dimensions.buildList.indentation} / 2);
      }
    }
  }
`

const UnauthorizedNote = styled(`div`)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spaces.xl} 0;
  width: 100%;
  ${Text} {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: ${spaces.m} 0;
    text-align: center;
    svg {
      color: ${palette.purple[500]};
      height: 3rem;
      margin-bottom: ${spaces.m};
      width: 3rem;
    }
  }
`

const BUILD_PAGE_LIMIT = 10

const buildCreatedSubscription = gql`
  subscription buildCreated($id: UUID!) {
    buildCreated(id: $id) {
      id
      siteId
      buildType
      buildStatus
      createdAt
      endedAt
    }
  }
`

function BuildListView({
  client,
  siteId,
  lastBuild,
  buildsEnabled,
  builds,
  prevBuilds,
  organizationId,
  sitePreviewStatus,
}) {
  useSubscription(client, buildCreatedSubscription, {
    options: {
      variables: {
        id: siteId,
      },
    },
    deps: [siteId],
    onResult: ({ data: { buildCreated } }) => {
      // check to see if we have this build in cache already

      const buildsForSiteQuery = client.readQuery({
        query: BUILDS_QUERY,
        variables: {
          siteId,
          pagination: {
            limit: BUILD_PAGE_LIMIT,
          },
        },
      })

      const builds = buildsForSiteQuery.buildsForSite.builds || []

      const hasBuild = builds.find(({ id }) => id === buildCreated.id)

      if (!hasBuild) {
        buildsForSiteQuery.buildsForSite.builds = [buildCreated, ...builds]

        client.writeQuery({
          query: BUILDS_QUERY,
          variables: {
            siteId,
            pagination: {
              limit: BUILD_PAGE_LIMIT,
            },
          },
          data: buildsForSiteQuery,
        })
      }
    },
  })
  return (
    <BuildList>
      {sitePreviewStatus === `PENDING` && builds.length < 1 && <ZeroBuilds />}
      {lastBuild && (
        <BuildDisplay
          {...builds[0]}
          lastBuild
          organizationId={organizationId}
          buildsEnabled={buildsEnabled}
        />
      )}
      {prevBuilds.length > 0 && <Heading>{text.previousBuilds}</Heading>}
      {prevBuilds.map(build => (
        <BuildDisplay
          {...build}
          key={build.id}
          organizationId={organizationId}
          buildsEnabled={buildsEnabled}
        />
      ))}
    </BuildList>
  )
}

export function BuildListRoot({
  siteId,
  organizationId,
  sitePreviewStatus,
  lastBuild,
  prevBuilds,
  builds,
  fetchMore,
  buildsEnabled,
  pageInfo,
}) {
  function handleScroll() {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    ) {
      return
    }

    if (pageInfo && !pageInfo.hasNextPage) {
      return
    }

    if (pageInfo && !pageInfo.endCursor) {
      return
    }

    return fetchMore({
      variables: {
        siteId,
        pagination: {
          limit: BUILD_PAGE_LIMIT,
          endCursor: pageInfo && pageInfo.endCursor,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult
        }

        if (
          fetchMoreResult.buildsForSite &&
          fetchMoreResult.buildsForSite.builds &&
          fetchMoreResult.buildsForSite.builds.length === 0
        ) {
          return previousResult
        }

        const buildsForSiteResult = [
          ...previousResult.buildsForSite.builds,
          ...fetchMoreResult.buildsForSite.builds,
        ]

        previousResult.buildsForSite.builds = buildsForSiteResult
        previousResult.buildsForSite.pageInfo =
          fetchMoreResult.buildsForSite.pageInfo

        return previousResult
      },
    })
  }

  useEffect(() => {
    window.addEventListener(`scroll`, handleScroll)
    return () => window.removeEventListener(`scroll`, handleScroll)
  }, [pageInfo])

  return sitePreviewStatus === `UNAUTHORIZED` ? (
    <UnauthorizedNote>
      <Text>
        <MdErrorOutline />
        {text.siteHasNotAuthorized}
      </Text>
      <PrimaryButton onClick={() => installGithubApplication({ siteId })}>
        {text.authorize} <MdArrowForward />
      </PrimaryButton>
    </UnauthorizedNote>
  ) : (
    <ApolloConsumer>
      {client => (
        <BuildListView
          buildsEnabled={buildsEnabled}
          organizationId={organizationId}
          siteId={siteId}
          client={client}
          builds={builds}
          lastBuild={lastBuild}
          prevBuilds={prevBuilds}
          sitePreviewStatus={sitePreviewStatus}
        />
      )}
    </ApolloConsumer>
  )
}
