import React, { Fragment, useState, useContext } from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "react-emotion"
import { MdEdit, MdArrowForward } from "react-icons/md"
import { Formik, Field } from "formik"
import { compose, graphql } from "react-apollo"
import gql from "graphql-tag"

import { palette, fontSizes, spaces } from "../../../utils/presets"
import {
  PrimaryButton,
  SecondaryButton,
  CancelButton,
} from "../../shared/Buttons"
import { SettingsCard, Header, Title } from "../../shared/Settings/SettingsCard"
import { Text } from "../../shared/Typography"
import {
  Form as BaseForm,
  Input,
  BigLabel as BaseLabel,
} from "../../shared/Forms"
import { BranchListContainer } from "../../shared/BranchList"
import { ModalContext } from "../../Modal"
import { NoGatsbyError, DefaultError } from "../../shared/ErrorModal"
import { ToastContext } from "../../shared/Toast"
import { previewTarget as text } from "../../../locales/default.json"

const SET_SITE_PREVIEW_TARGETS = gql`
  mutation setSitePreviewTargets(
    $siteId: UUID!
    $directoryPath: String!
    $branch: String!
  ) {
    setSitePreviewTargets(
      siteId: $siteId
      directoryPath: $directoryPath
      branch: $branch
    ) {
      success
      message
      errorType
    }
  }
`

const Content = styled(Text)`
  color: ${palette.grey[500]};
  display: flex;
  font-size: ${fontSizes.xs};
  flex-direction: column;

  svg {
    font-size: ${fontSizes.m};
    margin-right: ${spaces.xs};
    vertical-align: text-bottom;
  }

  strong {
    color: ${palette.purple[600]};
    font-size: ${fontSizes.s};
  }
`

const entry = keyframes`
  100% {
    opacity: 1;
  }
`

const Form = styled(BaseForm)`
  animation: ${entry} 0.75s ease forwards;
  margin: ${spaces.s} 0 0;
  opacity: 0;

  button {
    margin-bottom: ${spaces.s};
  }
`

const Actions = styled(`div`)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${spaces.l};
  width: 100%;
`

const Label = styled(BaseLabel)`
  margin-left: 0;
`

const PreviewTarget = ({
  permissionToEdit,
  sitePreviewDirectory = `/`,
  siteBranch,
  siteId,
}) => {
  const previewDirectory = sitePreviewDirectory || `/`
  const [branch, setBranch] = useState(siteBranch)
  const [formVisible, setFormVisible] = useState(false)
  return (
    <SettingsCard>
      <Header>
        <Title>{text.previewTarget}</Title>
        {!formVisible && permissionToEdit && (
          <SecondaryButton onClick={() => setFormVisible(!formVisible)}>
            {text.editTarget}
            <MdEdit />
          </SecondaryButton>
        )}
      </Header>
      {!formVisible && (
        <Content>
          <span>
            <strong>{text.targetBranch} </strong> {branch}
          </span>
          <span>
            <strong>{text.directoryPath} </strong> {previewDirectory}
          </span>
        </Content>
      )}

      {formVisible && (
        <ConfigurationForm
          siteId={siteId}
          sitePreviewDirectory={previewDirectory}
          siteBranch={branch}
          setBranch={setBranch}
          setFormVisible={() => setFormVisible(false)}
        />
      )}
    </SettingsCard>
  )
}

PreviewTarget.propTypes = {
  permissionToEdit: PropTypes.bool,
  sitePreviewDirectory: PropTypes.string,
  siteBranch: PropTypes.string,
}

export default PreviewTarget

let ConfigurationForm = function ConfigurationForm({
  siteId,
  sitePreviewDirectory,
  setPreviewTargetsMutation,
  siteBranch,
  setBranch,
  setFormVisible,
}) {
  const { showModal } = useContext(ModalContext)
  const { showToast } = useContext(ToastContext)
  return (
    <Formik
      onSubmit={values => {
        setPreviewTargetsMutation({
          variables: {
            siteId,
            branch: siteBranch,
            directoryPath: values.directoryPath,
          },
          update: (proxy, result) => {
            if (result.success) {
              const siteFrag = gql`
                fragment SiteSettings on Site {
                  id
                  previewDirectory
                  branch
                }
              `
              const site = proxy.readFragment({
                fragment: siteFrag,
                id: `Site:${siteId}`,
              })

              site.branch = siteBranch

              site.previewDirectory = values.directoryPath

              proxy.writeFragment({
                fragment: siteFrag,
                id: `Site:${siteId}`,
                data: site,
              })
            }
          },
        })
          .then(data => {
            const {
              data: {
                setSitePreviewTargets: { success },
              },
            } = data
            if (success) {
              return showToast(`<strong>Preview configuration</strong> updated`)
            } else {
              return showModal({
                Component: NoGatsbyError,
              })
            }
          })
          .then(() => setFormVisible(false))
          .catch(err => {
            showModal({
              Component: DefaultError,
              props: { errMsg: err.message },
            })
          })
      }}
      initialValues={{
        directoryPath: sitePreviewDirectory || `/`,
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form>
          <Label htmlFor="branch list">
            <small>Branch:</small>
          </Label>
          <BranchListContainer
            id="branch list"
            siteId={siteId}
            uiSource="Create and setup site"
            siteBranch={siteBranch}
            updateBranchState={branchName => {
              setBranch(branchName)
            }}
          />
          <Field
            name={`directoryPath`}
            type="text"
            aria-label={`directory path`}
          >
            {({ field }) => (
              <Fragment>
                <Label htmlFor="directory path">
                  <small>Directory Path:</small>
                </Label>
                <Input {...field} id="directory path" name="directoryPath" />
              </Fragment>
            )}
          </Field>
          <Actions>
            <CancelButton type={`reset`} onClick={setFormVisible}>
              {text.cancel}
            </CancelButton>
            <PrimaryButton
              type="button"
              onClick={submitForm}
              loading={isSubmitting}
            >
              {text.save} <MdArrowForward />
            </PrimaryButton>
          </Actions>
          {/* <Debug /> */}
        </Form>
      )}
    </Formik>
  )
}

ConfigurationForm = compose(
  graphql(SET_SITE_PREVIEW_TARGETS, {
    name: `setPreviewTargetsMutation`,
  })
)(ConfigurationForm)
