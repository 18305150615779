import React from "react"

export default () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 4H14V12H2V4ZM8 6C9.10667 6 10 6.89333 10 8C10 9.10667 9.10667 10 8 10C6.89333 10 6 9.10667 6 8C6 6.89333 6.89333 6 8 6ZM4.66667 5.33333C4.66667 6.06667 4.06667 6.66667 3.33333 6.66667V9.33333C4.06667 9.33333 4.66667 9.93333 4.66667 10.6667H11.3333C11.3333 9.93333 11.9333 9.33333 12.6667 9.33333V6.66667C11.9333 6.66667 11.3333 6.06667 11.3333 5.33333H4.66667Z"
      fill="#A1DA9E"
    />
  </svg>
)
