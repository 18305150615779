import React from "react"
import styled from "react-emotion"
import { MdHelpOutline } from "react-icons/md"

import { webhookCard as text } from "../../locales/default.json"
import { palette, fontSizes, radius, spaces, colors } from "../../utils/presets"
import Copy from "../../assets/Copy"

import {
  SettingsCard as WebhookCardRoot,
  Header,
  Title as Heading,
  Message,
} from "./Settings/SettingsCard"

const InfoLink = styled(`a`)`
  font-size: ${fontSizes.xs};
  margin-top: ${spaces.l};
`

const WebhookLink = styled(`input`)`
  text-align: center;
  background: ${palette.grey[50]};
  border: 1px solid ${palette.grey[200]};
  border-right: 0;
  border-radius: ${radius.default};
  display: block;
  width: 100%;
  padding: ${spaces.s};
  user-select: all;
  font-size: ${fontSizes.xs};
`

const LinkSection = styled(`div`)`
  display: flex;
  background: ${palette.grey[50]};
  border: 1px solid ${palette.grey[200]};
  border-radius: ${radius.default};
  width: 100%;
`

const WebhookMessage = styled(Message)`
  font-size: ${fontSizes.s};

  &.help-text {
    color: ${palette.grey[900]};
    margin-right: 0;
  }

  ${InfoLink} {
    margin-top: 0;
  }

  span {
    display: block;
    margin-bottom: ${spaces.s};
  }
`

const Title = styled(Heading)`
  align-items: center;
  color: ${palette.grey[800]};
  display: flex;
  font-size: ${fontSizes.l};
  margin-right: ${spaces[`2xl`]};

  svg {
    margin-left: ${spaces.xs};
    fill: ${colors.gatsby};
  }
`

const CopyButton = styled(`button`)`
  background: ${palette.grey[50]};
  border-style: none;

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-top: 8px;
  }
`

const Help = styled(MdHelpOutline)`
  height: 1rem;
  width: 1rem;
`

const WebhookCard = ({ siteId, origin = `setup` }) => (
  <WebhookCardRoot>
    <Header>
      <Title>
        Webhook <Help />
      </Title>

      <WebhookMessage className={origin === `setup` ? `help-text` : ``}>
        {text.sendPostRequest}
      </WebhookMessage>

      <LinkSection>
        <WebhookLink
          readOnly
          value={`${
            process.env.GATSBY_WEBHOOK_URL
          }/hooks/data_source/${siteId}`}
          className="webhook-link"
        />
        <CopyButton
          type="button"
          onClick={() => {
            document.querySelector(`.webhook-link`).select()
            document.execCommand(`copy`)
          }}
        >
          <Copy />
        </CopyButton>
      </LinkSection>
    </Header>
  </WebhookCardRoot>
)

export default WebhookCard
