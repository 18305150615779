import gql from "graphql-tag"
import {
  CREDIT_CARD_FRAGMENT,
  BILLING_PLAN_FRAGMENT,
} from "../Payment/fragments"

export const RESUBSCRIBE_MUTATION = gql`
  mutation resubscribeToPlan($input: SubscriptionResubscribeInput!) {
    resubscribeToPlan(input: $input) {
      errors {
        message
      }
      subscription {
        id
        creditCard {
          ...creditCardFragment
        }
        plan {
          ...billingPlanFragment
        }
        nextBillingDate
      }
    }
  }
  ${BILLING_PLAN_FRAGMENT}
  ${CREDIT_CARD_FRAGMENT}
`
