import React from "react"

export default () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="30" cy="30" r="30" fill="#EC1818" />
    <path
      d="M40.7914 21.6257C41.4589 20.9582 41.4589 19.876 40.7914 19.2086C40.124 18.5411 39.0418 18.5411 38.3743 19.2086L31.4142 26.1686C30.6332 26.9497 29.3668 26.9497 28.5858 26.1686L21.6257 19.2086C20.9582 18.5411 19.876 18.5411 19.2086 19.2086C18.5411 19.876 18.5411 20.9582 19.2086 21.6257L26.1686 28.5858C26.9497 29.3668 26.9497 30.6332 26.1686 31.4142L19.2086 38.3743C18.5411 39.0418 18.5411 40.124 19.2086 40.7914C19.876 41.4589 20.9582 41.4589 21.6257 40.7914L28.5858 33.8314C29.3668 33.0503 30.6332 33.0503 31.4142 33.8314L38.3743 40.7914C39.0418 41.4589 40.124 41.4589 40.7914 40.7914C41.4589 40.124 41.4589 39.0418 40.7914 38.3743L33.8314 31.4142C33.0503 30.6332 33.0503 29.3668 33.8314 28.5858L40.7914 21.6257Z"
      fill="white"
    />
  </svg>
)
