import React from "react"
import { DialogOverlay } from "@reach/dialog"
import styled, { keyframes } from "react-emotion"
import { breakpoints } from "../../utils/presets"

const overlayEntry = keyframes`
  100% {
     opacity: 1;
  }
`

const FullModalOverlay = ({ isOpen, mode = `error`, onDismiss, children }) => {
  const FullModalOverlayRoot = styled(DialogOverlay)`
    align-items: center;
    animation: ${overlayEntry} 0.75s ease forwards;
    background: rgba(102, 51, 153, 0.75);
    display: flex;
    justify-content: ${mode === `panel` ? `flex-end` : `center`};
    left: 0;
    min-height: 100vh;
    opacity: 0;
    right: 0;
    top: 0;
    z-index: 10000;

    @media (min-width: ${breakpoints.desktop}px) and (min-height: 30rem) {
      position: fixed;
    }
  `

  return (
    <FullModalOverlayRoot
      isOpen={isOpen}
      className={mode}
      onDismiss={onDismiss}
      data-testid="modal-overlay"
    >
      {children}
    </FullModalOverlayRoot>
  )
}

export default FullModalOverlay
