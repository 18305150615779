import gql from "graphql-tag"
import { PERMISSIONS_SITE_FRAGMENT } from "../../../permissions/fragments"

export const REPOSITORY_QUERY = gql`
  query repositoryFromSite($siteId: UUID!) {
    repositoryFromSite(siteId: $siteId) {
      id
      nameWithOwner
    }
  }
`

export const PUBLISH_SITE_MUTATION = gql`
  mutation publishSite($id: UUID!, $environmentVariables: [TagInput!]!) {
    publishSite(id: $id, environmentVariables: $environmentVariables) {
      id
    }
  }
`

export const SITE_DETAILS_QUERY = gql`
  query siteDetailsQuery($id: UUID!) {
    siteDetails(id: $id) {
      id
      organizationId
      name
      previewUrl
      branch
      previewStatus
      permissions {
        ...sitePermissionsFragment
      }
    }
  }

  ${PERMISSIONS_SITE_FRAGMENT}
`
