import React, { useState } from "react"
import styled, { keyframes } from "react-emotion"
import { MdEdit, MdDone } from "react-icons/md"
import PropTypes from "prop-types"

import BoltIcon from "../../../assets/BoltIcon"
import { MdKeyboardArrowUp } from "react-icons/md"

import { TextButton, SecondaryButton } from "../Buttons"
import {
  breakpoints,
  fontFamilies,
  fontSizes,
  palette,
  spaces,
} from "../../../utils/presets"
import { cmsIntegrations as text } from "../../../locales/default.json"
import IntegrationDots from "../../../assets/IntegrationDots"
import { Text } from "../Typography"
import { SettingsCard as IntegrationCard } from "../Settings/SettingsCard"
import { SitePermissions } from "../../../permissions"

export { Text as Description } from "../Typography"
export {
  SettingsCard as IntegrationCard,
  Header,
} from "../Settings/SettingsCard"

export const Checked = styled(MdDone)`
  stroke: ${palette.green[700]};
  stroke-width: 2;
`

export const HeadingRoot = styled(`h3`)`
  align-items: center;
  display: flex;
  margin: 0;
  img {
    height: 1.75rem;
    margin-bottom: 0;
    width: auto;
  }
`

export const Heading = ({ logoUrl, name, children }) => (
  <HeadingRoot>
    {logoUrl && <img src={logoUrl} alt={name} />}
    {children}
  </HeadingRoot>
)

Heading.propTypes = {
  logoUrl: PropTypes.string,
  name: PropTypes.string,
}

const ActionsRoot = styled(`div`)`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: ${spaces.m} 0 ${spaces.xs};
  width: auto;
  button {
    margin-left: ${spaces.xs};
  }
  svg {
    fill: currentColor;
    height: 1em;
    width: auto;
  }
`

export const DataContainer = styled(`section`)`
  display: flex;
  flex-direction: column;
  letter-spacing: 0.25px;
  overflow-x: auto;
  margin: ${spaces.m} ${spaces.m} 0 0;

  @media (min-width: ${breakpoints.desktop}px) {
    margin: ${spaces.l} 0 0;
  }
`

export const ConnectStatus = styled(`span`)`
  align-items: center;
  color: ${palette.green[700]};
  display: flex;
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${fontSizes.m};
  font-weight: bold;
  padding: 0 ${spaces.m};
  svg {
    fill: ${palette.green[700]} !important;
  }
`

const ActionButton = ({ type, onClick }) =>
  type === `connect` ? (
    <SecondaryButton type="button" onClick={onClick}>
      {text.connect} <BoltIcon />
    </SecondaryButton>
  ) : (
    <SecondaryButton type="button" onClick={onClick}>
      Edit <MdEdit />
    </SecondaryButton>
  )

const ButtonPermissions = ({ siteId, type, onClick, origin }) =>
  origin === `settings` ? (
    <SitePermissions id={siteId} resource="sites" action="edit">
      <ActionButton type={type} onClick={onClick} />
    </SitePermissions>
  ) : (
    <ActionButton type={type} onClick={onClick} />
  )

const DetailsButton = ({ children, toggle, isOpen }) =>
  !isOpen ? (
    <TextButton size={`M`} onClick={toggle}>
      {text.details}
    </TextButton>
  ) : null

export const Actions = ({
  connectIntegration,
  isConnected,
  toggleDetails,
  detailsIsOpen,
  origin,
  editIntegration,
  siteId,
}) => (
  <ActionsRoot>
    <DetailsButton toggle={toggleDetails} isOpen={detailsIsOpen} />
    {!isConnected ? (
      <ButtonPermissions
        type="connect"
        siteId={siteId}
        onClick={connectIntegration}
        origin={origin}
      />
    ) : origin === `setup` ? (
      <ConnectStatus>
        Connected <BoltIcon />
      </ConnectStatus>
    ) : (
      <ButtonPermissions
        type="edit"
        siteId={siteId}
        onClick={editIntegration}
        origin={origin}
      />
    )}
  </ActionsRoot>
)

const AdditionalCardRoot = styled(IntegrationCard)`
  &.additional-card {
    align-items: center;
    background: ${palette.teal[50]};
    display: flex;
    justify-content: space-between;
    padding: ${spaces.m} ${spaces.l};
  }
  svg {
    display: none;
  }
  @media (min-width: ${breakpoints.tablet}px) {
    &.additional-card {
      padding: 0 0 0 ${spaces[`2xl`]};
    }
    svg {
      display: block;
    }
  }
`

const UpdateText = styled(Text)`
  color: ${palette.teal[900]};
  margin: 0;
`

export const AdditionalCard = () => (
  <AdditionalCardRoot className="additional-card">
    <UpdateText>{text.moreIntegrations}</UpdateText>
    <IntegrationDots />
  </AdditionalCardRoot>
)

const detailsEntry = keyframes`
   to {
     opacity: 1;
   }
 `

const DetailsRoot = styled(`div`)`
  animation: ${detailsEntry} 1s ease forwards;
  display: none;
  opacity: 0;
  &.open {
    display: block;
  }
`

const DetailsTop = styled(`div`)`
  animation: ${detailsEntry} 1s ease forwards;
  display: flex;
  justify-content: flex-end;
  margin: ${spaces.s} 0;
  opacity: 0;
  position: relative;
  &:before {
    background: ${palette.grey[200]};
    content: "";
    height: 1px;
    left: -${spaces.l};
    position: absolute;
    top: 50%;
    width: 100%;
  }
  ${TextButton} {
    position: relative;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    &:before {
      left: -${spaces[`2xl`]};
    }
  }
`

export function useDetailsState(state = false) {
  const [detailsIsOpen, setDetailsIsOpen] = useState(state)

  const toggleDetails = () => {
    setDetailsIsOpen(!detailsIsOpen)
  }

  return { detailsIsOpen, toggleDetails }
}

export const Details = ({ children, isOpen, toggle }) => (
  <DetailsRoot className={isOpen ? `open` : ``}>
    <DetailsTop>
      <TextButton size={`M`} type="button" onClick={toggle}>
        {text.hideDetails} <MdKeyboardArrowUp />
      </TextButton>
    </DetailsTop>
    {children}
  </DetailsRoot>
)

export const Documentation = styled(`a`)`
  align-items: center;
  display: flex;
  svg {
    margin-left: ${spaces[`2xs`]};
  }
`
