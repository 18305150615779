import React, { Fragment } from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"
import { graphql } from "react-apollo"
import { navigate } from "gatsby"

import {
  ModalSecondaryHeader,
  ModalBody,
  ModalForm,
  ModalActions,
  CloseButton,
} from "../../Modal"
import { Heading as BaseHeading } from "../Typography"
import { CancelButton, PrimaryButton } from "../Buttons"
import { PaymentProvider, CheckoutForm } from "../../Payment"
import { palette, fontSizes } from "../../../utils/presets"
import { paymentInformation as text } from "../../../locales/default.json"
import { UPDATE_PAYMENT_INFO } from "../../Payment/mutations"
import {
  ErrorNotification,
  setNotificationError,
} from "../../shared/ErrorNotification"

const UpdatePaymentHeading = styled(BaseHeading)`
  strong {
    color: ${palette.green[700]};
  }
`

const ResubscribeHeading = styled(BaseHeading)``

const Body = styled(ModalBody)`
  label {
    font-size: ${fontSizes.xs};
  }
`

let CheckoutFormWithMutation = CheckoutForm

if (graphql) {
  CheckoutFormWithMutation = graphql(UPDATE_PAYMENT_INFO)(
    CheckoutFormWithMutation
  )
}

export const UpdatePayment = ({
  closeModal,
  name,
  organizationId,
  id,
  setResponse,
  showToast,
  resourceType = `UPDATE`,
}) => {
  const { err, setErr } = setNotificationError()

  return (
    <Fragment>
      <ModalSecondaryHeader>
        <UpdatePaymentHeading>
          {text.updatePaymentFor} <strong>{name}</strong>
        </UpdatePaymentHeading>
        <CloseButton onClick={closeModal} aria-label="Close modal" />
      </ModalSecondaryHeader>
      <Body>
        {err && <ErrorNotification err={err} />}
        <PaymentProvider>
          <CheckoutFormWithMutation
            checkoutType="UPDATE_PAYMENT"
            cancelCallback={() => closeModal()}
            submitButtonText={text.addPaymentMethod}
            cancelButtonText={text.cancel}
            planId={id}
            organizationId={organizationId}
            showSuccess={creditCard => {
              closeModal()
              setResponse({
                success: true,
                creditCard,
              })
              resourceType === `RESUBSCRIBE` &&
                showToast(`<strong>${text.paymentInformation}</strong> 
                ${text.updatedSuccessfully}
              `)
            }}
            showError={err => {
              setResponse({ err })
              return setErr(err)
            }}
          />
        </PaymentProvider>
      </Body>
    </Fragment>
  )
}

UpdatePayment.propTypes = {
  closeModal: PropTypes.func,
  name: PropTypes.string,
  organizationId: PropTypes.string,
  planId: PropTypes.string,
  setMessage: PropTypes.func,
}

export const Resubscribe = ({
  closeModal,
  name,
  organizationId,
  canResubscribe,
}) => (
  <Fragment>
    <ModalSecondaryHeader>
      <ResubscribeHeading>
        {text.resubscribe} <strong>{name}</strong>
        {`?`}
      </ResubscribeHeading>
      <CloseButton onClick={closeModal} aria-label="Close modal" />
    </ModalSecondaryHeader>
    <Body>
      {canResubscribe ? text.resubscribeToManage : text.ownerMustResubscribe}
      {canResubscribe && (
        <ModalForm
          onSubmit={e => {
            e.preventDefault()
            closeModal()
            navigate(`/dashboard/pricing/${organizationId}`)
          }}
        >
          <ModalActions>
            <CancelButton type="button" onClick={closeModal}>
              {text.cancel}
            </CancelButton>
            <PrimaryButton type="submit">{text.resubscribe}</PrimaryButton>
          </ModalActions>
        </ModalForm>
      )}
    </Body>
  </Fragment>
)

Resubscribe.propTypes = {
  closeModal: PropTypes.func,
  name: PropTypes.string,
  organizationId: PropTypes.string,
  canResubscribe: PropTypes.bool,
}
