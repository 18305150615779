import React, { Component } from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik"

import { MdSend, MdErrorOutline, MdInfoOutline } from "react-icons/md"

// import { Debug } from "../../../utils/debug/Formik"
import { inviteContributors as text } from "../../../locales/default.json"
import { PrimaryButton, CancelButton } from "../Buttons"
import { BigLabel, Textarea, Form as BaseForm, InputError } from "../Forms"
import { ModalActions } from "../../Modal"
import isValidEmail from "../../../utils/helpers/isValidEmail"

const Form = BaseForm.withComponent(FormikForm)

const validationSchema = Yup.object().shape({
  emails: Yup.string()
    .required(text.emailAddressRequired)
    .test(`validEmail`, text.emailAddressInvalid, function(value) {
      return value ? value.split(`\n`).every(isValidEmail) : false
    }),
})

class ContributorForm extends Component {
  render() {
    const { submitCallback, cancelCallback, emails } = this.props

    return (
      <Formik
        initialValues={{ emails }}
        validationSchema={validationSchema}
        onSubmit={({ emails }) => {
          submitCallback(emails.split(`\n`))
        }}
      >
        {({ isSubmitting }) => (
          <Form data-cy="invite-contributors-form">
            <BigLabel htmlFor="emails">
              {text.sendLabel}
              <small>
                <MdInfoOutline />
                {text.note}
              </small>
            </BigLabel>
            <Field name="emails" type="text" aria-label={text.sendLabel}>
              {({ field }) => (
                <Textarea
                  {...field}
                  placeholder={text.sendLabel}
                  id="emails"
                  data-cy="invite-contributors-emails-textarea"
                />
              )}
            </Field>
            <ErrorMessage name="emails">
              {msg => (
                <InputError>
                  <MdErrorOutline />
                  {msg}
                </InputError>
              )}
            </ErrorMessage>
            <ModalActions>
              <CancelButton onClick={cancelCallback} size={`L`} type={`reset`}>
                {text.cancel}
              </CancelButton>
              <PrimaryButton
                type="submit"
                disabled={isSubmitting}
                data-cy="submit-invites-button"
              >
                {text.send} <MdSend />
              </PrimaryButton>
            </ModalActions>

            {/* Uncomment to see Formik state */}
            {/* <Debug /> */}
          </Form>
        )}
      </Formik>
    )
  }
}

ContributorForm.propTypes = {
  emails: PropTypes.array,
  submitCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
}

export default ContributorForm
