import React, { useContext } from "react"
import styled from "react-emotion"
import PropTypes from "prop-types"
import { compose, graphql } from "react-apollo"
import { Formik, Form } from "formik"
import { withFlags } from "ld-react"

import { MdInfo } from "react-icons/md"

import EnvVars from "../../shared/EnvVars"
import { NarrowSingleColumn } from "../../shared/Containers"
import { validationSchema } from "../../shared/EnvVarsForm/EnvVarsForm"
import { SiteHeading as Heading } from "../../shared/Typography"
import { createSite as text } from "../../../locales/default.json"
import { breakpoints, spaces, palette, fontSizes } from "../../../utils/presets"
import { installGithubApplication } from "../../../auth"
import { DefaultError } from "../../shared/ErrorModal"
import { ModalContext } from "../../Modal"

import WebhookCard from "../../shared/WebhookCard"
import { useTracker } from "../../../analytics"
import BranchCard from "./BranchCard"
import IntegrationsCard from "./IntegrationsCard"
import Footer from "../Footer"
import NotificationSection from "../../shared/Notification/NotificationSection"
import { PUBLISH_SITE_MUTATION } from "./graphql"

const SiteHeading = styled(Heading)`
  text-align: center;
`

const Content = styled(NarrowSingleColumn)`
  margin: ${spaces.xl} auto;
  @media (min-width: ${breakpoints.desktop}px) {
    margin: ${spaces[`2xl`]} auto;
  }
`

const formattedEnvVars = environmentVariablesForSite => {
  if (environmentVariablesForSite.length === 0)
    return environmentVariablesForSite
  const envVarsInput = []
  environmentVariablesForSite.forEach(envVar => {
    envVarsInput.push({
      key: envVar.key,
      value: envVar.value,
    })
  })
  return envVarsInput
}

const Icon = styled(MdInfo)`
  fill: ${palette.yellow[900]} !important;
  width: 48px;
`

const Notification = styled(NotificationSection)`
  margin-top: ${spaces[`2xl`]};
  p {
    color: ${palette.yellow[900]} !important;
    font-size: ${fontSizes.s};
    font-weight: normal !important;
  }
`

const SetupSiteForm = ({
  siteId,
  repositoryFromSite,
  branch,
  cancel,
  publishSiteMutation,
  envVarsQuery,
  cmsIntegrationsForSite,
  environmentVariablesForSite,
  flags,
}) => {
  const { trackButtonClicked } = useTracker()
  const { showModal } = useContext(ModalContext)
  return (
    <Formik
      initialValues={{ envVars: formattedEnvVars(environmentVariablesForSite) }}
      validationSchema={validationSchema}
      onSubmit={values => {
        const mappedValues = values.envVars.map(({ key, value }) => {
          return { key, value }
        })
        trackButtonClicked(text.previewSite, {
          siteId,
          uiSource: `Create and setup site`,
        })
        publishSiteMutation({
          variables: {
            id: siteId,
            environmentVariables: mappedValues,
          },
        })
          .then(() =>
            installGithubApplication({
              siteId,
            })
          )
          .catch(err => {
            showModal({
              Component: DefaultError,
              props: { errMsg: err.message },
            })
          })
      }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <Content>
            <SiteHeading data-cy="create-site-setup-step-header">
              {text.setUpSite}
              {` `}
              <strong>{repositoryFromSite.nameWithOwner}</strong>
            </SiteHeading>

            {flags.integrations && cmsIntegrationsForSite.length === 0 && (
              <Notification
                mode="warning"
                icon={Icon}
                message={`${text.needHelp} ${text.stepTwo} ${text.setupLater}`}
              />
            )}
            <BranchCard branch={branch} />
            {flags.integrations && cmsIntegrationsForSite.length > 0 && (
              <IntegrationsCard
                siteIntegrations={cmsIntegrationsForSite}
                siteId={siteId}
                repository={repositoryFromSite}
              />
            )}
            <EnvVars
              siteId={siteId}
              origin="setup"
              envVarsQuery={envVarsQuery}
              values={values}
            />
            <WebhookCard siteId={siteId} />
            <Footer
              type="submit"
              cancel={cancel}
              isSubmitting={isSubmitting}
              buttonText="Create Preview Site"
            />
          </Content>
        </Form>
      )}
    </Formik>
  )
}

SetupSiteForm.propTypes = {
  siteId: PropTypes.string,
  cancel: PropTypes.func,
}

export default compose(
  graphql(PUBLISH_SITE_MUTATION, {
    name: `publishSiteMutation`,
  })
)(withFlags(SetupSiteForm))
