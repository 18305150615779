import React, { useState, createContext } from "react"
import styled from "react-emotion"

import {
  ModalOverlay,
  ModalContent,
  FullModalContent,
  FullModalOverlay,
} from "./"

const Page = styled(`div`)`
  display: ${props => (props.modalIsOpen ? `` : `static`)};
  height: 100%;
  position: ${props => (props.modalIsOpen ? `fixed` : `static`)};
  width: 100%;
`

const DEFAULT_MODAL_STATE = {
  // standard modal
  modalIsOpen: false,
  modalProps: {},
  Component: () => null,
  // full-width/height modal
  fullModalIsOpen: false,
  fullModalProps: {},
  FullComponent: () => null,
}

export const ModalContext = createContext()
export const ModalConsumer = ModalContext.Consumer

function Modal({ closeModal }) {
  return (
    <ModalContext.Consumer>
      {({ modalIsOpen, Component, closeModal, modalProps }) => (
        <ModalOverlay
          isOpen={modalIsOpen}
          onDismiss={closeModal}
          {...modalProps}
        >
          <ModalContent {...modalProps}>
            {Component && <Component closeModal={closeModal} {...modalProps} />}
          </ModalContent>
        </ModalOverlay>
      )}
    </ModalContext.Consumer>
  )
}

function FullModal({ closeFullModal }) {
  return (
    <ModalContext.Consumer>
      {({ fullModalIsOpen, FullComponent, closeFullModal, fullModalProps }) => (
        <FullModalOverlay
          isOpen={fullModalIsOpen}
          onDismiss={closeFullModal}
          {...fullModalProps}
        >
          <FullModalContent {...fullModalProps}>
            {FullComponent && (
              <FullComponent closeModal={closeFullModal} {...fullModalProps} />
            )}
          </FullModalContent>
        </FullModalOverlay>
      )}
    </ModalContext.Consumer>
  )
}

export const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState(DEFAULT_MODAL_STATE)

  const showModal = ({ Component, props }) =>
    setModalState({
      ...modalState,
      modalIsOpen: true,
      Component,
      modalProps: {
        ...props,
        isOpen: true,
      },
    })

  const closeModal = () =>
    setModalState({
      ...modalState,
      Component: null,
      modalIsOpen: false,
      modalProps: {},
    })

  const showFullModal = ({ Component, props }) =>
    setModalState({
      ...modalState,
      fullModalIsOpen: true,
      FullComponent: Component,
      fullModalProps: {
        ...props,
        isOpen: true,
      },
    })

  const closeFullModal = () =>
    setModalState({
      ...modalState,
      FullComponent: null,
      fullModalIsOpen: false,
      fullModalProps: {},
    })

  const providerValue = {
    ...modalState,
    showModal: showModal,
    closeModal: closeModal,
    showFullModal: showFullModal,
    closeFullModal: closeFullModal,
  }

  return (
    <ModalContext.Provider value={providerValue}>
      <Page modalIsOpen={modalState.modalIsOpen || modalState.fullModalIsOpen}>
        {children}
      </Page>
      <FullModal />
      <Modal />
    </ModalContext.Provider>
  )
}
