import React from "react"

export default () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 8.66666H11V10.5467L12.6267 11.4867L12.1267 12.3533L10 11.1267V8.66666ZM12.6667 5.33332H3.33333V12.6667H6.44667C6.16 12.06 6 11.38 6 10.6667C6 8.08666 8.08667 5.99999 10.6667 5.99999C11.38 5.99999 12.06 6.15999 12.6667 6.44666V5.33332ZM3.33333 14C2.59333 14 2 13.4 2 12.6667V3.33332C2 2.59332 2.59333 1.99999 3.33333 1.99999H4V0.666656H5.33333V1.99999H10.6667V0.666656H12V1.99999H12.6667C13.4 1.99999 14 2.59999 14 3.33332V7.39999C14.8267 8.23999 15.3333 9.39332 15.3333 10.6667C15.3333 13.2467 13.2467 15.3333 10.6667 15.3333C9.39333 15.3333 8.24 14.8267 7.4 14H3.33333ZM10.6667 7.43332C8.88 7.43332 7.43333 8.87999 7.43333 10.6667C7.43333 12.4533 8.88 13.9 10.6667 13.9C12.4533 13.9 13.9 12.4533 13.9 10.6667C13.9 8.87999 12.4533 7.43332 10.6667 7.43332Z"
      fill="#A1DA9E"
    />
  </svg>
)
