import React from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"
import { withFlags } from "ld-react"

import { createSite as text } from "../../locales/default.json"
import { Tabs, Tab } from "../shared/Tabs"
import {
  colors,
  dimensions,
  palette,
  fontFamilies,
  fontSizes,
  spaces,
  breakpoints,
} from "../../utils/presets"

const StepsRoot = styled(Tabs)`
  text-align: center;
  padding-top: ${spaces[`2xl`]};

  :after {
    border-bottom: 1px solid ${colors.standardLine};
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    width: calc(100% + (2 * ${dimensions.pagePadding.mobile}));
    margin-left: -${dimensions.pagePadding.mobile};
    z-index: 1;

    @media (min-width: ${breakpoints.tablet}px) {
      width: calc(100% + (2 * ${dimensions.pagePadding.tablet}));
      margin-left: -${dimensions.pagePadding.tablet};
    }
  }
`

const Step = styled(Tab)`
  background: ${colors.secondaryBackground};
  color: ${palette.grey[400]};

  a {
    color: ${palette.purple[400]};
  }

  &.active {
    background: ${colors.primaryBackground};
    color: ${palette.grey[900]};
  }
`

const Figure = styled(`span`)`
  align-items: center;
  background: ${palette.grey[300]};
  border-radius: 50%;
  color: ${palette.white};
  display: flex;
  font-family: ${fontFamilies.bodyFontFamily};
  font-size: ${fontSizes[`3xs`]};
  font-weight: bold;
  height: 20px;
  justify-content: center;
  line-height: 1;
  width: 20px;

  .active & {
    background: ${palette.purple[400]};
    margin-right: ${spaces.s};
  }

  .done & {
    background: ${palette.purple[300]};
  }

  @media (min-width: ${breakpoints.tablet}px) {
    margin-right: ${spaces.s};
  }
`

const Label = styled(`span`)`
  display: none;

  .active & {
    display: inline;
  }

  &.desktop {
    display: none;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    display: inline;

    &.desktop {
      display: inline;
    }

    &.mobile {
      display: none;
    }
  }
`

const Steps = ({ siteId, activeStep = `repository`, flags }) => (
  <StepsRoot>
    <Step
      to={siteId ? `/dashboard/sites/create` : ``}
      className={activeStep === `repository` ? `active` : `done`}
    >
      <Figure>1</Figure>
      <Label>{text.repository}</Label>
    </Step>
    {flags.integrations && (
      <Step
        to={
          activeStep === `setup`
            ? `/dashboard/sites/create/${siteId}/integrations`
            : ``
        }
        className={
          activeStep === `integrations`
            ? `active`
            : activeStep === `setup`
            ? `done`
            : ``
        }
      >
        <Figure>2</Figure>
        <Label>{text.integrations}</Label>
      </Step>
    )}
    <Step className={activeStep === `setup` ? `active` : ``}>
      <Figure>{flags.integrations ? 3 : 2}</Figure>
      <Label>{text.setup}</Label>
    </Step>
  </StepsRoot>
)

Steps.propTypes = {
  activeStep: PropTypes.oneOf([`repository`, `integrations`, `setup`]),
  siteId: PropTypes.string,
}

export default withFlags(Steps)
