import gql from "graphql-tag"
import { PERMISSIONS_ORGANIZATION_FRAGMENT } from "../../permissions/fragments"
import {
  CREDIT_CARD_FRAGMENT,
  BILLING_PLAN_FRAGMENT,
  BILLING_FIELDS_FRAGMENT,
} from "../Payment/fragments"

export const ORG_DETAILS_QUERY = gql`
  query organizationDetailsQuery($id: UUID!) {
    organizationDetails(id: $id) {
      id
      name
      billing {
        ...billingFieldsFragment
        creditCard {
          ...creditCardFragment
        }
        plan {
          ...billingPlanFragment
        }
      }
      permissions {
        ...orgPermissionsFragment
      }
      sites {
        id
        name
        branch
        updatedAt
        previewUrl
        previewStatus
      }
    }
  }
  ${BILLING_FIELDS_FRAGMENT}
  ${BILLING_PLAN_FRAGMENT}
  ${CREDIT_CARD_FRAGMENT}
  ${PERMISSIONS_ORGANIZATION_FRAGMENT}
`
