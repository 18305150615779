import React, { Fragment, useState, useContext } from "react"
import { withFlags } from "ld-react"
import { graphql, compose } from "react-apollo"

import { MdStarBorder } from "react-icons/md"

import { ModalContext } from "../../Modal"
import { useTracker } from "../../../analytics"
import { DefaultError } from "../../shared/ErrorModal"
import { Checkbox } from "../../shared/Forms"
import { TinyHeading, SubHeading } from "./Sidebar"
import gql from "graphql-tag"
import { siteDetails as text } from "../../../locales/default.json"

const toggleBuilds = gql`
  mutation toggleBuilds($siteId: UUID!) {
    toggleIncrementalBuildFlag(siteId: $siteId) {
      success
      message
    }
  }
`

let EnableBuilds = function EnableBuilds({
  siteId,
  flags,
  value,
  buildsEnabled,
  mutate,
}) {
  const { trackButtonClicked } = useTracker()
  const { showModal } = useContext(ModalContext)
  const [isSubmitting, setIsSubmitting] = useState(false)

  if (!flags.builds) {
    return null
  }

  return (
    <Fragment>
      <SubHeading>
        <MdStarBorder /> {text.experimentalFeatures}
      </SubHeading>
      <TinyHeading>{text.incrementalBuilds}</TinyHeading>
      <Checkbox
        fieldName="buildsEnabled"
        label={text.enabled}
        indicateSubmitting={isSubmitting}
        disabled={isSubmitting}
        onChange={() => {
          setIsSubmitting(true)

          trackButtonClicked(`update incremental builds`, {
            uiSource: `Site Overview`,
          })

          mutate({
            variables: {
              siteId,
            },
            update: (proxy, { data }) => {
              if (!data.toggleIncrementalBuildFlag.success) {
                return
              }
              const params = {
                id: `Site:${siteId}`,
                fragment: gql`
                  fragment siteDetailsBuildsFrag on Site {
                    id
                    buildsEnabled
                  }
                `,
              }

              const site = proxy.readFragment(params)

              site.buildsEnabled = !buildsEnabled

              proxy.writeFragment({
                ...params,
                data: site,
              })
              setIsSubmitting(false)
            },
          }).catch(err => {
            setIsSubmitting(false)
            showModal({
              Component: DefaultError,
              props: { errMsg: err.message },
            })
          })
        }}
        value={true}
        checked={!!buildsEnabled}
      />
    </Fragment>
  )
}

EnableBuilds = compose(
  withFlags,
  graphql(toggleBuilds)
)(EnableBuilds)

export default EnableBuilds
