import React, { Fragment } from "react"
import { withApolloFragment } from "apollo-fragment-react"
import {
  ORGANIZATION_WITH_PERMISSIONS_FRAGMENT,
  SITES_WITH_PERMISSIONS_FRAGMENT,
} from "./fragments"

function createPermissionComponent(fragment) {
  function PermissionsComponent({ id, resource, action, data, children }) {
    const permissions =
      (data && data.permissions && data.permissions[resource]) || {}
    if (!permissions[action]) {
      return null
    }

    return <Fragment>{children}</Fragment>
  }

  return withApolloFragment(fragment)(PermissionsComponent)
}

export const OrganizationPermissions = createPermissionComponent(
  ORGANIZATION_WITH_PERMISSIONS_FRAGMENT
)

export const SitePermissions = createPermissionComponent(
  SITES_WITH_PERMISSIONS_FRAGMENT
)
