import React, { useState, useRef, createContext } from "react"
import Alert from "@reach/alert"
import styled, { keyframes } from "react-emotion"
import { MdDone, MdClose } from "react-icons/md"

import {
  fontSizes,
  dimensions,
  palette,
  radius,
  spaces,
} from "../../utils/presets"

const ToastRoot = styled(`div`)`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  left: 50%;
  position: fixed;
  transform: translate(-50%, 0);
  width: 100%;
`

const toastEntry = keyframes`
  100% {
     transform: perspective(1000px) rotateX(0);
  }
`

const Toast = styled(Alert)`
  align-items: center;
  animation: ${toastEntry} 0.5s 0.25s ease forwards;
  background: ${palette.grey[900]};
  border-left: 8px solid ${palette.green[500]};
  border-radius: ${radius.default} ${radius.default} 0 0;
  color: ${palette.green[50]};
  display: flex;
  font-size: ${fontSizes.xs};
  min-height: ${dimensions.toast.minHeight};
  max-width: calc(100% - (${spaces.l} * 2));
  padding-left: ${spaces.s};
  transform: perspective(1000px) rotateX(90deg);
  transform-origin: bottom center;

  svg {
    height: auto;
    width: calc(${dimensions.toast.minHeight} * 0.4);
  }

  &:not(:first-child) {
    border-radius: ${radius.default};
    margin-bottom: ${spaces[`3xs`]};
  }
`

const Msg = styled(`span`)`
  line-height: 1;
  margin: 0 ${spaces[`2xs`]} 0 ${spaces.xs};
`

const StatusIcon = styled(`span`)`
  align-items: center;
  color: ${palette.green[500]};
  display: flex;
`

const CloseButton = styled(`button`)`
  align-items: center;
  background: none;
  border: none;
  color: ${palette.grey[400]};
  cursor: pointer;
  display: flex;
  height: ${dimensions.toast.minHeight};
  justify-content: center;
  width: ${dimensions.toast.minHeight};
`

export const ToastContext = createContext()
export const ToastConsumer = ToastContext.Consumer

const timeouts = []

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([])

  const toastsRef = useRef()
  toastsRef.current = toasts

  const showToast = toastMsg => {
    const toastId = Math.random()
    setToasts([...toasts, { id: toastId, msg: toastMsg }])

    timeouts[toastId] = setTimeout(function() {
      setToasts(toastsRef.current.slice(1))
      clearTimeout(timeouts[toastId])
    }, 5000)
  }

  const removeToast = toastId => e => {
    setToasts(toasts.filter(toast => toast.id !== toastId))
    clearTimeout(timeouts[toastId])
  }

  return (
    <ToastContext.Provider value={{ toasts, showToast }}>
      {children}
      <ToastRoot>
        {toasts.map(toast => (
          <Toast key={toast.id} data-cy="toast">
            <StatusIcon>
              <MdDone />
            </StatusIcon>
            <Msg dangerouslySetInnerHTML={{ __html: toast.msg }} />
            <CloseButton type="button" onClick={removeToast(toast.id)}>
              <MdClose />
            </CloseButton>
          </Toast>
        ))}
      </ToastRoot>
    </ToastContext.Provider>
  )
}
