import React from "react"
import styled from "react-emotion"
import PropTypes from "prop-types"
import { MenuButton as BaseMenuButton } from "@reach/menu-button"

import { Heading } from "../../shared/Typography"
import BaseBadge from "../../shared/Badge"
import { SecondaryButton } from "../../shared/Buttons"
import { Card as BaseCard } from "../../shared/Card"

import {
  breakpoints,
  colors,
  fontSizes,
  fontFamilies,
  palette,
  spaces,
  radius,
  dimensions,
} from "../../../utils/presets"

const statusToMode = {
  BUILDING: `purple`,
  SUCCESS: `green`,
  ERROR: `red`,
}

const statusToText = {
  BUILDING: `STARTING`,
  SUCCESS: `SUCCESS`,
  ERROR: `ERROR`,
  TIMED_OUT: `TIMED OUT`,
}

export const MenuButton = styled(BaseMenuButton)`
  align-items: center;
  background: ${palette.white};
  border: 1px solid ${palette.purple[200]};
  border-radius: ${radius.default};
  box-sizing: border-box;
  color: ${colors.lilac};
  cursor: pointer;
  display: inline-flex;
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${fontSizes.m};
  justify-content: center;
  line-height: 1;
  height: 2.25rem;
  margin-left: auto;
  margin-top: ${spaces.m};
  padding: 0.55rem 1rem;
  text-decoration: none;
  transition: 0.5s;

  :focus,
  :hover {
    border: 1px solid ${palette.purple[600]};
    color: ${palette.purple[600]};
  }

  svg {
    flex-shrink: 0;
    transform: translateX(0.2em) scale(1);
  }

  @media (min-width: ${breakpoints.phablet}px) {
    margin-left: auto;
    width: auto;
    margin-top: 0;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    position: absolute;
    top: ${spaces.l};
    right: ${spaces.l};
  }
`

export const CardHeading = styled(Heading)`
  font-size: 1.2rem;
  margin-bottom: ${spaces.m};
  color: ${palette.grey[600]};

  span {
    font-weight: normal;
  }

  strong {
    font-size: 1.2em;
    color: ${palette.grey[900]};
  }
`

export const BuildStatus = ({ children, status, ...rest }) => (
  <Badge mode={statusToMode[status]} {...rest}>
    {statusToText[status]}
  </Badge>
)

BuildStatus.propTypes = {
  status: PropTypes.string,
}

export const CardFooter = styled(`div`)`
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;

  ${SecondaryButton} {
    margin-left: auto;
    margin-top: ${spaces.m};
  }

  @media (min-width: ${breakpoints.phablet}px) {
    ${SecondaryButton} {
      margin-left: auto;
      width: auto;
      margin-top: 0;
    }
  }

  @media (min-width: ${breakpoints.desktop}px) {
    ${SecondaryButton} {
      position: absolute;
      top: ${spaces.l};
      right: ${spaces.l};
    }
  }
`

export const Row = styled(`div`)`
  font-size: ${fontSizes.xs};
  margin-bottom: ${spaces[`2xs`]};
  display: flex;
  color: ${palette.grey[600]};
`
export const Label = styled(`span`)`
  font-style: normal;
  color: ${palette.grey[400]};
  flex-shrink: 0;
`

export const Separator = styled(`span`)`
  display: inline-block;
  padding: 0 ${spaces.xs};
  color: ${palette.grey[300]};
`

export const Badge = styled(BaseBadge)`
  margin-top: ${spaces.s};
  margin-right: ${spaces.m};
`

const Card = styled(BaseCard)`
  padding: ${spaces.l};
  position: relative;

  @media (min-width: ${breakpoints.desktop}px) {
    :after {
      position: absolute;
      content: "";
      display: block;
      border-radius: 50%;
      transform: translateX(-48%);
      border: 0.3rem solid ${props => statusToMode[props.status]};
      width: 25px;
      height: 25px;
      top: ${spaces.l};
    }
  }
`

export const BuildCard = styled(Card)`
  margin-bottom: ${spaces.m};

  @media (min-width: ${breakpoints.desktop}px) {
    margin-bottom: ${spaces.l};
    margin-left: ${dimensions.buildList.indentation};

    :after {
      left: calc(${dimensions.buildList.indentation} / -2);
      background: ${colors.secondaryBackground};
    }

    :before {
      content: "";
      width: 1px;
      height: calc(100% + ${spaces.l});
      background: ${palette.grey[300]};
      position: absolute;
      top: 0;
      left: calc(${dimensions.buildList.indentation} / -2);
    }

    :last-child {
      :before {
        height: ${spaces.l};
      }
    }
  }
`

export const PreviewUrl = styled(`div`)`
  a {
    align-items: center;
    display: flex;
    color: ${colors.gatsby};
    font-weight: bold;
    text-decoration: none;
  }

  svg {
    width: ${spaces.m};
    height: ${spaces.m};
    margin-left: ${spaces[`2xs`]};
  }
`

export const LastBuildCard = styled(Card)`
  :after {
    transform: scale(1.15) translateX(-45%);
    transform-origin: center center;
    left: calc(${dimensions.buildList.indentation} / 2);
  }

  ${PreviewUrl} {
    margin-bottom: ${spaces.xs};
  }

  @media (min-width: ${breakpoints.desktop}px) {
    padding-left: ${dimensions.buildList.indentation};
  }
`
