import React, { useContext, Fragment } from "react"
import PropTypes from "prop-types"
import { MdHelpOutline, MdDone, MdNotInterested } from "react-icons/md"
import styled from "react-emotion"

import BoltIcon from "../../../assets/BoltIcon"
import { ModalContext } from "../../Modal"
import { ToastContext } from "../../shared/Toast"
import {
  IntegrationCard,
  Header,
  Heading,
  Actions,
  Description,
  AdditionalCard,
  Details,
  useDetailsState,
  Documentation,
  ConnectStatus,
} from "./IntegrationCard"
import ContentfulModal from "../ContentfulModal/ContentfulModal"
import EditContentfulIntegration from "../ContentfulModal/EditContentfulIntegration"
import Loading from "../Loading"
import ErrorModal from "../ErrorModal"
import { Text } from "../Typography"
import { spaces, palette, fontSizes, breakpoints } from "../../../utils/presets"
import { cmsIntegrations as text } from "../../../locales/default.json"

const ZeroState = styled(Text)`
  margin: 0 0 ${spaces.m} 0;
`

const Checked = styled(MdDone)`
  stroke: ${palette.green[700]};
  stroke-width: 2;
`

const Unchecked = styled(MdNotInterested)`
  opacity: 0.5;
  stroke: ${palette.grey[200]};
`

const Features = styled(`section`)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.phablet}px) {
    flex-direction: row;
  }
`

const FeatureText = styled(Text)`
  align-items: center;
  color: ${palette.grey[600]};
  display: flex;
  font-size: ${fontSizes.m};

  :not(:first-of-type) {
    padding-left: 0;
    @media (min-width: ${breakpoints.phablet}px) {
      padding-left: ${spaces.l};
    }
  }

  svg {
    margin-left: ${spaces.xs};
  }
`

const featureList = [`envVars`, `webhooks`, `extension`]

export const CmsIntegrationCard = ({
  repository,
  siteId,
  loading,
  error,
  cmsIntegrations,
  values,
  origin = `setup`,
}) => {
  const { showFullModal } = useContext(ModalContext)
  const { showToast } = useContext(ToastContext)
  const { detailsIsOpen, toggleDetails } = useDetailsState()

  if (loading) {
    return (
      <Loading
        delay={1000}
        message="loading available cms integrations..."
        fullPageHeight={false}
      />
    )
  }

  if (error) return <ErrorModal errMsg={error.message} />

  const filteredCmsIntegrations =
    values && values.filter && values.filter === `Connected`
      ? cmsIntegrations.filter(cmsIntegration => cmsIntegration.connected)
      : cmsIntegrations

  return (
    <Fragment>
      {filteredCmsIntegrations.map(
        ({ logoUrl, name, description, details, connected, features }) => (
          <IntegrationCard key={name}>
            <Header>
              <Heading logoUrl={logoUrl} name={name}>
                {origin === `settings` && connected && (
                  <ConnectStatus>
                    {text.connected} <BoltIcon />
                  </ConnectStatus>
                )}
              </Heading>
              <Actions
                connectIntegration={() =>
                  showFullModal({
                    Component: ContentfulModal,
                    props: {
                      siteId,
                      isConnected: !!connected,
                      orgSiteName: repository.nameWithOwner,
                      name,
                    },
                  })
                }
                editIntegration={() => {
                  showFullModal({
                    Component: EditContentfulIntegration,
                    props: {
                      siteId,
                      orgSiteName: repository.nameWithOwner,
                      name,
                      showToast,
                    },
                  })
                }}
                isConnected={connected}
                detailsIsOpen={detailsIsOpen}
                toggleDetails={toggleDetails}
                origin={origin}
                siteId={siteId}
              />
            </Header>
            <Description>{description}</Description>
            <Features>
              {origin === `settings` &&
                connected &&
                featureList.map(feature => (
                  <FeatureText key={feature}>
                    {feature === `envVars`
                      ? `Environment variables`
                      : feature === `webhooks`
                      ? `Preview Webhook`
                      : `Preview Extension`}
                    {features[feature] ? <Checked /> : <Unchecked />}
                  </FeatureText>
                ))}
            </Features>
            <Details toggle={toggleDetails} isOpen={detailsIsOpen}>
              {details}
              {` `}
              <Documentation
                target="_blank"
                href={`https://www.gatsbyjs.com/docs/preview/getting-started-with-gatsby-preview-and-${name.toLowerCase()}`}
              >
                {text.documentation} <MdHelpOutline />
              </Documentation>
            </Details>
          </IntegrationCard>
        )
      )}
      {filteredCmsIntegrations.length === 0 && (
        <ZeroState>{text.zeroState}</ZeroState>
      )}
      <AdditionalCard />
    </Fragment>
  )
}

export default CmsIntegrationCard

CmsIntegrationCard.propTypes = {
  siteId: PropTypes.string,
  loading: PropTypes.bool,
  cmsIntegration: PropTypes.array,
}
