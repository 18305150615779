import React, { Fragment, useState, useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import gql from "graphql-tag"
import { graphql } from "react-apollo"
import styled from "react-emotion"

import { MdReplay, MdInfoOutline, MdBlock, MdSettings } from "react-icons/md"

import PreviewStatus from "../../shared/PreviewStatus"
import { PrimaryButton } from "../../shared/Buttons"
import { DefaultError } from "../../shared/ErrorModal"
import { ModalContext } from "../../Modal"
import {
  breakpoints,
  colors,
  fontSizes,
  fontFamilies,
  palette,
  spaces,
} from "../../../utils/presets"
import { siteDetails as text } from "../../../locales/default.json"
import { useTracker } from "../../../analytics"
import { visitorAccessOptions } from "../Settings/VisitorAccess"
import { BUILDS_QUERY } from "./Overview"
import EnableBuilds from "./EnableBuilds"

const REBUILD_SITE_MUTATION = gql`
  mutation rebuildSite($id: UUID!) {
    rebuildSite(id: $id) {
      site {
        id
        previewStatus
      }
      build {
        id
        siteId
        buildType
        buildStatus
        createdAt
        endedAt
      }
    }
  }
`

const SidebarRoot = styled(`aside`)`
  width: 100%;
  flex-shrink: 0;
  padding: 0 ${spaces.l} ${spaces.xl};

  @media (min-width: ${breakpoints.desktop}px) {
    width: 15rem;
    padding: 0 ${spaces.xs} 0 0;
  }
`
export const SubHeading = styled(`h2`)`
  align-items: center;
  border-top: 1px solid ${colors.standardLine};
  color: ${palette.grey[500]};
  display: flex;
  font-size: ${fontSizes.s};
  font-family: ${fontFamilies.headerFontFamily};
  font-weight: bold;
  margin: ${spaces[`2xl`]} 0 0;
  padding: ${spaces.xl} 0 0;
  text-transform: uppercase;

  svg {
    margin-right: ${spaces[`2xs`]};
    color: ${palette.purple[500]};
  }
`

export const TinyHeading = styled(`h3`)`
  color: ${palette.grey[500]};
  text-transform: uppercase;
  font-size: ${fontSizes.xs};
  font-family: ${fontFamilies.headerFontFamily};
  font-weight: 300;
  letter-spacing: 0.2em;
  margin: 0;
  padding: ${spaces.xl} 0 ${spaces[`2xs`]};

  &:first-child {
    padding-top: 0;
  }
`

const SidebarValue = styled(`span`)`
  color: ${palette.grey[500]};
  font-size: ${fontSizes.s};
`

const PreviewUrl = styled(`a`)`
  font-weight: bold;
  color: ${colors.gatsby};
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid ${palette.purple[200]};
`

const VisitorAccess = styled(`div`)`
  align-items: center;
  display: flex;
  color: ${palette.grey[500]};

  svg {
    font-size: ${fontSizes[`2xs`]};
    margin-right: ${spaces[`2xs`]};
  }

  a {
    align-items: center;
    color: ${palette.purple[500]};
    display: flex;
    font-size: 0.9em;
    margin-left: ${spaces.xs};

    svg {
      font-size: ${fontSizes.s};
    }
  }
`

const RebuildButton = styled(PrimaryButton)`
  margin-top: ${spaces.xs};
`

const Notice = styled(`p`)`
  font-size: ${fontSizes[`2xs`]};
  color: ${palette.grey[500]};
  padding: ${spaces.xs} ${spaces.xl} 0 0;
  margin: 0;

  svg {
    vertical-align: text-bottom;
    color: ${palette.grey[900]};
  }
`

const ALLOWED_STATUSES = [`READY`, `BUILDING`, `BUILD_ERROR`, `SYSTEM_ERROR`]

const Sidebar = ({
  siteId,
  siteName,
  buildsEnabled,
  sitePreviewStatus,
  sitePreviewProtection,
  sitePreviewDirectory = ``,
  siteBranch,
  previewUrl,
  rebuildSiteMutation,
  organizationId,
}) => {
  let formattedPreviewDirectory = sitePreviewDirectory || ``
  if (formattedPreviewDirectory.length === 0) {
    formattedPreviewDirectory = `/${formattedPreviewDirectory}`
  }

  const [disabled, setDisabled] = useState(false)
  const { trackButtonClicked } = useTracker()
  const isBuilding = !ALLOWED_STATUSES.includes(sitePreviewStatus)
  const { showModal } = useContext(ModalContext)

  const visitorAccess = visitorAccessOptions.find(
    option => option.value === sitePreviewProtection
  )
  const AccessIcon = visitorAccess.icon

  return (
    <SidebarRoot aria-label="Overview sidebar">
      <TinyHeading>{text.branch}</TinyHeading>
      <SidebarValue>{siteBranch}</SidebarValue>
      <TinyHeading>Directory path</TinyHeading>
      <SidebarValue>{formattedPreviewDirectory}</SidebarValue>
      {[`READY`].includes(sitePreviewStatus) && (
        <Fragment>
          <TinyHeading>{text.previewUrl}</TinyHeading>
          <PreviewUrl
            target="_blank"
            href={previewUrl}
            onClick={() =>
              trackButtonClicked(`Site Preview URL`, {
                siteId,
                uiSource: `Site overview`,
              })
            }
          >
            {previewUrl}
          </PreviewUrl>
          {process.env.GATSBY_SHOW_PREVIEW_VISIBILITY_SETTINGS === `true` && (
            <Fragment>
              <TinyHeading>Visitor Access</TinyHeading>
              <VisitorAccess>
                <AccessIcon /> {visitorAccess.label}
                <Link
                  to={`/dashboard/${organizationId}/sites/settings/${siteId}`}
                >
                  {` `}
                  <MdSettings />
                </Link>
              </VisitorAccess>
            </Fragment>
          )}
        </Fragment>
      )}
      <TinyHeading>{text.previewStatus}</TinyHeading>
      <PreviewStatus status={sitePreviewStatus} />
      <TinyHeading>{text.deploysFrom}</TinyHeading>
      <SidebarValue>
        {siteName}/{siteBranch}
      </SidebarValue>

      {sitePreviewStatus !== `UNAUTHORIZED` && (
        <Fragment>
          <TinyHeading>{text.triggerRebuild}</TinyHeading>
          <RebuildButton
            onClick={() => {
              trackButtonClicked(`Rebuild Site`, {
                organizationId,
                uiSource: `Site Overview`,
                siteId,
              })
              setDisabled(!disabled)
              rebuildSiteMutation({
                variables: {
                  id: siteId,
                },
                update: (cache, data) => {
                  const {
                    data: {
                      rebuildSite: { build },
                    },
                  } = data

                  const buildsFromCache = cache.readQuery({
                    query: BUILDS_QUERY,
                    variables: {
                      siteId,
                      pagination: {
                        limit: 10,
                      },
                    },
                  })

                  const builds = buildsFromCache.buildsForSite.builds || []

                  const hasBuild = builds.find(({ id }) => id === build.id)

                  if (hasBuild) {
                    return
                  }

                  buildsFromCache.buildsForSite.builds = [build, ...builds]

                  cache.writeQuery({
                    query: BUILDS_QUERY,
                    data: buildsFromCache,
                    variables: {
                      siteId,
                      pagination: {
                        limit: 10,
                      },
                    },
                  })
                },
              })
                .then(() => setDisabled(!disabled))
                .catch(err => {
                  setDisabled(false)
                  showModal({
                    Component: DefaultError,
                    props: { errMsg: err.message },
                  })
                })
            }}
            disabled={isBuilding || disabled}
          >
            {text.restart} {isBuilding ? <MdBlock /> : <MdReplay />}
          </RebuildButton>
          {isBuilding && (
            <Notice>
              <MdInfoOutline /> {text.youCannotTrigger}
            </Notice>
          )}
        </Fragment>
      )}
      <EnableBuilds buildsEnabled={buildsEnabled} siteId={siteId} />
    </SidebarRoot>
  )
}

Sidebar.propTypes = {
  siteId: PropTypes.string.isRequired,
  siteName: PropTypes.string.isRequired,
  previewUrl: PropTypes.string.isRequired,
  lastBuild: PropTypes.object,
}

export default graphql(REBUILD_SITE_MUTATION, {
  name: `rebuildSiteMutation`,
})(Sidebar)
