import React, { useContext } from "react"
import { graphql } from "react-apollo"
import styled from "react-emotion"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { MdSettings } from "react-icons/md"

import { palette, spaces, breakpoints } from "../../../utils/presets"
import Loading from "../../shared/Loading"
import ErrorModal from "../../shared/ErrorModal"
import { SettingsCard, Header, Title } from "../../shared/Settings/SettingsCard"
import { SecondaryButton } from "../../shared/Buttons"
import { Text } from "../../shared/Typography"
import { SitePermissions } from "../../../permissions"
import Paywall from "../../shared/PaymentInformation/Paywall"
import { ModalContext } from "../../Modal"
import EditContentfulIntegration from "../../shared/ContentfulModal/EditContentfulIntegration"
import BoltIcon from "../../../assets/BoltIcon"
import VisuallyHidden from "../../shared/VisuallyHidden"
import { cmsIntegrations as text } from "../../../locales/default.json"
import { SITE_INTEGRATIONS_QUERY } from "../../../graphql/queries"
import { Table, TableBody, TableRow as TableRowRoot } from "../../shared/Table"
import {
  Checked,
  DataContainer,
} from "../../shared/CmsIntegration/IntegrationCard"

const TableRow = styled(TableRowRoot)`
  border-style: hidden;
`

const Cell = styled(`td`)`
  :first-child {
    width: 100%;
    @media (min-width: ${breakpoints.tablet}px) {
      width: 15%;
    }
  }
  :last-child {
    border-bottom: 0;
    float: right;
  }
`

const LogoImage = styled(`img`)`
  align-items: center;
  display: none;
  max-height: 1.25rem;
  margin: 0;
  @media (min-width: ${breakpoints.tablet}px) {
    display: flex;
  }
`

const IntegrationText = styled(Text)`
  display: block;
  margin: 0;

  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`

const ConnectedText = styled(Text)`
  align-items: center;
  color: ${palette.green[700]};
  display: flex;
  margin: 0;
  svg {
    margin-left: ${spaces.xs};
  }
`

const NoIntegrationsText = styled(Text)`
  color: ${palette.grey[500]};
`

const SettingsIcon = styled(MdSettings)`
  margin-right: 8px;
`

const SiteIntegrationsData = ({
  repository,
  siteId,
  siteIntegrations,
  showFullModal,
}) =>
  siteIntegrations.length > 0 ? (
    <Table>
      <TableBody>
        {siteIntegrations.map(({ logoUrl, name }) => (
          <TableRow key={name}>
            <Cell>
              <IntegrationText>{name}</IntegrationText>
              <LogoImage src={logoUrl} alt={name} />
            </Cell>
            <Cell>
              <ConnectedText>
                Connected <Checked />
              </ConnectedText>
            </Cell>
            <SitePermissions id={siteId} resource="sites" action="edit">
              <Cell>
                <SecondaryButton
                  type="button"
                  onClick={() =>
                    showFullModal({
                      Component: EditContentfulIntegration,
                      props: {
                        siteId,
                        repository,
                        name,
                      },
                    })
                  }
                >
                  <VisuallyHidden>{text.editIntegrations}</VisuallyHidden>
                  <SettingsIcon />
                </SecondaryButton>
              </Cell>
            </SitePermissions>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <NoIntegrationsText>{text.zeroState}</NoIntegrationsText>
  )

const SiteIntegrations = ({
  siteId,
  organizationId,
  loading,
  error,
  siteIntegrations,
  repository,
}) => {
  const { showFullModal } = useContext(ModalContext)
  if (loading) {
    return (
      <Loading
        delay={1000}
        message="loading available cms integrations..."
        fullPageHeight={false}
      />
    )
  }

  if (error) return <ErrorModal errMsg={error.message} />

  return (
    <SettingsCard>
      <Header>
        <Title>Integrations</Title>
        <SitePermissions id={siteId} resource="sites" action="edit">
          <Paywall
            id={organizationId}
            onSuccess={() => {
              navigate(
                `/dashboard/${organizationId}/sites/integrations/${siteId}`
              )
            }}
          >
            {({ onClick }) => (
              <SecondaryButton onClick={onClick}>
                {text.addIntegration} <BoltIcon />
              </SecondaryButton>
            )}
          </Paywall>
        </SitePermissions>
      </Header>
      <DataContainer>
        <SiteIntegrationsData
          repository={repository}
          siteId={siteId}
          siteIntegrations={siteIntegrations}
          showFullModal={showFullModal}
        />
      </DataContainer>
    </SettingsCard>
  )
}

SiteIntegrations.propTypes = {
  siteId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
}

export default graphql(SITE_INTEGRATIONS_QUERY, {
  options: props => {
    return {
      variables: {
        siteId: props.siteId,
        connected: true,
      },
      fetchPolicy: `cache-and-network`,
    }
  },
  props: ({ data, ...rest }) => {
    return {
      loading: data.loading,
      siteIntegrations: (data && data.cmsIntegrationsForSite) || [],
      error: data.error,
      ...rest,
    }
  },
})(SiteIntegrations)
