import React from "react"
import PropTypes from "prop-types"
import { withFlags } from "ld-react"

import { TabsNav, Tabs, Tab } from "../shared/Tabs"
import BackNavigation from "./BackNavigation"
import { siteDetails as text } from "../../locales/default.json"
import { PageWithTabsNavSection } from "../shared/Containers"
import { useTracker } from "../../analytics"

const getPathToScreen = (screen, siteId, organizationId) =>
  `/dashboard/${organizationId}/sites/${screen}/${siteId}`

// Remove the back navigation component when we implement the global nav
const SiteTabsNav = ({ activeStep, siteId, organizationId, name, flags }) => {
  const { trackButtonClicked } = useTracker()
  return (
    <PageWithTabsNavSection>
      <TabsNav aria-label="Site menu">
        <BackNavigation title={name} to={`/dashboard/sites`} />
        <Tabs>
          <Tab
            to={
              activeStep !== `overview`
                ? getPathToScreen(`overview`, siteId, organizationId)
                : ``
            }
            data-cy="site-details-overview-tab-button"
            className={activeStep === `overview` ? `active` : ``}
            onClick={() => {
              trackButtonClicked(text.overview, {
                siteId,
                uiSource: `Site Settings`,
              })
            }}
          >
            {text.overview}
          </Tab>
          <Tab
            to={
              activeStep !== `settings`
                ? getPathToScreen(`settings`, siteId, organizationId)
                : ``
            }
            data-cy="site-details-settings-tab-button"
            className={activeStep === `settings` ? `active` : ``}
            onClick={() => {
              trackButtonClicked(text.settings, {
                siteId,
                uiSource: `Site Settings`,
              })
            }}
          >
            {text.settings}
          </Tab>
          {flags.integrations && (
            <Tab
              to={
                activeStep !== `integrations`
                  ? getPathToScreen(`integrations`, siteId, organizationId)
                  : ``
              }
              data-cy="site-details-integrations-tab-button"
              className={activeStep === `integrations` ? `active` : ``}
              onClick={() => {
                trackButtonClicked(text.integrations, {
                  siteId,
                  uiSource: `Site Settings`,
                })
              }}
            >
              {text.integrations}
            </Tab>
          )}
        </Tabs>
      </TabsNav>
    </PageWithTabsNavSection>
  )
}

SiteTabsNav.propTypes = {
  siteId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  name: PropTypes.string,
}

export default withFlags(SiteTabsNav)
