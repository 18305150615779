import React from "react"
import styled from "react-emotion"
import { MdMoreVert } from "react-icons/md"
import { MenuButton as BaseMenuButton } from "@reach/menu-button"
import { colors } from "../../../../utils/presets"

const MenuButtonRoot = styled(BaseMenuButton)`
  align-items: center;
  cursor: pointer;
  display: flex;
  fill: ${colors.lilac};
`

const MenuButton = props => (
  <MenuButtonRoot {...props}>
    <MdMoreVert aria-hidden />
  </MenuButtonRoot>
)

export default MenuButton
