import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { ApolloFragment } from "apollo-fragment-react"
import { graphql } from "react-apollo"
import gql from "graphql-tag"
import { withFlags } from "ld-react"

import SiteContributors from "./SiteContributors/SiteContributors"
import DeleteSite from "./DeleteSite"
import EnvVars from "../../shared/EnvVars"
import VisitorAccess from "./VisitorAccess"
import WebhookCard from "../../shared/WebhookCard"
import PreviewTarget from "./PreviewTarget"
import SiteIntegrations from "./SiteIntegrations"
import {
  StandardSingleColumn,
  PageWithTabsContentSection,
} from "../../shared/Containers"
import { useTracker } from "../../../analytics"
import { ENV_VARS_QUERY } from "../../../graphql/queries"

// leaving this permissions fragment intact because these are either ternarys
// or combination checks (not translating to permission component)
const permissionsFragment = `
  fragment sitePermissionsFields on Site {
    id
    name
    permissions {
      sites {
        edit
        delete
      }
      contributor {
        read
        invite
        delete
        deleteSelf
      }
    }
  }
`

const Settings = ({
  siteName,
  siteId,
  organizationId,
  orgName,
  sitePreviewPassword,
  sitePreviewProtection,
  sitePreviewDirectory,
  siteBranch,
  envVarsQuery,
  flags,
}) => {
  const { trackPageViewed } = useTracker()

  useEffect(() => {
    trackPageViewed(`Site Settings`)
  }, [])

  return (
    <PageWithTabsContentSection>
      <ApolloFragment id={siteId} fragment={permissionsFragment}>
        {({ data }) => {
          const {
            name: siteName,
            permissions: {
              sites: { edit, delete: deleteSitePermission },
              contributor: {
                read,
                invite,
                delete: deleteContributorPermission,
              },
            },
          } = data

          return (
            <StandardSingleColumn>
              {process.env.GATSBY_SHOW_PREVIEW_VISIBILITY_SETTINGS ===
                `true` && (
                <VisitorAccess
                  siteId={siteId}
                  organizationId={organizationId}
                  sitePreviewProtection={sitePreviewProtection}
                  sitePreviewPassword={sitePreviewPassword}
                />
              )}
              <PreviewTarget
                siteId={siteId}
                permissionToEdit={edit}
                sitePreviewDirectory={sitePreviewDirectory}
                siteBranch={siteBranch}
              />
              {flags.integrations && (
                <SiteIntegrations
                  siteId={siteId}
                  organizationId={organizationId}
                  siteName={siteName}
                  orgSiteName={`${orgName}/${siteName}`}
                />
              )}
              <EnvVars
                siteId={siteId}
                organizationId={organizationId}
                envVarsQuery={envVarsQuery}
                loading={envVarsQuery.loading}
                error={envVarsQuery.error}
              />
              <WebhookCard siteId={siteId} origin="settings" />
              <SiteContributors
                siteId={siteId}
                organizationId={organizationId}
                permissionToInvite={invite}
                permissionToRead={read}
                permissionToDelete={deleteContributorPermission}
              />
              <DeleteSite
                siteId={siteId}
                organizationId={organizationId}
                siteName={siteName}
                permissionToDelete={deleteSitePermission}
              />
            </StandardSingleColumn>
          )
        }}
      </ApolloFragment>
    </PageWithTabsContentSection>
  )
}

Settings.propTypes = {
  siteId: PropTypes.string.isRequired,
}

export default graphql(ENV_VARS_QUERY, {
  name: `envVarsQuery`,
  options: props => {
    return {
      variables: {
        id: props.siteId,
      },
    }
  },
})(withFlags(Settings))
