import React, { useState, useContext } from "react"
import styled from "react-emotion"
import gql from "graphql-tag"
import { compose, graphql } from "react-apollo"
import { Formik, Form as BaseForm, ErrorMessage } from "formik"
import * as Yup from "yup"

import { MdErrorOutline } from "react-icons/md"
import { FieldError as BaseFieldError } from "../../shared/Forms"
import { palette, spaces, fontSizes } from "../../../utils/presets"
import RepositoryList from "./RepositoryList"
import Loading from "../../shared/Loading"
import ErrorModal from "../../shared/ErrorModal"
import { ModalContext } from "../../Modal"
import Footer from "../Footer"
import {
  NoGatsbyError,
  DefaultError,
  NoOrganizationPermissionError,
} from "../../shared/ErrorModal"
// import { Debug } from "../../../utils/debug/Formik"

const USER_REPOS_QUERY = gql`
  query UserReposQuery(
    $searchText: String
    $organizationName: String
    $endCursor: String
  ) {
    repositoriesForUser(
      searchText: $searchText
      organizationName: $organizationName
      endCursor: $endCursor
    ) {
      repos @connection(key: "repos") {
        id
        name
        nameWithOwner
        isPrivate
        url
        updatedAt
        createdAt
      }
      pageInfo {
        lastId
        hasNextPage
      }
    }
  }
`

const CREATE_SITE_MUTATION = gql`
  mutation createSite(
    $nameWithOwner: String!
    $branch: String
    $directoryPath: String
  ) {
    createSite(
      nameWithOwner: $nameWithOwner
      branch: $branch
      directoryPath: $directoryPath
    ) {
      site {
        id
      }
      validation {
        success
        message
        errorType
      }
    }
  }
`

const Form = styled(BaseForm)`
  margin: 0;
`

const FieldError = styled(BaseFieldError)`
  padding-top: ${spaces.l};
  text-align: center;
  font-size: ${fontSizes.xs};
`

export const FooterHint = styled(`p`)`
  align-items: center;
  color: ${palette.grey[600]};
  display: flex;
  font-size: ${fontSizes.xs};
  line-height: 1.3;
  margin: 0;

  svg {
    color: ${palette.grey[400]};
    flex-shrink: 0;
    height: auto;
    margin-right: ${spaces.xs};
    width: 1.3rem;
  }
`

const UserRepositories = ({
  formState,
  nickname,
  selectRepo,
  createSiteMutation,
  userReposQuery,
  cancel,
}) => {
  const [createSiteLoading, setLoading] = useState(false)
  const [branch, setBranch] = useState(`master`)
  const { showModal } = useContext(ModalContext)
  const { loading, error, fetchMore } = userReposQuery
  if (loading) {
    return (
      <Loading
        delay={1000}
        message="loading your sites..."
        fullPageHeight={false}
      />
    )
  }

  if (error) return <ErrorModal errMsg={error.message} />

  const {
    repositoriesForUser: { repos, pageInfo },
  } = userReposQuery

  const loadRepos = () => {
    if (!pageInfo.hasNextPage) {
      return
    }

    return fetchMore({
      variables: {
        endCursor: pageInfo.lastId,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult
        }

        previousResult.repositoriesForUser.repos = [
          ...previousResult.repositoriesForUser.repos,
          ...fetchMoreResult.repositoriesForUser.repos,
        ]

        previousResult.repositoriesForUser.pageInfo =
          fetchMoreResult.repositoriesForUser.pageInfo

        return previousResult
      },
    })
  }

  const validationSchema = Yup.object().shape({
    nameWithOwner: Yup.string().required(
      `You must select a repository to continue`
    ),
  })

  return createSiteLoading ? (
    <Loading
      message="creating your site..."
      fullPageHeight={false}
      style={{ marginTop: spaces.xl }}
    />
  ) : (
    <Formik
      initialValues={{ nameWithOwner: ``, directoryPath: `` }}
      validationSchema={validationSchema}
      onSubmit={values => {
        setLoading(!createSiteLoading)

        return createSiteMutation({
          variables: {
            nameWithOwner: values.nameWithOwner,
            directoryPath: values.directoryPath,
            branch,
          },
        })
          .then(({ data }) => {
            const createSiteResult = data.createSite
            const success = createSiteResult.validation.success
            const errorType = createSiteResult.validation.errorType
            setLoading(false)
            if (success) return selectRepo(data.createSite.site.id)
            if (errorType === `NO_ORGANIZATION_PERMISSION`) {
              return showModal({
                Component: NoOrganizationPermissionError,
              })
            }
            showModal({
              Component: NoGatsbyError,
            })
          })
          .catch(err => {
            setLoading(false)
            showModal({
              Component: DefaultError,
              props: { errMsg: err.message },
            })
          })
      }}
    >
      {props =>
        repos.length === 0 ? (
          <p>No results</p>
        ) : (
          <Form>
            <RepositoryList
              repositories={repos}
              loadRepositories={loadRepos}
              setBranch={branch => setBranch(branch)}
              branch={branch}
            />
            <ErrorMessage name={`nameWithOwner`}>
              {msg => (
                <FieldError>
                  <MdErrorOutline /> {msg}
                </FieldError>
              )}
            </ErrorMessage>
            <Footer
              cancel={cancel}
              submitForm={props.submitForm}
              buttonText="Next"
            />
            {/* <Debug /> */}
          </Form>
        )
      }
    </Formik>
  )
}

export default compose(
  graphql(USER_REPOS_QUERY, {
    name: `userReposQuery`,
    options: props => {
      return {
        variables: {
          searchText: props.formState[`input`],
          organizationName:
            props.formState[`selectedOrg`] === null
              ? props.nickname
              : props.formState[`selectedOrg`],
        },
      }
    },
  }),
  graphql(CREATE_SITE_MUTATION, {
    name: `createSiteMutation`,
  })
)(UserRepositories)
