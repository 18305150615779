import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { graphql } from "react-apollo"
import gql from "graphql-tag"
import { navigate } from "gatsby"
import { withFlags } from "ld-react"

import Loading from "../shared/Loading"
import SiteTabsNav from "./SiteTabsNav"
import Settings from "./Settings"
import Overview from "./Overview"
import Integrations from "./Integrations"
import {
  PERMISSIONS_SITE_FRAGMENT,
  PERMISSIONS_ORGANIZATION_FRAGMENT,
} from "../../permissions/fragments"
import { setSiteEnvVars } from "../../utils/helpers/fullstory"

const SITE_DETAILS_QUERY = gql`
  query siteDetailsQuery($id: UUID!) {
    siteDetails(id: $id) {
      id
      organizationId
      name
      previewUrl
      buildsEnabled
      branch
      previewStatus
      previewPassword
      previewProtection
      previewDirectory
      permissions {
        ...sitePermissionsFragment
      }
    }
  }

  ${PERMISSIONS_SITE_FRAGMENT}
`

const ORG_DETAILS_QUERY = gql`
  query organizationDetails($id: UUID!) {
    organizationDetails(id: $id) {
      id
      name
      billing {
        id
        status
      }
      permissions {
        ...orgPermissionsFragment
      }
    }
  }

  ${PERMISSIONS_ORGANIZATION_FRAGMENT}
`

let SiteDetailsWrapped = ({
  path,
  organizationId,
  siteId,
  siteDetailsQuery,
  orgDetails,
  flags,
}) => {
  const { loading, error } = siteDetailsQuery
  if (loading)
    return <Loading delay={1000} message="loading your site details..." />
  if (error) {
    navigate(`/dashboard/sites`)
    return null
  }
  const {
    siteDetails: {
      name,
      previewUrl,
      branch,
      previewStatus,
      previewProtection,
      previewPassword,
      buildsEnabled,
      previewDirectory,
      permissions: {
        role,
        sites: { edit },
      },
    },
  } = siteDetailsQuery
  const { name: orgName } = orgDetails
  // Record user role for site in fullstory
  setSiteEnvVars(role)
  const setActiveStep = () => {
    if (flags.integrations && path.includes(`integrations`))
      return `integrations`
    if (path.includes(`settings`)) return `settings`

    return `overview`
  }

  return (
    <Fragment>
      <SiteTabsNav
        siteId={siteId}
        organizationId={organizationId}
        name={name}
        activeStep={setActiveStep()}
      />
      {path.includes(`settings`) && (
        <Settings
          siteName={name}
          siteId={siteId}
          organizationId={organizationId}
          sitePreviewPassword={previewPassword}
          sitePreviewProtection={previewProtection}
          sitePreviewDirectory={previewDirectory}
          siteBranch={branch}
          orgName={orgName}
        />
      )}
      {path.includes(`overview`) && (
        <Overview
          organizationId={organizationId}
          siteId={siteId}
          siteName={name}
          buildsEnabled={buildsEnabled}
          sitePreviewPassword={previewPassword}
          sitePreviewProtection={previewProtection}
          sitePreviewDirectory={previewDirectory}
          sitePreviewStatus={previewStatus}
          siteBranch={branch}
          previewUrl={previewUrl}
          permissionToEdit={edit}
        />
      )}
      {flags.integrations && path.includes(`integrations`) && (
        <Integrations siteId={siteId} siteName={name} orgName={orgName} />
      )}
    </Fragment>
  )
}

SiteDetailsWrapped.propTypes = {
  siteId: PropTypes.string.isRequired,
}

SiteDetailsWrapped = graphql(SITE_DETAILS_QUERY, {
  name: `siteDetailsQuery`,
  options: props => {
    return {
      name: `siteDetails`,
      variables: { id: props.siteId },
      fetchPolicy: `cache-and-network`,
    }
  },
})(withFlags(SiteDetailsWrapped))

const SiteDetails = ({
  path,
  siteId,
  organizationId,
  organizationDetailsQuery,
}) => {
  const { loading, error, organizationDetails } = organizationDetailsQuery
  if (loading)
    return <Loading delay={1000} message="loading your site details..." />
  if (error) {
    navigate(`/dashboard/sites`)
    return null
  }

  return (
    <SiteDetailsWrapped
      organizationId={organizationId}
      orgDetails={organizationDetails}
      siteId={siteId}
      path={path}
    />
  )
}

SiteDetails.propTypes = {
  location: PropTypes.object.isRequired,
  siteId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
}

export default graphql(ORG_DETAILS_QUERY, {
  name: `organizationDetailsQuery`,
  options: props => {
    return {
      name: `organizationDetails`,
      variables: { id: props.organizationId },
      fetchPolicy: `cache-and-network`,
    }
  },
})(SiteDetails)
