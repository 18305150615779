import React from "react"
import styled from "react-emotion"
import PropTypes from "prop-types"

import DotsFour from "../../../assets/DotsFour"

import { EmphaticHeading } from "../../shared/Typography"
import { CloseButton } from "../FullModalElements"
import {
  breakpoints,
  fontFamilies,
  fontSizes,
  palette,
  spaces,
} from "../../../utils/presets"

const contentMaxWidth = `46rem`

const LayoutRoot = styled(`div`)`
  padding: 0 ${spaces.l};

  @media (min-width: ${breakpoints.desktop}px) {
    padding: 0;
  }
`

const Dots = styled(DotsFour)`
  position: absolute;
  height: ${spaces[`5xl`]};
  left: 0;
  top: -${spaces[`2xl`]};
  width: auto;

  @media (min-width: ${breakpoints.desktop}px) {
    top: ${spaces[`4xl`]};
  }
`

const Brand = styled(`span`)`
  align-items: center;
  border-radius: 50%;
  background: white;
  display: flex;
  position: absolute;
  justify-content: center;
  left: 0;
  top: 0;
  transform: translate(-${spaces[`5xl`]}, -${spaces.l});
  height: ${spaces[`4xl`]};
  width: ${spaces[`4xl`]};

  svg {
    width: ${spaces[`2xl`]};
    height: ${spaces[`2xl`]};
  }
`

const Heading = styled(EmphaticHeading)`
  font-size: ${fontSizes[`2xl`]};
  width: ${contentMaxWidth};
  max-width: 100%;
  position: relative;
  margin: 0 auto;

  &:before {
    content: "";
    position: absolute;
    top: 0.5em;
    width: 100%;
    height: 1px;
    background: ${palette.grey[200]};
  }

  @media (min-width: ${breakpoints.desktop}px) {
    font-size: ${fontSizes[`3xl`]};
  }
`

const Title = styled(`span`)`
  background: white;
  position: relative;
  padding-right: ${spaces.m};
`

const Header = styled(`header`)`
  font-size: ${fontSizes[`2xl`]};
  margin: ${spaces[`4xl`]} 0 ${spaces.m};
  justify-content: center;
  position: relative;

  @media (min-width: ${breakpoints.desktop}px) {
    font-size: ${fontSizes[`3xl`]};
  }

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0.5em;
    width: 100%;
    height: 1px;
    background: ${palette.grey[200]};
  }
`

const Content = styled(`div`)`
  margin: ${spaces.l} auto ${spaces[`2xl`]};
  max-width: 100%;
  width: ${contentMaxWidth};
`

export const Instruction = styled(`p`)`
  color: ${palette.grey[700]};
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${fontSizes.l};
  line-height: 1.5;
  margin: 0;

  strong {
    color: ${palette.purple[500]};
  }
`

export const Actions = styled(`div`)`
  display: flex;
  justify-content: space-between;
  margin-top: ${spaces[`2xl`]};
`

export const Layout = ({ title, children, closeModal, logo: Logo }) => (
  <LayoutRoot>
    <CloseButton onClick={closeModal} aria-label="Close modal">
      close
    </CloseButton>
    <Dots />
    <Header>
      <Heading>
        <Brand>
          <Logo />
        </Brand>
        <Title>{title}</Title>
      </Heading>
    </Header>
    <Content>{children}</Content>
  </LayoutRoot>
)

Layout.propTypes = {
  title: PropTypes.string.isRequired,
}

/* end of Integration layout */
