import React from "react"
import PropTypes from "prop-types"
import ContentfulMonogram from "../../../assets/ContentfulMonogram"

import { Layout } from "../../Modal/fullModalLayouts/IntegrationLayout"
import ContentfulForm from "./ContentfulForm"

export const EditContentfulIntegration = ({
  siteId,
  closeModal,
  orgSiteName,
  name,
  showToast,
}) => (
  <Layout
    title="Contentful Integration"
    closeModal={closeModal}
    logo={ContentfulMonogram}
  >
    <ContentfulForm
      siteId={siteId}
      closeModal={closeModal}
      orgSiteName={orgSiteName}
      name={name}
      showToast={showToast}
    />
  </Layout>
)

EditContentfulIntegration.propTypes = {
  siteId: PropTypes.string,
  name: PropTypes.string,
}

export default EditContentfulIntegration
