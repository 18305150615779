import React from "react"
import { Formik } from "formik"
import styled from "react-emotion"
import { compose, graphql } from "react-apollo"

import {
  StandardSingleColumn,
  PageWithTabsContentSection,
} from "../../shared/Containers"
import { spaces, palette, fontSizes } from "../../../utils/presets"
import {
  Form as BaseForm,
  RadioInputGroupBlock,
  FieldBlock,
} from "../../shared/Forms"
import { CmsIntegrationCard } from "../../shared/CmsIntegration/CmsIntegrationCard"
import { SITE_INTEGRATIONS_QUERY } from "../../../graphql/queries"

const Form = styled(BaseForm)`
  display: flex;
  flex-direction: column;

  ${FieldBlock} {
    align-items: center;
    display: flex;

    > label {
      color: ${palette.grey[700]};
      font-size: ${fontSizes.m};
    }
  }
`

const IntegrationList = styled(`div`)`
  margin: ${spaces.xl} 0;
`

const FilterRadio = styled(RadioInputGroupBlock)`
  margin-left: ${spaces.xs};
`

const Integrations = ({
  siteId,
  loading,
  error,
  cmsIntegrations,
  siteName,
  orgName,
}) => (
  <PageWithTabsContentSection>
    <StandardSingleColumn>
      <Formik
        initialValues={{ filter: `All` }}
        validationSchema={{}}
        onSubmit={values => {}}
      >
        {({ values }) => (
          <Form>
            {` `}

            <FilterRadio
              label="Filter integrations: "
              fieldName="filter"
              options={[
                {
                  label: `All`,
                  value: `All`,
                },
                {
                  label: `Connected`,
                  value: `Connected`,
                },
              ]}
            />

            <IntegrationList>
              <CmsIntegrationCard
                siteId={siteId}
                repository={{ nameWithOwner: `${orgName}/${siteName}` }}
                loading={loading}
                error={error}
                cmsIntegrations={cmsIntegrations}
                values={values}
                origin="settings"
              />
            </IntegrationList>
          </Form>
        )}
      </Formik>
    </StandardSingleColumn>
  </PageWithTabsContentSection>
)

export default compose(
  graphql(SITE_INTEGRATIONS_QUERY, {
    options: ({ siteId }) => {
      return {
        variables: {
          siteId,
        },
        fetchPolicy: `cache-and-network`,
      }
    },
    props: ({ data, ...rest }) => {
      return {
        loading: data.loading,
        cmsIntegrations: (data && data.cmsIntegrationsForSite) || [],
        error: data.error,
        ...rest,
      }
    },
  })
)(Integrations)
