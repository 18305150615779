import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "react-emotion"
import {
  palette,
  breakpoints,
  spaces,
  fontSizes,
  fontFamilies,
} from "../../../utils/presets"

import { Subheading } from "../Typography"
import { Card } from "../Card"
import PreviewStatus from "../../shared/PreviewStatus"
import { MdArrowForward, MdOpenInNew } from "react-icons/md"
import { sites as sitesText } from "../../../locales/default.json"
import { formatDistance } from "date-fns"
import { useTracker } from "../../../analytics"
import { OrganizationPermissions } from "../../../permissions"
import { getGithubAppInstallUrl } from "../../../auth"

const SiteCardContainer = styled(Card.withComponent(`li`))`
  margin-bottom: ${spaces.m};
  margin-top: ${spaces.l};
`

const SiteCardRow = styled(`div`)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 ${spaces.m} 0;
`

const SiteDetailsWrapper = styled(`div`)`
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: ${spaces[`xs`]};

  @media (min-width: ${breakpoints.tablet}px) {
    margin-top: 0;
  }
`

const SitePreviewUrl = styled(`a`)`
  color: ${palette.purple[500]};
  font-size: ${fontSizes[`xs`]};
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.25px;
  margin: 0 ${spaces[`2xs`]} 0 0;
  text-decoration: none;

  :hover {
    color: ${palette.purple[400]};
    text-decoration: underline;
  }
`

const LaunchIcon = styled(MdOpenInNew)`
  display: inline-block;
  margin-left: ${spaces[`2xs`]};
  vertical-align: middle;
`

const SiteDetailsLink = styled(Link)`
  color: ${palette.purple[500]};
  font-size: ${fontSizes[`xs`]};
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.25px;
  margin-left: auto;
  text-decoration: none;

  :hover {
    color: ${palette.purple[400]};
    text-decoration: underline;
  }

  > svg {
    vertical-align: middle;
  }
`

const LastUpdatedLabel = styled(`span`)`
  color: ${palette.grey[600]};
  font-size: ${fontSizes[`2xs`]};
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.25px;
  margin: 0 ${spaces.m} 0 0;
`

export const SiteListContainer = styled(`ul`)`
  list-style: none;
  margin: 0;
  padding: 0;
`
export const SiteHeader = styled(Subheading)`
  color: ${palette.purple[900]};
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${fontSizes.m};
  line-height: normal;
  margin: 0 ${spaces[`m`]} 0 0;
  width: 100%;

  @media (min-width: ${breakpoints.tablet}px) {
    width: inherit;
  }
`

export const NoSitesHeader = styled(SiteHeader)`
  color: ${palette.grey[600]};
`

export const NoSitesCard = styled(SiteCardContainer)`
  background: ${palette.grey[100]};
`

const SiteCard = ({ site, organizationId }) => {
  const { trackButtonClicked } = useTracker()
  const {
    id: siteId,
    updatedAt,
    previewUrl,
    repositoryId,
    previewStatus,
    name: siteName,
  } = site

  return (
    <SiteCardContainer>
      <SiteCardRow data-cy={site.name}>
        <SiteHeader data-cy={site.name + `-header`}>{siteName}</SiteHeader>
        <SiteDetailsWrapper>
          {previewStatus === `READY` && (
            <SitePreviewUrl
              target="_blank"
              href={previewUrl}
              onClick={() => {
                trackButtonClicked(`Sites Preview URL`, {
                  siteId,
                  organizationId,
                  uiSource: `Sites List`,
                })
              }}
            >
              {previewUrl}
              <LaunchIcon />
            </SitePreviewUrl>
          )}

          {previewStatus === `UNAUTHORIZED` && (
            <OrganizationPermissions
              id={organizationId}
              resource="sites"
              action="create"
            >
              <SitePreviewUrl
                href={getGithubAppInstallUrl({
                  repositoryId,
                  siteId,
                })}
                onClick={() => {
                  trackButtonClicked(`Connect Github App`, {
                    siteId,
                    organizationId,
                    uiSource: `Sites List`,
                  })
                }}
              >
                {sitesText.authorizeGithubApp}
                <LaunchIcon />
              </SitePreviewUrl>
            </OrganizationPermissions>
          )}

          <SiteDetailsLink
            data-cy="view-site-details-link"
            to={`/dashboard/${organizationId}/sites/overview/${siteId}`}
            onClick={() => {
              trackButtonClicked(sitesText.viewDetails, {
                siteId,
                organizationId,
                uiSource: `Sites List`,
              })
            }}
          >
            {sitesText.viewDetails} <MdArrowForward />
          </SiteDetailsLink>
        </SiteDetailsWrapper>
      </SiteCardRow>
      <SiteCardRow>
        <PreviewStatus status={previewStatus} />
        <LastUpdatedLabel>
          {`${sitesText.updated} ${formatDistance(
            new Date(updatedAt),
            new Date(),
            { addSuffix: true }
          )}`}
        </LastUpdatedLabel>
      </SiteCardRow>
    </SiteCardContainer>
  )
}

SiteCard.propTypes = {
  site: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
}

export { SiteCard }
