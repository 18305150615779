import styled from "react-emotion"
import hex2rgba from "hex2rgba"

import { fontSizes, palette, spaces, radius } from "../../utils/presets"

const modes = {
  red: { color: palette.red[800], bg: hex2rgba(palette.red[200], 0.1) },
  purple: {
    color: palette.purple[800],
    bg: hex2rgba(palette.purple[400], 0.1),
  },
  green: { color: palette.green[800], bg: hex2rgba(palette.green[200], 0.1) },
  grey: { color: palette.grey[800], bg: hex2rgba(palette.grey[400], 0.1) },
}

const Badge = styled(`span`)`
  border-radius: ${radius.default};
  font-size: ${fontSizes[`2xs`]};
  letter-spacing: 0.02em;
  padding: ${spaces[`2xs`]} ${spaces.s};
  display: inline-block;
  background: ${props =>
    props.mode ? modes[props.mode].bg : modes[`grey`].bg};
  color: ${props =>
    props.mode ? modes[props.mode].color : modes[`grey`].color};
`

export default Badge
