import React from "react"
import styled, { keyframes } from "react-emotion"
import hex2rgba from "hex2rgba"

import { Heading, Text } from "../shared/Typography"
import { PrimaryButton } from "../shared/Buttons"
import { MdClose } from "react-icons/md"
import { Form } from "../shared/Forms"
import {
  breakpoints,
  fontSizes,
  palette,
  radius,
  spaces,
} from "../../utils/presets"

const entry = keyframes`
  100% {
     opacity: 1
  }
`

const iconEntry = keyframes`
  70% {
     transform: scale(1.25);
  }
  100% {
     transform: scale(1);
  }
`

const CloseButtonRoot = styled(`button`)`
  aling-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  transform: translateX(30%);
  width: 40px;
  svg {
    height: 20px;
    width: 20px;
  }
`

export const CloseButton = props => (
  <CloseButtonRoot {...props}>
    <MdClose />
  </CloseButtonRoot>
)

export const Section = styled(`div`)`
  display: flex;
  padding: ${spaces.m};
  background: ${palette.white};

  @media (min-width: ${breakpoints.mobile}px) {
    padding: ${spaces.l};
  }
  @media (min-width: ${breakpoints.phablet}px) {
    padding: ${spaces.xl};
  }
`

export const ModalPrimaryHeader = styled(Section)`
  align-items: center;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: ${radius.large} ${radius.large} 0 0;

  ${Heading} {
    animation: ${entry} 0.75s 0.7s ease forwards;
    opacity: 0;
    text-align: center;

    strong {
      color: ${palette.red[600]};

      .success & {
        color: ${palette.green[900]};
      }
    }
  }
`

export const ModalPrimaryIcon = styled(`div`)`
  animation: ${iconEntry} 0.75s 0.6s ease forwards;
  height: 40px;
  margin-bottom: ${spaces.l};
  transform: scale(0);
  width: 40px;
  svg {
    height: 100%;
    width: 100%;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    height: 60px;
    margin-bottom: ${spaces.xl};
    width: 60px;
  }
`

export const ModalSecondaryHeader = styled(Section)`
  align-items: center;
  border-radius: ${radius.large} ${radius.large} 0 0;
  justify-content: space-between;
  padding: ${spaces.l} ${spaces.xl};

  @media (min-width: ${breakpoints.mobile}px) {
    padding: ${spaces.l} ${spaces.xl};
  }

  @media (min-width: ${breakpoints.phablet}px) {
    padding: ${spaces.l} ${spaces[`2xl`]};
  }

  ${Heading} {
    animation: ${entry} 0.75s 0.7s ease forwards;
    font-size: ${fontSizes.l};
    opacity: 0;

    strong {
      color: ${palette.red[600]};
    }
  }
`

export const ModalBody = styled(Section)`
  animation: ${entry} 0.75s 0.8s ease forwards;
  border-radius: 0 0 ${radius.large} ${radius.large};
  flex-direction: column;
  opacity: 0;
  padding-top: ${spaces.m};

  @media (min-width: ${breakpoints.mobile}px) {
    padding-top: ${spaces.l};
  }
  @media (min-width: ${breakpoints.phablet}px) {
    padding-top: ${spaces.xl};
  }

  .error & {
    background: ${palette.red[50]};
    border-top: 1px solid ${palette.red[100]};
  }

  .action & {
    background: ${palette.purple[50]};
    border-top: 1px solid ${palette.purple[100]};
  }

  .success & {
    background: ${palette.green[50]};
    border-top: 1px solid ${palette.green[100]};
  }

  .warning &,
  .retake & {
    position: relative;

    :before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background-size: 50px 50px;
    }
  }

  .warning & {
    background: ${palette.red[50]};

    :before {
      background-color: ${hex2rgba(palette.red[500], 0.8)};
      background-image: linear-gradient(
        130deg,
        ${hex2rgba(palette.white, 0.5)} 25%,
        transparent 25%,
        transparent 50%,
        ${hex2rgba(palette.white, 0.5)} 50%,
        ${hex2rgba(palette.white, 0.5)} 75%,
        transparent 75%,
        transparent
      );
    }
  }

  .retake & {
    background: ${palette.purple[50]};

    :before {
      background-color: ${hex2rgba(palette.purple[500], 0.8)};
      background-image: linear-gradient(
        130deg,
        ${hex2rgba(palette.white, 0.5)} 25%,
        transparent 25%,
        transparent 50%,
        ${hex2rgba(palette.white, 0.5)} 50%,
        ${hex2rgba(palette.white, 0.5)} 75%,
        transparent 75%,
        transparent
      );
    }
  }
`

export const ModalMessage = styled(`div`)`
  ${Text} {
    width: 100%;

    &:first-child {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const ModalEmphasizedText = styled(Text)`
  font-weight: bold;
  margin-top: 0;

  svg {
    margin-right: ${spaces.xs};
    vertical-align: middle;
    margin-top: -0.1rem;
  }
`

export const ModalForm = styled(Form)`
  margin-top: ${spaces.m};

  ${ModalBody} &:first-child {
    margin-top: 0;
  }
`

export const ModalAction = styled(`div`)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: ${spaces.l};

  @media (min-width: ${breakpoints.desktop}px) {
    margin-top: ${spaces.xl};
  }

  .success & ${PrimaryButton} {
    background: ${palette.green[500]};

    :focus,
    :hover {
      background: ${palette.green[600]};
    }
  }

  .warning & ${PrimaryButton} {
    background: ${palette.red[500]};

    :focus,
    :hover {
      background: ${palette.red[600]};
    }
  }
`

export const ModalActions = styled(ModalAction)`
  justify-content: space-between;

  ${ModalForm} &:first-child {
    margin-top: ${spaces.s};
  }
`

export const ConfirmButton = styled(PrimaryButton)`
  animation: ${entry} 0.75s 0.9s ease forwards;
  background: ${palette.grey[900]};
  font-size: ${fontSizes.s};
  opacity: 0;

  :focus,
  :hover {
    background: ${palette.grey[700]};
  }
`
