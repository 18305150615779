import React from "react"
import styled from "react-emotion"
import PropTypes from "prop-types"
import { MdHelpOutline } from "react-icons/md"

import { fontSizes, palette, colors, spaces } from "../../../utils/presets"
import { Heading, Text } from "../../shared/Typography"
import { SettingsCard } from "../../shared/Settings/SettingsCard"
import { setupSite as text } from "../../../locales/default.json"

const BranchSettingsCard = styled(SettingsCard)`
  display: flex;
  margin-top: ${spaces[`2xl`]};
`

const Title = styled(Heading)`
  align-items: center;
  color: ${palette.grey[800]};
  display: flex;
  font-size: ${fontSizes.l};
  margin-right: ${spaces[`2xl`]};

  svg {
    margin-left: ${spaces.xs};
    fill: ${colors.gatsby};
  }
`

const Name = styled(Text)`
  margin: 0;
`

const Icon = styled(MdHelpOutline)`
  height: 1rem;
  width: 1rem;
`

const BranchCard = ({ branch }) => (
  <BranchSettingsCard>
    <Title>
      {text.pickBranch}
      {` `}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${window.location.origin}/docs/`}
        aria-label="Branch docs"
      >
        <Icon />
      </a>
    </Title>
    <Name>{branch}</Name>
  </BranchSettingsCard>
)

BranchCard.propTypes = {
  branch: PropTypes.string,
}

export default BranchCard
