import React from "react"
import styled from "react-emotion"
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from "react-stripe-elements"

import { Input } from "../shared/Forms"
import {
  palette,
  fontSizes,
  spaces,
  breakpoints,
  radius,
} from "../../utils/presets"

export const Label = styled(`label`)`
  color: ${palette.grey[700]};
  display: flex;
  font-size: ${fontSizes.xs};
  flex-direction: column;
  line-height: 1.5;
  margin-top: ${spaces.m};
`

const Email = styled(Input)`
  @media (min-width: ${breakpoints.tablet}px) {
    margin-right: ${spaces.l};
    width: auto;
  }
`

export const OptionalLabel = styled(Label)`
  width: 100%;
  @media (min-width: ${breakpoints.tablet}px) {
    width: 50%;
  }
`

export const CardLabel = styled(Label)`
  font-size: ${fontSizes.s};
  width: 100%;

  .StripeElement--focus {
    border: 1px solid ${palette.purple[400]};
    box-shadow: 0 0 0 3px ${palette.purple[200]};
    outline: 0;
    transition: box-shadow 0.15s ease-in-out;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    width: 25%;

    :first-child {
      width: 50%;
    }

    :last-child {
      padding-left: ${spaces.l};
    }
  }
`

export const CardNumber = styled(CardNumberElement)`
  background-color: white;
  border-radius: ${radius.default};
  border: 1px solid ${palette.grey[300]};
  margin-right: 0;
  height: 2.25rem;
  padding: ${spaces.s};

  @media (min-width: ${breakpoints.tablet}px) {
    margin-right: ${spaces.l};
  }
`

export const CardExpiry = styled(CardExpiryElement)`
  background-color: white;
  border-radius: ${radius.default};
  border: 1px solid ${palette.grey[300]};
  height: 2.25rem;
  padding: ${spaces.s};
`

export const CardCVC = styled(CardCVCElement)`
  background-color: white;
  border-radius: ${radius.default};
  border: 1px solid ${palette.grey[300]};
  height: 2.25rem;
  padding: ${spaces.s};
`

export const NameInput = ({ field }) => (
  <Input type="text" placeholder="Name" {...field} id="name" />
)

export const EmailInput = ({ field }) => (
  <Email type="email" placeholder="Email" {...field} id="email" />
)

export const CompanyInput = ({ field }) => (
  <Input type="text" placeholder="Company" {...field} id="company" />
)
