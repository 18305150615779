import React from "react"
import { DialogContent } from "@reach/dialog"
import styled, { keyframes } from "react-emotion"

import { palette } from "../../utils/presets"

const entry = keyframes`
  100% {
     transform: translate(0, 0) scale(1) perspective(1000px) rotateX(0);
  }
`

export const ModalContentRoot = styled(DialogContent)`
  animation: ${entry} 0.5s 0.25s ease forwards;
  background: ${palette.white};
  height: 100vh;
  width: 100%;
  outline: none;
  position: relative;
  transform: translate(0, 150vh) scale(0.9) perspective(1000px) rotateX(-90deg);
  transform-origin: top center;
`

const ModalContent = ({ isOpen, mode = `error`, children }) => (
  <ModalContentRoot className={mode} data-testid="modal-content">
    {children}
  </ModalContentRoot>
)

export default ModalContent
