export const trackCardValidation = (track, e, organizationId) => {
  if (!e.empty && !!e.complete) {
    track({
      eventType: `UPDATE_CHECKOUT_FORM`,
      fieldName: e.elementType,
      valid: !e.empty && !e.error && !!e.complete,
      organizationId,
      uiSource: `Pricing Page`,
    })
  } else {
    track({
      eventType: `UPDATE_CHECKOUT_FORM`,
      fieldName: e.elementType,
      valid: false,
      organizationId,
      uiSource: `Pricing Page`,
    })
  }
  return
}

const trackTextValidation = (track, values, err, organizationId) => {
  track({
    eventType: `UPDATE_CHECKOUT_FORM`,
    fieldName: values,
    valid: err,
    organizationId,
    uiSource: `Pricing Page`,
  })
  return
}

export const trackSubmitDisabled = (track, organizationId) => {
  track({
    eventType: `COMPLETED_CHECKOUT_FORM`,
    organizationId,
    valid: true,
    uiSource: `Pricing Page`,
  })
  return
}

export const subscribePlan = (
  createToken,
  name,
  mutate,
  planId,
  company,
  organizationId,
  email,
  setDisabled,
  showError,
  showSuccess
) => {
  createToken({ name })
    .then(({ token }) =>
      mutate({
        variables: {
          input: {
            token: token.id,
            planId,
            companyName: company,
            organizationId,
            billingEmail: email,
            customerName: name,
          },
        },
      })
    )
    .then(data => {
      const {
        data: {
          subscribeToPlan: { errors, subscription },
        },
      } = data

      if (errors && errors.message) {
        showError(errors.message, subscription && subscription.creditCard)
      } else {
        showSuccess(
          subscription.plan,
          subscription.creditCard,
          subscription.nextBillingDate
        )
      }
      return setDisabled(false)
    })
    .catch(err => {
      if (err && err.message) showError(err.message)
      setDisabled(false)
    })
}

export const updatePayment = (
  createToken,
  name,
  mutate,
  organizationId,
  email,
  setDisabled,
  showError,
  showSuccess
) => {
  createToken({ name })
    .then(({ token }) =>
      mutate({
        variables: {
          token: token.id,
          organizationId,
          customerName: name,
          billingEmail: email,
        },
      })
    )
    .then(data => {
      const {
        data: {
          updatePaymentInformation: { errors, creditCard },
        },
      } = data

      if (errors && errors.message) {
        showError(errors.message, creditCard)
        return setDisabled(false)
      }
      return showSuccess(creditCard)
    })
    .catch(err => {
      if (err && err.message) showError(err.message)
      setDisabled(false)
    })
}

export const validateForm = (
  values,
  formState,
  setFormState,
  text,
  organizationId,
  track
) => {
  const errors = {}
  if (!values.name) errors.name = text.requiredField
  if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) &&
    values.email
  )
    errors.email = text.validEmail

  trackTextValidation(
    track,
    values,
    Object.entries(errors).length === 0,
    organizationId
  )

  if (!Object.keys(formState).every(value => value === `valid`)) {
    const newFormState = Object.assign({}, formState)

    for (const key in newFormState) {
      if (newFormState.hasOwnProperty(key)) {
        if (newFormState[key].length === 0) {
          newFormState[key] = text.requiredField
        }

        if (newFormState[key] !== `valid`) {
          errors[key] = newFormState[key]
        }
      }
    }
    setFormState({
      ...newFormState,
    })
  }

  return errors
}
