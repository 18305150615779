import * as uuid from "uuid"
import { navigate } from "gatsby"
import * as qs from "query-string"

const GATSBY_DASHBOARD_AUTHENTICATION_URL =
  process.env.GATSBY_DASHBOARD_AUTHENTICATION_URL || `http://localhost:8083`

const GATSBY_PREVIEW_AUTHENTICATION_URL =
  process.env.GATSBY_PREVIEW_AUTHENTICATION_URL || `http://localhost:8083`

export function authenticatePreview(queryString) {
  const parsed = qs.parse(queryString)
  if (!parsed.csrfToken) {
    parsed.csrfToken = uuid.v4()
  }

  return window.location.assign(
    `${GATSBY_PREVIEW_AUTHENTICATION_URL}/oauth/github?${qs.stringify({
      ...parsed,
      authType: `PREVIEW_AUTH`,
    })}`
  )
}

export function login(queryString) {
  const parsed = qs.parse(queryString)
  if (!parsed.csrfToken && !parsed.invitationToken) {
    parsed.csrfToken = uuid.v4()
  }

  if (process.env.GATSBY_MOCK_MODE) {
    localStorage.setItem(`gatsby:token`, `1234`)
    localStorage.setItem(
      `gatsby:userid`,
      `367764c4-ca45-4d74-a274-ee1f262ac5b2`
    )
    navigate(`/dashboard/sites`)
    return
  }

  // When testing with Cypress, this call will break the tests - you cannot navigate to superdomains in Cypress tests
  // As such, you must authenticate manually with GitHub in Cypress tests
  window.location.assign(
    `${GATSBY_DASHBOARD_AUTHENTICATION_URL}/oauth/github?${qs.stringify(
      parsed
    )}`
  )
}

export function logoutBannedUser() {
  localStorage.setItem(`gatsby:token`, ``)
  localStorage.setItem(`gatsby:userid`, ``)
  navigate(`/dashboard/login?error=blocked`)
}

export function logout() {
  localStorage.setItem(`gatsby:token`, ``)
  localStorage.setItem(`gatsby:userid`, ``)
  navigate(`/dashboard/login`)
}

export function isAuthenticated() {
  const token = localStorage.getItem(`gatsby:token`)
  if (token === `undefined` || token === `null`) {
    return false
  }

  return !!token
}

export function getGithubAppInstallUrl({ siteId, repositoryId }) {
  const URI = `${GATSBY_DASHBOARD_AUTHENTICATION_URL}/oauth/github_start_installation?siteId=${siteId}`
  return URI
}

export function installGithubApplication({ siteId }) {
  if (process.env.GATSBY_MOCK_MODE || process.env.GATSBY_TEST_MODE) {
    navigate(`/dashboard/sites`)
    return
  }
  const URI = `${GATSBY_DASHBOARD_AUTHENTICATION_URL}/oauth/github_start_installation?siteId=${siteId}`
  return window.location.assign(URI)
}
