import React, { Fragment, useContext } from "react"
import PropTypes from "prop-types"
import gql from "graphql-tag"
import { graphql } from "react-apollo"

import { MdSend } from "react-icons/md"
import { Heading } from "../../Typography"
import {
  ModalContext,
  ModalSecondaryHeader,
  ModalBody,
  ModalActions,
  ModalMessage,
  ModalForm,
  CloseButton,
} from "../../../Modal"
import { DefaultError } from "../../../shared/ErrorModal"
import { Text } from "../../Typography"
import { CancelButton, PrimaryButton } from "../../Buttons"
import { toastNotifications as toastText } from "../../../../locales/default.json"
import { manageContributors as text } from "../../../../locales/default.json"

const RESEND_CONTRIBUTORS_INVITATION_MUTATION = gql`
  mutation resendInvitation($id: UUID!) {
    resendInvitation(id: $id) {
      success
      message
    }
  }
`
const ReinviteContributorModal = ({
  closeModal,
  email,
  contributorId,
  resourceType,
  showToast,
  resendContributorsInvitationMuation,
}) => {
  const { showModal } = useContext(ModalContext)

  return (
    <Fragment>
      <ModalSecondaryHeader>
        <Heading>{text.resendInvite}?</Heading>
        <CloseButton onClick={closeModal} />
      </ModalSecondaryHeader>
      <ModalBody>
        <ModalMessage>
          <Text>
            {resourceType === `SITE`
              ? text.resendSiteInviteConf
              : text.resendOrgInviteConf}
            <strong>
              {` `}
              {email}
            </strong>
            ?
          </Text>
        </ModalMessage>
        <ModalForm
          onSubmit={e => {
            e.preventDefault()
            resendContributorsInvitationMuation({
              variables: {
                id: contributorId,
              },
            })
              .then(() => {
                closeModal()
                showToast(
                  `<strong>${toastText.contributorReinvitationSent}</strong> ${
                    toastText.successfully
                  }`
                )
              })
              .catch(err =>
                showModal({
                  Component: DefaultError,
                  props: { errMsg: err.message },
                })
              )
          }}
        >
          <ModalActions>
            <CancelButton type="button" onClick={closeModal}>
              {text.cancel}
            </CancelButton>
            <PrimaryButton type="submit">
              {text.resendInvite} <MdSend />
            </PrimaryButton>
          </ModalActions>
        </ModalForm>
      </ModalBody>
    </Fragment>
  )
}

ReinviteContributorModal.propTypes = {
  closeModal: PropTypes.func,
  email: PropTypes.string,
  siteId: PropTypes.string,
}

export default graphql(RESEND_CONTRIBUTORS_INVITATION_MUTATION, {
  name: `resendContributorsInvitationMuation`,
})(ReinviteContributorModal)
