import React from "react"
import styled from "react-emotion"
import { ErrorMessage } from "formik"
import { MdErrorOutline } from "react-icons/md"

import { palette, fontSizes, spaces } from "../../utils/presets"

const InputError = styled(`span`)`
  align-items: center;
  color: ${palette.red[600]};
  display: flex;
  font-size: ${fontSizes[`2xs`]};
  line-height: 1;
  padding: ${spaces.xs} ${spaces[`2xs`]} 0;

  svg {
    color: red;
    flex-shrink: 0;
    margin-right: ${spaces[`3xs`]};
  }
`

export const GenericError = ({ name }) => (
  <ErrorMessage name={name}>
    {msg => (
      <InputError>
        <MdErrorOutline /> {msg}
      </InputError>
    )}
  </ErrorMessage>
)

export const StripeError = ({ error }) =>
  error && (
    <InputError>
      <MdErrorOutline /> {error}
    </InputError>
  )
