import React from "react"
import gql from "graphql-tag"
import { graphql, ApolloConsumer } from "react-apollo"
import styled from "react-emotion"

import ErrorModal from "../../shared/ErrorModal"
import Loading from "../../shared/Loading"
import {
  PageWithTabsContentSection,
  StandardSingleColumn,
} from "../../shared/Containers"
import { BuildListRoot } from "./BuildList"
import Sidebar from "./Sidebar"
import { breakpoints } from "../../../utils/presets"
import useSubscription from "../../../graphql/subscribe"

export const BUILDS_QUERY = gql`
  query buildsForSite($siteId: UUID!, $pagination: CursorPagination) {
    buildsForSite(siteId: $siteId, pagination: $pagination) {
      builds {
        id
        siteId
        buildType
        buildStatus
        createdAt
        endedAt
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

const OverviewRoot = styled(StandardSingleColumn)`
  display: flex;
  flex-direction: column;
  @media (min-width: ${breakpoints.desktop}px) {
    flex-direction: row;
  }
`

const BUILD_PAGE_LIMIT = 10

const siteChangedSubscription = gql`
  subscription siteChanged($id: UUID!) {
    siteChanged(id: $id) {
      id
      previewStatus
      updatedAt
    }
  }
`

function SidebarWrapper({
  siteId,
  status,
  sitePreviewPassword,
  sitePreviewProtection,
  sitePreviewDirectory,
  client,
  buildsEnabled,
  siteBranch,
  siteName,
  previewUrl,
  lastBuild,
  organizationId,
  builds,
  permissionToEdit,
}) {
  useSubscription(client, siteChangedSubscription, {
    options: {
      variables: { id: siteId },
    },
    deps: [siteId],
  })
  return (
    (status !== `PENDING` || builds.length > 0) && (
      <Sidebar
        organizationId={organizationId}
        buildsEnabled={buildsEnabled}
        sitePreviewStatus={status}
        sitePreviewPassword={sitePreviewPassword}
        sitePreviewProtection={sitePreviewProtection}
        sitePreviewDirectory={sitePreviewDirectory}
        siteBranch={siteBranch}
        siteId={siteId}
        siteName={siteName}
        previewUrl={previewUrl}
        lastBuild={lastBuild}
        permissionToEdit={permissionToEdit}
      />
    )
  )
}

const Overview = ({
  siteId,
  buildsQuery,
  organizationId,
  sitePreviewStatus,
  sitePreviewProtection,
  permissionToEdit,
  buildsEnabled,
  sitePreviewPassword,
  sitePreviewDirectory,
  ...rest
}) => {
  const { loading, error, fetchMore } = buildsQuery
  const builds =
    (buildsQuery &&
      buildsQuery.buildsForSite &&
      buildsQuery.buildsForSite.builds) ||
    []
  const [lastBuild, ...prevBuilds] = builds

  if (loading && builds.length === 0) {
    return <Loading delay={1000} />
  }

  if (error) {
    return <ErrorModal errMsg={error.message} />
  }

  const pageInfo =
    (buildsQuery &&
      buildsQuery.buildsForSite &&
      buildsQuery.buildsForSite.pageInfo) ||
    {}
  return (
    <PageWithTabsContentSection>
      <OverviewRoot>
        <ApolloConsumer>
          {client => (
            <SidebarWrapper
              {...rest}
              organizationId={organizationId}
              client={client}
              siteId={siteId}
              lastBuild={lastBuild}
              builds={builds}
              buildsEnabled={buildsEnabled}
              sitePreviewPassword={sitePreviewPassword}
              sitePreviewProtection={sitePreviewProtection}
              sitePreviewDirectory={sitePreviewDirectory}
              status={sitePreviewStatus}
              permissionToEdit={permissionToEdit}
            />
          )}
        </ApolloConsumer>
        <BuildListRoot
          {...rest}
          fetchMore={fetchMore}
          organizationId={organizationId}
          builds={builds}
          buildsEnabled={buildsEnabled}
          siteId={siteId}
          lastBuild={lastBuild}
          prevBuilds={prevBuilds}
          pageInfo={pageInfo}
          sitePreviewStatus={sitePreviewStatus}
        />
      </OverviewRoot>
    </PageWithTabsContentSection>
  )
}

export default graphql(BUILDS_QUERY, {
  name: `buildsQuery`,
  options: props => {
    return {
      name: `builds`,
      variables: {
        siteId: props.siteId,
        pagination: {
          limit: BUILD_PAGE_LIMIT,
        },
      },
      fetchPolicy: `cache-and-network`,
    }
  },
})(Overview)
