import React, { Fragment } from "react"
import PropTypes from "prop-types"
import FullStory from "react-fullstory"

import Layout from "../dashboard/components/Layout"
import ProductDashboard from "../dashboard"

const isBrowser = global.window

const Dashboard = ({ location }) =>
  isBrowser ? (
    <Fragment>
      <FullStory org={process.env.GATSBY_FULLSTORY_ORG} />
      <Layout pathname={location.pathname}>
        <ProductDashboard />
      </Layout>
    </Fragment>
  ) : null

Dashboard.propTypes = {
  location: PropTypes.object,
}

export default Dashboard
