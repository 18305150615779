import React, { Fragment, useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"
import { navigate } from "@reach/router"
import SitesByOrg from "../shared/SiteList/SiteList"
import { useTracker } from "../../analytics"

import { AddSiteButton } from "../shared/Buttons"
import BaseBillingStatus from "../shared/BillingStatus"
import { SiteListFilter as BaseSiteListFilter } from "../shared/SiteList"
import {
  OrgType,
  OrgSection,
  OrgInfo,
  OrgName,
  OrgDetailsWrapper,
  OrgSettings,
} from "../shared/OrgList"

import { spaces } from "../../utils/presets"
import {
  sites as sitesText,
  organizations as organizationsText,
} from "../../locales/default.json"
import { MdAdd, MdSettings } from "react-icons/md"
import { OrganizationPermissions } from "../../permissions"
import Paywall from "../shared/PaymentInformation/Paywall"
import { recordCreateSite } from "../../utils/helpers/fullstory"

const BillingStatus = styled(BaseBillingStatus)`
  margin-top: ${spaces.l};
`

const AddIcon = styled(MdAdd)`
  margin: auto ${spaces[`3xs`]};
`

const SiteListFilter = styled(BaseSiteListFilter)`
  margin-bottom: ${spaces.xl};
`

function renderOrgList(organizations, searchTerm) {
  const { trackButtonClicked } = useTracker()

  return organizations.map(org => {
    const {
      name: orgName,
      id: organizationId,
      sites,
      billing,
      permissions,
    } = org

    const filteredSites = searchTerm
      ? sites.filter(site => site.name.toLowerCase().includes(searchTerm))
      : sites

    return (
      <Fragment key={organizationId}>
        <OrgInfo>
          <OrgName data-cy="org-name">{orgName}</OrgName>
          <OrgDetailsWrapper>
            <OrganizationPermissions
              id={organizationId}
              resource="organization"
              action="read"
            >
              <OrgSettings
                onClick={() => {
                  trackButtonClicked(`Organization Settings`, {
                    organizationId: organizationId,
                    uiSource: `Sites List`,
                  })
                }}
                to={`/dashboard/organization/settings/${organizationId}`}
              >
                {organizationsText.settings} <MdSettings />
              </OrgSettings>
            </OrganizationPermissions>
            <OrganizationPermissions
              id={organizationId}
              resource="sites"
              action="create"
            >
              <Paywall
                id={organizationId}
                onSuccess={() => {
                  navigate(`/dashboard/sites/create`)
                  recordCreateSite()
                  trackButtonClicked(sitesText.addNewSite, {
                    organizationId,
                    uiSource: `Sites List`,
                  })
                }}
              >
                {({ onClick }) => (
                  <AddSiteButton
                    size={`M`}
                    onClick={onClick}
                    data-cy="create-site-button"
                  >
                    {sitesText.addNewSite} <AddIcon />
                  </AddSiteButton>
                )}
              </Paywall>
            </OrganizationPermissions>
          </OrgDetailsWrapper>
        </OrgInfo>
        <BillingStatus
          orgId={organizationId}
          billing={billing}
          uiSource="Sites list"
          showLink={
            permissions && permissions.billing && permissions.billing.create
          }
        />
        <SitesByOrg
          sites={filteredSites}
          organizationId={organizationId}
          siteLength={sites.length}
        />
      </Fragment>
    )
  })
}

renderOrgList.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  sites: PropTypes.array,
  billing: PropTypes.object,
}

export const Organizations = ({ organizations, contributorOrganizations }) => {
  const [searchTerm, updateSearchTerm] = useState(``)
  const { trackPageViewed } = useTracker()

  useEffect(() => {
    trackPageViewed(`Sites List`)
  }, [])

  return (
    <Fragment>
      <SiteListFilter
        ariaLabel="Filter sites"
        placeholder="Filter sites"
        value={searchTerm}
        onChange={e => {
          updateSearchTerm(e.target.value.toLowerCase())
        }}
      />

      <OrgSection>
        {organizations.length > 0 && (
          <OrgType>
            <span>{organizationsText.yourOrganization.toUpperCase()}</span>
          </OrgType>
        )}

        {renderOrgList(organizations, searchTerm)}
      </OrgSection>

      <OrgSection>
        {contributorOrganizations.length > 0 && (
          <OrgType>
            <span>{organizationsText.contributorAccessTo.toUpperCase()}</span>
          </OrgType>
        )}

        {renderOrgList(contributorOrganizations, searchTerm)}
      </OrgSection>
    </Fragment>
  )
}

Organizations.propTypes = {
  organizations: PropTypes.array,
  contributorOrganizations: PropTypes.array,
}
