import React from "react"
import { DialogContent } from "@reach/dialog"
import styled, { keyframes } from "react-emotion"
import { breakpoints, palette, radius, spaces } from "../../utils/presets"

const defaultEntry = keyframes`
  100% {
     transform: translate(0, 0) scale(1) perspective(1000px) rotateX(0);
  }
`

const panelEntry = keyframes`
  100% {
     transform: translateX(0);
  }
`

export const ModalContentPanel = styled(DialogContent)`
  animation: ${panelEntry} 0.25s 0.5s ease forwards;
  background: ${palette.white};
  width: calc(50rem);
  max-width: 100%;
  outline: none;
  transform: translateX(100%);
  right: 0;
`

export const ModalContentDefault = styled(DialogContent)`
  animation: ${defaultEntry} 0.5s 0.25s ease forwards;
  background: ${palette.white};
  border-radius: ${radius.large};
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.2);
  margin: ${spaces.m} auto;
  max-width: calc(100% - (${spaces.m} * 2));
  outline: none;
  transform: translate(0, 90vh) scale(0.9) perspective(1000px) rotateX(-90deg);
  transform-origin: top center;

  @media (min-width: ${breakpoints.mobile}px) {
    max-width: calc(100% - (${spaces.l} * 2));
  }

  @media (min-width: ${breakpoints.phablet}px) {
    width: 620px;
  }
`

const ModalContent = ({ isOpen, mode = `error`, children }) => {
  const Component = mode === `panel` ? ModalContentPanel : ModalContentDefault

  return (
    <Component className={mode} data-testid="modal-content">
      {children}
    </Component>
  )
}

export default ModalContent
