import React from "react"

export default () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
      fill="#7F7C82"
    />
    <path
      d="M17.75 19H9.5V8.5H17.75V19ZM17.75 7H9.5C8.675 7 8 7.675 8 8.5V19C8 19.825 8.675 20.5 9.5 20.5H17.75C18.575 20.5 19.25 19.825 19.25 19V8.5C19.25 7.675 18.575 7 17.75 7ZM15.5 4H6.5C5.675 4 5 4.675 5 5.5V16H6.5V5.5H15.5V4Z"
      fill="white"
    />
  </svg>
)
