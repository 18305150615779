import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import "./globalStyles"
import { isAuthenticated } from "../../auth"
import { ModalProvider } from "../Modal"
import { Header } from "./Header"

/* Apollo Configuration */
import { ApolloProvider } from "react-apollo"

/* Product Dashboard */
import createApolloClient from "../../graphql/client"

import "../../../assets/fonts/futura-pt/Webfonts/futurapt_book_macroman/stylesheet.css"
import "../../../assets/fonts/futura-pt/Webfonts/futurapt_bookitalic_macroman/stylesheet.css"
import "../../../assets/fonts/futura-pt/Webfonts/futurapt_demi_macroman/stylesheet.css"
import "../../../assets/fonts/futura-pt/Webfonts/futurapt_demiitalic_macroman/stylesheet.css"
import "../../../assets/fonts/futura-pt/Webfonts/futurapt_bold/MyFontsWebfontsKit.css"

function testPathnameForBg(path) {
  return /\/dashboard\/sites\/*$/.test(path)
}

let bodyRef
let lastPathname
let greyBg = false

const Layout = ({ children, pathname }) => {
  if (!bodyRef) {
    bodyRef = document.querySelector(`body`)
  }

  if (!lastPathname || pathname !== lastPathname) {
    greyBg = testPathnameForBg(pathname)

    if (greyBg) {
      bodyRef.classList.add(`dashboardGreyBg`)
    } else {
      bodyRef.classList.remove(`dashboardGreyBg`)
    }
  }

  lastPathname = pathname

  return (
    <ApolloProvider client={createApolloClient()}>
      <Helmet title="Gatsby Dashboard">
        <html lang="en" />
      </Helmet>
      <ModalProvider>
        {isAuthenticated() && <Header pathname={pathname} />}
        {children}
      </ModalProvider>
    </ApolloProvider>
  )
}

Layout.propTypes = {
  pathname: PropTypes.string,
}

export default Layout
