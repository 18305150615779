import React from "react"
import PropTypes from "prop-types"

import { TabsNav, Tabs, Tab } from "../shared/Tabs"
import { orgDetails as text } from "../../locales/default.json"
import { PageWithTabsNavSection } from "../shared/Containers"

const getPathToScreen = (screen, orgId) =>
  `/dashboard/organization/${screen}/${orgId}`

const OrgTabsNav = ({ pathname, organizationId }) => {
  const sitesScreen = pathname.includes(`sites`)

  return (
    <PageWithTabsNavSection>
      <TabsNav aria-label="Organization menu">
        <Tabs>
          <Tab
            to={!sitesScreen ? getPathToScreen(`sites`, organizationId) : ``}
            className={sitesScreen ? `active` : ``}
          >
            {text.sites}
          </Tab>
          <Tab
            to={sitesScreen ? getPathToScreen(`settings`, organizationId) : ``}
            className={!sitesScreen ? `active` : ``}
          >
            {text.settings}
          </Tab>
        </Tabs>
      </TabsNav>
    </PageWithTabsNavSection>
  )
}

OrgTabsNav.propTypes = {
  pathname: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
}

export default OrgTabsNav
