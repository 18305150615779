import React from "react"

export default () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="30" cy="30" r="30" fill="#37B635" />
    <path
      d="M25.2442 38.3809L19.6717 30.7731C19.1114 29.9623 19.2602 28.8583 20.0152 28.2248L20.0152 28.2248C20.7702 27.5913 21.8833 27.6364 22.5845 28.329L29.109 35.1379C30.171 36.1868 30.1007 37.9219 28.9572 38.8814L28.9572 38.8814C27.8137 39.8409 26.0928 39.6089 25.2442 38.3809Z"
      fill="white"
    />
    <path
      d="M25.701 38.8627C24.5394 37.888 24.4253 36.1413 25.4503 35.0238L38.3949 20.9101C39.0523 20.1934 40.1574 20.1212 40.9024 20.7464L40.9024 20.7464C41.6474 21.3715 41.7682 22.4723 41.1765 23.2442L29.5251 38.443C28.6025 39.6464 26.8626 39.8374 25.701 38.8627L25.701 38.8627Z"
      fill="white"
    />
    <path
      d="M25.995 31.9338C26.6471 32.7163 27.1253 33.238 27.9513 32.3251C28.7772 31.4122 27.6904 34.6291 27.6904 34.6291L24.9951 33.7597L25.995 31.9338Z"
      fill="white"
    />
  </svg>
)
