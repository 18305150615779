import React, { Fragment } from "react"
import styled, { keyframes } from "react-emotion"
import { Link } from "gatsby"
import {
  palette,
  spaces,
  fontFamilies,
  colors,
  fontSizes,
  breakpoints,
} from "../../utils/presets"
import { login as text } from "../../locales/default.json"
import Monogram from "../../assets/Monogram"
import LogoDribbles from "../../assets/LogoDribbles"
import Logotype from "../../assets/Logotype"
import DotsOne from "../../assets/DotsOne"
import DotsTwo from "../../assets/DotsTwo"
import { HeaderLogoLink } from "../Layout/Header"
import { PrimaryButton } from "../shared/Buttons"
import LoginErrors from "./LoginErrors"

export const LoginRoot = styled(`div`)`
  align-items: center;
  background: ${palette.white};
  display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
`

export const Content = styled(`div`)`
  max-width: 100%;
  padding: ${spaces.l};
  padding-bottom: ${spaces[`5xl`]};
  width: 50rem;
`

export const LearnMoreLink = styled(Link)`
  border-bottom: 1px solid ${palette.purple[200]};
  color: ${colors.lilac};
  font-size: ${fontSizes.xs};
  text-decoration: none;
`

export const LogoLink = styled(HeaderLogoLink)`
  left: 20px;
  position: absolute;
  top: 16px;
`

export const MinorDotsOrnament = styled(DotsOne)`
  left: 0;
  position: absolute;
  top: 0;
`

export const MainDotsOrnament = styled(DotsTwo)`
  position: absolute;
  right: 0;
  top: 70%;
  transform: translateY(-50%) scale(1, -1);

  @media (min-width: ${breakpoints.desktop}px) {
    top: 52%;
    transform: translateY(-50%) scale(1);
  }
`

export const VerticalLogotype = styled(Logotype)`
  bottom: ${spaces[`2xs`]};
  display: none;
  height: auto;
  left: ${spaces.l};
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: 0% 0%;
  width: 50px;

  @media (min-width: ${breakpoints.desktop}px) {
    display: block;
  }
`

export const Footer = styled(`footer`)`
  align-items: flex-start;
  bottom: 0;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: ${spaces.l};
  padding-bottom: ${spaces.m};
  position: absolute;
  width: 50rem;

  svg {
    height: auto;
    margin-top: ${spaces.xs};
    width: 100%;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    align-items: center;
    flex-direction: row;

    svg {
      margin: 0;
      margin-left: ${spaces.m};
      width: auto;
    }
  }
`

const entry = keyframes`
   to {
     opacity: 1;
     transform: translateY(0);
   }
 `

export const Heading = styled(`header`)`
  animation: ${entry} 0.5s ease forwards;
  opacity: 0;
  transform: translateY(30px);

  * {
    font-family: ${fontFamilies.headerFontFamily};
    margin: 0;
  }

  h1 {
    color: ${palette.grey[900]};
    font-size: ${fontSizes[`5xl`]};
    letter-spacing: -0.02em;
    font-weight: 800;
    line-height: 1;
    margin-top: ${spaces[`2xs`]};
    text-align: ${props => props.textAlign || `left`};
    span {
      display: block;
    }
  }

  h3 {
    color: ${palette.grey[500]};
    font-size: ${fontSizes.s};
    font-weight: normal;
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }
`

export const SignInButton = styled(PrimaryButton)`
  animation: ${entry} 0.5s 0.2s ease forwards;
  margin-top: ${spaces.xl};
  opacity: 0;
  transform: translateY(30px);
`

export function Layout({ children }) {
  return (
    <Fragment>
      <LoginRoot>
        <VerticalLogotype />
        <MainDotsOrnament />
        <MinorDotsOrnament />

        <LogoLink
          to="/"
          aria-label="gatsbyjs.com link"
          data-cy="gatsby-logo-link"
        >
          <Monogram />
        </LogoLink>

        <Content>{children}</Content>

        <Footer>
          <LearnMoreLink to="/preview/" data-cy="learn-more-link">
            {text.learnMore}
          </LearnMoreLink>
          <LogoDribbles />
        </Footer>
      </LoginRoot>
      <LoginErrors />
    </Fragment>
  )
}
