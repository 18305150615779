import React, { useState, useContext, Fragment } from "react"
import gql from "graphql-tag"
import styled, { keyframes } from "react-emotion"
import { graphql } from "react-apollo"
import PropTypes from "prop-types"
import { MdEdit } from "react-icons/md"
import { Formik, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

import {
  MdLockOpen,
  MdLockOutline,
  MdLock,
  MdArrowForward,
  MdErrorOutline,
} from "react-icons/md"

import { ModalContext } from "../../Modal"
import { DefaultError } from "../../shared/ErrorModal"
import { ToastContext } from "../../shared/Toast"
import {
  PrimaryButton,
  SecondaryButton,
  CancelButton,
} from "../../shared/Buttons"
import { Radio } from "../../shared/Forms"
import { SettingsCard, Header, Title } from "../../shared/Settings/SettingsCard"
import { Form as BaseForm, Input } from "../../shared/Forms"
import { breakpoints, fontSizes, spaces, palette } from "../../../utils/presets"
import { Text } from "../../shared/Typography"
import { visitorAccess as text } from "../../../locales/default.json"
import { useTracker } from "../../../analytics"

// import { Debug } from "../../../utils/debug/Formik"

const SET_PREVIEW_PROTECTION = gql`
  mutation setSitePreviewProtection(
    $id: UUID!
    $type: PreviewProtectionType!
    $password: String
  ) {
    setSitePreviewProtection(id: $id, type: $type, password: $password) {
      success
      message
    }
  }
`

export const visitorAccessOptions = [
  {
    value: `PUBLIC`,
    label: text.public,
    description: text.anyoneCanVisit,
    icon: MdLockOpen,
  },
  {
    value: `PASSWORD`,
    label: text.passwordProtected,
    description: text.visitorHasToKnowPassword,
    icon: MdLockOutline,
  },
  {
    value: `CLOUD_AUTH`,
    label: text.logInRequired,
    description: text.visitorMustHaveGatsbyAccount,
    icon: MdLock,
  },
]

const Content = styled(Text)`
  color: ${palette.grey[500]};
  font-size: ${fontSizes.xs};

  svg {
    font-size: ${fontSizes.m};
    margin-right: ${spaces.xs};
    vertical-align: text-bottom;
  }

  strong {
    color: ${palette.purple[600]};
    font-size: ${fontSizes.s};
  }
`

const entry = keyframes`
  100% {
    opacity: 1;
  }
`

const Form = styled(BaseForm)`
  animation: ${entry} 0.75s ease forwards;
  opacity: 0;
`

const Options = styled(`fieldset`)`
  border: none;
  margin: ${spaces.s} 0 0;
`

const Option = styled(`div`)`
  align-items: flex-start;
  border-bottom: 1px solid ${palette.grey[200]};
  display: flex;
  flex-direction: column;
  padding: ${spaces[`xs`]} 0;

  :last-of-type {
    border-bottom: none;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`

const Label = styled(`label`)`
  align-items: center;
  cursor: pointer;
  display: flex;
  min-height: ${spaces.xl};
  padding: ${spaces.xs} 0;
`

const PasswordField = styled(`fieldset`)`
  border: none;
  margin-bottom: 0;

  & > div {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding-left: ${spaces.xl};
  }

  ${Input} {
    margin: ${spaces.xs};
    margin-left: 0;
    width: 100%;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    & > div {
      align-items: center;
      flex-direction: row;
      padding-left: 0;
    }

    ${Input} {
      margin-left: ${spaces.xs};
    }
  }
`

const ErrorWrapper = styled(`div`)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  ${Label} {
    color: ${palette.grey[500]};
    font-size: ${fontSizes.s};
    min-height: auto;
    padding: 0;
    white-space: nowrap;
  }
`

const ErrorMsg = styled(`span`)`
  align-items: center;
  color: ${palette.red[600]};
  display: flex;
  font-size: ${fontSizes[`2xs`]};
  line-height: 1;
  white-space: nowrap;

  svg {
    color: red;
    flex-shrink: 0;
    margin-right: ${spaces[`2xs`]};
  }
`

const Actions = styled(`div`)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${spaces.l};
  width: 100%;
`

const validationSchema = Yup.object().shape({
  previewProtect: Yup.string().required(`Visitor Access Type is required`),
  previewPass: Yup.string().test(`previewPass`, text.cantBeEmpty, function(
    val
  ) {
    const previewProtect = this.parent.previewProtect

    if (previewProtect === `PASSWORD` && !val) {
      return false
    }
    return true
  }),
})

let VisitorAccess = ({
  siteId,
  organizationId,
  sitePreviewProtection,
  sitePreviewPassword,
  mutate,
}) => {
  const { trackButtonClicked } = useTracker()
  const { showModal } = useContext(ModalContext)
  const { showToast } = useContext(ToastContext)
  const [formVisible, setFormVisible] = useState(false)
  const [showPassword, toggleShowPassword] = useState(false)

  const currentOption = visitorAccessOptions.find(
    option => option.value === sitePreviewProtection
  )
  const Icon = currentOption.icon

  return (
    <SettingsCard>
      <Header>
        <Title>
          {!formVisible ? text.visitorAccess : text.editVisitorAccess}
        </Title>
        {!formVisible && (
          <SecondaryButton
            onClick={() => {
              trackButtonClicked(text.editAccess, {
                uiSource: `Site Settings`,
              })
              return setFormVisible(!formVisible)
            }}
          >
            {text.editAccess}
            <MdEdit />
          </SecondaryButton>
        )}
      </Header>
      {!formVisible && (
        <Content>
          <Icon />
          <strong>{currentOption.label}</strong> - {currentOption.description}
        </Content>
      )}

      {formVisible && (
        <Formik
          validationSchema={validationSchema}
          onSubmit={values => {
            trackButtonClicked(`save visitor access`, {
              uiSource: `Site Settings`,
            })
            return mutate({
              variables: {
                id: siteId,
                type: values.previewProtect,
                password: values.previewPass ? values.previewPass : undefined,
              },
              update: cache => {
                const siteFrag = cache.readFragment({
                  id: `Site:${siteId}`,
                  fragment: gql`
                    fragment siteFrag on Site {
                      id
                      previewPassword
                      previewProtection
                    }
                  `,
                })
                if (values.previewPass) {
                  siteFrag.previewPassword = values.previewPass
                }

                siteFrag.previewProtection = values.previewProtect

                cache.writeFragment({
                  id: `Site:${siteId}`,
                  fragment: gql`
                    fragment siteFrag on Site {
                      id
                      previewPassword
                      previewProtection
                    }
                  `,
                  data: siteFrag,
                })
              },
            })
              .then(() =>
                showToast(
                  `<strong>${text.visitorAccess}</strong> ${text.updated}`
                )
              )
              .then(() => setFormVisible(false))
              .catch(err => {
                showModal({
                  Component: DefaultError,
                  props: { errMsg: err.message },
                })
              })
          }}
          initialValues={{
            previewPass: sitePreviewPassword ? sitePreviewPassword : ``,
            previewProtect: sitePreviewProtection || `PUBLIC`,
          }}
        >
          {({ isSubmitting, submitForm, values }) => (
            <Form>
              <Options>
                {visitorAccessOptions.map(({ label, value, description }) => (
                  <Option key={value}>
                    <Field name={`previewProtect`} key={label}>
                      {({ field, form, ...formikProps }) => (
                        <Radio
                          fieldName={`space`}
                          optionValue={value}
                          htmlLabel={`${label}<small> - ${description}</small>`}
                          {...field}
                          {...formikProps}
                          {...form}
                        />
                      )}
                    </Field>
                    {value === `PASSWORD` && values.previewProtect === value && (
                      <PasswordField>
                        <div>
                          <Field
                            name={`previewPass`}
                            type={showPassword ? `text` : `password`}
                            aria-label={`Preview Password`}
                          >
                            {({ field }) => (
                              <Fragment>
                                <ErrorWrapper>
                                  <Label>
                                    {text.setPassword} <MdArrowForward />
                                  </Label>
                                  <ErrorMessage name={`previewPass`}>
                                    {msg => (
                                      <ErrorMsg>
                                        <MdErrorOutline /> {msg}
                                      </ErrorMsg>
                                    )}
                                  </ErrorMessage>
                                </ErrorWrapper>
                                <Input
                                  {...field}
                                  id="previewPass"
                                  type={showPassword ? `text` : `password`}
                                />
                              </Fragment>
                            )}
                          </Field>
                          {values.previewPass && (
                            <SecondaryButton
                              size={`M`}
                              type="button"
                              onClick={() => toggleShowPassword(!showPassword)}
                            >
                              {` `}
                              {!showPassword ? text.show : text.hide}
                            </SecondaryButton>
                          )}
                        </div>
                      </PasswordField>
                    )}
                  </Option>
                ))}
              </Options>
              <Actions>
                <CancelButton
                  type={`reset`}
                  onClick={() => {
                    trackButtonClicked(`cancel visitor access`, {
                      uiSource: `Site Settings`,
                    })
                    return setFormVisible(false)
                  }}
                >
                  {text.cancel}
                </CancelButton>
                <PrimaryButton
                  type="button"
                  onClick={submitForm}
                  loading={isSubmitting}
                >
                  {text.save} <MdArrowForward />
                </PrimaryButton>
              </Actions>
              {/* <Debug /> */}
            </Form>
          )}
        </Formik>
      )}
    </SettingsCard>
  )
}

VisitorAccess.propTypes = {
  siteId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  sitePreviewProtection: PropTypes.string.isRequired,
  sitePreviewPassword: PropTypes.string,
}

export default (VisitorAccess = graphql(SET_PREVIEW_PROTECTION)(VisitorAccess))
