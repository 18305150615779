import React from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"

import { palette, fontSizes, spaces, breakpoints } from "../../../utils/presets"
import Amex from "../../../assets/Amex"
import Discover from "../../../assets/Discover"
import Mastercard from "../../../assets/Mastercard"
import Visa from "../../../assets/Visa"
import Chip from "../../../assets/Chip"
import { Text } from "../../shared/Typography"
import { billing as text } from "../../../locales/default.json"

const CreditCardRoot = styled(`div`)`
  background: ${palette.grey[100]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: ${spaces.m} ${spaces.m} ${spaces.xl} ${spaces.xl};
  margin-top: ${spaces.l};
  width: 100%;

  > svg {
    margin-top: ${spaces.m};
    width: 48px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    width: 45%;
  }
`

const CardDetails = styled(Text)`
  font-size: ${fontSizes.s};
  margin: 0;

  :last-child {
    font-size: ${fontSizes.xs};
  }
`

const CardNumber = styled(CardDetails)`
  letter-spacing: ${spaces[`2xs`]};
`

const Row = styled(`div`)`
  display: flex;
  width: 100%;

  :first-child {
    justify-content: flex-end;
  }

  :last-child {
    align-items: center;
    justify-content: space-between;
    margin-top: ${spaces.m};
  }

  svg {
    width: 38px;
    height: 24px;
  }
`

const ExpirationDetails = styled(`div`)`
  display: flex;
  flex-direction: column;
`

const ExpirationLabel = styled(CardDetails)`
  color: ${palette.grey[400]};
  text-transform: uppercase;
  font-size: ${fontSizes[`4xs`]};
  margin: 0;
`

const CreditCard = ({
  creditCard: { brand, lastFour, expMonth, expYear, name },
}) => (
  <CreditCardRoot>
    <Row>
      {brand === `VISA` && <Visa />}
      {brand === `MASTERCARD` && <Mastercard />}
      {brand === `AMEX` && <Amex />}
      {brand === `DISCOVER` && <Discover />}
    </Row>
    <Chip />
    <CardNumber>•••• •••• •••• {lastFour}</CardNumber>
    <Row>
      <CardDetails>{name}</CardDetails>
      <ExpirationDetails>
        <ExpirationLabel>{text.monthYear}</ExpirationLabel>
        <CardDetails>
          {expMonth} / {expYear}
        </CardDetails>
      </ExpirationDetails>
    </Row>
  </CreditCardRoot>
)

CreditCard.propTypes = {
  billing: PropTypes.object,
}

export default CreditCard
