import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import qs from "query-string"
import gql from "graphql-tag"
import { Mutation } from "react-apollo"
import Loading from "../../components/shared/Loading"
import ErrorModal from "../shared/ErrorModal"
import { ModalContext } from "../Modal/Modal"

const INSTALL_CONTENTFUL_INTEGRATION = gql`
  mutation installContentfulIntegration(
    $siteId: UUID!
    $integrationToken: String!
  ) {
    installContentfulIntegration(
      siteId: $siteId
      integrationToken: $integrationToken
    ) {
      success
      message
    }
  }
`

function ContentfulRedirect({ mutate, location }) {
  const query = qs.parse(location.search)
  const hash = qs.parse(location.hash)

  const siteId = query.siteId
  const integrationToken = hash.access_token

  const { showModal } = useContext(ModalContext)

  function showError({ message }) {
    return showModal({
      Component: ErrorModal,
      props: {
        errMsg: message,
        // onClose: () => navigate(`/dashboard/sites`),
      },
    })
  }

  function installContentful() {
    mutate({
      variables: {
        siteId,
        integrationToken,
      },
    })
      .then(({ data }) => {
        const resultData = data.installContentfulIntegration

        if (resultData.success) {
          return window.close()
        }

        return showError({ message: resultData && resultData.message })
      })
      .catch(e => showError({ message: e.message }))
  }

  useEffect(() => installContentful(), [])

  return <Loading delay={1000} message="Connecting with Contentful ..." />
}

ContentfulRedirect.propTypes = {
  invitationId: PropTypes.string.isRequired,
  mutate: PropTypes.func,
}

export default function RedirectWrapped(props) {
  return (
    <Mutation mutation={INSTALL_CONTENTFUL_INTEGRATION}>
      {mutate => <ContentfulRedirect mutate={mutate} {...props} />}
    </Mutation>
  )
}
