import gql from "graphql-tag"

export const SITE_INTEGRATIONS_QUERY = gql`
  query cmsIntegrationsForSite($siteId: UUID!, $connected: Boolean) {
    cmsIntegrationsForSite(siteId: $siteId, connected: $connected) {
      id
      name
      logoUrl
      details
      description
      connected
      features {
        envVars
        webhooks
        extension
      }
    }
  }
`

export const ENV_VARS_QUERY = gql`
  query environmentVariablesForSiteQuery($id: UUID!) {
    environmentVariablesForSite(id: $id) {
      key
      value
      truncatedValue
    }
  }
`
