import React, { Fragment } from "react"
import styled from "react-emotion"
import { spaces, breakpoints, colors } from "../../utils/presets"
import Monogram from "../../assets/Monogram"
import DotsTwo from "../../assets/DotsTwo"
import { HeaderLogoLink } from "../Layout/Header"

export const LoginRoot = styled(`div`)`
  align-items: center;
  background: ${colors.secondaryBackground};
  display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
`

export const Content = styled(`div`)`
  max-width: 100%;
  padding: ${spaces.l};
  padding-bottom: ${spaces[`2xl`]};
  max-width: 100%;
  width: 50rem;
  z-index: 1;
`

export const LogoLink = styled(HeaderLogoLink)`
  left: 20px;
  position: absolute;
  top: 16px;
`

export const MainDotsOrnament = styled(DotsTwo)`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(-50%) scale(1, -1);

  @media (min-width: ${breakpoints.desktop}px) {
    top: 80%;
    transform: translateY(-50%) scale(1);
  }
`

function Layout({ children }) {
  return (
    <Fragment>
      <LoginRoot>
        <MainDotsOrnament />
        <LogoLink to="/" aria-label="gatsbyjs.com link">
          <Monogram />
        </LogoLink>
        <Content>{children}</Content>
      </LoginRoot>
    </Fragment>
  )
}

export default Layout
