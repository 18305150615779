import { FullStoryAPI } from "react-fullstory"

function setIdentify(currentUser) {
  const { id, name, email } = currentUser
  FullStoryAPI(`identify`, id, {
    displayName: name,
    email: email,
  })
}

function setSiteEnvVars(role) {
  FullStoryAPI(`setUserVars`, {
    user_site_role: role,
  })
}

function setOrgEnvVars(name, plan, status, trialDaysRemaining, role) {
  FullStoryAPI(`setUserVars`, {
    user_organization_name: name,
    user_organization_plan: plan,
    user_organization_plan_status: status,
    user_organization_trial_days_remaining:
      status === `TRIALING` ? trialDaysRemaining : 0,
    user_organization_role: role,
  })
}

function recordCreateSite() {
  FullStoryAPI(`event`, `Create site`, {})
}

function recordFirstTimeVisitor() {
  FullStoryAPI(`event`, `First time visitor`, {})
}

export {
  setIdentify,
  setSiteEnvVars,
  setOrgEnvVars,
  recordCreateSite,
  recordFirstTimeVisitor,
}
