import React, { Fragment, useContext } from "react"
import styled from "react-emotion"
import { navigate } from "gatsby"
import gql from "graphql-tag"
import { graphql } from "react-apollo"
import PropTypes from "prop-types"
import { MdDeleteForever, MdWarning } from "react-icons/md"
import { useTracker } from "../../../../analytics"

import { palette } from "../../../../utils/presets"
import { sites as text } from "../../../../locales/default.json"
import {
  SettingsCard,
  Header,
  Title as BaseTitle,
  Message,
} from "../../../shared/Settings/SettingsCard"
import { SecondaryDeleteButton } from "../../../shared/Buttons"
import { Text } from "../../../shared/Typography"
import {
  ModalContext,
  ModalSecondaryHeader,
  ModalBody,
  ModalMessage,
  CloseButton,
} from "../../../Modal"
import { DefaultError } from "../../../shared/ErrorModal"
import { Heading } from "../../../shared/Typography"
import DeleteSiteForm from "./DeleteSiteForm"
import { ToastContext } from "../../../shared/Toast"
import { SitePermissions } from "../../../../permissions"
import Paywall from "../../../shared/PaymentInformation/Paywall"

const DELETE_SITE_MUTATION = gql`
  mutation deleteSite($id: UUID!) {
    deleteSite(id: $id) {
      success
      message
    }
  }
`

const Title = styled(BaseTitle)`
  color: ${palette.red[900]};
`

const WarningIcon = styled(MdWarning)`
  color: ${palette.red[900]};
  vertical-align: middle;
  margin-top: -3px;
`

let DeleteSiteModal = ({
  closeModal,
  siteName,
  siteId,
  showToast,
  deleteSiteMutation,
}) => {
  const { showModal } = useContext(ModalContext)
  const { trackButtonClicked } = useTracker()

  return (
    <Fragment>
      <ModalSecondaryHeader>
        <Heading>
          {text.deleteSiteConf} <strong>{siteName}?</strong>
        </Heading>
        <CloseButton
          onClick={closeModal}
          aria-label="Close modal"
          data-cy="delete-site-close-modal-button"
        />
      </ModalSecondaryHeader>
      <ModalBody>
        <ModalMessage>
          <Text>
            <WarningIcon /> {text.losePreviewUrl}
          </Text>
        </ModalMessage>
        <DeleteSiteForm
          submitCallback={() => {
            trackButtonClicked(text.yesDeleteSite, {
              siteId,
              uiSource: `Site settings`,
            })
            deleteSiteMutation({
              variables: {
                id: siteId,
              },
            })
              .then(({ data }) => {
                if (data.deleteSite.success) {
                  closeModal()
                  showToast(
                    `<strong>${siteName} </strong> ${text.deletedSuccessfully}`
                  )
                  return navigate(`/dashboard/sites`)
                }
              })
              .catch(err =>
                showModal({
                  Component: DefaultError,
                  props: { errMsg: err.message },
                })
              )
          }}
          cancelCallback={closeModal}
          siteName={siteName}
        />
      </ModalBody>
    </Fragment>
  )
}

DeleteSiteModal.propTypes = {
  closeModal: PropTypes.func,
}

DeleteSiteModal = graphql(DELETE_SITE_MUTATION, {
  name: `deleteSiteMutation`,
})(DeleteSiteModal)

const DeleteSiteCard = ({
  siteId,
  organizationId,
  siteName,
  permissionToDelete,
}) => {
  const { showToast } = useContext(ToastContext)
  const { showModal } = useContext(ModalContext)

  return (
    <SettingsCard>
      <Header>
        <Title>{text.deleteSite}</Title>
        <SitePermissions id={siteId} resource="sites" action="delete">
          <Paywall
            id={organizationId}
            onSuccess={() => {
              showModal({
                Component: DeleteSiteModal,
                props: { siteName, siteId, showToast, mode: `warning` },
              })
            }}
          >
            {({ onClick }) => (
              <SecondaryDeleteButton
                onClick={onClick}
                data-cy="delete-site-button"
              >
                {text.deleteSite} <MdDeleteForever />
              </SecondaryDeleteButton>
            )}
          </Paywall>
        </SitePermissions>
      </Header>
      <Message>
        {permissionToDelete
          ? `${text.deleteSiteInfo}`
          : `${text.deleteSiteNoPermission}`}
      </Message>
    </SettingsCard>
  )
}

DeleteSiteCard.propTypes = {
  siteId: PropTypes.string.isRequired,
  permissionToDelete: PropTypes.bool,
}

export default DeleteSiteCard
