import React, { Fragment } from "react"
import styled from "react-emotion"
import { PropTypes } from "prop-types"

import { Card } from "../../shared/Card"
import { Text, Subheading } from "../../shared/Typography"
import {
  palette,
  fontSizes,
  breakpoints,
  spaces,
  radius,
} from "../../../utils/presets"
import ContactUsLink from "../../shared/ContactUsLink"
import IntervalRadio from "./IntervalRadio"
import PlanRadio from "./PlanRadio"
import Price from "./Price"
import { billing as text } from "../../../locales/default.json"
import PlanFeatures from "../../shared/PlanFeatures"

const CardsRoot = styled(`div`)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: row;
    max-width: 100%;
    margin: ${spaces.xl} 0 ${spaces[`xl`]};
  }
`

const PlanCard = styled(Card)`
  box-sizing: border-box;
  box-shadow: none;
  width: 100%;
  padding: ${spaces.l};
  position: relative;

  :before,
  :after {
    content: "";
    position: absolute;
  }

  :before {
    background-image: linear-gradient(
      110deg,
      #8954a8 0,
      #663399 25%,
      #bc027f 50%,
      #ffdf37 75%,
      #05f7f4 100%
    );

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: ${radius.large};
    opacity: 0;
  }

  :after {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background: ${palette.white};
    border-radius: 5px;
    border: 1px solid ${palette.grey[300]};
  }

  &:first-child {
    margin-bottom: ${spaces.l};
  }

  @media (min-width: ${breakpoints.tablet}px) {
    width: 50%;
    &:first-child {
      margin: 0 ${spaces.s} 0 0;
    }
    &:last-child {
      margin: 0 0 0 ${spaces.s};
    }
  }

  &.isRecommended {
    :before {
      opacity: 1;
    }
    :after {
      border-color: ${palette.white};
    }
  }
`

const Header = styled(`section`)`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
`

const Content = styled(`section`)`
  display: flex;
  flex-direction: column;
  margin-top: ${spaces.s};
  margin-left: 0;
  position: relative;
  z-index: 1;

  @media (min-width: ${breakpoints.tablet}px) {
    margin-left: ${spaces.xl};
  }
`

const Title = styled(Subheading)`
  font-size: ${fontSizes.l};
  margin: 0;
  margin-left: ${spaces.xl};
  padding-top: 2px;
  text-transform: capitalize;

  span {
    font-size: ${fontSizes.s};
    padding-left: ${spaces.xs};
  }
`
const Contact = styled(Text)`
  color: ${palette.grey[500]};
  font-size: ${fontSizes.xs};
  font-weight: normal;
  margin: 0;
  margin-top: ${spaces.xl};
  padding: 0;
`

const Cards = ({ availableSubscriptionPlans, planInterval, setInterval }) => (
  <CardsRoot>
    {availableSubscriptionPlans.map(
      ({
        id,
        name,
        amount,
        formattedAmount,
        features,
        isRecommended,
        formattedMonthlyAmount,
      }) => (
        <PlanCard key={id} className={isRecommended ? `isRecommended` : ``}>
          <Header>
            <PlanRadio checked={isRecommended} disabled={!isRecommended} />
            <Title>
              {name}
              {isRecommended && (
                <span>
                  {` `}
                  {`${planInterval}`.toLowerCase()} {text.lowerCasePlan}
                </span>
              )}
            </Title>
          </Header>
          <Content>
            {features.map((feature, index) => (
              <PlanFeatures
                key={index}
                feature={feature.split(` `)}
                index={index}
              />
            ))}
            {!isRecommended && (
              <Contact>
                <ContactUsLink />
                {` `}
                {text.setUpPersonal}
              </Contact>
            )}
            {amount > 0 && (
              <Fragment>
                <Price
                  formattedAmount={
                    planInterval === `ANNUAL`
                      ? formattedMonthlyAmount
                      : formattedAmount
                  }
                  planInterval={planInterval}
                  yearlyFormattedAmount={formattedAmount}
                />
                <IntervalRadio
                  onChange={interval => setInterval(interval)}
                  planInterval={planInterval}
                />
              </Fragment>
            )}
          </Content>
        </PlanCard>
      )
    )}
  </CardsRoot>
)

Cards.propTypes = {
  availableSubscriptionPlans: PropTypes.array.isRequired,
  planInterval: PropTypes.string,
  setInterval: PropTypes.func,
}

export default Cards
