import React, { Fragment } from "react"
import styled from "react-emotion"

import { billing as text } from "../../../locales/default.json"
import { palette, fontSizes, spaces } from "../../../utils/presets"
import { Text } from "../../shared/Typography"

const PriceRoot = styled(Text)`
  align-items: center;
  color: ${palette.grey[900]};
  display: inline;
  font-weight: bold;
  margin: 0;
  margin-top: ${spaces.xs};
`

const Details = styled(`span`)`
  color: ${palette.grey[500]};
  font-size: ${fontSizes.xs};
  font-weight: normal;
  margin: 0;
`

const AnnualDiscount = styled(Details)`
  color: ${palette.purple[500]};
  font-weight: bold;
`

const Discount = styled(Text)`
  color: ${palette.purple[500]};
  font-size: ${fontSizes.xs};
  font-style: italic;
  margin: 0;
`

const AnnualPrice = styled(Text)`
  color: ${palette.grey[500]};
  font-size: ${fontSizes.xs};
  margin: 0;
`

const Price = ({ formattedAmount, planInterval, yearlyFormattedAmount }) => (
  <Fragment>
    <PriceRoot>
      {`${formattedAmount}`.replace(/\.00$/, ``)}
      {` `}
      {` `}
      <Details>
        {` `} / {text.month} {``}
      </Details>
      {planInterval === `ANNUAL` && (
        <AnnualDiscount>{text.discount}</AnnualDiscount>
      )}
    </PriceRoot>
    {planInterval === `MONTHLY` ? (
      <Discount>
        {text.switchAnnual} <strong>{text.getDiscount}</strong>
        {` `}
      </Discount>
    ) : (
      <AnnualPrice>
        <strong>{`${yearlyFormattedAmount}`.replace(/\.00$/, ``)}</strong> /
        {` `}
        {text.year}
      </AnnualPrice>
    )}
  </Fragment>
)

export default Price
