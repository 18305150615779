import { injectGlobal } from "react-emotion"

import {
  colors,
  breakpoints,
  fontSizes,
  spaces,
  palette,
  radius,
} from "../../utils/presets"

injectGlobal`
  body {
    &.dashboardGreyBg {
      background: ${colors.secondaryBackground};
    }
  }

  /* @reach/button-menu styles */

  /* Used to detect in JavaScript if apps have loaded styles or not. It turns off annoying console warnings */
  :root {
    --reach-menu-button: 1;
    --reach-dialog: 1;
  }

  [data-reach-menu] {
    display: block;
    position: absolute;
  }

  @keyframes listEntry {
    to {
      transform: translate(0, 0)  perspective(1000px) rotateX(0);
    }
  }

  [data-reach-menu-list] {
    animation: listEntry 0.5s ease forwards;
    background: ${colors.primaryBackground};
    border: 1px solid ${palette.grey[100]};
    border-radius: ${radius.large};
    box-shadow: 0 5px 10px 3px rgba(0, 0, 0, 0.1);
    display: block;
    outline: none;
    padding: 0;
    position: absolute;;
    right: .8rem;
    top: 0.2rem;
    transform: translate(0, 0)  perspective(1000px) rotateX(-35deg);
    transform-origin: top center;
    width: 15rem;
    z-index: 1000;

    ::before {
      background: ${colors.primaryBackground};
      border: 1px solid ${palette.grey[100]};
      content: "";
      height: 40px;
      position: absolute;
      right: -1px;
      transform: skewY(135deg);
      transform-origin: center;
      width: 20px;
    }

    div {
      border-bottom: 1px solid ${palette.grey[100]};
      background: ${colors.primaryBackground};
      margin: 0;
      position: relative;

      &:last-child {
        border-bottom: 0;
        border-radius: 0 0 ${radius.large} ${radius.large};
        overflow: hidden;
      }

      &:first-child {
        border-radius: ${radius.large} ${radius.large} 0 0;
        overflow: hidden;
      }
    }

     &.manageMember {
      width: 12rem;
    }

    &.manageInvitee {
      width: 10rem;
    }

     &.showLogs {
      width: 9rem;
    }

    &.orgsMenu {
      left: ${spaces.l};
      top: -0.2rem;
      width: calc(100% - (${spaces.l} * 2));

      :before {
        height: 20px;
        right: 50%;
        transform: translate(50%, -40%) rotate(45deg);
      }

      @media (min-width: ${breakpoints.phablet}px) {
        left: auto;
        top: 0.2rem;
        width: 15rem;

        :before {
          height: 40px;
          right: -1px;
          transform: skewY(135deg);
          width: 20px;
        }
      }
    }

    &.sourceOrgsMenu, &.branchListMenu {
      left: 0;
      max-height: 26rem;
      height: auto;
      overflow-y: scroll;

      @media (min-width: ${breakpoints.phablet}px) {
        top: .2rem;
        width: 14rem;

        :before {
          height: 20px;
          left: 0;
          transform: translate(50%, -40%) rotate(45deg);
        }
      }
    }

    &.branchListMenu {
        @media (min-width: ${breakpoints.phablet}px) {
          width: 16rem;
        }
      }
  }

  [data-reach-menu-item] {
    align-items: center;
    cursor: pointer;
    display: flex;
    color: ${palette.grey[900]};
    flex-wrap: wrap;
    font-size: ${fontSizes.xs};
    padding: ${spaces.s} ${spaces.m};
    overflow: hidden;
    position: relatvie;
    text-decoration: none;
    transition: 0.5s;

    small {
      color: ${palette.grey[600]};
      display: block;
      flex-shrink: 0;
      width: 100%;
    }

    svg {
      fill: ${palette.grey[400]};
      height: 1.1em;
      margin-right: ${spaces.xs};
      transition: 0.5s;
      width: 1.1em;
    }

    &.warn {
      color: ${palette.red[900]};

      svg {
        fill: ${palette.red[200]};
      }
    }

    &.active {
      color: ${palette.purple[900]};
      align-items: center;
      display: flex;
      font-size: ${fontSizes.xs};
      padding: ${spaces.s} ${spaces.m};
      position: relative;


    }

    &.inactive {
      align-items: center;
      background-color: ${palette.grey[100]};
      color: ${palette.grey[600]};
      display: flex;
      font-size: ${fontSizes.xs};
      padding: ${spaces.s} ${spaces.m};
      position: relative;

      &[data-selected] {
        background-color: ${palette.grey[100]};
        color: ${palette.grey[600]};
      }
    }

    &[data-selected] {
      background: ${palette.purple[50]};
      color: ${palette.purple[500]};
      outline: none;

      svg {
        fill: ${palette.purple[500]};
        transform: scale(1.2);
      }

      &.warn {
        color: ${palette.red[900]};

        svg {
          fill: ${palette.red[900]};
        }
      }
    }
  }

  /* end of @reach/button-menu styles */
`
