import React, { useContext, Fragment } from "react"
import { formatDistance } from "date-fns"
import gql from "graphql-tag"
import { withApollo, compose } from "react-apollo"
import { withFlags } from "ld-react"

import {
  MdSubject,
  MdPictureInPicture,
  MdTab,
  MdPhonelink,
} from "react-icons/md"

import { Menu, MenuList, MenuItem, MenuLink } from "@reach/menu-button"

import Logs, { LOGS_QUERY } from "./Logs"
import { SecondaryButton } from "../../shared/Buttons"
import {
  BuildCard,
  LastBuildCard,
  CardHeading,
  BuildStatus,
  Row,
  Label,
  Separator,
  Badge,
  CardFooter,
  MenuButton,
  PreviewUrl,
} from "./BuildCards"
import { ModalContext } from "../../Modal"
import { siteDetails as text } from "../../../locales/default.json"
import useSubscription from "../../../graphql/subscribe"
import { useTracker } from "../../../analytics"

const buildChangedSubscription = gql`
  subscription buildChanged($id: UUID!) {
    buildChanged(id: $id) {
      id
      buildStatus
    }
  }
`

export function ViewUrl({ buildId, className }) {
  return (
    <PreviewUrl className={className}>
      <a
        href={`https://build-${buildId}${process.env.GATSBY_PREVIEW_DOMAIN}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text.view} <MdPhonelink />
      </a>
    </PreviewUrl>
  )
}

const BuildDisplay = function BuildDisplay({
  id,
  siteId,
  organizationId,
  buildType,
  buildStatus,
  buildsEnabled,
  createdAt,
  flags,
  client,
  lastBuild,
}) {
  const Card = lastBuild ? LastBuildCard : BuildCard
  const { showModal } = useContext(ModalContext)
  const { trackButtonClicked } = useTracker()

  useSubscription(client, buildChangedSubscription, {
    options: {
      variables: {
        id,
      },
    },
    deps: [id],
  })

  return (
    <Card status={buildStatus}>
      {lastBuild && (
        <Fragment>
          <CardHeading>
            <strong>{text.lastBuild}</strong> <span>{text.created}</span>
            {` `}
            <time dateTime="{createdAt}">{`${formatDistance(
              new Date(createdAt),
              new Date(),
              {
                addSuffix: true,
              }
            )}`}</time>
          </CardHeading>

          {flags.builds && buildsEnabled && <ViewUrl buildId={id} />}
        </Fragment>
      )}

      {!lastBuild && (
        <Row>
          <Label>{text.created}</Label>
          <Separator>›</Separator>
          <time dateTime="{createdAt}">{`${formatDistance(
            new Date(createdAt),
            new Date(),
            {
              addSuffix: true,
            }
          )}`}</time>
          {flags.builds && buildsEnabled && (
            <Fragment>
              <Separator>›</Separator>
              <ViewUrl buildId={id} />
            </Fragment>
          )}
        </Row>
      )}

      <CardFooter>
        <BuildStatus status={buildStatus} />
        <Badge>{buildType && buildType.replace(`_`, ` `)}</Badge>

        {flags.builds && buildsEnabled ? (
          <Menu>
            <MenuButton>
              {text.showLogs} <MdSubject />
            </MenuButton>
            <MenuList className="showLogs">
              <MenuItem
                onSelect={() => {
                  trackButtonClicked(text.showLogs, {
                    organizationId,
                    uiSource: `Site Overview`,
                    buildId: id, // If we have it in the scope.
                  })
                  showModal({
                    Component: Logs,
                    props: { mode: `panel`, LOGS_QUERY, id, siteId, client },
                  })
                }}
              >
                <MdPictureInPicture /> {text.inModal}
              </MenuItem>
              <MenuLink
                target={`_blank`}
                href={`${window.location.origin}/dashboard/builds/${id}/logs`}
                component={`a`}
              >
                <MdTab /> {text.inNewTab}
              </MenuLink>
            </MenuList>
          </Menu>
        ) : (
          <SecondaryButton
            onClick={() => {
              trackButtonClicked(text.showLogs, {
                organizationId,
                uiSource: `Site Overview`,
                buildId: id, // If we have it in the scope.
              })
              showModal({
                Component: Logs,
                props: { mode: `panel`, LOGS_QUERY, id, siteId, client },
              })
            }}
          >
            {text.showLogs} <MdSubject />
          </SecondaryButton>
        )}
      </CardFooter>
    </Card>
  )
}

export default compose(
  withApollo,
  withFlags
)(BuildDisplay)
