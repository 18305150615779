import gql from "graphql-tag"

export const SITE_CONTRIBUTORS_QUERY = gql`
  query contributorsBySiteQuery($id: UUID!) {
    contributorsBySite(id: $id) {
      id
      name
      email
      avatarUrl
      contributorRole
    }
  }
`
