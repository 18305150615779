import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"
import * as Sentry from "@sentry/browser"

import { MdCheck } from "react-icons/md"
import AlertHexagon from "../../assets/AlertHexagon"

import {
  ModalConsumer,
  ModalPrimaryHeader,
  ModalPrimaryIcon,
  ModalBody,
  ModalMessage,
  ModalActions as BaseModalActions,
  ConfirmButton,
  ModalEmphasizedText,
} from "../Modal"
import { CancelButton } from "../shared/Buttons"
import { Heading, Text } from "./Typography"
import ErrorIcon from "../../assets/ErrorIcon"
import { modalErrors as text } from "../../locales/default.json"

const ModalActions = styled(BaseModalActions)`
  flex-direction: row-reverse;
`
const ErrorTemplate = ({
  heading,
  Message,
  label = text.gotIt,
  closeModal,
  onClose,
}) => (
  <Fragment>
    <ModalPrimaryHeader>
      <ModalPrimaryIcon>
        <ErrorIcon />
      </ModalPrimaryIcon>
      <Heading>{heading}</Heading>
    </ModalPrimaryHeader>
    <ModalBody>
      <Message />
      <ModalActions>
        <ConfirmButton
          onClick={() => {
            closeModal()

            if (onClose) {
              return onClose()
            }
          }}
        >
          {label}
          {` `}
          <MdCheck />
        </ConfirmButton>
        <CancelButton href="mailto:support@gatsbyjs.com">
          {text.contactSupport}
        </CancelButton>
      </ModalActions>
    </ModalBody>
  </Fragment>
)

ErrorTemplate.propTypes = {
  locales: PropTypes.object,
  closeModal: PropTypes.func,
}

export const NoGatsbyError = ({ closeModal }) => {
  const heading = text[`noGatsby`].heading
  const Message = () => (
    <ModalMessage>
      <ModalEmphasizedText>{text[`noGatsby`].message[0]}</ModalEmphasizedText>
      <Text>{text[`noGatsby`].message[1]}</Text>
      <Text>{text[`noGatsby`].message[2]}</Text>
      <Text>{text.emailSupport}</Text>
    </ModalMessage>
  )

  return (
    <ErrorTemplate
      heading={heading}
      Message={Message}
      closeModal={closeModal}
    />
  )
}

export const NoOrganizationPermissionError = ({ closeModal }) => {
  const heading = text[`noOrganizationPermission`].heading
  const Message = () => (
    <ModalMessage>
      <ModalEmphasizedText>
        {text[`noOrganizationPermission`].message[0]}
      </ModalEmphasizedText>
      <Text>{text[`noOrganizationPermission`].message[1]}</Text>
      <Text>{text.emailSupport}</Text>
    </ModalMessage>
  )

  return (
    <ErrorTemplate
      heading={heading}
      Message={Message}
      closeModal={closeModal}
    />
  )
}

export const DefaultError = ({ errMsg, onClose, closeModal }) => {
  const heading = text[`defaultError`].heading
  const Message = () => (
    <ModalMessage data-testid="error-modal-msg">
      <ModalEmphasizedText>
        <AlertHexagon />
        {errMsg.replace(`GraphQL error:`, ``)}
      </ModalEmphasizedText>
      <Text>{text[`defaultError`].message[0]}</Text>
      <Text>{text.emailSupport}</Text>
    </ModalMessage>
  )

  return (
    <ErrorTemplate
      heading={heading}
      onClose={onClose}
      Message={Message}
      closeModal={closeModal}
    />
  )
}

class ErrorMessage extends Component {
  state = {
    error: null,
  }

  componentDidMount() {
    const { errMsg, onClose, showModal } = this.props

    showModal({
      Component: DefaultError,
      props: {
        errMsg,
        onClose,
      },
    })
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  render() {
    return null
  }
}

ErrorMessage.propTypes = {
  errMsg: PropTypes.string,
  showModal: PropTypes.func,
}

const ErrorModal = ({ errMsg, onClose }) => (
  <ModalConsumer>
    {({ showModal }) => (
      <ErrorMessage showModal={showModal} onClose={onClose} errMsg={errMsg} />
    )}
  </ModalConsumer>
)

ErrorModal.propTypes = {
  errMsg: PropTypes.string.isRequired,
}

export default ErrorModal
