import React, { useEffect, useContext, Fragment } from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"
import gql from "graphql-tag"
import { rot13 } from "simple-rot13"
import { graphql } from "react-apollo"
import * as Yup from "yup"
import { Formik, Field, ErrorMessage, Form as BaseForm } from "formik"
import qs from "query-string"

import { MdArrowForward, MdErrorOutline, MdLock, MdCheck } from "react-icons/md"
import {
  ModalPrimaryHeader,
  ModalPrimaryIcon,
  ModalBody,
  ModalMessage,
  ModalActions as BaseModalActions,
  ModalContext,
  ConfirmButton,
} from "../Modal"
import { DefaultError } from "../shared/ErrorModal"
import { authenticatePreview } from "../../auth"
import Layout from "./Layout"
import { previewLogin as text } from "../../locales/default.json"
import { useTracker } from "../../analytics"
import { Input, InputError, Label } from "../shared/Forms"
import { PrimaryButton, CancelButton } from "../shared/Buttons"
import { Card as BaseCard } from "../shared/Card"
import {
  breakpoints,
  fontSizes,
  palette,
  spaces,
  colors,
} from "../../utils/presets"
import { Text } from "../shared/Typography"
import { setIdentify } from "../../utils/helpers/fullstory"

const Card = styled(BaseCard)`
  background: ${palette.white};
  margin: 0 auto;
  max-width: 100%;
  padding: ${spaces.l};
  width: 28rem;

  @media (min-width: ${breakpoints.desktop}px) {
    padding: ${spaces[`2xl`]};
  }
`

const Heading = styled(Text)`
  font-weight: bold;
  margin: 0 0 ${spaces.m};

  svg {
    color: ${palette.purple[500]};
    font-size: ${fontSizes.l};
    vertical-align: text-bottom;
  }
`

const Authenticate = styled(`div`)`
  display: flex;
  justify-content: center;
  margin: ${spaces.xl} 0 0;
`

const Form = styled(BaseForm)`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin: ${spaces.xl} 0 0;

  ${PrimaryButton} {
    margin-top: ${spaces.l};
    flex-shrink: 0;
  }
`

const PasswordField = styled(`fieldset`)`
  border: 0;
  flex-grow: 1;
  margin: 0;
  margin-right: ${spaces.m};

  ${Label} {
    color: ${palette.grey[500]};
    height: ${spaces.l};
    font-size: ${fontSizes.xs};
    margin-bottom: 0;
  }

  ${InputError} {
    font-size: ${fontSizes[`2xs`]};
  }
`

const ModalActions = styled(BaseModalActions)`
  flex-direction: row-reverse;
`

const AuthError = ({ closeModal }) => (
  <Fragment>
    <ModalPrimaryHeader>
      <ModalPrimaryIcon>
        <MdLock />
      </ModalPrimaryIcon>
      <Heading>{text.youDoNotHavePermissions}</Heading>
    </ModalPrimaryHeader>
    <ModalBody>
      <ModalMessage>
        <Text>{text.ifYouNeedAssistance}</Text>
      </ModalMessage>
      <ModalActions>
        <ConfirmButton onClick={closeModal}>
          {text.gotIt} <MdCheck />
        </ConfirmButton>
        <CancelButton href="mailto:support@gatsbyjs.com">
          {text.contactSupport}
        </CancelButton>
      </ModalActions>
    </ModalBody>
  </Fragment>
)

const Links = styled(`div`)`
  font-size: ${fontSizes.xs};
  justify-content: center;
  margin-top: ${spaces.m};
  text-align: center;

  a {
    :after {
      color: ${palette.grey[500]};
      content: "|";
      display: inline-block;
      margin: ${spaces.s};
    }

    :last-of-type {
      :after {
        content: "";
      }
    }
  }
`

function getHostnameFromParam(url) {
  const urlObj = new URL(url)
  const hostname = urlObj.hostname
  return hostname
}

const verifyPassword = gql`
  mutation verifyPreviewPassword($hostname: String!, $password: String!) {
    verifyPreviewPassword(hostname: $hostname, password: $password) {
      success
      message
    }
  }
`

let PreviewPasswordForm = function PreviewPasswordForm({
  mutate,
  hostname,
  loginRedirectUrl,
}) {
  const { showModal } = useContext(ModalContext)

  return (
    <Formik
      initialValues={{ previewPass: `` }}
      validationSchema={Yup.object().shape({
        previewPass: Yup.string().required(`Password is required`),
      })}
      onSubmit={values =>
        mutate({
          variables: {
            hostname,
            password: values.previewPass,
          },
        })
          .then(result => {
            const data = result.data

            const verifyPreviewPassword = data && data.verifyPreviewPassword

            if (verifyPreviewPassword && verifyPreviewPassword.success) {
              document.cookie = `PreviewPasswordAuth=${
                values.previewPass
              };max-age=86400;path=/;`

              return window.location.assign(loginRedirectUrl)
            }

            return showModal({
              Component: AuthError,
            })
          })
          .catch(err => {
            showModal({
              Component: DefaultError,
              props: { errMsg: err.message },
            })
          })
      }
    >
      {() => (
        <Form>
          <Field
            name={`previewPass`}
            type="previewPass"
            aria-label={`Site Preview Password`}
          >
            {({ field }) => (
              <PasswordField>
                <Label>{text.password}</Label>
                <Input {...field} type="password" id="previewPass" />
                <ErrorMessage name={`previewPass`}>
                  {msg => (
                    <InputError>
                      <MdErrorOutline /> {msg}
                    </InputError>
                  )}
                </ErrorMessage>
              </PasswordField>
            )}
          </Field>

          <PrimaryButton size={`L`} type="submit">
            {text.viewSite} <MdArrowForward />
          </PrimaryButton>
        </Form>
      )}
    </Formik>
  )
}

PreviewPasswordForm = graphql(verifyPassword)(PreviewPasswordForm)

const PreviewLogin = ({ location }) => {
  const { showModal } = useContext(ModalContext)
  const { trackPageViewed } = useTracker()
  const query = qs.parse(location.search)

  const token = query && query.token

  const csrfToken = query.csrfToken

  const loginRedirectUrl = query.loginRedirectUrl

  const hostname = getHostnameFromParam(query.loginRedirectUrl)

  const protectionType = (query && query.protectionType) || `CLOUD_AUTH`

  const authError = query && query.error && query.error === `preview-access`

  useEffect(() => {
    trackPageViewed(`Preview Log In Page`)

    if (authError) {
      showModal({
        Component: AuthError,
      })
    }

    if (token) {
      const name = rot13(decodeURIComponent(query.contact))
      const userId = rot13(query.contactId)
      const email = rot13(decodeURIComponent(query.loc))

      setIdentify({
        id: userId,
        name,
        email,
      })

      document.cookie = `PreviewAuth=${token};max-age=86400;domain=${
        process.env.GATSBY_PREVIEW_DOMAIN
      };path=/`
      return window.location.assign(loginRedirectUrl)
    }
  }, [token])

  return (
    <Layout>
      <Card>
        {protectionType === `CLOUD_AUTH` ? (
          <Fragment>
            <Heading>
              <MdLock /> {text.pleaseIdentifyYourself}
            </Heading>
            <Authenticate>
              <PrimaryButton
                size={`L`}
                onClick={() => {
                  authenticatePreview(location.search)
                }}
              >
                {text.signInWithGithub} <MdArrowForward />
              </PrimaryButton>
            </Authenticate>
          </Fragment>
        ) : (
          <Fragment>
            <Heading>
              <MdLock /> {text.thisPreviewIsProtected}
            </Heading>
            <PreviewPasswordForm
              csrfToken={csrfToken}
              hostname={hostname}
              loginRedirectUrl={loginRedirectUrl}
            />
          </Fragment>
        )}
      </Card>
      <Links>
        <LinkUrl
          href="https://www.gatsbyjs.com/preview"
          target="_blank"
          rel="noreferrer noopener"
        >
          {text.gatsbyPreview}
        </LinkUrl>
        {/* TODO: Update link when integration docs are all on one page */}
        <LinkUrl
          href="https://www.gatsbyjs.com/docs"
          target="_blank"
          rel="noreferrer noopener"
        >
          {text.documentation}
        </LinkUrl>
        {` `}
      </Links>
    </Layout>
  )
}

const LinkUrl = styled(`a`)`
  color: ${colors.gatsby};
  text-decoration: none;
`

PreviewLogin.propTypes = {
  location: PropTypes.object,
}

export default PreviewLogin
