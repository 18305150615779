import React, { useState, Fragment } from "react"
import styled from "react-emotion"
import gql from "graphql-tag"
import { withApollo } from "react-apollo"
import { MdArrowForward } from "react-icons/md"
import ContentfulMonogram from "../../../assets/ContentfulMonogram"
import useSubscription from "../../../graphql/subscribe"

import Loading from "../Loading"
import { PrimaryButton, CancelButton } from "../Buttons"
import {
  Layout,
  Actions,
  Instruction,
} from "../../Modal/fullModalLayouts/IntegrationLayout"
import { breakpoints, spaces } from "../../../utils/presets"
import { contentfulModal as text } from "../../../locales/default.json"
import ContentfulForm from "./ContentfulForm"

const AuthorizeActions = styled(Actions)`
  justify-content: space-between;
  margin-top: ${spaces[`4xl`]};

  button {
    margin-right: ${spaces.m};
  }

  ${PrimaryButton} {
    span {
      display: none;
    }
  }

  @media (min-width: ${breakpoints.desktop}px) {
    justify-content: flex-start;

    ${PrimaryButton} {
      span {
        display: static;
      }
    }
  }
`

const contentfulAddedSubscription = gql`
  subscription contentfulAdded($siteId: UUID!) {
    contentfulIntegrationAdded(siteId: $siteId)
  }
`

export const ContentfulModal = ({
  siteId,
  closeModal,
  orgSiteName,
  isConnected,
  name,
  client,
}) => {
  const [modalState, setModalState] = useState(
    isConnected ? `authorized` : `initial`
  )

  if (siteId) {
    useSubscription(client, contentfulAddedSubscription, {
      options: {
        variables: { siteId },
      },
      deps: [siteId],
      onResult: ({ data: { contentfulIntegrationAdded } }) => {
        if (contentfulIntegrationAdded) {
          setModalState(`authorized`)
        }
      },
    })
  }

  return (
    <Layout
      title="Contentful Integration"
      closeModal={closeModal}
      logo={ContentfulMonogram}
    >
      {modalState === `initial` && (
        <Fragment>
          <Instruction>
            {text.requireAuthorization}
            {` `}
            <strong>{orgSiteName}</strong>.
          </Instruction>
          <AuthorizeActions>
            <CancelButton onClick={closeModal}>Cancel</CancelButton>
            <PrimaryButton
              onClick={() => {
                setModalState(`authorization`)
                if (process.env.GATSBY_MOCK_MODE) {
                  setModalState(`authorized`)
                  return
                }
                /* TODO: Finish Oauth when implementation is complete */
                window.open(
                  `${
                    process.env.GATSBY_DASHBOARD_AUTHENTICATION_URL
                  }/oauth/contentful?siteId=${siteId}`,
                  `_blank`,
                  process.env.NODE_ENV === `production`
                    ? `height=600,width=700`
                    : ``
                )
              }}
            >
              {text.authorize} <span>{text.withContentful}</span>
              <MdArrowForward />
            </PrimaryButton>
          </AuthorizeActions>
        </Fragment>
      )}

      {modalState === `authorization` && (
        <Fragment>
          <Loading message="Authorizing..." />
        </Fragment>
      )}

      {modalState === `authorized` && (
        <ContentfulForm
          siteId={siteId}
          closeModal={closeModal}
          orgSiteName={orgSiteName}
          name={name}
        />
      )}
    </Layout>
  )
}

export default withApollo(ContentfulModal)
