import React, { Fragment, useState } from "react"
import styled from "react-emotion"
import PropTypes from "prop-types"
import gql from "graphql-tag"
import { compose, graphql } from "react-apollo"

import { MdInfo } from "react-icons/md"

import { Text } from "../../shared/Typography"
import { NarrowSingleColumn } from "../../shared/Containers"
import { createSite as text } from "../../../locales/default.json"
import {
  breakpoints,
  palette,
  radius,
  spaces,
  fontSizes,
} from "../../../utils/presets"
import { Search, DropdownLabel } from "../../shared/Forms"
import OrganizationList from "./OrganizationList"
import Loading from "../../shared/Loading"
import UserRepositories from "./UserRepositories"
import { useTracker } from "../../../analytics"

const ORGANIZATIONS_QUERY = gql`
  query OrganizationsInfo {
    currentUser {
      id
      nickname
      sourceOrganizations {
        name
      }
    }
  }
`

const SearchSection = styled(`div`)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.phablet}px) {
    flex-wrap: nowrap;
  }
`

const SearchRepo = styled(Search)`
  width: 100%;
  margin-left: 0;
  margin-top: 0.4rem;

  :disabled {
    cursor: not-allowed;
  }

  @media (min-width: ${breakpoints.phablet}px) {
    margin-left: 1rem;
    margin-top: 0;
  }
`

const Content = styled(NarrowSingleColumn)`
  margin: ${spaces.xl} auto;

  @media (min-width: ${breakpoints.desktop}px) {
    margin: ${spaces[`2xl`]} auto;
  }
`

const Instruction = styled(Text)`
  margin-top: 0;

  strong {
    display: block;
  }

  span {
    display: block;
    margin-top: ${spaces.m};
  }
`

const Hint = styled(`p`)`
  align-items: center;
  background: ${palette.orange[50]};
  border: 1px solid ${palette.orange[100]};
  border-radius: ${radius.default};
  color: ${palette.yellow[900]};
  display: flex;
  font-size: ${fontSizes.xs};
  line-height: 1.3;
  margin: 0;
  margin-top: ${spaces.l};
  padding: ${spaces.m};

  svg {
    color: ${palette.yellow[600]};
    flex-shrink: 0;
    height: auto;
    margin-right: ${spaces.s};
    width: 1.3rem;
  }
`

export const FooterHint = styled(`p`)`
  align-items: center;
  color: ${palette.grey[600]};
  display: flex;
  font-size: ${fontSizes.xs};
  line-height: 1.3;
  margin: 0;

  svg {
    color: ${palette.grey[400]};
    flex-shrink: 0;
    height: auto;
    margin-right: ${spaces.xs};
    width: 1.3rem;
  }
`

const OAuthSettingsLink = styled(`a`)`
  cursor: pointer;
  text-decoration: underline;
`

const PickRepository = ({ cancel, selectRepo, organizationsQuery }) => {
  const { trackButtonClicked } = useTracker()
  const [formState, setFormState] = useState({ input: ``, selectedOrg: `` })

  if (organizationsQuery.loading) {
    return (
      <Loading
        delay={1000}
        message="loading your sites..."
        fullPageHeight={false}
        style={{ marginTop: spaces.xl }}
      />
    )
  }

  if (organizationsQuery.error)
    return <p>Error: {organizationsQuery.error.message}</p>

  const {
    currentUser: { sourceOrganizations, nickname },
  } = organizationsQuery

  return (
    <Fragment>
      <Content>
        <Instruction>
          <strong>{text.chooseRepository}</strong>
          {text.whenYouPushOr}
          <span>
            {text.noGitHubOrg}
            {` `}
            <OAuthSettingsLink
              onClick={() =>
                trackButtonClicked(text.requestPermission, {
                  uiSource: `Create site and pick repo`,
                })
              }
              target="_blank"
              href={process.env.GATSBY_OAUTH_SETTINGS_URL}
            >
              {text.requestPermission}
            </OAuthSettingsLink>
          </span>
        </Instruction>
        <Hint>
          <MdInfo />
          {text.yourRepositoryMust}
        </Hint>
        <DropdownLabel>{text.organization}</DropdownLabel>
        <SearchSection>
          <OrganizationList
            selectedOrg={
              formState[`selectedOrg`] === null
                ? nickname
                : formState[`selectedOrg`]
            }
            sourceOrganizations={sourceOrganizations}
            chooseAllOrgs={() =>
              setFormState({ ...formState, input: ``, selectedOrg: `` })
            }
            changeOrg={org => setFormState({ ...formState, selectedOrg: org })}
          />
          <SearchRepo
            aria-label={text.searchPlaceholder}
            value={formState[`input`]}
            placeholder={text.searchPlaceholder}
            onChange={e =>
              setFormState({ ...formState, input: e.target.value })
            }
            disabled={formState[`selectedOrg`] === ``}
          />
        </SearchSection>
        <UserRepositories
          formState={formState}
          nickname={nickname}
          selectRepo={selectRepo}
          cancel={cancel}
        />
      </Content>
    </Fragment>
  )
}

PickRepository.propTypes = {
  selectRepo: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}

export default compose(
  graphql(ORGANIZATIONS_QUERY, {
    name: `organizationsQuery`,
  })
)(PickRepository)
