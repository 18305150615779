import React, { useContext, Fragment } from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"
import { Formik, Field, ErrorMessage } from "formik"
import gql from "graphql-tag"
import { graphql, compose } from "react-apollo"
import { MdArrowForward, MdErrorOutline } from "react-icons/md"
import * as Yup from "yup"

import { Form as BaseForm, Radio } from "../Forms"
import Loading from "../Loading"
import { PrimaryButton, CancelButton } from "../Buttons"
import { DefaultError } from "../ErrorModal"
import { Text } from "../Typography"
import {
  Actions,
  Instruction,
} from "../../Modal/fullModalLayouts/IntegrationLayout"
import { palette, spaces } from "../../../utils/presets"
import { contentfulModal as text } from "../../../locales/default.json"
import { ModalContext } from "../../Modal"
import { ENV_VARS_QUERY } from "../../../graphql/queries"

const SET_SITE_CMS_INTEGRATION_MUTATION = gql`
  mutation setSiteCMSIntegration(
    $config: JSON!
    $siteId: UUID!
    $vendor: CmsVendor!
  ) {
    setSiteCMSIntegration(config: $config, siteId: $siteId, vendor: $vendor) {
      success
      message
      errorType
    }
  }
`

const Form = styled(BaseForm)`
  margin-top: ${spaces.xl};
`

const ErrorText = styled(Text)`
  align-items: center;
  color: ${palette.red[600]};
  display: flex;
  svg {
    fill: ${palette.red[600]};
    margin-right: ${spaces.xs};
  }
`

const contentfulSpacesList = gql`
  query contentfulSpacesForIntegration($siteId: UUID!) {
    contentfulSpacesForIntegration(siteId: $siteId) {
      id
      name
      selected
    }
  }
`

const validationSchema = Yup.object().shape({
  space: Yup.string().required(
    `You must select a Contentful Space to continue`
  ),
})

export const ContentfulForm = ({
  siteId,
  closeModal,
  orgSiteName,
  name,
  setSiteCMSIntegration,
  showToast,
  contentfulSpaces,
  error,
  loading,
}) => {
  const { showModal } = useContext(ModalContext)
  if (loading) {
    return (
      <Loading
        delay={1000}
        message="loading contentful spaces..."
        fullPageHeight={false}
      />
    )
  }

  if (error) return <p>Error: {error.message}</p>

  const selectedSpace =
    (contentfulSpaces || []).find(({ selected }) => !!selected) || {}

  const selectedSpaceId = (selectedSpace && selectedSpace.id) || ``

  return (
    <Fragment>
      <Instruction>
        {text.chooseSpace}
        {` `}
        <strong>{orgSiteName}</strong>.
      </Instruction>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ space: selectedSpaceId }}
        onSubmit={values => {
          setSiteCMSIntegration({
            variables: {
              config: { spaceId: values.space },
              siteId,
              vendor: name,
            },
            refetchQueries: [
              `cmsIntegrationsForSite`,
              {
                query: ENV_VARS_QUERY,
                variables: { id: siteId },
              },
            ],
          })
            .then(() => {
              closeModal()
              if (showToast) {
                showToast(
                  `<strong>${text.integrationUpdated}</strong> ${
                    text.successfully
                  }`
                )
              }
            })
            .catch(err => {
              closeModal()
              showModal({
                Component: DefaultError,
                props: { errMsg: err.message },
              })
            })
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Fragment>
            <Form>
              <ErrorMessage name="space">
                {msg => (
                  <ErrorText>
                    <MdErrorOutline />
                    {msg}
                  </ErrorText>
                )}
              </ErrorMessage>
              {contentfulSpaces.map(({ id, name }, index) => (
                <Field name={`space`} key={index}>
                  {({ field, form, ...formikProps }) => (
                    <Radio
                      fieldName={`space`}
                      type={`colourful`}
                      optionValue={id}
                      htmlLabel={name}
                      id={name}
                      {...field}
                      {...formikProps}
                      {...form}
                    />
                  )}
                </Field>
              ))}
            </Form>
            <Actions>
              <CancelButton type="button" onClick={closeModal}>
                {text.cancel}
              </CancelButton>
              <PrimaryButton
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {text.continue}
                <MdArrowForward />
              </PrimaryButton>
            </Actions>
          </Fragment>
        )}
      </Formik>
    </Fragment>
  )
}

ContentfulForm.propTypes = {
  siteId: PropTypes.string,
  closeModal: PropTypes.func,
  name: PropTypes.string,
  showToast: PropTypes.func,
  orgSiteName: PropTypes.string,
}

export default compose(
  graphql(SET_SITE_CMS_INTEGRATION_MUTATION, {
    name: `setSiteCMSIntegration`,
  }),
  graphql(contentfulSpacesList, {
    options: ({ siteId }) => {
      return {
        variables: {
          siteId,
        },
        fetchPolicy: `cache-and-network`,
      }
    },
    props: ({ data, ...rest }) => {
      return {
        loading: data.loading,
        contentfulSpaces: (data && data.contentfulSpacesForIntegration) || [],
        error: data.error,
        ...rest,
      }
    },
  })
)(ContentfulForm)
