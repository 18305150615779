import React from "react"
import { withApollo } from "react-apollo"
import PropTypes from "prop-types"
import gql from "graphql-tag"

import {
  SiteListContainer,
  SiteCard,
  NoSitesCard,
  NoSitesHeader,
} from "./SiteListElements"
import useSubscription from "../../../graphql/subscribe"
import { sites as sitesText } from "../../../locales/default.json"

const siteChangedSubscription = gql`
  subscription siteChanged($id: UUID!) {
    siteChanged(id: $id) {
      id
      previewStatus
      updatedAt
    }
  }
`

function SiteCardRow({ client, organizationId, site }) {
  useSubscription(client, siteChangedSubscription, {
    options: {
      variables: { id: site.id },
    },
    deps: [site.id],
  })
  return <SiteCard organizationId={organizationId} site={site} />
}

const SitesByOrg = ({ client, sites, organizationId, siteLength }) => (
  <SiteListContainer>
    {sites.length ? (
      sites.map(site => (
        <SiteCardRow
          client={client}
          key={site.id}
          organizationId={organizationId}
          site={site}
        />
      ))
    ) : (
      <NoSitesCard>
        <NoSitesHeader data-cy="sites-list-no-sites-header">
          {siteLength === 0
            ? `${sitesText.noSites}`
            : `${sitesText.noMatchingSites}`}
        </NoSitesHeader>
      </NoSitesCard>
    )}
  </SiteListContainer>
)

SitesByOrg.propTypes = {
  sites: PropTypes.array,
  siteLength: PropTypes.number,
  organizationId: PropTypes.string,
}

export default withApollo(SitesByOrg)
