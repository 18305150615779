import React from "react"

export default () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="30" cy="30" r="30" fill="#EC1818" />
    <path
      d="M28.2679 15C29.0377 13.6667 30.9622 13.6667 31.732 15L45.5885 39C46.3583 40.3333 45.396 42 43.8564 42H16.1436C14.604 42 13.6417 40.3333 14.4115 39L28.2679 15Z"
      fill="white"
    />
    <path
      d="M31.5385 29.6923C31.5385 30.542 30.8497 31.2308 30 31.2308C29.1503 31.2308 28.4615 30.542 28.4615 29.6923V23.5385C28.4615 22.6888 29.1503 22 30 22C30.8497 22 31.5385 22.6888 31.5385 23.5385V29.6923ZM30 37.8462C28.8923 37.8462 28 36.9538 28 35.8462C28 34.7385 28.8923 33.8462 30 33.8462C31.1077 33.8462 32 34.7385 32 35.8462C32 36.9538 31.1077 37.8462 30 37.8462Z"
      fill="#EC1818"
    />
  </svg>
)
