import React, { Fragment } from "react"
import styled, { keyframes } from "react-emotion"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik"

import { MdArrowForward, MdAdd, MdCancel, MdErrorOutline } from "react-icons/md"

// import { Debug } from "../../../utils/debug/Formik"
import { Input, Label } from "../Forms"
import { createSite as text } from "../../../locales/default.json"
import { PrimaryButton, SecondaryButton, CancelButton } from "../Buttons"
import { breakpoints, fontSizes, palette, spaces } from "../../../utils/presets"
import { useTracker } from "../../../analytics"

const Vars = styled(`div`)`
  display: flex;
  flex-direction: column;
  margin: ${spaces.xl} 0;
`

const varEntry = keyframes`
   to {
     opacity: 1;
     transform: translateY(0);
   }
 `

const Var = styled(`div`)`
  animation: ${varEntry} 0.5s ease forwards;
  display: flex;
  margin: ${spaces.xs} 0;
  opacity: 0;
  transform: translateY(${spaces.s});
`

const Fieldset = styled(`fieldset`)`
  border: 0;
  margin: 0;

  ${Input} {
    width: 100%;
  }
`

const Key = styled(Fieldset)`
  flex-shrink: 1;

  margin-right: ${spaces.m};
  width: 50%;

  ${Input} {
    ::placeholder {
      text-transform: none;
    }
  }
`

const Val = styled(Fieldset)`
  width: 50%;
`

const InputError = styled(`span`)`
  align-items: center;
  color: ${palette.red[600]};
  display: flex;
  font-size: ${fontSizes[`2xs`]};
  line-height: 1;
  padding: ${spaces.xs} ${spaces[`2xs`]} 0;

  svg {
    color: red;
    flex-shrink: 0;
    margin-right: ${spaces[`3xs`]};
  }
`

const RemoveButton = styled(`button`)`
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  height: 2.2rem;
  justify-content: center;
  margin: 0;
  width: 100%;

  svg {
    color: ${palette.red[600]};
    transition: 0.5s ease;
  }
`

const Remove = styled(Fieldset)`
  flex-shrink: 0;
  width: 60px;

  ${Label} {
    padding-left: 0;
    text-align: center;
    width: 100%;
  }

  @media (hover: hover) {
    &:hover {
      ${RemoveButton} {
        svg {
          transform: scale(1.5);
        }
      }
    }
  }
`

const AddVarButton = styled(SecondaryButton)`
  margin-top: ${spaces.l};
`

const Footer = styled(`footer`)`
  display: flex;
  justify-content: space-between;
  margin-top: ${spaces[`2xl`]};
  padding: ${spaces.xl} 0 0;
  position: relative;

  :before {
    border-top: 1px solid ${palette.grey[200]};
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    padding-top: ${spaces[`2xl`]};
  }
`

const Cancel = styled(CancelButton)`
  margin-right: ${spaces.xl};
`

const envVarSchema = Yup.object().shape({
  key: Yup.string().required(text.cantBeEmpty),
  value: Yup.string().required(text.cantBeEmpty),
})

export const validationSchema = Yup.object().shape({
  envVars: Yup.array().of(envVarSchema),
})

const EnvVarsForm = ({
  envVars,
  submitCallback,
  submitButtonLabel,
  cancelCallback,
  siteId,
  uiSource,
}) => {
  const { trackButtonClicked } = useTracker()
  return (
    <Formik
      initialValues={{ envVars }}
      validationSchema={validationSchema}
      onSubmit={values => {
        const mappedValues = values.envVars.map(({ key, value }) => {
          return { key, value }
        })
        submitCallback(mappedValues)
      }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <FieldArray name="envVars">
            {({ push, remove }) => (
              <Fragment>
                {values.envVars.length > 0 && (
                  <Vars>
                    {values.envVars.map((envVar, idx) => (
                      <Var key={idx}>
                        <Key>
                          {idx === 0 && (
                            <Label data-cy="env-vars-key-label">
                              {text.key}
                            </Label>
                          )}
                          <Field
                            name={`envVars[${idx}].key`}
                            type="text"
                            aria-label={text.key}
                          >
                            {({ field }) => (
                              <Input
                                {...field}
                                placeholder={text.key}
                                aria-label="Environment variable key"
                                data-cy="env-vars-key"
                              />
                            )}
                          </Field>
                          <ErrorMessage name={`envVars[${idx}].key`}>
                            {msg => (
                              <InputError data-cy="env-vars-key-error">
                                <MdErrorOutline /> {msg}
                              </InputError>
                            )}
                          </ErrorMessage>
                        </Key>
                        <Val>
                          {idx === 0 && (
                            <Label data-cy="env-vars-value-label">
                              {text.value}
                            </Label>
                          )}
                          <Field
                            name={`envVars[${idx}].value`}
                            aria-label={text.value}
                          >
                            {({ field }) => (
                              <Input
                                {...field}
                                type={text}
                                placeholder={text.value}
                                aria-label="Environment variable value"
                                data-cy="env-vars-value"
                              />
                            )}
                          </Field>
                          <ErrorMessage name={`envVars[${idx}].value`}>
                            {msg => (
                              <InputError data-cy="env-vars-value-error">
                                <MdErrorOutline /> {msg}
                              </InputError>
                            )}
                          </ErrorMessage>
                        </Val>
                        <Remove>
                          {idx === 0 && <Label>{text.remove}</Label>}
                          <RemoveButton
                            data-cy="remove-env-vars"
                            aria-label="Remove environment variable"
                            type="button"
                            onClick={() => {
                              trackButtonClicked(`Remove variable`, {
                                siteId,
                                uiSource: uiSource,
                              })
                              remove(idx)
                            }}
                          >
                            <MdCancel />
                          </RemoveButton>
                        </Remove>
                      </Var>
                    ))}
                  </Vars>
                )}
                <AddVarButton
                  data-cy="add-env-vars"
                  type="button"
                  onClick={() => {
                    trackButtonClicked(text.addVariable, {
                      siteId,
                      uiSource: uiSource,
                    })
                    push({ key: ``, value: `` })
                  }}
                >
                  {text.addVariable} <MdAdd />
                </AddVarButton>
              </Fragment>
            )}
          </FieldArray>
          <Footer>
            <Cancel
              data-cy="cancel-button"
              onClick={cancelCallback}
              size={`L`}
              type={`reset`}
            >
              {text.cancel}
            </Cancel>
            <PrimaryButton
              type="submit"
              disabled={isSubmitting}
              data-cy="preview-site-button"
            >
              {submitButtonLabel} <MdArrowForward />
            </PrimaryButton>
          </Footer>

          {/* Uncomment to see Formik state */}
          {/* <Debug /> */}
        </Form>
      )}
    </Formik>
  )
}

EnvVarsForm.propTypes = {
  envVars: PropTypes.array.isRequired,
  submitCallback: PropTypes.func.isRequired,
  submitButtonLabel: PropTypes.string.isRequired,
  cancelCallback: PropTypes.func.isRequired,
}

export default EnvVarsForm
