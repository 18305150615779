import React, { Fragment } from "react"
import { PropTypes } from "prop-types"
import styled from "react-emotion"
import { navigate } from "gatsby"
import { graphql } from "react-apollo"
import { spaces } from "../../../utils/presets"
import { Heading } from "../../shared/Typography"
import { billing as text } from "../../../locales/default.json"
import { PaymentProvider, CheckoutForm } from "../../Payment"
import { SUBSCRIBE_TO_PLAN } from "../../Payment/mutations"
import { ErrorModal, SuccessModal } from "../Modals"
import Review from "../Review"
import Footer from "../Footer"

const PaymentHeader = styled(Heading)`
  padding: ${spaces[`2xl`]} 0 ${spaces.l};
`

const FormRoot = styled(`div`)`
  margin: 0 auto;
  max-width: 100%;
`

let CheckoutFormWithMutation = CheckoutForm

if (graphql) {
  CheckoutFormWithMutation = graphql(SUBSCRIBE_TO_PLAN)(
    CheckoutFormWithMutation
  )
}

const Subscribe = ({ organizationId, showModal, plan, name }) => (
  <Fragment>
    <PaymentHeader>{text.addPayment}</PaymentHeader>
    <PaymentProvider>
      <FormRoot>
        <CheckoutFormWithMutation
          cancelCallback={() =>
            navigate(`/dashboard/organization/sites/${organizationId}`)
          }
          submitButtonText={text.startSubscription}
          cancelButtonText={text.cancel}
          planId={plan.id}
          organizationId={organizationId}
          showError={(err, creditCard) =>
            showModal({
              Component: ErrorModal,
              props: { err, creditCard, name },
            })
          }
          showSuccess={(plan, creditCard, billingDate) =>
            showModal({
              Component: SuccessModal,
              props: {
                plan,
                creditCard,
                billingDate,
                name,
                organizationId,
                mode: `success`,
              },
            })
          }
        >
          <Review plan={plan} />
        </CheckoutFormWithMutation>
      </FormRoot>
    </PaymentProvider>
    <Footer text={text.subscriptionNote} />
  </Fragment>
)

Subscribe.propTypes = {
  organizationId: PropTypes.string,
  plan: PropTypes.object,
  name: PropTypes.string,
}

export default Subscribe
