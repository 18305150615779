import React from "react"

export default ({ className, svgRef }) => (
  <svg
    width="128"
    height="107"
    viewBox="0 0 128 107"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={svgRef}
    className={className}
  >
    <circle
      r="11.001"
      transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 11.001 95.001)"
      fill="#CCFFFC"
    />
    <circle cx="67.001" cy="11.001" r="11.001" fill="#FFEF9B" />
    <circle cx="67.001" cy="39.001" r="11.001" fill="#EEC0DF" />
    <circle cx="39.001" cy="39.001" r="11.001" fill="#E8E1F0" />
    <circle
      r="10.9998"
      transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 94.9998 94.9998)"
      fill="#DCF0FD"
    />
    <circle
      r="10.9998"
      transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 117 66.9998)"
      fill="#F8E1F1"
    />
    <circle
      r="11.001"
      transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 67.001 67.001)"
      fill="#FFD6D4"
    />
  </svg>
)
