import React from "react"

export default () => (
  <svg
    width="142"
    height="160"
    viewBox="0 0 142 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.1555 111.845C39.6939 103.407 34.9383 91.9491 34.9383 79.9998C34.9383 68.0504 39.6939 56.5924 48.1555 48.155C55.0188 41.2917 55.0188 30.164 48.1555 23.3006C41.2921 16.4372 30.1644 16.4372 23.301 23.3006C8.42823 38.4326 0.0652576 58.7825 0 79.9998C0.21099 101.188 8.55243 121.485 23.301 136.699C27.7409 141.139 34.212 142.873 40.2769 141.248C46.3418 139.623 51.0791 134.885 52.7042 128.82C54.3292 122.756 52.5953 116.284 48.1555 111.845Z"
      fill="#FFD85F"
    />
    <path
      d="M48.1553 48.1555C56.5927 39.6939 68.0507 34.9383 80 34.9383C91.9494 34.9383 103.407 39.6939 111.845 48.1555C118.708 55.0188 129.836 55.0188 136.699 48.1555C143.563 41.2921 143.563 30.1644 136.699 23.301C121.567 8.42823 101.217 0.0652576 80 0C58.812 0.21099 38.5145 8.55243 23.3008 23.301C16.4375 30.1644 16.4375 41.2921 23.3008 48.1555C30.1642 55.0188 41.2919 55.0188 48.1553 48.1555Z"
      fill="#3BB4E7"
    />
    <path
      d="M111.845 111.844C103.407 120.306 91.9494 125.062 80 125.062C68.0507 125.062 56.5927 120.306 48.1553 111.844C41.2919 104.981 30.1642 104.981 23.3008 111.844C16.4375 118.708 16.4375 129.836 23.3008 136.699C38.4328 151.572 58.7827 159.935 80 160C101.188 159.789 121.486 151.448 136.699 136.699C141.139 132.259 142.873 125.788 141.248 119.723C139.623 113.658 134.886 108.921 128.821 107.296C122.756 105.671 116.285 107.405 111.845 111.844Z"
      fill="#ED5C68"
    />
  </svg>
)
