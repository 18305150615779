import React, { Fragment, useContext, useState } from "react"
import styled from "react-emotion"
import PropTypes from "prop-types"
import { MdEdit } from "react-icons/md"
import { ApolloFragment } from "apollo-fragment-react"

import {
  breakpoints,
  spaces,
  palette,
  fontSizes,
} from "../../../../utils/presets"
import { paymentInformation as paymentText } from "../../../../locales/default.json"
import {
  SettingsCard,
  Header,
  Title,
} from "../../../shared/Settings/SettingsCard"
import { SecondaryButton } from "../../../shared/Buttons"
import { ModalContext } from "../../../Modal"
import { Resubscribe } from "../../../shared/PaymentInformation/Modals"
import { UpdatePaymentForm as BaseUpdatePaymentForm } from "../../../shared/PaymentInformation/UpdatePaymentForm"
import PaymentHistory from "./PaymentHistory"
import { PAYMENT_FRAGMENT } from "../queries"
import CardInformation from "../../../shared/PaymentInformation/CardInformation"
import { OrganizationPermissions } from "../../../../permissions"

const Button = styled(SecondaryButton)`
  font-size: ${fontSizes.s};

  @media (min-width: ${breakpoints.mobile}px) {
    font-size: ${fontSizes.m};
  }
`

const PaymentRoot = styled(SettingsCard)`
  margin-bottom: ${spaces.m};
  padding: 0;

  @media (min-width: ${breakpoints.desktop}px) {
    padding: 0;
    margin-bottom: ${spaces.l};
  }
`

const Information = styled(`section`)`
  padding: ${spaces.m} ${spaces.l};

  @media (min-width: ${breakpoints.desktop}px) {
    padding: ${spaces.xl} ${spaces[`2xl`]};
  }

  &.success {
    background: ${palette.green[50]};
  }

  &.error {
    background: ${palette.red[50]};
  }
`

const UpdatePaymentForm = styled(BaseUpdatePaymentForm)`
  padding: ${spaces.m} ${spaces.l};

  @media (min-width: ${breakpoints.desktop}px) {
    padding: ${spaces.xl} ${spaces[`2xl`]};
  }
`

const PaymentInformation = ({
  organizationId,
  billingStatus,
  setResponse,
  res,
}) => {
  const [paymentFormVisible, setPaymentFormVisible] = useState(false)
  const { showModal } = useContext(ModalContext)

  return (
    <PaymentRoot>
      <ApolloFragment id={organizationId} fragment={PAYMENT_FRAGMENT}>
        {({ data }) => {
          const {
            name,
            billing: {
              nextBillingDate,
              creditCard: { lastFour, brand },
              plan: { interval, formattedAmount, amount, id },
            },
          } = data

          const cardBrand = !res.creditCard ? brand : res.creditCard.brand
          const cardNumber = !res.creditCard
            ? lastFour
            : res.creditCard.lastFour

          return (
            <Fragment>
              {!paymentFormVisible ? (
                <Fragment>
                  <Information
                    className={res.success ? `success` : res.err ? `error` : ``}
                  >
                    <Header>
                      <Title>{paymentText.paymentInformation}</Title>
                      <OrganizationPermissions
                        id={organizationId}
                        resource="billing"
                        action="edit"
                      >
                        <Button onClick={() => setPaymentFormVisible(true)}>
                          {paymentText.updatePayment} <MdEdit />
                        </Button>
                      </OrganizationPermissions>
                      {billingStatus === `CANCELED` && (
                        <OrganizationPermissions
                          id={organizationId}
                          resource="billing"
                          action="edit"
                        >
                          <Button
                            onClick={() => {
                              showModal({
                                Component: Resubscribe,
                                props: {
                                  name,
                                  organizationId,
                                  id,
                                  mode: `action`,
                                },
                              })
                            }}
                          >
                            {paymentText.updatePayment} <MdEdit />
                          </Button>
                        </OrganizationPermissions>
                      )}
                    </Header>

                    <CardInformation
                      cardBrand={cardBrand}
                      cardNumber={cardNumber}
                      interval={interval}
                      amount={amount}
                      formattedAmount={formattedAmount}
                      nextBillingDate={nextBillingDate}
                    />
                  </Information>
                  <PaymentHistory />
                </Fragment>
              ) : (
                <UpdatePaymentForm
                  name={name}
                  organizationId={organizationId}
                  id={id}
                  setResponse={res => setResponse(res)}
                  cancelCallback={() => setPaymentFormVisible(false)}
                />
              )}
            </Fragment>
          )
        }}
      </ApolloFragment>
    </PaymentRoot>
  )
}

PaymentInformation.propTypes = {
  organizationId: PropTypes.string.isRequired,
}

export default PaymentInformation
