import React from "react"
import PropTypes from "prop-types"

import { billing as text } from "../../locales/default.json"
import { StripeError } from "./Error"
import { CardLabel, CardNumber, CardExpiry, CardCVC } from "./PaymentElements"

const InputFields = [
  { Component: CardNumber, Label: `${text.cardNumber}`, Name: `cardNumber` },
  {
    Component: CardExpiry,
    Label: `${text.expirationDate}`,
    Name: `cardExpiry`,
  },
  { Component: CardCVC, Label: `${text.cvcCode}`, Name: `cardCvc` },
]

const StripeElements = ({ formState, validateCard }) =>
  InputFields.map((Input, index) => (
    <CardLabel key={index} htmlFor={Input.Name} data-cy={Input.Name}>
      {Input.Label}
      <Input.Component name={Input.Name} onChange={validateCard} />
      <StripeError
        error={
          formState[Input.Name] !== `valid` && formState[Input.Name].length > 0
            ? formState[Input.Name]
            : null
        }
      />
    </CardLabel>
  ))

StripeElements.propTypes = {
  errors: PropTypes.object,
  validateCard: PropTypes.func,
}

export default StripeElements
