import React, { Fragment } from "react"
import styled, { keyframes } from "react-emotion"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import {
  colors,
  fontFamilies,
  radius,
  palette,
  fontSizes,
  breakpoints,
  spaces,
} from "../../utils/presets"

import { MdRefresh } from "react-icons/md"

const loading = keyframes`
  0% {
    transform: translateX(0.2em) rotate(0);
  }
  100% {
    transform: translateX(0.2em) rotate(360deg);
  }
`

const pulse = keyframes`
  33% {
    transform: translateX(0.2em) scale(1.05);
  }
  66% {
    transform: translateX(0.2em) scale(0.85);
  }

`

const sizes = {
  S: {
    fontSize: 0.875,
    height: 1.5,
    horizontalPadding: 0.5,
    verticalPadding: 0.3,
  },
  M: {
    fontSize: 1,
    height: 2,
    horizontalPadding: 0.75,
    verticalPadding: 0.45,
  },
  L: {
    fontSize: 1.125,
    height: 2.25,
    horizontalPadding: 1,
    verticalPadding: 0.55,
  },
  XL: {
    fontSize: 1.5,
    height: 3.25,
    horizontalPadding: 1.25,
    verticalPadding: 0.65,
  },
}

const BaseButtonRoot = styled(`button`)`
  align-items: center;
  border: ${palette.grey[600]};
  border-radius: ${radius.default};
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${props => sizes[props.size].fontSize}rem;
  line-height: 1;
  min-height: ${props => sizes[props.size].height}rem;
  padding: ${props => sizes[props.size].verticalPadding}rem
    ${props => sizes[props.size].horizontalPadding}rem;
  text-decoration: none;
  transition: 0.5s;

  &[disabled],
  &[disabled]:hover {
    cursor: not-allowed;
    background: ${palette.grey[400]};
    border: 1px solid ${palette.grey[400]};
  }

  svg {
    flex-shrink: 0;
    transform: translateX(0.2em) scale(1);
    animation: ${props =>
      props.loading ? `${loading} 1s linear infinite` : ``};
  }

  &:hover:not([disabled]) {
    svg {
      animation: ${pulse} 1s linear infinite;
    }
  }
`

const ButtonAsExternalLink = styled(BaseButtonRoot.withComponent(`a`))`
  display: inline-flex;
  text-decoration: none;
`

const ButtonAsInternalLink = ButtonAsExternalLink.withComponent(
  ({ ...rest }) => <Link {...rest} />
)

export const BaseButton = ({
  size = `L`,
  onClick,
  children,
  className,
  to,
  href,
  disabled = false,
  loading = null,
  loadingLabel = `Loading`,
  ...rest
}) => {
  const Button = to
    ? ButtonAsInternalLink
    : href
    ? ButtonAsExternalLink
    : BaseButtonRoot

  return (
    <Button
      size={size}
      className={className}
      onClick={onClick}
      to={to}
      href={href}
      disabled={loading ? true : disabled}
      loading={loading}
      {...rest}
    >
      {loading ? (
        <Fragment>
          {loadingLabel} <MdRefresh />
        </Fragment>
      ) : (
        children
      )}
    </Button>
  )
}

BaseButton.displayName = `BaseButton`

BaseButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf([`S`, `M`, `L`, `XL`]),
  to: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
}

export const PrimaryButton = styled(BaseButton)`
  background: ${colors.gatsby};
  border: 0;
  color: ${palette.white};
  font-weight: bold;

  :focus,
  :hover {
    background: ${palette.purple[700]};
  }
`

export const SecondaryButton = styled(BaseButton)`
  background: ${palette.white};
  border: 1px solid ${palette.purple[200]};
  color: ${colors.lilac};

  :focus,
  :hover {
    border: 1px solid ${palette.purple[600]};
    color: ${palette.purple[600]};
  }
`

export const TextButton = styled(BaseButton)`
  background: ${palette.white};
  border: 1px solid ${palette.white};
  color: ${colors.lilac};

  :focus,
  :hover {
    color: ${colors.gatsby};
  }
`

export const CancelButton = styled(BaseButton)`
  background: ${palette.white};
  border: 1px solid ${palette.grey[300]};
  color: ${palette.grey[600]};

  :focus,
  :hover {
    border: 1px solid ${palette.grey[600]};
    color: ${palette.grey[900]};
  }
`

export const PrimaryDeleteButton = styled(BaseButton)`
  background: ${palette.red[600]};
  border: 1px solid ${palette.red[600]};
  color: ${palette.white};
  font-weight: bold;

  :focus,
  :hover {
    background: ${palette.red[700]};
    border-color: ${palette.red[900]};
  }
`

export const SecondaryDeleteButton = styled(BaseButton)`
  background: ${palette.white};
  border: 1px solid ${palette.red[200]};
  color: ${palette.red[500]};

  :focus,
  :hover {
    border-color: ${palette.red[600]};
    color: ${palette.red[600]};
  }
`

export const AddSiteButton = styled(PrimaryButton)`
  flex-grow: 0;
  font-size: ${fontSizes.s};
  font-weight: normal;
  padding-right: ${spaces[`xs`]};
  margin-left: ${spaces.s};

  @media (min-width: ${breakpoints.tablet}px) {
    margin-left: auto;
  }
`

export const SuccessButton = styled(BaseButton)`
  background: ${palette.green[600]};
  border: 1px solid ${palette.green[600]};
  color: ${palette.white};
  font-weight: bold;

  :focus,
  :hover {
    background: ${palette.green[700]};
    border-color: ${palette.green[900]};
  }
`
