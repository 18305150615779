import { useEffect } from "react"

export default function useSubscription(
  client,
  document,
  { options, onResult, deps = [] }
) {
  const optionsInput = options || {}
  function subscribe() {
    return client.subscribe({ query: document, ...optionsInput }).subscribe({
      next: onResult,
    })
  }

  useEffect(() => {
    if (!process.env.GATSBY_ENABLE_SUBSCRIPTIONS) {
      return () => {}
    }

    const subscription = subscribe()

    return () => {
      subscription.unsubscribe()
    }
  }, deps)
}
