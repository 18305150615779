import React, { Fragment, useContext, useState } from "react"
import styled from "react-emotion"
import { navigate } from "gatsby"
import { MdArrowForward } from "react-icons/md"
import { SuccessModal } from "../Modals"

import Review from "../Review/index"
import { ModalContext } from "../../Modal"
import { PrimaryButton, CancelButton } from "../../shared/Buttons"
import { spaces } from "../../../utils/presets"
import { paymentInformation as paymentText } from "../../../locales/default.json"
import { graphql } from "react-apollo"
import { RESUBSCRIBE_MUTATION } from "../mutations"
import { DefaultError } from "../../shared/ErrorModal"
import Footer from "../Footer"
import { billing as text } from "../../../locales/default.json"

const Actions = styled(`div`)`
  display: flex;
  justify-content: space-between;
  margin-top: ${spaces[`2xl`]};
  width: 100%;
`

const ResubscribeFooter = styled(Footer)`
  margin-bottom: ${spaces[`2xl`]};
`

const Resubscribe = ({
  billing,
  organizationId,
  name,
  plan,
  resubscribeMutation,
}) => {
  const { showModal } = useContext(ModalContext)

  const [paymentFormVisible, setPaymentFormVisible] = useState(false)

  const [res, setResponse] = useState({
    creditCard: null,
  })

  const creditCard = !res.creditCard ? billing.creditCard : res.creditCard

  return (
    <Fragment>
      <Review
        plan={plan}
        billing={billing}
        organizationId={organizationId}
        name={name}
        setResponse={setResponse}
        creditCard={creditCard}
        paymentFormVisible={paymentFormVisible}
        setPaymentFormVisible={setPaymentFormVisible}
      >
        {!paymentFormVisible && (
          <Actions>
            <CancelButton
              data-testid="cancel"
              onClick={() =>
                navigate(`/dashboard/organization/sites/${organizationId}`)
              }
              type={`reset`}
            >
              {paymentText.cancelText}
            </CancelButton>
            <PrimaryButton
              data-testid="resubscribe"
              type="button"
              onClick={() =>
                resubscribeMutation({
                  variables: {
                    input: {
                      organizationId,
                      planId: plan.id,
                    },
                  },
                })
                  .then(result => {
                    const resultData =
                      result.data && result.data.resubscribeToPlan
                    return showModal({
                      Component: SuccessModal,
                      props: {
                        plan: resultData.subscription.plan,
                        creditCard: resultData.subscription.creditCard,
                        billingDate: resultData.subscription.nextBillingDate,
                        name,
                        organizationId,
                        mode: `success`,
                      },
                    })
                  })
                  .catch(err => {
                    showModal({
                      Component: DefaultError,
                      props: { errMsg: err.message },
                    })
                  })
              }
            >
              {paymentText.resubscribe} <MdArrowForward />
            </PrimaryButton>
          </Actions>
        )}
      </Review>
      {!paymentFormVisible && <ResubscribeFooter text={text.resubscribeNote} />}
    </Fragment>
  )
}

export default graphql(RESUBSCRIBE_MUTATION, {
  name: `resubscribeMutation`,
})(Resubscribe)
