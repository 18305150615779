import React, { Component } from "react"
import styled from "react-emotion"
import PropTypes from "prop-types"

import { StandardSingleColumn } from "./Containers"
import DotsThree from "../../assets/DotsThree"
import {
  dimensions,
  fontSizes,
  fontFamilies,
  palette,
  spaces,
} from "../../utils/presets"

const LoadingRoot = styled(StandardSingleColumn)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: ${props =>
    props.fullPageHeight
      ? `calc(100vh - ${dimensions.siteHeader.height})`
      : ``};
  opacity: ${props => (props.show ? 1 : 0)};
  padding: ${spaces.xl} ${spaces.l};
`

const Message = styled(`span`)`
  color: ${palette.grey[500]};
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${fontSizes.l};
  line-height: 1;
  margin-top: ${spaces.l};
  text-align: center;
`

const Dots = styled(DotsThree)`
  > * {
    opacity: 1;
    transition: opacity 0.5s;

    &.off {
      opacity: 0;
    }
  }
`

class Loading extends Component {
  elementsRef = React.createRef()
  transitioningInterval = null
  transitioningTimeouts = []
  showTimeout = null

  state = {
    elementsInTransition: [],
    stillElements: [],
    show: this.props.delay ? false : true,
  }

  componentDidMount() {
    const elements = Array.from(this.elementsRef.current.childNodes)
    const { delay } = this.props

    this.setState({
      stillElements: elements.map((el, idx) => idx),
    })

    if (delay) {
      this.showTimeout = setTimeout(
        () =>
          this.setState({
            show: true,
          }),
        delay
      )
    }

    this.setElementsTransitioning()
  }

  componentWillUnmount() {
    clearInterval(this.transitioningInterval)
    clearTimeout(this.showTimeout)
    this.transitioningTimeouts.forEach(timeout => clearTimeout(timeout))
  }

  setElementsTransitioning = () => {
    this.transitioningInterval = setInterval(() => {
      if (this.state.stillElements.length > 0) {
        const stillElements = [...this.state.stillElements]

        const randomStillElementIdx = Math.floor(
          Math.random() * stillElements.length
        )

        const elementIdx = stillElements[randomStillElementIdx]
        stillElements.splice(randomStillElementIdx, 1)

        const elementsInTransition = [
          ...this.state.elementsInTransition,
          elementIdx,
        ]

        this.setState(
          {
            elementsInTransition,
            stillElements,
          },
          () => {
            this.elementOff(elementIdx)
          }
        )
      }
    }, 200)
  }

  elementOff = index => {
    this.elementsRef.current.childNodes[index].classList.add(`off`)

    this.transitioningTimeouts[index] = setTimeout(() => {
      const elementsInTransition = [...this.state.elementsInTransition]
      elementsInTransition.splice(index, 1)
      const stillElements = [...this.state.stillElements, index]

      this.setState(
        {
          elementsInTransition,
          stillElements,
        },
        () => {
          this.elementOn(index)
        }
      )
    }, 400)
  }

  elementOn = index => {
    this.elementsRef.current.childNodes[index].classList.remove(`off`)
    clearTimeout(this.transitioningTimeouts[index])
  }

  render() {
    const { message, fullPageHeight, delay, ...rest } = this.props
    const { show } = this.state

    return (
      <LoadingRoot show={show} fullPageHeight={fullPageHeight} {...rest}>
        <Dots svgRef={this.elementsRef} />
        <Message>{message}</Message>
      </LoadingRoot>
    )
  }
}

Loading.propTypes = {
  message: PropTypes.string,
  delay: PropTypes.number,
  fullPageHeight: PropTypes.bool,
}

Loading.defaultProps = {
  message: `Loading...`,
  delay: null,
  fullPageHeight: true,
}

export default Loading
