import React from "react"
import { graphql } from "react-apollo"
import gql from "graphql-tag"
import { ZeroSites } from "../shared/ZeroState"
import Loading from "../shared/Loading"
import { Organizations } from "./Organizations"
import {
  StandardSingleColumn,
  StandardPageContentSection,
} from "../shared/Containers"
import ErrorModal from "../shared/ErrorModal"
import VisuallyHidden from "../shared/VisuallyHidden"
import { PERMISSIONS_ORGANIZATION_FRAGMENT } from "../../permissions/fragments"
import {
  CREDIT_CARD_FRAGMENT,
  BILLING_FIELDS_FRAGMENT,
} from "../Payment/fragments"

const SITES_BY_ORGANIZATION = gql`
  query SitesInfo($organizationId: UUID) {
    sitesByOrganization(organizationId: $organizationId) {
      total
      organizations {
        ...OrgDetails
      }
      contributorOrganizations {
        ...OrgDetails
      }
    }
  }

  fragment OrgDetails on Organization {
    id
    name
    sites {
      id
      name
      branch
      updatedAt
      previewUrl
      previewStatus
    }
    billing {
      ...billingFieldsFragment
      creditCard {
        ...creditCardFragment
      }
    }
    permissions {
      ...orgPermissionsFragment
    }
  }
  ${BILLING_FIELDS_FRAGMENT}
  ${CREDIT_CARD_FRAGMENT}
  ${PERMISSIONS_ORGANIZATION_FRAGMENT}
`

const PageHeading = VisuallyHidden.withComponent(`h2`)

const Sites = ({ sitesByOrganizationQuery }) => {
  const { loading, error } = sitesByOrganizationQuery
  if (loading) return <Loading delay={1000} message="loading your sites..." />
  if (error) return <ErrorModal errMsg={error.message} />
  const { sitesByOrganization } = sitesByOrganizationQuery

  if (
    !sitesByOrganization ||
    (sitesByOrganization && sitesByOrganization.total === 0)
  ) {
    return <ZeroSites pagePosition="fullPage" />
  }

  const organizations = sitesByOrganization && sitesByOrganization.organizations

  const contributorOrganizations =
    sitesByOrganization && sitesByOrganization.contributorOrganizations

  return (
    <StandardPageContentSection>
      <StandardSingleColumn>
        <PageHeading>My Sites</PageHeading>
        <Organizations
          organizations={organizations}
          contributorOrganizations={contributorOrganizations}
        />
      </StandardSingleColumn>
    </StandardPageContentSection>
  )
}

export default graphql(SITES_BY_ORGANIZATION, {
  name: `sitesByOrganizationQuery`,
  options: props => {
    return {
      name: `sitesByOrganization`,
      fetchPolicy: `cache-and-network`,
    }
  },
})(Sites)
