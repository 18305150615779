import React from "react"
import styled from "react-emotion"
import PropTypes from "prop-types"
import hex2rgba from "hex2rgba"

import Notification, { notificationModes } from "./Notification"
import { Text, ErrorText } from "../Typography"
import { breakpoints, spaces, fontSizes } from "../../../utils/presets"

const NotificationSectionRoot = styled(`div`)`
  background: ${props => notificationModes[props.mode].bgCol};
  padding: ${spaces.m} ${spaces.l};
  width: 100%;

  @media (min-width: ${breakpoints.desktop}px) {
    padding: ${spaces.l} ${spaces[`2xl`]};
  }
`
const Details = styled(`div`)`
  margin-top: ${spaces.m};
  padding-top: ${spaces.m};
  border-top: 1px solid
    ${props => hex2rgba(notificationModes[props.mode].brdCol, 0.2)};

  ${Text}, ${ErrorText} {
    font-size: ${fontSizes.xs};
    margin-top: ${spaces.s};

    &:first-child {
      margin-top: 0;
    }
  }
`

const NotificationSection = props => {
  const { className, mode, details, showLink } = props
  const DetailsContent = details

  return (
    <NotificationSectionRoot className={className} mode={mode}>
      <Notification {...props} showLink={showLink} />
      {details && (
        <Details mode={mode}>
          <DetailsContent />
        </Details>
      )}
    </NotificationSectionRoot>
  )
}

NotificationSection.propTypes = {
  message: PropTypes.any.isRequired,
  mode: PropTypes.oneOf([`default`, `success`, `warning`, `error`]),
  icon: PropTypes.func,
  showLink: PropTypes.bool,
  linkLabel: PropTypes.string,
  linkTo: PropTypes.string,
  linkOnClick: PropTypes.func,
  className: PropTypes.string,
  details: PropTypes.any,
}

export default NotificationSection
