import React, { Fragment } from "react"
import styled, { keyframes } from "react-emotion"
import { FieldArray } from "formik"

import { MdRefresh } from "react-icons/md"

import { FieldAbstraction } from "./FieldAbstractions"
import { palette, spaces } from "../../../utils/presets"

const CheckboxContainer = styled(`div`)`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: ${spaces.m} ${spaces[`2xs`]};
  position: relative;
`

const Label = styled(`label`)`
  align-items: center;
  cursor: pointer;
  display: flex;
  line-height: 1;
  position: relative;
  width: 100%;

  :before {
    content: "";
    display: flex;
    border: 2px solid ${palette.grey[300]};
    border-radius: 3px;
    height: 22px;
    margin-right: ${spaces.m};
    transition: 0.15s ease-in-out;
    width: 22px;
  }
`

const rotation = keyframes`
  100% {
    transform: rotate(360deg)
  }
`

const SubmittingIndicator = styled(MdRefresh)`
  animation: ${rotation} 1s linear infinite;
  color: ${palette.purple[300]};
  height: 1.2em;
  float: right;
  position: absolute;
  right: 0;
  width: 1.2em;
`

const CheckboxInput = styled(`input`)`
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  opacity: 0;
  cursor: pointer;

  &:checked + label::before {
    background: ${palette.purple[600]};
    border-color: ${palette.purple[600]};
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNNi4zMjUwNSAxMy43MTQyTDMuMTkwNDkgOS40MzQ4N0MyLjg3NTMzIDguOTc4NzggMi45NTkwNSA4LjM1Nzc3IDMuMzgzNzMgOC4wMDE0MkwzLjM4Mzc0IDguMDAxNDJDMy44MDg0MiA3LjY0NTA3IDQuNDM0NTIgNy42NzA0NiA0LjgyODk2IDguMDYwMDNMOC40OTg5OSAxMS44OTAxQzkuMDk2MzggMTIuNDgwMSA5LjA1NjggMTMuNDU2MSA4LjQxMzYxIDEzLjk5NThMOC40MTM2IDEzLjk5NThDNy43NzA0IDE0LjUzNTUgNi44MDIzNyAxNC40MDUgNi4zMjUwNSAxMy43MTQyWiIgZmlsbD0id2hpdGUiLz4KICA8cGF0aCBkPSJNNi41ODE4MiAxMy45ODUzQzUuOTI4NDMgMTMuNDM3IDUuODY0MjYgMTIuNDU0NSA2LjQ0MDc4IDExLjgyNTlMMTMuNzIyMSAzLjg4Njk2QzE0LjA5MTkgMy40ODM4IDE0LjcxMzUgMy40NDMyIDE1LjEzMjYgMy43OTQ4M0wxNS4xMzI2IDMuNzk0ODRDMTUuNTUxNyA0LjE0NjQ3IDE1LjYxOTYgNC43NjU2OSAxNS4yODY4IDUuMTk5ODVMOC43MzI4NSAxMy43NDkyQzguMjEzOTIgMTQuNDI2MSA3LjIzNTIyIDE0LjUzMzUgNi41ODE4MiAxMy45ODUzTDYuNTgxODIgMTMuOTg1M1oiIGZpbGw9IndoaXRlIi8+CiAgPHBhdGggZD0iTTYuNzQ3MTIgMTAuMDg3OEM3LjExMzkyIDEwLjUyNzkgNy4zODI5MSAxMC44MjE0IDcuODQ3NTIgMTAuMzA3OEM4LjMxMjE0IDkuNzk0MzMgNy43MDA4IDExLjYwMzkgNy43MDA4IDExLjYwMzlMNi4xODQ2OSAxMS4xMTQ4TDYuNzQ3MTIgMTAuMDg3OFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=");
  }

  &:hover + label::before {
    border-color: ${palette.purple[400]};
  }

  &:focus + label::before {
    border-color: ${palette.purple[600]};
    box-shadow: 0 0 0 3px ${palette.purple[200]};
  }

  &:disabled + label::before {
    opacity: 0.5;
  }
`

export function Checkbox({
  label,
  fieldName,
  value,
  indicateSubmitting,
  ...rest
}) {
  return (
    <CheckboxContainer>
      <CheckboxInput
        type="checkbox"
        name={fieldName}
        id={fieldName}
        {...rest}
      />
      <Label htmlFor={fieldName}>
        {label} {indicateSubmitting ? <SubmittingIndicator /> : ``}
      </Label>
    </CheckboxContainer>
  )
}

export function CheckboxGroup({ options, fieldName, push, remove, values }) {
  return (
    <Fragment>
      {options.map(({ label, value }, index) => (
        <Checkbox
          key={`${fieldName}.${index}`}
          label={label}
          fieldName={`${fieldName}.${index}`}
          onChange={e => {
            const fieldValues = values[fieldName] || []

            const valueInFieldValues = fieldValues.find(val => val === value)

            if (valueInFieldValues) {
              const idx = fieldValues.indexOf(value)
              return remove(idx)
            }

            return push(value)
          }}
        />
      ))}
    </Fragment>
  )
}

export const CheckboxInputBlock = FieldAbstraction({
  Component: Checkbox,
  hideLabel: true,
})

export const CheckboxInputGroupBlock = FieldAbstraction({
  Component: CheckboxGroup,
  FieldComponent: FieldArray,
})
