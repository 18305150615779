import React, { Fragment } from "react"
import PropTypes from "prop-types"
import InviteeDropdown from "./DropdownMenus/Invitee"
import MemberDropdown from "./DropdownMenus/Member"

const ContributorManage = ({
  contributor,
  showModal,
  resourceId,
  resourceType,
  permissionToInvite,
  permissionToDelete,
  showToast,
}) => (
  <Fragment>
    {permissionToInvite && contributor.contributorRole === `INVITED` && (
      <InviteeDropdown
        contributor={contributor}
        showModal={showModal}
        resourceId={resourceId}
        resourceType={resourceType}
        showToast={showToast}
      />
    )}
    {permissionToDelete && contributor.contributorRole === `MEMBER` && (
      <MemberDropdown
        contributor={contributor}
        showModal={showModal}
        resourceId={resourceId}
        resourceType={resourceType}
        showToast={showToast}
      />
    )}
  </Fragment>
)

ContributorManage.propTypes = {
  contributor: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  resourceType: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
}

export default ContributorManage
