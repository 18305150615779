import React from "react"
import styled from "react-emotion"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { withFlags } from "ld-react"

import PickRepository from "./PickRepository"
import AddIntegrations from "./AddIntegrations"
import SetupSite from "./SetupSite/SetupSite"
import Steps from "./Steps"
import Header from "./Header"

import { spaces } from "../../utils/presets"

const CreateSiteRoot = styled(`div`)`
  padding: ${spaces[`2xl`]} 0;
`

const CreateSite = ({ siteId, path, flags }) => {
  const addIntegrations = path.includes(`integrations`)

  const selectRepo = id => {
    if (flags.integrations) {
      return navigate(`/dashboard/sites/create/${id}/integrations`)
    }

    return navigate(`/dashboard/sites/create/${id}/setup`)
  }

  const cancel = () => {
    if (siteId) {
      if (flags.integrations) {
        if (addIntegrations) {
          return navigate(`/dashboard/sites/create`)
        }
        return navigate(`/dashboard/sites/create/${siteId}/integrations`)
      }
      return navigate(`/dashboard/sites/create`)
    }
    return navigate(`/dashboard/sites`)
  }

  const setActiveStep = () => {
    if (siteId) {
      if (flags.integrations) {
        if (addIntegrations) {
          return `integrations`
        }
      }
      return `setup`
    }

    return `repository`
  }

  return (
    <CreateSiteRoot>
      <Header />
      <Steps siteId={siteId} activeStep={setActiveStep()} />
      {!siteId && <PickRepository selectRepo={selectRepo} cancel={cancel} />}
      {flags.integrations && siteId && addIntegrations && (
        <AddIntegrations siteId={siteId} cancel={cancel} />
      )}
      {siteId && !addIntegrations && (
        <SetupSite cancel={cancel} siteId={siteId} />
      )}
    </CreateSiteRoot>
  )
}

CreateSite.propTypes = {
  siteId: PropTypes.string,
}

export default withFlags(CreateSite)
