import React, { useState, useCallback } from "react"
import { injectStripe } from "react-stripe-elements"
import { MdArrowForward } from "react-icons/md"
import PropTypes from "prop-types"
import { Formik, Field } from "formik"
import styled, { keyframes } from "react-emotion"

import {
  Label,
  NameInput,
  EmailInput,
  CompanyInput,
  OptionalLabel,
} from "./PaymentElements"
import { CancelButton, PrimaryButton } from "../shared/Buttons"
import { billing as text } from "../../locales/default.json"
import { GenericError } from "./Error"
import { Subheading } from "../shared/Typography"
import { breakpoints, spaces } from "../../utils/presets"
import Amex from "../../assets/Amex"
import Discover from "../../assets/Discover"
import Mastercard from "../../assets/Mastercard"
import Visa from "../../assets/Visa"
import StripeElements from "./StripeElements"
import { useTracker } from "../../analytics"
import {
  trackCardValidation,
  validateForm,
  subscribePlan,
  updatePayment,
} from "./utils"

const entry = keyframes`
  100% {
    opacity: 1;
  }
`

const Form = styled(`form`)`
  animation: ${entry} 0.75s ease forwards;
  margin-bottom: 0;
  opacity: 0;
  width: 100%;
`

const Header = styled(`section`)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  ${Subheading} {
    margin-top: 0;
  }
`

const CardIcons = styled(`div`)`
  align-self: flex-end;
  display: flex;
  > * {
    margin-left: ${spaces.xs};
  }
`

const Content = styled(`section`)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`

const CardDetails = styled(`section`)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`

const Actions = styled(`div`)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${spaces.l};
  width: 100%;

  @media (min-width: ${breakpoints.desktop}px) {
    margin-top: ${spaces.xl};
  }
`

function CheckoutForm({
  children,
  stripe: { createToken },
  planId,
  mutate,
  organizationId,
  showError,
  showSuccess,
  cancelCallback,
  submitButtonText,
  cancelButtonText,
  checkoutType = `SUBSCRIBE`,
  className,
}) {
  const { track, trackButtonClicked } = useTracker()
  const [formState, setFormState] = useState({
    cardNumber: ``,
    cardExpiry: ``,
    cardCvc: ``,
  })
  const [disabled, setDisabled] = useState(false)

  const validateCard = useCallback(
    e => {
      trackCardValidation(track, e, organizationId)
      let message = ``
      if (e.error) {
        message = e.error.message ? e.error.message : ``
      }
      setFormState({
        ...formState,
        [e.elementType]: !e.empty && !e.error ? `valid` : message,
      })
    },
    [formState]
  )

  const validate = values =>
    validateForm(
      values,
      formState,
      setFormState,
      text,
      organizationId,
      track,
      setDisabled
    )

  const submitForm = values => {
    const { name, company, email } = values

    trackButtonClicked(text.startSubscription, {
      organizationId,
      uiSource: `Pricing Page`,
    })

    setDisabled(true)
    if (checkoutType === `SUBSCRIBE`)
      subscribePlan(
        createToken,
        name,
        mutate,
        planId,
        company,
        organizationId,
        email,
        setDisabled,
        showError,
        showSuccess
      )
    else
      updatePayment(
        createToken,
        name,
        mutate,
        organizationId,
        email,
        setDisabled,
        showError,
        showSuccess
      )
  }

  return (
    <Formik
      initialValues={{
        name: ``,
        email: ``,
        company: ``,
      }}
      validate={validate}
      onSubmit={submitForm}
    >
      {({ handleSubmit }) => (
        <Form
          onSubmit={handleSubmit}
          className={className}
          data-cy="checkout-form"
        >
          <Header>
            <Subheading>{text.creditCardInformation}</Subheading>
            <CardIcons>
              <Visa />
              <Mastercard />
              <Discover />
              <Amex />
            </CardIcons>
          </Header>
          <Label htmlFor="name" data-cy="card-name-input-wrapper">
            {text.name}
            <Field name="name" component={NameInput} />
            <GenericError name="name" />
          </Label>
          <CardDetails>
            <StripeElements formState={formState} validateCard={validateCard} />
          </CardDetails>
          <Subheading>{text.optionalInformation}</Subheading>
          <Content>
            <OptionalLabel htmlFor="email">
              {text.formEmail}
              <Field name="email" component={EmailInput} />
              <GenericError name="email" />
            </OptionalLabel>
            <OptionalLabel htmlFor="company">
              {text.company}
              <Field name="company" component={CompanyInput} />
            </OptionalLabel>
          </Content>
          {children}
          <Actions>
            <CancelButton onClick={() => cancelCallback()} type={`reset`}>
              {cancelButtonText}
            </CancelButton>
            <PrimaryButton
              type="submit"
              loading={disabled}
              data-cy="start-subscription-button"
            >
              {submitButtonText} <MdArrowForward />
            </PrimaryButton>
          </Actions>
        </Form>
      )}
    </Formik>
  )
}

CheckoutForm.propTypes = {
  planId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  mutate: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
}

export default injectStripe(CheckoutForm)
