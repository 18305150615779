import styled from "react-emotion"
import {
  breakpoints,
  colors,
  fontSizes,
  fontFamilies,
  palette,
  spaces,
} from "../../utils/presets"

export const SiteHeading = styled(`h1`)`
  color: ${palette.grey[900]};
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${fontSizes.xl};
  margin: 0;

  strong {
    color: ${colors.lilac};
  }
`

export const EmphaticHeading = styled(`h2`)`
  color: ${palette.grey[900]};
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${fontSizes[`3xl`]};
  font-weight: 800;
  letter-spacing: -0.02em;
  line-height: 1;
  margin: 0;

  @media (min-width: ${breakpoints.desktop}px) {
    font-size: ${fontSizes[`4xl`]};
  }
`

export const Heading = styled(`h2`)`
  color: ${palette.grey[900]};
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${fontSizes.xl};
  margin: 0;

  strong {
    color: ${colors.lilac};
  }

  small {
    color: ${palette.grey[500]};
    display: block;
    font-size: ${fontSizes.xs};
    font-weight: normal;
    letter-spacing: 0.03em;
    margin-bottom: ${spaces.xs};
    text-transform: uppercase;
  }
`

export const Subheading = styled(`h3`)`
  color: ${palette.grey[900]};
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${fontSizes.m};
  margin: 1.5rem 0 0;

  small {
    color: ${palette.grey[500]};
    display: block;
    font-size: ${fontSizes[`2xs`]};
    font-weight: normal;
    letter-spacing: 0.03em;
    margin-bottom: ${spaces.xs};
    text-transform: uppercase;
  }
`

export const Lede = styled(`p`)`
  color: ${palette.grey[700]};
  font-family: ${fontFamilies.bodyFontFamily};
  font-size: ${fontSizes.m};
  margin: 0.5rem 0 0;
`

export const EmphaticLede = styled(`p`)`
  color: ${palette.grey[700]};
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${fontSizes.xl};
  line-height: 1.3;
  margin: 1em 0 0;
`

export const Text = styled(`p`)`
  color: ${palette.grey[700]};
  font-family: ${fontFamilies.bodyFontFamily};
  font-size: ${fontSizes.s};
  line-height: 1.5;
  margin: 1rem 0 0;
`

export const EmphasizedText = styled(Text)`
  font-weight: bold;
`

export const ErrorText = styled(EmphasizedText)`
  svg {
    margin-right: ${spaces.xs};
    vertical-align: middle;
    margin-top: -0.1rem;
    fill: ${palette.red[500]};
  }
`
