import React from "react"
import styled from "react-emotion"
import { PropTypes } from "prop-types"

import { palette, fontSizes, breakpoints, spaces } from "../../../utils/presets"
import { billing as text } from "../../../locales/default.json"

const IntervalRadioRoot = styled(`div`)`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: ${spaces.m};

    @media (min-width: ${breakpoints.mobile}px) {
      align-items: center;
    }
  } 
`

const Appearance = styled(`span`)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.white};
  border: 2px solid ${palette.purple[500]};
  border-radius: 50%;
  height: 1.125rem;
  margin-right: ${spaces.xs};
  width: 1.125rem;
`

const Option = styled(`label`)`
  color: ${palette.grey[500]};
  display: flex;
  font-size: ${fontSizes[`2xs`]};
  font-style: italic;
  margin: 0 ${spaces.xs};
  overflow: hidden;
  position: relative;
  :first-child {
    margin-left: 0;
  }
  @media (min-width: ${breakpoints.mobile}px) {
    margin: 0 ${spaces.xs};
  }

  :focus {
    outline: 0;
    ${Appearance} {
      border-color: ${palette.purple[400]};
      box-shadow: 0 0 0 3px ${palette.purple[200]};
    }
  }
`

const Input = styled(`input`)`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  position: absolute;
  margin: -1px;
  padding: 0;
  visibility: unset;
  width: 1px;
  white-space: nowrap;
  
  :focus + label {
     ${Appearance} {
      border-color: ${palette.purple[400]};
      box-shadow: 0 0 0 3px ${palette.purple[200]};
     } 
    }
  }

  :checked + label {
    ${Appearance}:before {
      content: "";
      display: inline-block;
      position: relative;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: ${palette.purple[500]};
    }
  }
`

const IntervalRadio = ({ onChange, planInterval }) => {
  const changeValue = e => {
    const {
      target: { value },
    } = e
    onChange(value)
  }

  return (
    <IntervalRadioRoot>
      <Input
        type="radio"
        id="annual"
        value="ANNUAL"
        onChange={e => changeValue(e)}
        checked={planInterval === `ANNUAL`}
        aria-checked={planInterval === `ANNUAL`}
        data-testid="annual"
        onKeyDown={e => {
          if (e.key === `Enter`) return changeValue(e)
        }}
      />
      <Option htmlFor="annual" aria-label="annual" tabIndex="0">
        <Appearance />
        {text.billedAnually}
      </Option>
      <Input
        type="radio"
        id="monthly"
        value="MONTHLY"
        onChange={e => changeValue(e)}
        checked={planInterval === `MONTHLY`}
        aria-checked={planInterval === `MONTHLY`}
        data-testid="monthly"
        onKeyDown={e => {
          if (e.key === `Enter`) return changeValue(e)
        }}
      />
      <Option htmlFor="monthly" aria-label="monthly">
        <Appearance />
        {text.billedMonthly}
      </Option>
    </IntervalRadioRoot>
  )
}

IntervalRadio.propTypes = {
  onChange: PropTypes.func,
  planInterval: PropTypes.string,
}

export default IntervalRadio
