import React, { useContext } from "react"
import gql from "graphql-tag"
import { graphql } from "react-apollo"
import styled from "react-emotion"
import { Formik, Form as BaseForm } from "formik"
import * as Yup from "yup"
import { navigate } from "gatsby"
import { MdArrowForward, MdPersonPin, MdDevices } from "react-icons/md"

import { useTracker } from "../analytics"
import { ModalContext } from "./Modal"
import { DefaultError } from "./shared/ErrorModal"
import { TextInputBlock, RadioInputGroupBlock } from "./shared/Forms"
import { NarrowSingleColumn } from "./shared/Containers"
import { EmphaticHeading, EmphaticLede } from "./shared/Typography"
import { PrimaryButton } from "./shared/Buttons"
import { leadCapture as text } from "../locales/default"
import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  palette,
  radius,
  spaces,
} from "../utils/presets"
// import { Debug } from "../utils/debug/Formik"

const UPDATE_USER_WELCOME_FIELDS = gql`
  mutation updateUserWelcomeFields($user: UserWelcomeInput!) {
    updateUserWelcomeFields(user: $user) {
      success
      message
    }
  }
`

const LeadCaptureRoot = styled(NarrowSingleColumn)`
  align-items: center;
  border-radius: ${radius.large};
  display: flex;
  flex-direction: column;
  margin: ${spaces[`2xl`]} auto ${spaces[`5xl`]};

  @media (min-width: ${breakpoints.desktop}px) {
    border: 2px solid ${colors.standardLine};
    margin: ${spaces[`3xl`]} auto calc(${spaces[`3xl`]} * 1.2);
  }
`

const Heading = styled(EmphaticHeading)`
  background: #fefefe;
  padding: 0 ${spaces.l};
  position: relative;
  text-align: center;

  @media (min-width: ${breakpoints.desktop}px) {
    transform: translateY(-0.5em);
  }
`

const Intro = styled(EmphaticLede)`
  position: relative;
  text-align: center;

  @media (min-width: ${breakpoints.desktop}px) {
    transform: translateY(-0.5em);
  }
`

const SubmitButton = styled(PrimaryButton)`
  margin: 0 auto;
  bottom: 0;
  transform: translateY(50%);
  position: relative;
`

const Form = styled(BaseForm)`
  display: flex;
  flex-direction: column;
  margin: ${spaces[`2xl`]} auto 0;
  max-width: 100%;
  width: 30rem;
`

const Fieldset = styled(`fieldset`)`
  border: 0;
`

const FieldsetLabel = styled(`label`)`
  display: flex;
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${fontSizes.xl};
  font-weight: bold;
  justify-content: center;
  line-height: 1;
  margin-bottom: ${spaces.l};
  position: relative;
  text-align: center;
  width: 100%;

  span {
    align-items: center;
    background: ${colors.primaryBackground};
    display: flex;
    padding: 0 ${spaces.l};
    position: relative;
  }

  svg {
    width: auto;
    margin-right: ${spaces.xs};
    color: ${palette.purple[500]};
  }

  :before {
    border-top: 1px solid ${colors.standardLine};
    content: "";
    height: 0;
    left: 0;
    top: 0.5em;
    position: absolute;
    width: 100%;
  }
`

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(`The field is required`),
  lastName: Yup.string().required(`The field is required`),
  companyEmail: Yup.string()
    .email(`Invalid email`)
    .required(`Required`),
})

function LeadCaptureForm({ name, email, mutate }) {
  const { trackButtonClicked } = useTracker()
  const { showModal } = useContext(ModalContext)

  return (
    <Formik
      initialValues={{
        firstName: name.substr(0, name.indexOf(` `)),
        lastName: name.substr(name.indexOf(` `) + 1),
        companyEmail: ``,
        companyName: ``,
        projectType: ``,
        cms: [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values, isSubmitting) => {
        trackButtonClicked(`update welcome fields`, {
          uiSource: `Welcome form`,
        })

        return mutate({
          variables: {
            user: {
              firstName: values.firstName,
              lastName: values.lastName,
              companyEmail: values.companyEmail,
              projectType: values.projectType,
            },
          },
        })
          .then(() => navigate(`/dashboard/sites`))
          .catch(err => {
            showModal({
              Component: DefaultError,
              props: { errMsg: err.message },
            })

            isSubmitting.setSubmitting(false)
          })
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Fieldset>
            <FieldsetLabel>
              <span>
                {` `}
                <MdPersonPin /> {text.youAtWork}
              </span>
            </FieldsetLabel>
            <TextInputBlock label="First name" fieldName="firstName" required />
            <TextInputBlock label="Last name" fieldName="lastName" required />
            <TextInputBlock
              label="Company email"
              fieldName="companyEmail"
              required
            />
          </Fieldset>

          <Fieldset>
            <FieldsetLabel>
              <span>
                {` `}
                <MdDevices /> {text.yourProjects}
              </span>
            </FieldsetLabel>

            <RadioInputGroupBlock
              label="What type of project do you want to use Gatsby Preview for?"
              fieldName="projectType"
              options={[
                {
                  label: `Company Project`,
                  value: `COMPANY_PROJECT`,
                },
                {
                  label: `Client Project`,
                  value: `CLIENT_PROJECT`,
                },
                {
                  label: `Personal Project`,
                  value: `PERSONAL_PROJECT`,
                },
              ]}
            />
          </Fieldset>

          <SubmitButton type="submit" loading={isSubmitting}>
            {text.moveForword} <MdArrowForward />
          </SubmitButton>
          {/* <Debug /> */}
        </Form>
      )}
    </Formik>
  )
}

let LeadCapture = ({ user, mutate }) => (
  <LeadCaptureRoot>
    <Heading>
      {text.heyThere} {user && user.name && user.name.split(` `)[0]}!{` `}
    </Heading>
    <Intro>{text.beforeYouContinue}</Intro>
    <LeadCaptureForm mutate={mutate} {...user} />
  </LeadCaptureRoot>
)

export default (LeadCapture = graphql(UPDATE_USER_WELCOME_FIELDS)(LeadCapture))
