import React from "react"

export default ({ width = 45, height = 16 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 45 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M8.54645 5.1361H4.81764C4.72145 5.1361 4.6434 5.21419 4.6434 5.31042V7.13346C4.6434 7.22966 4.72145 7.30797 4.81764 7.30797H6.27226V9.57295C6.27226 9.57295 5.94562 9.68431 5.04268 9.68431C3.97731 9.68431 2.48913 9.29505 2.48913 6.0225C2.48913 2.7493 4.03877 2.31862 5.49365 2.31862C6.75301 2.31862 7.29558 2.54032 7.6408 2.64721C7.74926 2.68041 7.84959 2.57241 7.84959 2.47616L8.26556 0.714709C8.26556 0.669644 8.25031 0.615413 8.19897 0.578602C8.05875 0.478579 7.20344 0 5.04268 0C2.55337 0 0 1.05904 0 6.15013C0 11.2413 2.92342 12 5.38692 12C7.42668 12 8.66411 11.1284 8.66411 11.1284C8.71509 11.1002 8.7206 11.0289 8.7206 10.9963V5.31042C8.7206 5.21419 8.64264 5.1361 8.54645 5.1361Z"
        fill="#362066"
      />
      <path
        d="M27.7627 0.610085C27.7627 0.513081 27.6859 0.434814 27.5897 0.434814H25.4902C25.3943 0.434814 25.3163 0.513081 25.3163 0.610085C25.3163 0.610529 25.3168 4.66747 25.3168 4.66747H22.0442V0.610085C22.0442 0.513081 21.967 0.434814 21.871 0.434814H19.7716C19.676 0.434814 19.5981 0.513081 19.5981 0.610085V11.5964C19.5981 11.6933 19.676 11.7722 19.7716 11.7722H21.871C21.967 11.7722 22.0442 11.6933 22.0442 11.5964V6.89714H25.3168C25.3168 6.89714 25.3111 11.596 25.3111 11.5964C25.3111 11.6933 25.389 11.7722 25.485 11.7722H27.5895C27.6857 11.7722 27.7625 11.6933 27.7627 11.5964V0.610085Z"
        fill="#362066"
      />
      <path
        d="M12.5083 2.05169C12.5083 1.29572 11.9022 0.684814 11.1543 0.684814C10.4073 0.684814 9.80078 1.29572 9.80078 2.05169C9.80078 2.80693 10.4073 3.41951 11.1543 3.41951C11.9022 3.41951 12.5083 2.80693 12.5083 2.05169Z"
        fill="#362066"
      />
      <path
        d="M12.3582 9.27878C12.3582 8.99727 12.3582 4.20747 12.3582 4.20747C12.3582 4.11119 12.2805 4.03247 12.1845 4.03247H10.0916C9.99556 4.03247 9.90967 4.1315 9.90967 4.22777C9.90967 4.22777 9.90967 10.3302 9.90967 11.4934C9.90967 11.7069 10.0428 11.7704 10.2149 11.7704C10.2149 11.7704 11.1082 11.7704 12.1006 11.7704C12.3075 11.7704 12.3582 11.6688 12.3582 11.49C12.3582 11.1006 12.3582 9.57493 12.3582 9.27878Z"
        fill="#362066"
      />
      <path
        d="M35.7422 4.04907H33.6588C33.5632 4.04907 33.4855 4.12784 33.4855 4.22475V9.61165C33.4855 9.61165 32.9562 9.99893 32.2049 9.99893C31.4537 9.99893 31.2543 9.65804 31.2543 8.92247C31.2543 8.18601 31.2543 4.22475 31.2543 4.22475C31.2543 4.12784 31.1767 4.04907 31.0811 4.04907H28.9666C28.8711 4.04907 28.793 4.12784 28.793 4.22475C28.793 4.22475 28.793 7.09348 28.793 9.27816C28.793 11.463 30.0107 11.9976 31.6858 11.9976C33.06 11.9976 34.1681 11.2384 34.1681 11.2384C34.1681 11.2384 34.2208 11.6384 34.2447 11.6859C34.2686 11.7332 34.3307 11.781 34.398 11.781L35.7431 11.775C35.8386 11.775 35.9167 11.6962 35.9167 11.5996L35.9161 4.22475C35.9161 4.12784 35.8382 4.04907 35.7422 4.04907Z"
        fill="#362066"
      />
      <path
        d="M41.4365 3.80229C40.2532 3.80229 39.4483 4.33023 39.4483 4.33023V0.610085C39.4483 0.513081 39.3708 0.434814 39.2751 0.434814H37.1696C37.0739 0.434814 36.9961 0.513081 36.9961 0.610085V11.5964C36.9961 11.6933 37.0739 11.7722 37.1699 11.7722C37.1701 11.7722 38.6302 11.7722 38.6306 11.7722C38.6964 11.7722 38.7462 11.7382 38.783 11.6789C38.8193 11.6198 38.8717 11.1723 38.8717 11.1723C38.8717 11.1723 39.7326 11.9882 41.3625 11.9882C43.2758 11.9882 44.3731 11.0176 44.3731 7.63126C44.3731 4.24483 42.6207 3.80229 41.4365 3.80229ZM40.6147 9.99226C39.8921 9.97024 39.4019 9.64233 39.4019 9.64233V6.16319C39.4019 6.16319 39.8855 5.86683 40.4787 5.81382C41.229 5.74664 41.952 5.97326 41.952 7.763C41.952 9.65028 41.6257 10.0227 40.6147 9.99226Z"
        fill="#362066"
      />
      <path
        d="M18.3925 4.03148H16.8176C16.8176 4.03148 16.8153 1.95133 16.8153 1.95087C16.8153 1.8721 16.7746 1.83276 16.6836 1.83276H14.5375C14.454 1.83276 14.4092 1.86949 14.4092 1.94966V4.09969C14.4092 4.09969 13.3337 4.35934 13.2609 4.38031C13.1886 4.40134 13.1353 4.46811 13.1353 4.54778V5.89887C13.1353 5.99603 13.2129 6.07441 13.3089 6.07441H14.4092C14.4092 6.07441 14.4092 7.48752 14.4092 9.32481C14.4092 11.739 16.1027 11.9762 17.2454 11.9762C17.7676 11.9762 18.3921 11.8085 18.4953 11.7704C18.5576 11.7475 18.5939 11.6829 18.5939 11.6129L18.5956 10.1266C18.5956 10.0297 18.5138 9.9512 18.4216 9.9512C18.3298 9.9512 18.095 9.98856 17.8533 9.98856C17.0797 9.98856 16.8176 9.6289 16.8176 9.16329C16.8176 8.69807 16.8175 6.07441 16.8175 6.07441H18.3925C18.4885 6.07441 18.5663 5.99603 18.5663 5.89887V4.20653C18.5663 4.10957 18.4885 4.03148 18.3925 4.03148Z"
        fill="#362066"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="44.3731" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
