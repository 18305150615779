import React, { Fragment, useContext } from "react"
import styled from "react-emotion"
import { graphql } from "react-apollo"
import gql from "graphql-tag"
import PropTypes from "prop-types"
import { MdAdd, MdHelpOutline } from "react-icons/md"
import ContributorsForm from "../../../shared/ContributorsForm/ContributorsForm"
import { ToastContext } from "../../../shared/Toast"
import { useTracker } from "../../../../analytics"
import {
  breakpoints,
  colors,
  spaces,
  fontSizes,
} from "../../../../utils/presets"
import { orgMembers as text } from "../../../../locales/default.json"
import Loading from "../../../shared/Loading"
import ContributorDetails from "../../../shared/Settings/ContributorDetails"
import {
  SettingsCard,
  Header,
  Title,
  Message,
} from "../../../shared/Settings/SettingsCard"
import { SecondaryButton } from "../../../shared/Buttons"
import { Heading } from "../../../shared/Typography"
import {
  ModalContext,
  ModalSecondaryHeader,
  ModalBody,
  CloseButton,
} from "../../../Modal"
import { DefaultError } from "../../../shared/ErrorModal"
import { OrganizationPermissions } from "../../../../permissions"
import Paywall from "../../../shared/PaymentInformation/Paywall"

const ORG_CONTRIBUTORS_QUERY = gql`
  query contributorsByOrganizationQuery($id: UUID!) {
    contributorsByOrganization(id: $id) {
      id
      name
      email
      avatarUrl
      contributorRole
    }
  }
`

const INVITE_CONTRIBUTORS_MUTATION = gql`
  mutation inviteContributorsToResource(
    $id: UUID!
    $emails: [String!]!
    $resourceType: CloudResourceType!
  ) {
    inviteContributorsToResource(
      id: $id
      emails: $emails
      resourceType: $resourceType
    ) {
      id
      name
      email
      avatarUrl
      contributorRole
    }
  }
`

const DocumentationLink = styled(`a`)`
  align-items: center;
  color: ${colors.gatsby};
  display: flex;
  padding-top: ${spaces.m};
  svg {
    display: none;
    margin-left: ${spaces[`2xs`]};
  }
  @media (min-width: ${breakpoints.phablet}px) {
    svg {
      display: block;
    }
  }
`

const Button = styled(SecondaryButton)`
  font-size: ${fontSizes.s};
  @media (min-width: ${breakpoints.mobile}px) {
    font-size: ${fontSizes.m};
  }
`

let InviteContributor = ({
  closeModal,
  organizationId,
  showToast,
  inviteContributorsMutation,
}) => {
  const { showModal } = useContext(ModalContext)
  const { trackButtonClicked } = useTracker()

  return (
    <Fragment>
      <ModalSecondaryHeader>
        <Heading>{text.inviteContributors}</Heading>
        <CloseButton onClick={closeModal} aria-label="Close modal" />
      </ModalSecondaryHeader>
      <ModalBody>
        <ContributorsForm
          submitCallback={emails => {
            trackButtonClicked(text.inviteContributors, {
              organizationId,
              uiSource: `Organization settings`,
            })
            inviteContributorsMutation({
              variables: {
                id: organizationId,
                emails: emails,
                resourceType: `ORGANIZATION`,
              },
              update: (cache, data) => {
                const contributorsFromCache = cache.readQuery({
                  query: ORG_CONTRIBUTORS_QUERY,
                  variables: {
                    id: organizationId,
                  },
                })

                const {
                  data: { inviteContributorsToResource },
                } = data

                contributorsFromCache.contributorsByOrganization = contributorsFromCache.contributorsByOrganization.concat(
                  inviteContributorsToResource
                )

                cache.writeQuery({
                  query: ORG_CONTRIBUTORS_QUERY,
                  variables: {
                    id: organizationId,
                  },
                  data: contributorsFromCache,
                })
              },
            })
              .then(() => {
                closeModal()
                showToast(
                  `<strong>${text.contributorInvitationSent}</strong> ${
                    text.successfully
                  }`
                )
              })
              .catch(err =>
                showModal({
                  Component: DefaultError,
                  props: { errMsg: err.message },
                })
              )
          }}
          cancelCallback={closeModal}
        />
      </ModalBody>
    </Fragment>
  )
}

InviteContributor.propTypes = {
  closeModal: PropTypes.func,
  organizationId: PropTypes.string,
  emails: PropTypes.array,
  resourceType: PropTypes.string,
}

InviteContributor = graphql(INVITE_CONTRIBUTORS_MUTATION, {
  name: `inviteContributorsMutation`,
})(InviteContributor)

function InviteContributorAction({ organizationId }) {
  const { showModal } = useContext(ModalContext)
  const { showToast } = useContext(ToastContext)
  return (
    <Paywall
      id={organizationId}
      onSuccess={() => {
        showModal({
          Component: InviteContributor,
          props: {
            organizationId,
            mode: `action`,
            showToast,
          },
        })
      }}
    >
      {({ onClick }) => (
        <Button onClick={onClick}>
          {text.inviteContributors} <MdAdd />
        </Button>
      )}
    </Paywall>
  )
}

// @TODO: Change out permissions
const OrganizationContributors = ({
  organizationId,
  permissionToInvite,
  permissionToRead,
  permissionToDelete,
  orgContributorsQuery,
}) => {
  const { showToast } = useContext(ToastContext)
  const { showModal } = useContext(ModalContext)
  const { loading, error, contributorsByOrganization } = orgContributorsQuery

  if (loading)
    return (
      <Loading
        delay={1000}
        message="loading your organization members list..."
        fullPageHeight={false}
      />
    )
  if (error) return <p>Error: {error.message}</p>

  return (
    <SettingsCard>
      <Header>
        <Title>{text.orgMembers}</Title>
        <OrganizationPermissions
          id={organizationId}
          resource="contributor"
          action="invite"
        >
          <InviteContributorAction organizationId={organizationId} />
        </OrganizationPermissions>
      </Header>

      <Message>
        {permissionToRead
          ? `${text.orgMembersText}`
          : `${text.orgMembersNoPermissions}`}
        {/* TODO: Update link when we have docs with role and permissions not cms specific */}
        <DocumentationLink
          target="_blank"
          href={`${
            window.location.origin
          }/docs/preview/getting-started-with-gatsby-preview-and-contentful/#role-and-permissioning-structure`}
        >
          {text.orgMembersLink} <MdHelpOutline />
        </DocumentationLink>
      </Message>
      <OrganizationPermissions
        id={organizationId}
        resource="contributor"
        action="read"
      >
        <ContributorDetails
          contributors={contributorsByOrganization}
          resourceId={organizationId}
          showModal={showModal}
          permissionToInvite={permissionToInvite}
          permissionToDelete={permissionToDelete}
          showToast={showToast}
          resourceType="ORGANIZATION"
        />
      </OrganizationPermissions>
    </SettingsCard>
  )
}

OrganizationContributors.propTypes = {
  organizationId: PropTypes.string.isRequired,
  permissionToInvite: PropTypes.bool,
  permissionToRead: PropTypes.bool,
  permissionToDelete: PropTypes.bool,
}

export default graphql(ORG_CONTRIBUTORS_QUERY, {
  name: `orgContributorsQuery`,
  options: props => {
    return {
      name: `orgContributors`,
      variables: { id: props.organizationId },
      fetchPolicy: `cache-and-network`,
    }
  },
})(OrganizationContributors)
