import React from "react"

export default () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.77316 0V0.016024V1.61843C12.2904 2.05108 14.7822 5.24787 14.3495 8.76515C13.981 11.6815 11.6895 13.997 8.77316 14.3415V15.9439C13.1798 15.5033 16.3846 11.5934 15.9439 7.18678C15.5834 3.38107 12.5628 0.376565 8.77316 0ZM7.17076 0.024036C5.60841 0.176264 4.11818 0.777166 2.90035 1.78668L4.04607 2.97246C4.94342 2.25138 6.02504 1.78668 7.17076 1.62644V0.024036ZM1.77066 2.91637C0.761142 4.1342 0.16024 5.61643 0 7.18678H1.6024C1.75463 6.04907 2.20331 4.96745 2.91637 4.06209L1.77066 2.91637ZM0.00801201 8.78918C0.168252 10.3595 0.785178 11.8418 1.77867 13.0596L2.91637 11.9139C2.21132 11.0085 1.75463 9.92689 1.61042 8.78918H0.00801201ZM4.04607 13.0916L2.90035 14.1893C4.11017 15.1988 5.6004 15.8317 7.17076 16V14.3976C6.03305 14.2534 4.95143 13.7967 4.04607 13.0916ZM8.37256 3.98197V8.18828L11.978 10.3275L11.3771 11.313L7.17076 8.78918V3.98197H8.37256Z" />
  </svg>
)
