import React, { Fragment } from "react"
import styled from "react-emotion"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { ApolloFragment } from "apollo-fragment-react"

import { MdLaunch } from "react-icons/md"

import {
  palette,
  spaces,
  breakpoints,
  fontSizes,
} from "../../../../utils/presets"
import ContactUsLink from "../../../shared/ContactUsLink"
import { planInformation as planText } from "../../../../locales/default.json"
import { OrganizationPermissions } from "../../../../permissions"
import BillingStatus from "../../../shared/BillingStatus"
import PlanFeatures from "../../../shared/PlanFeatures"
import {
  SettingsCard,
  Header,
  Title,
  Message,
} from "../../../shared/Settings/SettingsCard"
import { PLAN_FRAGMENT } from "../queries"

const PlanCard = styled(SettingsCard)`
  padding: 0;

  @media (min-width: ${breakpoints.desktop}px) {
    padding: 0;
  }
`

const Row = styled(Header)`
  padding: 0 ${spaces.l};
  :first-of-type {
    padding-top: ${spaces.m};
  }
  :last-of-type {
    padding-bottom: ${spaces.m};
  }

  @media (min-width: ${breakpoints.desktop}px) {
    padding: 0 ${spaces[`2xl`]};

    :first-of-type {
      padding-top: ${spaces.xl};
    }
    :last-of-type {
      padding-bottom: ${spaces.xl};
    }
  }
`

const Contact = styled(Message)`
  margin: 0;

  @media (min-width: ${breakpoints.desktop}px) {
    margin: 0;
  }
`

const CompareLink = styled(Link)`
  align-items: center;
  font-size: ${fontSizes.xs};
  margin-right: ${spaces[`2xs`]};
  text-decoration: none;
`

const BaseTitle = styled(Title)`
  color: ${palette.grey[900]};
  font-size: ${fontSizes.xl};
`

const Trial = styled(`span`)`
  font-weight: normal;
`

const PlanInformation = ({ organizationId, permissionToCreate }) => (
  <PlanCard>
    <ApolloFragment id={organizationId} fragment={PLAN_FRAGMENT}>
      {({ data }) => {
        const {
          billing,
          billing: {
            status,
            plan: { name, features },
          },
        } = data

        return (
          <Fragment>
            <Row>
              <BaseTitle>
                {`${name}`.split(` `)[0]}
                <Trial>&nbsp;{`${name}`.split(` `)[1]}</Trial>
              </BaseTitle>
              <OrganizationPermissions
                id={organizationId}
                resource="billing"
                action="read"
              >
                {name !== `Enterprise` && (
                  <Contact>
                    <ContactUsLink />
                    {` `}
                    {planText.setUpEnterprise}
                  </Contact>
                )}
              </OrganizationPermissions>
            </Row>
            <Row>
              {status !== `TRIALING` && (
                <PlanFeatures
                  feature={features[0].replace(`/`, `and`).split(` `)}
                />
              )}
              <OrganizationPermissions
                id={organizationId}
                resource="billing"
                action="read"
              >
                {status === `TRIALING` && (
                  <CompareLink to={`/dashboard/pricing/${organizationId}`}>
                    {planText.comparePlans} <MdLaunch />
                  </CompareLink>
                )}
              </OrganizationPermissions>
            </Row>
            <BillingStatus
              orgId={organizationId}
              billing={billing}
              styling={`section`}
              showLink={false}
              uiSource="Organization settings"
            />
          </Fragment>
        )
      }}
    </ApolloFragment>
  </PlanCard>
)

PlanInformation.propTypes = {
  organizationId: PropTypes.string.isRequired,
  permissionToCreate: PropTypes.bool,
}

export default PlanInformation
