import React from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"
import { Search } from "../Forms"

const SiteSearch = styled(Search)`
  height: 2rem;
  width: 100%;
`

const SiteListFilter = ({
  ariaLabel = `Search bar`,
  placeholder = `Enter search term`,
  value,
  onChange,
  className,
}) => (
  <SiteSearch
    aria-label={ariaLabel}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    className={className}
    data-cy="site-list-filter-input"
  />
)

SiteListFilter.propTypes = {
  ariaLabel: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default SiteListFilter
