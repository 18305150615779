import gql from "graphql-tag"
import { PERMISSIONS_ORGANIZATION_FRAGMENT } from "../../permissions/fragments"
import {
  CREDIT_CARD_FRAGMENT,
  BILLING_PLAN_FRAGMENT,
  BILLING_FIELDS_FRAGMENT,
} from "../Payment/fragments"

export const AVAILABLE_PLANS = gql`
  query availablePlans($organizationId: UUID!, $interval: BillingInterval!) {
    availableSubscriptionPlans(
      organizationId: $organizationId
      interval: $interval
    ) {
      ...billingPlanFragment
      isRecommended
      nextBillingDate
      formattedMonthlyAmount
    }
  }
  ${BILLING_PLAN_FRAGMENT}
`

export const ORGANIZATION_DETAILS = gql`
  query organizationDetails($id: UUID!) {
    organizationDetails(id: $id) {
      id
      name
      billing {
        ...billingFieldsFragment
        creditCard {
          ...creditCardFragment
        }
        plan {
          ...billingPlanFragment
        }
      }
      permissions {
        ...orgPermissionsFragment
      }
    }
  }
  ${BILLING_FIELDS_FRAGMENT}
  ${BILLING_PLAN_FRAGMENT}
  ${CREDIT_CARD_FRAGMENT}
  ${PERMISSIONS_ORGANIZATION_FRAGMENT}
`
