import React from "react"
import PropTypes from "prop-types"
import { MdDeleteForever } from "react-icons/md"
import { Menu, MenuList, MenuItem } from "@reach/menu-button"
import MenuButton from "./MenuButton"
import {
  RemoveSiteContributorModal,
  RemoveOrgContributorModal,
} from "../Modals"
import { manageContributors as text } from "../../../../locales/default.json"

const MemberDropdown = ({
  contributor,
  showModal,
  resourceId,
  resourceType,
  showToast,
}) => (
  <Menu>
    <MenuButton
      aria-label={`${text.manageContributor} ${contributor.name}`}
      data-testid="member-button"
    />
    <MenuList className="manageMember" data-testid="member-list">
      {/* This is a hack because of this https://github.com/reach/reach-ui/issues/114 */}
      {[
        <MenuItem
          key="remove"
          className="warn"
          onSelect={() => {
            if (resourceType === `SITE`) {
              showModal({
                Component: RemoveSiteContributorModal,
                props: {
                  mode: `warning`,
                  contributorId: contributor.id,
                  email: contributor.email,
                  siteId: resourceId,
                  showToast,
                },
              })
            } else {
              showModal({
                Component: RemoveOrgContributorModal,
                props: {
                  mode: `warning`,
                  contributorId: contributor.id,
                  email: contributor.email,
                  organizationId: resourceId,
                  showToast,
                },
              })
            }
          }}
        >
          <MdDeleteForever />
          Remove contributor
        </MenuItem>,
      ]}
    </MenuList>
  </Menu>
)

MemberDropdown.propTypes = {
  contributor: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  resourceType: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
}

export default MemberDropdown
