import React, { Fragment } from "react"
import styled from "react-emotion"
import { navigate } from "gatsby"
import { format } from "date-fns"

import { MdArrowForward, MdDone, MdCreditCard } from "react-icons/md"

import {
  ModalPrimaryHeader,
  ModalPrimaryIcon,
  ModalBody,
  ModalMessage,
  ModalAction,
  ModalActions,
} from "../Modal"

import { PrimaryButton, CancelButton } from "../shared/Buttons"
import AlertIcon from "../../assets/AlertIcon"
import AlertHexagon from "../../assets/AlertHexagon"
import SuccessIcon from "../../assets/SuccessIcon"
import BillCalendar from "../../assets/BillCalendar"
import Cash from "../../assets/Cash"
import { Heading, Text } from "../shared/Typography"
import {
  billing as text,
  modalErrors as modalErrorText,
} from "../../locales/default.json"
import { palette, spaces, breakpoints, fontSizes } from "../../utils/presets"

const ErrorIcon = styled(AlertHexagon)`
  margin-right: ${spaces.m};
`

const ErrorActions = styled(ModalActions)`
  a,
  button {
    font-size: ${fontSizes.s};
  }
`

export const ErrorModal = ({ closeModal, err, name, creditCard }) => (
  <Fragment>
    <ModalPrimaryHeader>
      <ModalPrimaryIcon>
        <AlertIcon />
      </ModalPrimaryIcon>
      <Heading>
        {text.paymentProblem} <strong>{name}</strong>
      </Heading>
    </ModalPrimaryHeader>
    <ModalBody>
      <ModalMessage>
        {!!creditCard && (
          <Text>
            {text.billingBrand} {creditCard.brand} {text.billingEnding}
            {` `}
            {creditCard.lastFour} {text.billingError}
          </Text>
        )}
        <Text>
          <ErrorIcon />
          {err}
        </Text>
        <Text>{text.errorReview}</Text>
        <Text>{modalErrorText.emailSupport}</Text>
      </ModalMessage>
      <ErrorActions>
        <CancelButton href="mailto:support@gatsbyjs.com">
          {text.contactSupport}
        </CancelButton>
        <PrimaryButton onClick={closeModal}>
          {text.updatePayment} <MdArrowForward />
        </PrimaryButton>
      </ErrorActions>
    </ModalBody>
  </Fragment>
)

const Row = styled(`div`)`
  align-items: center;
  display: flex;
  width: 100%;

  &:first-of-type {
    margin-top: ${spaces.m};
  }

  svg {
    margin-right: ${spaces.s};
    fill: ${palette.green[200]};
    width: 16px;
  }
`

const Key = styled(`span`)`
  color: ${palette.grey[900]};
  font-weight: bold;
  padding-right: ${spaces.xs};
  width: auto;

  @media (min-width: ${breakpoints.tablet}px) {
    padding-right: 0;
    min-width: 200px;
  }
`

const Details = styled(Text)`
  color: ${palette.grey[900]};
  margin: 0;
`

export const SuccessModal = ({
  closeModal,
  plan,
  name,
  creditCard,
  billingDate,
  organizationId,
}) => (
  <Fragment>
    <ModalPrimaryHeader>
      <ModalPrimaryIcon>
        <SuccessIcon />
      </ModalPrimaryIcon>
      <Heading>
        {`${text.successfullySubscribed} `}
        <strong>{name}</strong>
        {` ${text.toPreview} ${plan.name}`}
      </Heading>
    </ModalPrimaryHeader>
    <ModalBody>
      <ModalMessage>
        <Text>{`${text.successfulConnection} ${plan.name} ${text.plan}`}</Text>
        <Row>
          <MdCreditCard />
          <Key>{creditCard.brand}</Key>
          <Details>**** **** **** {creditCard.lastFour}</Details>
        </Row>
        <Row>
          <BillCalendar />
          <Key>
            {text.billed} {`${plan.interval}`.toLowerCase()}
          </Key>
          <Details>{plan.formattedAmount}</Details>
        </Row>
        <Row>
          <Cash />
          <Key>{text.nextPayment}</Key>
          <Details>{format(new Date(billingDate), `yyyy-MM-dd`)}</Details>
        </Row>
      </ModalMessage>
      <ModalAction>
        <PrimaryButton
          data-cy="modal-primary-button"
          onClick={() => {
            closeModal()
            return navigate(`/dashboard/organization/sites/${organizationId}`)
          }}
        >
          {text.gotIt} <MdDone />
        </PrimaryButton>
      </ModalAction>
    </ModalBody>
  </Fragment>
)
