import React from "react"
import PropTypes from "prop-types"

import { NotificationCard, NotificationSection } from "../Notification"
import getNotificationProps from "./getNotificationProps"
import { useTracker } from "../../../analytics"

const BillingStatus = React.memo(props => {
  const {
    billing,
    orgId,
    styling = `card`,
    showLink,
    className,
    uiSource,
  } = props

  if (!billing || !orgId) {
    return null
  }

  const { trackButtonClicked } = useTracker()

  const noticationProps = getNotificationProps({
    billing,
    orgId,
    trackButtonClicked,
    uiSource,
  })

  const Component =
    styling === `section` ? NotificationSection : NotificationCard

  return noticationProps.message ? (
    <Component showLink={showLink} {...noticationProps} className={className} />
  ) : null
})

BillingStatus.propTypes = {
  orgId: PropTypes.string,
  billing: PropTypes.object,
  styling: PropTypes.oneOf([`card`, `section`]),
  showLink: PropTypes.bool,
  className: PropTypes.string,
  uiSource: PropTypes.string,
}

export default BillingStatus
