import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ApolloFragment } from "apollo-fragment-react"

import PlanInformation from "./PlanInformation"
import PaymentInformation from "./PaymentInformation"
import OrganizationContributors from "./OrganizationContributors"
import DowngradePlan from "./DowngradePlan"
import {
  StandardSingleColumn,
  PageWithTabsContentSection,
} from "../../shared/Containers"
import { useTracker } from "../../../analytics"
import { PERMISSIONS_ORGANIZATION_FRAGMENT } from "../../../permissions/fragments"
import { OrganizationPermissions } from "../../../permissions"
import {
  CREDIT_CARD_FRAGMENT,
  BILLING_FIELDS_FRAGMENT,
} from "../../Payment/fragments"

const permissionsFragment = `
  fragment orgPermissionsFields on Organization {
    id
    name
    billing {
      ...billingFieldsFragment
      creditCard {
        ...creditCardFragment
      }
    }
    permissions {
      ...orgPermissionsFragment
    }
  }
  ${BILLING_FIELDS_FRAGMENT}
  ${CREDIT_CARD_FRAGMENT}
  ${PERMISSIONS_ORGANIZATION_FRAGMENT}
`

const Settings = ({ organizationId }) => {
  const [res, setResponse] = useState({
    success: false,
    err: null,
    creditCard: null,
  })

  const { trackPageViewed } = useTracker()

  useEffect(() => {
    trackPageViewed(`Organization Settings`)
  }, [])

  return (
    <PageWithTabsContentSection>
      <ApolloFragment id={organizationId} fragment={permissionsFragment}>
        {({ data }) => {
          const {
            name,
            billing,
            permissions: {
              billing: { create },
              contributor: {
                read,
                invite,
                delete: deleteContributorPermission,
              },
            },
          } = data

          return (
            <StandardSingleColumn>
              <PlanInformation
                organizationId={organizationId}
                permissionToCreate={!!create}
              />
              {billing && billing.creditCard && (
                <OrganizationPermissions
                  id={organizationId}
                  resource="billing"
                  action="read"
                >
                  <PaymentInformation
                    organizationId={organizationId}
                    setResponse={res => setResponse(res)}
                    res={res}
                  />
                </OrganizationPermissions>
              )}
              <OrganizationContributors
                billingStatus={billing && billing.status}
                organizationName={name}
                organizationId={organizationId}
                permissionToInvite={invite}
                permissionToRead={read}
                permissionToDelete={deleteContributorPermission}
              />
              {billing && billing.status === `ACTIVE` && (
                <OrganizationPermissions
                  id={organizationId}
                  resource="billing"
                  action="read"
                >
                  <DowngradePlan organizationId={organizationId} />
                </OrganizationPermissions>
              )}
            </StandardSingleColumn>
          )
        }}
      </ApolloFragment>
    </PageWithTabsContentSection>
  )
}

Settings.propTypes = {
  organizationId: PropTypes.string.isRequired,
}

export default Settings
