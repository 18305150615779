import React, { Fragment } from "react"
import { format } from "date-fns"

import Clock from "../../../assets/Clock"
import { MdWarning } from "react-icons/md"
import AlertHexagon from "../../../assets/AlertHexagon"

import { Text, ErrorText } from "../Typography"
import { staticNotifications as text } from "../../../locales/default.json"

function creditCardExpired(creditCard) {
  const expDate = new Date(creditCard.expYear, creditCard.expMonth, 0)
  const nowDate = new Date()

  return nowDate > expDate ? format(expDate, `yyyy-MM-dd`) : false
}

export default function getNotificationProps({
  billing,
  orgId,
  trackButtonClicked,
  uiSource,
}) {
  const { status, trialDaysRemaining, creditCard } = billing
  const props = {}

  if (status === `TRIALING` && trialDaysRemaining > 0) {
    props.icon = Clock
    props.mode = `warning`
    props.message = `${trialDaysRemaining} ${
      trialDaysRemaining === 1 ? text.day : text.days
    } <span>${text.remainingInTrial}</span>`
    props.linkLabel = text.addPayment
    props.linkTo = `/dashboard/pricing/${orgId}`
    props.linkOnClick = () => {
      trackButtonClicked(text.addPayment, {
        organizationId: orgId,
        uiSource,
      })
    }
  }

  if (status === `TRIALING` && trialDaysRemaining <= 0) {
    props.icon = Clock
    props.mode = `error`
    props.message = text.yourTrialExpired
    props.linkLabel = text.addPaymentToContinue
    props.linkTo = `/dashboard/pricing/${orgId}`
    props.linkOnClick = () => {
      trackButtonClicked(text.addPaymentToContinue, {
        organizationId: orgId,
        uiSource,
      })
    }
  }

  if (billing && billing.creditCard) {
    if (status === `UNPAID`) {
      props.icon = MdWarning
      props.mode = `error`
      props.message = `${text.stoppedPaymentFailed[0]} ${creditCard.lastFour} ${
        text.stoppedPaymentFailed[1]
      }`
      props.linkLabel = text.updatePaymentInformation
      props.linkTo = `/dashboard/organization/settings/${orgId}`
      props.linkOnClick = () => {
        trackButtonClicked(text.updatePaymentInformation, {
          organizationId: orgId,
          uiSource,
        })
      }
      props.details = () => (
        <Fragment>
          <Text>{text.whenTryingToBill}</Text>
          <ErrorText>
            <AlertHexagon />
            {text.pleaseUpdatePayment}
          </ErrorText>
        </Fragment>
      )
    }

    if (status === `CANCELED`) {
      props.icon = MdWarning
      props.mode = `error`
      props.message = `${text.planCanceled} ${format(
        billing.canceledAt ? new Date(billing.canceledAt) : new Date(),
        `MMM do, yyyy`
      )}`
      props.linkLabel = text.updatePaymentResubscribeInformation
      props.linkTo = `/dashboard/pricing/${orgId}`
      props.linkOnClick = () => {
        trackButtonClicked(text.updatePaymentResubscribeInformation, {
          organizationId: orgId,
          uiSource,
        })
      }
    }

    const creditCardExpiredDateStr = creditCardExpired(billing.creditCard)

    const hasCreditCardFields =
      billing.creditCard.lastFour &&
      billing.creditCard.expYear &&
      billing.creditCard.expMonth

    if (
      status === `ACTIVE` &&
      hasCreditCardFields &&
      creditCardExpiredDateStr
    ) {
      props.icon = MdWarning
      props.mode = `error`
      props.message = `${
        text.yourCreditCardExpired
      } ${creditCardExpiredDateStr}`
      props.linkLabel = text.updatePaymentInformation
      props.linkTo = `/dashboard/organization/settings/${orgId}`
      props.linkOnClick = () => {
        trackButtonClicked(text.addPaymentToContinue, {
          organizationId: orgId,
          uiSource,
        })
      }
      props.details = () => (
        <Fragment>
          <Text>{text.updatePaymentInformation}</Text>
        </Fragment>
      )
    }
  } else {
    if (status === `UNPAID`) {
      props.icon = MdWarning
      props.mode = `error`
      props.message = `${text.yourTrialExpired}`
      props.linkLabel = text.updatePaymentInformation
      props.linkTo = `/dashboard/pricing/${orgId}`
      props.linkOnClick = () => {
        trackButtonClicked(text.updatePaymentInformation, {
          organizationId: orgId,
          uiSource,
        })
      }
    }
  }

  return props
}
