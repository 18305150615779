import React, { useState, Fragment } from "react"
import { MdWarning } from "react-icons/md"

import { NotificationCard as ErrorNotificationRoot } from "./Notification"

export const setNotificationError = () => {
  const [err, setErr] = useState(null)

  return {
    err,
    setErr,
  }
}

export const ErrorNotification = ({ err }) => (
  <Fragment>
    {err && (
      <ErrorNotificationRoot icon={MdWarning} message={err} mode={`error`} />
    )}
  </Fragment>
)
