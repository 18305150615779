import React, { useContext } from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"
import { format } from "date-fns"
import { MdAdd } from "react-icons/md"

import { Text, Heading, Subheading } from "../../shared/Typography"
import { palette, fontSizes, spaces, breakpoints } from "../../../utils/presets"
import { ToastContext } from "../../shared/Toast"
import {
  billing as text,
  paymentInformation as paymentText,
} from "../../../locales/default.json"
import CreditCard from "./CreditCard"
import { UpdatePaymentForm } from "../../shared/PaymentInformation/UpdatePaymentForm"
import { SecondaryButton } from "../../shared/Buttons"
import Features from "./Features"

const ReviewRoot = styled(`div`)`
  margin: 0;
  margin-top: ${spaces[`2xl`]};
  max-width: 100%;
`

const Header = styled(Text)`
  color: ${palette.grey[500]};
  font-size: ${fontSizes.xs};
  text-transform: uppercase;
`

const Content = styled(`section`)`
  align-items: center;
  border-bottom: 1px solid ${palette.grey[200]};
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${spaces.xl} 0;
`

const TotalPrice = styled(Subheading)`
  font-size: ${fontSizes.xl};
  margin: 0;
`

const Price = styled(Text)`
  color: ${palette.grey[900]};
  font-weight: bold;
  margin: 0;
  padding: 0;
`

const PaymentHeading = styled(Header)`
  border-top: 0;
  margin: 0;
  padding-top: 0;
`

const PaymentHeader = styled(`div`)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${spaces.m};
  width: 100%;
`

const PaymentDetails = styled(`div`)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  ${Text} {
    margin: 0;
    width: 100%;
  }

  .resubscribe {
    width: 100%;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    .resubscribe {
      width: 25%;
    }
  }
`

const Review = ({
  plan,
  billing,
  organizationId,
  name,
  children,
  setResponse,
  creditCard,
  paymentFormVisible,
  setPaymentFormVisible,
}) => {
  const { showToast } = useContext(ToastContext)

  return (
    <ReviewRoot>
      <Header>{text.yourOrder}</Header>
      <Content>
        <Heading>
          {text.gatsbyPreview} {plan.name}
          <Features feature={plan.features[0].split(` `)} />
        </Heading>
        <Price>{`${plan.formattedAmount}`.replace(/\.00$/, ``)}</Price>
      </Content>
      <Content>
        <Heading>{text.total}</Heading>
        <TotalPrice>
          {`${plan.formattedAmount}`.replace(/\.00$/, ``)}
        </TotalPrice>
      </Content>
      <Content>
        <PaymentHeader>
          <PaymentHeading>
            {paymentFormVisible
              ? text.updatePaymentDetails
              : text.paymentDetails}
          </PaymentHeading>
          {billing && billing.creditCard && !paymentFormVisible && (
            <SecondaryButton onClick={() => setPaymentFormVisible(true)}>
              {paymentText.updatePayment} <MdAdd />
            </SecondaryButton>
          )}
        </PaymentHeader>
        {paymentFormVisible && (
          <UpdatePaymentForm
            name={name}
            organizationId={organizationId}
            id={plan.id}
            setResponse={res => setResponse(res)}
            showToast={showToast}
            resourceType={`RESUBSCRIBE`}
            cancelCallback={() => setPaymentFormVisible(false)}
          />
        )}
        {!paymentFormVisible && (
          <PaymentDetails>
            <Text
              className={billing && billing.creditCard ? `resubscribe` : ``}
            >
              {text.billed} {` `} {`${plan.interval}`.toLowerCase()}
              {` `} {text.billedDetails}
              {` `}
              <strong>
                {format(new Date(plan.nextBillingDate), `MMMM do, yyyy`)}
              </strong>
            </Text>
            {billing && billing.creditCard && (
              <CreditCard creditCard={creditCard} />
            )}
          </PaymentDetails>
        )}
      </Content>
      {children}
    </ReviewRoot>
  )
}

Review.propTypes = {
  plan: PropTypes.object.isRequired,
  billing: PropTypes.object,
  organizationId: PropTypes.string,
  name: PropTypes.string,
}

export default Review
