import React, { Fragment } from "react"
import styled, { keyframes } from "react-emotion"
import PropTypes from "prop-types"
import { Field, FieldArray, ErrorMessage } from "formik"

import { MdAdd, MdCancel, MdErrorOutline } from "react-icons/md"

// import { Debug } from "../../../utils/debug/Formik"
import { Input, Label } from "../Forms"
import { createSite as text } from "../../../locales/default.json"
import { SecondaryButton } from "../Buttons"
import { fontSizes, palette, spaces } from "../../../utils/presets"
import { useTracker } from "../../../analytics"

const Vars = styled(`div`)`
  display: flex;
  flex-direction: column;
  margin: ${spaces.xl} 0;
`

const varEntry = keyframes`
   to {
     opacity: 1;
     transform: translateY(0);
   }
 `

const Var = styled(`div`)`
  animation: ${varEntry} 0.5s ease forwards;
  display: flex;
  margin: ${spaces.xs} 0;
  opacity: 0;
  transform: translateY(${spaces.s});
`

const Fieldset = styled(`fieldset`)`
  border: 0;
  margin: 0;

  ${Input} {
    width: 100%;
  }
`

const Key = styled(Fieldset)`
  flex-shrink: 1;

  margin-right: ${spaces.m};
  width: 50%;

  ${Input} {
    ::placeholder {
      text-transform: none;
    }
  }
`

const Val = styled(Fieldset)`
  width: 50%;
`

const InputError = styled(`span`)`
  align-items: center;
  color: ${palette.red[600]};
  display: flex;
  font-size: ${fontSizes[`2xs`]};
  line-height: 1;
  padding: ${spaces.xs} ${spaces[`2xs`]} 0;

  svg {
    color: red;
    flex-shrink: 0;
    margin-right: ${spaces[`3xs`]};
  }
`

const RemoveButton = styled(`button`)`
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  height: 2.2rem;
  justify-content: center;
  margin: 0;
  width: 100%;

  svg {
    color: ${palette.red[600]};
    transition: 0.5s ease;
  }
`

const Remove = styled(Fieldset)`
  flex-shrink: 0;
  width: 60px;

  ${Label} {
    padding-left: 0;
    text-align: center;
    width: 100%;
  }

  @media (hover: hover) {
    &:hover {
      ${RemoveButton} {
        svg {
          transform: scale(1.5);
        }
      }
    }
  }
`

const AddVarButton = styled(SecondaryButton)`
  margin-top: ${spaces.l};
  width: 10rem;
`

const EnvVarsFormSkeleton = ({ siteId, uiSource, values }) => {
  const { trackButtonClicked } = useTracker()
  return (
    <FieldArray name="envVars">
      {({ push, remove }) => (
        <Fragment>
          {values.envVars.length > 0 && (
            <Vars>
              {values.envVars.map((envVar, idx) => (
                <Var key={idx}>
                  <Key>
                    {idx === 0 && (
                      <Label data-cy="env-vars-key-label">{text.key}</Label>
                    )}
                    <Field
                      name={`envVars[${idx}].key`}
                      type="text"
                      aria-label={text.key}
                    >
                      {({ field }) => (
                        <Input
                          {...field}
                          placeholder={text.key}
                          aria-label="Environment variable key"
                          data-cy="env-vars-key"
                        />
                      )}
                    </Field>
                    <ErrorMessage name={`envVars[${idx}].key`}>
                      {msg => (
                        <InputError data-cy="env-vars-key-error">
                          <MdErrorOutline /> {msg}
                        </InputError>
                      )}
                    </ErrorMessage>
                  </Key>
                  <Val>
                    {idx === 0 && (
                      <Label data-cy="env-vars-value-label">{text.value}</Label>
                    )}
                    <Field
                      name={`envVars[${idx}].value`}
                      aria-label={text.value}
                    >
                      {({ field }) => (
                        <Input
                          {...field}
                          type={text}
                          placeholder={text.value}
                          aria-label="Environment variable value"
                          data-cy="env-vars-value"
                        />
                      )}
                    </Field>
                    <ErrorMessage name={`envVars[${idx}].value`}>
                      {msg => (
                        <InputError data-cy="env-vars-value-error">
                          <MdErrorOutline /> {msg}
                        </InputError>
                      )}
                    </ErrorMessage>
                  </Val>
                  <Remove>
                    {idx === 0 && <Label>{text.remove}</Label>}
                    <RemoveButton
                      data-cy="remove-env-vars"
                      aria-label="Remove environment variable"
                      type="button"
                      onClick={() => {
                        trackButtonClicked(`Remove variable`, {
                          siteId,
                          uiSource: uiSource,
                        })
                        remove(idx)
                      }}
                    >
                      <MdCancel />
                    </RemoveButton>
                  </Remove>
                </Var>
              ))}
            </Vars>
          )}
          <AddVarButton
            data-cy="add-env-vars"
            type="button"
            onClick={() => {
              trackButtonClicked(text.addVariable, {
                siteId,
                uiSource: uiSource,
              })
              push({ key: ``, value: `` })
            }}
          >
            {text.addVariable} <MdAdd />
          </AddVarButton>
        </Fragment>
      )}
    </FieldArray>
  )
}

EnvVarsFormSkeleton.propTypes = {
  siteId: PropTypes.string,
  uiSource: PropTypes.string,
}

export default EnvVarsFormSkeleton
