import React, { Fragment, useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"
import { navigate } from "gatsby"
import { ApolloFragment } from "apollo-fragment-react"
import { breakpoints, spaces } from "../../../utils/presets"
import {
  StandardSingleColumn,
  PageWithTabsContentSection,
} from "../../shared/Containers"
import { ZeroSites } from "../../shared/ZeroState"
import BillingStatus from "../../shared/BillingStatus"
import { SiteList, SiteListFilter } from "../../shared/SiteList"
import { sites as sitesText } from "../../../locales/default.json"
import { AddSiteButton as AddSiteButtonBase } from "../../shared/Buttons"
import { MdAdd } from "react-icons/md"
import { useTracker } from "../../../analytics"
import { PERMISSIONS_ORGANIZATION_FRAGMENT } from "../../../permissions/fragments"

import { OrganizationPermissions } from "../../../permissions"
import Paywall from "../../shared/PaymentInformation/Paywall"
import {
  CREDIT_CARD_FRAGMENT,
  BILLING_FIELDS_FRAGMENT,
} from "../../Payment/fragments"
import { recordCreateSite } from "../../../utils/helpers/fullstory"

const sitesFragment = `
  fragment orgDetailsSites on Organization {
    id
    name
    sites {
      id
      name
      branch
      updatedAt
      previewUrl
      previewStatus
    }
    billing {
      ...billingFieldsFragment
      creditCard {
        ...creditCardFragment
      }
    }
    permissions {
      ...orgPermissionsFragment
    }
  }
  ${BILLING_FIELDS_FRAGMENT}
  ${CREDIT_CARD_FRAGMENT}
  ${PERMISSIONS_ORGANIZATION_FRAGMENT}
`

const FlexContainer = styled(`div`)`
  display: flex;
  margin-top: ${spaces.l};

  &:first-child {
    margin-top: 0;
  }
`

const AddSiteButton = styled(AddSiteButtonBase)`
  flex-shrink: 0;

  @media (min-width: ${breakpoints.tablet}px) {
    margin-left: ${spaces.s};
  }
`
const AddIcon = styled(MdAdd)`
  margin: auto ${spaces[`3xs`]};
`

const Sites = ({ organizationId }) => {
  const { trackPageViewed, trackButtonClicked } = useTracker()
  const [searchTerm, updateSearchTerm] = useState(``)

  useEffect(() => {
    trackPageViewed(`Organization Sites`)
  }, [])

  return (
    <PageWithTabsContentSection>
      <ApolloFragment id={organizationId} fragment={sitesFragment}>
        {({ data }) => {
          const { sites, billing, permissions } = data

          const filteredSites = searchTerm
            ? sites.filter(site => site.name.toLowerCase().includes(searchTerm))
            : sites

          return (
            <StandardSingleColumn>
              <BillingStatus
                orgId={organizationId}
                billing={billing}
                showLink={
                  permissions &&
                  permissions.billing &&
                  permissions.billing.create
                }
                uiSource="Organization sites list"
              />
              {sites.length ? (
                <Fragment>
                  <FlexContainer>
                    <SiteListFilter
                      ariaLabel="Filter sites"
                      placeholder="Filter sites"
                      value={searchTerm}
                      onChange={e => {
                        updateSearchTerm(e.target.value.toLowerCase())
                      }}
                    />
                    <OrganizationPermissions
                      id={organizationId}
                      resource="sites"
                      action="create"
                    >
                      <Paywall
                        id={organizationId}
                        onSuccess={() => {
                          navigate(`/dashboard/sites/create`)
                          recordCreateSite()
                          trackButtonClicked(sitesText.addNewSite, {
                            organizationId,
                            uiSource: `Org Site List`,
                          })
                        }}
                      >
                        {({ onClick }) => (
                          <AddSiteButton size={`M`} onClick={onClick}>
                            {sitesText.addNewSite} <AddIcon />
                          </AddSiteButton>
                        )}
                      </Paywall>
                    </OrganizationPermissions>
                  </FlexContainer>

                  <SiteList
                    sites={filteredSites}
                    organizationId={organizationId}
                    editPermission={permissions.sites.create}
                  />
                </Fragment>
              ) : (
                <ZeroSites />
              )}
            </StandardSingleColumn>
          )
        }}
      </ApolloFragment>
    </PageWithTabsContentSection>
  )
}

Sites.propTypes = {
  organizationId: PropTypes.string.isRequired,
}

export default Sites
