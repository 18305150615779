import React, { Fragment } from "react"
import styled from "react-emotion"
import PropTypes from "prop-types"
import gql from "graphql-tag"
import { compose, graphql } from "react-apollo"
import { navigate } from "gatsby"
import { MdHelpOutline } from "react-icons/md"

import { NarrowSingleColumn } from "../../shared/Containers"
import { Heading as BaseHeading, Text } from "../../shared/Typography"
import { CmsIntegrationCard } from "../../shared/CmsIntegration/CmsIntegrationCard"
import { spaces } from "../../../utils/presets"
import { cmsIntegrations as text } from "../../../locales/default.json"
import Footer from "../Footer"
import { Documentation } from "../../shared/CmsIntegration/IntegrationCard"
import { SITE_INTEGRATIONS_QUERY } from "../../../graphql/queries"

const REPOSITORY_QUERY = gql`
  query repositoryFromSite($siteId: UUID!) {
    repositoryFromSite(siteId: $siteId) {
      id
      nameWithOwner
    }
  }
`

const Heading = styled(BaseHeading)`
  text-align: center;
`

const Intro = styled(Text)`
  margin: ${spaces.l} 0;
`

const IntegrationList = styled(`div`)`
  margin: ${spaces.xl} 0;
`

const AddIntegrations = ({
  siteId,
  repositoryQuery,
  cancel,
  loading,
  error,
  cmsIntegrations,
}) => {
  const {
    repositoryFromSite: { nameWithOwner } = {},
    repositoryFromSite: repository = {},
  } = repositoryQuery

  return (
    <Fragment>
      <NarrowSingleColumn>
        {nameWithOwner && (
          <Fragment>
            <Heading>
              {text.title} <strong>{nameWithOwner}</strong>
            </Heading>
            <Intro>
              {text.intro}
              {` `}
              <Documentation
                target="_blank"
                href="https://www.gatsbyjs.com/docs/"
              >
                {text.documentation} <MdHelpOutline />
              </Documentation>
            </Intro>
            <IntegrationList>
              <CmsIntegrationCard
                siteId={siteId}
                repository={repository}
                loading={loading}
                error={error}
                cmsIntegrations={cmsIntegrations}
              />
            </IntegrationList>
          </Fragment>
        )}
      </NarrowSingleColumn>
      <Footer
        cancel={cancel}
        submitForm={() => navigate(`/dashboard/sites/create/${siteId}/setup`)}
        buttonText={
          cmsIntegrations.find(cmsIntegration => cmsIntegration.connected)
            ? text.setupSite
            : text.skipStep
        }
        showMessage
      />
    </Fragment>
  )
}

AddIntegrations.propTypes = {
  siteId: PropTypes.string,
  cancel: PropTypes.func,
}

export default compose(
  graphql(REPOSITORY_QUERY, {
    name: `repositoryQuery`,
    options: props => {
      return {
        name: `repository`,
        variables: { siteId: props.siteId },
      }
    },
  }),
  graphql(SITE_INTEGRATIONS_QUERY, {
    options: ({ siteId }) => {
      return {
        variables: {
          siteId,
        },
      }
    },
    props: ({ data, ...rest }) => {
      return {
        loading: data.loading,
        cmsIntegrations: (data && data.cmsIntegrationsForSite) || [],
        error: data.error,
        ...rest,
      }
    },
  })
)(AddIntegrations)
