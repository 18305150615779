import React, { useCallback, useState } from "react"

export function withState(name, initialState) {
  return Component => {
    function RenderComponent(props) {
      const [state, setState] = useState(initialState)

      const stateHandler = useCallback(newState => setState(newState), [state])

      const stateProps = {
        [name]: state,
        [`set${name.charAt(0).toUpperCase() + name.slice(1)}`]: stateHandler,
      }

      return <Component {...props} {...stateProps} />
    }

    return RenderComponent
  }
}
