export const CREDIT_CARD_FRAGMENT = `
    fragment creditCardFragment on CreditCard {
        name
        lastFour
        brand
        expMonth
        expYear
    }

`

export const BILLING_PLAN_FRAGMENT = `
    fragment billingPlanFragment on SubscriptionPlan {
        id
        planId
        name
        interval
        amount
        formattedAmount
        features
        nextBillingDate
    }
`

export const BILLING_FIELDS_FRAGMENT = `
    fragment billingFieldsFragment on BillingInformation {
        id
        status
        trialDaysRemaining
        nextBillingDate
        canceledAt
    }
`
