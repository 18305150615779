import React, { Fragment } from "react"
import { Field, ErrorMessage, FieldArray } from "formik"
import styled from "react-emotion"

import { MdInfoOutline } from "react-icons/md"

import { MdErrorOutline } from "react-icons/md"
import { InputError, Label, CheckboxGroup } from "./"

import { spaces, fontSizes, palette } from "../../../utils/presets"

export const FieldBlock = styled(`div`)`
  margin-bottom: ${spaces.s};

  & > input:focus + label {
    background: red;
  }
`

export const FieldLabel = styled(Label)`
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: ${palette.grey[500]};
  font-size: ${fontSizes.xs};
  margin: 0 ${spaces[`2xs`]} ${spaces[`2xs`]} ${spaces[`2xs`]};

  span {
    color: ${palette.purple[500]};
    font-size: ${fontSizes[`2xs`]};
  }
`

const FieldTip = styled(`p`)`
  color: ${palette.grey[500]};
  font-size: ${fontSizes[`2xs`]};
  margin: ${spaces[`2xs`]} ${spaces[`2xs`]} 0 ${spaces[`2xs`]};

  svg {
    vertical-align: text-top;
  }
`

export const FieldError = styled(InputError)`
  font-size: ${fontSizes[`2xs`]};
  margin-top: ${spaces[`2xs`]};
`

export function FieldAbstraction({
  FieldComponent = Field,
  Component,
  hideLabel = false,
  required = false,
}) {
  return ({
    fieldName,
    label,
    fieldTip,
    placeholder,
    required,
    className,
    ...rest
  }) => (
    <FieldBlock className={className}>
      <FieldComponent name={fieldName} aria-label={label}>
        {({ field, form, ...formikProps }) => (
          <Fragment>
            {!hideLabel && (
              <FieldLabel htmlFor={fieldName}>
                {label} {required ? <span>required</span> : ``}
              </FieldLabel>
            )}
            <Component
              fieldName={fieldName}
              label={label}
              placeholder={placeholder}
              {...field}
              {...rest}
              {...formikProps}
              {...form}
            />
            {fieldTip && (
              <FieldTip>
                <MdInfoOutline /> {fieldTip}
              </FieldTip>
            )}
          </Fragment>
        )}
      </FieldComponent>
      <ErrorMessage name={fieldName}>
        {msg => (
          <FieldError data-cy={`lead-capture-${fieldName}-error-message`}>
            <MdErrorOutline /> {msg}
          </FieldError>
        )}
      </ErrorMessage>
    </FieldBlock>
  )
}

export function FieldGroupAbstraction({ Component, required = false }) {
  return ({ options, fieldName, label, required, ...rest }) => (
    <FieldBlock>
      <FieldLabel>{label}</FieldLabel>
      {options.map(({ label, value }, index) => (
        <Field name={fieldName} aria-label={label} key={index}>
          {({ field, form, ...formikProps }) => (
            <Component
              fieldName={fieldName}
              optionValue={value}
              label={label}
              id={`${fieldName}${index}`}
              {...field}
              {...rest}
              {...formikProps}
              {...form}
            />
          )}
        </Field>
      ))}
      <ErrorMessage name={fieldName}>
        {msg => (
          <InputError data-cy={`lead-capture-${fieldName}-error-message`}>
            <MdErrorOutline /> {msg}
          </InputError>
        )}
      </ErrorMessage>
    </FieldBlock>
  )
}

export const CheckboxInputGroupBlock = FieldAbstraction({
  Component: CheckboxGroup,
  FieldComponent: FieldArray,
})
