import React from "react"

export default () => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.5334 0.333328L10.0096 3.33333V10.6667L13.5334 7.66666V0.333328ZM4.72385 2.33333C3.34956 2.33333 1.86956 2.59999 0.847656 3.33333V13.1067C0.847656 13.2733 1.02385 13.44 1.20004 13.44C1.27051 13.44 1.30575 13.3933 1.37623 13.3933C2.32766 12.96 3.70194 12.6667 4.72385 12.6667C6.09813 12.6667 7.57813 12.9333 8.60004 13.6667C9.55147 13.1 11.2781 12.6667 12.4762 12.6667C13.6391 12.6667 14.8372 12.8733 15.8238 13.3733C15.8943 13.4067 15.9296 13.3933 16 13.3933C16.1762 13.3933 16.3524 13.2267 16.3524 13.06V3.33333C15.9296 3.03333 15.4715 2.83333 14.9429 2.66666V3.99999V7.66666V11.6667C14.1677 11.4333 13.3219 11.3333 12.4762 11.3333C11.2781 11.3333 9.55147 11.7667 8.60004 12.3333V7.66666V4.66666V3.33333C7.57813 2.59999 6.09813 2.33333 4.72385 2.33333Z" />
  </svg>
)
