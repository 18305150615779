import React, { Fragment, useContext } from "react"
import PropTypes from "prop-types"
import gql from "graphql-tag"
import { graphql } from "react-apollo"

import { SITE_CONTRIBUTORS_QUERY } from "../../../SiteDetails/Settings/queries"
import { ORG_CONTRIBUTORS_QUERY } from "../../../OrganizationDetails/Settings/queries"

import { Heading } from "../../Typography"
import {
  ModalContext,
  ModalSecondaryHeader,
  ModalBody,
  ModalActions,
  ModalMessage,
  CloseButton,
  ModalForm,
} from "../../../Modal"
import { DefaultError } from "../../../shared/ErrorModal"
import { CancelButton, PrimaryDeleteButton } from "../../../shared/Buttons"
import { Text } from "../../Typography"
import { toastNotifications as toastText } from "../../../../locales/default.json"
import { manageContributors as text } from "../../../../locales/default.json"

const REVOKE_CONTRIBUTOR_INVITATION_MUTATION = gql`
  mutation revokeInvitation($id: UUID!) {
    revokeInvitation(id: $id) {
      success
      message
    }
  }
`

const RevokeContributorInviteModal = ({
  closeModal,
  email,
  contributorId,
  resourceType,
  showToast,
  resourceId,
  revokeContributorInvitationMutation,
}) => {
  const { showModal } = useContext(ModalContext)

  return (
    <Fragment>
      <ModalSecondaryHeader>
        <Heading>{text.revokeInvite}?</Heading>
        <CloseButton onClick={closeModal} />
      </ModalSecondaryHeader>
      <ModalBody>
        <ModalMessage>
          <Text>
            {resourceType === `SITE`
              ? text.revokeSiteInviteConf
              : text.revokeOrgInviteConf}
            <strong>
              {` `}
              {email}
            </strong>
            ?
          </Text>
        </ModalMessage>
        <ModalForm
          onSubmit={e => {
            e.preventDefault()
            revokeContributorInvitationMutation({
              variables: {
                id: contributorId,
              },
              update: cache => {
                const QUERY_TYPE =
                  resourceType === `SITE`
                    ? SITE_CONTRIBUTORS_QUERY
                    : ORG_CONTRIBUTORS_QUERY
                const contributorsFromCache = cache.readQuery({
                  query: QUERY_TYPE,
                  variables: {
                    id: resourceId,
                  },
                })

                if (resourceType === `SITE`) {
                  contributorsFromCache.contributorsBySite = contributorsFromCache.contributorsBySite.filter(
                    ({ id }) => id !== contributorId
                  )
                } else {
                  contributorsFromCache.contributorsByOrganization = contributorsFromCache.contributorsByOrganization.filter(
                    ({ id }) => id !== contributorId
                  )
                }

                cache.writeQuery({
                  query: QUERY_TYPE,
                  variables: {
                    id: resourceId,
                  },
                  data: contributorsFromCache,
                })
              },
            })
              .then(() => {
                closeModal()
                showToast(
                  `<strong>${toastText.contributorRevoke}</strong> ${
                    toastText.successfully
                  }`
                )
              })
              .catch(err =>
                showModal({
                  Component: DefaultError,
                  props: { errMsg: err.message },
                })
              )
          }}
        >
          <ModalActions>
            <CancelButton type="button" onClick={closeModal}>
              {text.cancel}
            </CancelButton>
            <PrimaryDeleteButton type="submit">
              {text.revokeInvite}
            </PrimaryDeleteButton>
          </ModalActions>
        </ModalForm>
      </ModalBody>
    </Fragment>
  )
}

RevokeContributorInviteModal.propTypes = {
  closeModal: PropTypes.func,
  email: PropTypes.string,
  siteId: PropTypes.string,
  resourceId: PropTypes.string,
}

export default graphql(REVOKE_CONTRIBUTOR_INVITATION_MUTATION, {
  name: `revokeContributorInvitationMutation`,
})(RevokeContributorInviteModal)
