import React from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "react-emotion"
import { graphql } from "react-apollo"
import gql from "graphql-tag"

import {
  MdArrowDropDown,
  MdDone,
  MdInfoOutline,
  MdArrowBack,
} from "react-icons/md"
import ErrorModal from "../shared/ErrorModal"
import {
  Menu,
  MenuList,
  MenuButton as BaseMenuButton,
  MenuLink,
} from "@reach/menu-button"
import { header as text } from "../../locales/default.json"
import {
  breakpoints,
  dimensions,
  fontSizes,
  fontFamilies,
  spaces,
  palette,
} from "../../utils/presets"

export const ORGANIZATIONS_INFO = gql`
  query OrganizationsInfo {
    currentOrganizations {
      id
      name
      permissions {
        organization {
          read
        }
      }
    }
  }
`

const menuButtonEntry = keyframes`
   to {
     opacity: 1;
   }
 `

const MenuButton = styled(BaseMenuButton)`
  align-items: center;
  animation: ${menuButtonEntry} 0.5s ease forwards;
  background: none;
  border: 0;
  color: ${palette.grey[900]};
  cursor: pointer;
  display: flex;
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${fontSizes.l};
  font-weight: bold;
  height: ${dimensions.siteHeader.height};
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  top: ${dimensions.siteHeader.height};
  width: 100%;
  white-space: nowrap;

  svg {
    flex-shrink: 0;
    height: ${spaces.m};
    width: ${spaces.m};
  }

  @media (min-width: ${breakpoints.tablet}px) {
    height: auto;
    width: auto;
    left: auto;
    margin-left: ${spaces.xl};
    position: relative;
    top: auto;
    width: auto;
  }
`

export const NoOrganizationsInfo = styled(`p`)`
  background: transparent;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${fontSizes.m};
  line-height: 1.3;
  padding: ${spaces.m};
  text-align: center;

  svg {
    height: 30px;
    margin-bottom: ${spaces.xs};
    width: 30px;
  }
`

const ActiveOrg = styled(`div`)`
  align-items: center;
  color: ${palette.grey[600]};
  display: flex;
  font-size: ${fontSizes.xs};
  padding: ${spaces.s} ${spaces.m};
  position: relative;

  svg {
    fill: ${palette.purple[500]};
    transform: scale(1.2);
    height: 1.1em;
    margin-right: ${spaces.xs};
    width: 1.1em;
  }
`

const MenuButtonLoading = MenuButton.withComponent(`span`)

const lookForOrganizationId = path => {
  const orgMatch =
    path.match(
      /\/organization\/(settings|sites)\/([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}).*$/i
    ) ||
    path.match(
      /\/pricing\/([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}).*$/i
    )
  return orgMatch
}

const getOrganizationName = ({ orgId, organizations }) => {
  const org = organizations.find(item => item.id === orgId)

  return org ? org.name : null
}

const filterOrganizationsForMember = organizations =>
  organizations.filter(org => {
    const {
      permissions: {
        organization: { read },
      },
    } = org

    return read
  })

const organizationMenuLinks = ({ organizations, orgId }) =>
  organizations.map(org =>
    orgId !== org.id ? (
      <MenuLink
        key={org.id}
        to={`/dashboard/organization/sites/${org.id}`}
        selected={orgId === org.id}
      >
        {org.name} {orgId === org.id ? `[x]` : ``}
      </MenuLink>
    ) : (
      <ActiveOrg key={org.id}>
        <MdDone /> {org.name}
      </ActiveOrg>
    )
  )

const OrgsMenu = ({ path, organizationInfoQuery }) => {
  const { error, loading, currentOrganizations } = organizationInfoQuery

  if (loading)
    return (
      <MenuButtonLoading data-testid="orgs-menu-loading">
        Loading...
      </MenuButtonLoading>
    )
  if (error) return <ErrorModal errMsg={error.message} />
  const organizations = filterOrganizationsForMember(currentOrganizations)

  const orgIdTest = lookForOrganizationId(path)
  const orgId = orgIdTest
    ? orgIdTest.find(org => !org.includes(`/`) && org.includes(`-`))
    : null
  const currentOrgName = orgId
    ? getOrganizationName({ organizations, orgId })
    : null

  return (
    <Menu data-testid="orgs-menu">
      {organizations && (
        <MenuButton data-testid="orgs-menu-button">
          {currentOrgName ? currentOrgName : text.allOrganizations}
          <MdArrowDropDown />
        </MenuButton>
      )}

      <MenuList className="orgsMenu" data-testid="orgs-menu-list">
        {organizations.length > 0
          ? currentOrgName
            ? [
                <MenuLink
                  key={`all`}
                  to={`/dashboard/sites`}
                  className="active"
                >
                  <MdArrowBack /> {text.allOrganizations}
                </MenuLink>,
                ...organizationMenuLinks({ organizations, orgId }),
              ]
            : organizationMenuLinks({ organizations, orgId })
          : [
              <MenuLink key="noOrganizations" to="/dashboard/sites/create">
                <NoOrganizationsInfo data-cy="no-orgs-message-dropdown">
                  <MdInfoOutline />
                  {text.youDontHaveOrg}
                </NoOrganizationsInfo>
              </MenuLink>,
            ]}
      </MenuList>
    </Menu>
  )
}

OrgsMenu.propTypes = {
  path: PropTypes.string.isRequired,
}

export default graphql(ORGANIZATIONS_INFO, {
  name: `organizationInfoQuery`,
})(OrgsMenu)
