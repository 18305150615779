import React from "react"
import styled from "react-emotion"
import PropTypes from "prop-types"
import { ApolloFragment } from "apollo-fragment-react"

import { breakpoints, spaces, palette } from "../../../../utils/presets"
import {
  billing as billingText,
  planInformation as planInformationText,
} from "../../../../locales/default.json"
import {
  SettingsCard,
  Header,
  Title as BaseTitle,
  Message,
} from "../../../shared/Settings/SettingsCard"
import { PLAN_FRAGMENT } from "../queries"

const DowngradeRoot = styled(SettingsCard)`
  margin-bottom: ${spaces.m};
  padding: 0;

  @media (min-width: ${breakpoints.desktop}px) {
    padding: 0;
    margin-bottom: ${spaces.l};
  }
`

const DowngradeContainer = styled(`section`)`
  padding: ${spaces.l} ${spaces.xl};

  @media (min-width: ${breakpoints.desktop}px) {
    padding: ${spaces.xl} ${spaces[`2xl`]};
  }

  &.success {
    background: ${palette.green[50]};
  }

  &.error {
    background: ${palette.red[50]};
  }
`

const Title = styled(BaseTitle)`
  color: ${palette.red[900]};
`

const Sales = styled(`a`)`
  text-decoration: none;
`

const DowngradePlan = ({ organizationId }) => (
  <DowngradeRoot>
    <ApolloFragment id={organizationId} fragment={PLAN_FRAGMENT}>
      {({ data }) => (
        <DowngradeContainer>
          <Header>
            <Title>{billingText.downgradePlan}</Title>
            {/* add modal when we support downgrading in product */}
            {/* {true && (
                  <SecondaryDeleteButton
                    onClick={() => {
                      // @TODO
                    }}
                  >
                    {billingText.downgradePlan} <MdArrowDownward />
                  </SecondaryDeleteButton>
                )} */}
          </Header>
          <Message>
            {billingText.downgradeAnytime}
            {` `}
            <strong>{billingText.losePreviewAccess}</strong>
          </Message>
          <Message>
            {billingText.contactToDowngrade}
            {planInformationText.contactUs.toLowerCase()}
            {` `}
            <Sales href="mailto:sales@gatsbyjs.com">sales@gatsbyjs.com</Sales>
          </Message>
          {/* restore when we support downgrading in product */}
          {/* <Message>
                {billingText.needToTalk}
                {` `}
                <Support href="mailto:support@gatsbyjs.com">
                  {planInformationText.contactUs}
                  {`.`}
                </Support>
                {` `}
                {billingText.loveToHelp}
              </Message> */}
        </DowngradeContainer>
      )}
    </ApolloFragment>
  </DowngradeRoot>
)

DowngradePlan.propTypes = {
  organizationId: PropTypes.string.isRequired,
}

export default DowngradePlan
