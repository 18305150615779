import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { graphql } from "react-apollo"
import { navigate } from "gatsby"

import OrgTabsNav from "./OrgTabsNav"
import Settings from "./Settings"
import Sites from "./Sites"
import Loading from "../shared/Loading"
import { ORG_DETAILS_QUERY } from "./queries"
import { setOrgEnvVars } from "../../utils/helpers/fullstory"

const OrganizationDetails = ({
  location: { pathname },
  organizationId,
  organizationDetailsQuery,
}) => {
  const { loading, error } = organizationDetailsQuery
  if (loading) {
    return <Loading delay={1000} message="loading your site details..." />
  }
  if (error) {
    navigate(`/dashboard/organization/sites/${organizationId}`)
    return null
  }
  const {
    organizationDetails: {
      name,
      permissions,
      billing: {
        status,
        trialDaysRemaining,
        plan: { name: planName },
      },
    },
  } = organizationDetailsQuery
  if (
    !permissions ||
    !permissions.organization ||
    !permissions.organization.read
  ) {
    navigate(`/dashboard/sites`)
    return null
  }
  // Call env vars API from fullstory
  setOrgEnvVars(name, planName, status, trialDaysRemaining, permissions.role)

  return (
    <Fragment>
      <OrgTabsNav pathname={pathname} organizationId={organizationId} />
      {pathname.includes(`settings`) ? (
        <Settings organizationId={organizationId} />
      ) : (
        <Sites organizationId={organizationId} />
      )}
    </Fragment>
  )
}

OrganizationDetails.propTypes = {
  location: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
}

export default graphql(ORG_DETAILS_QUERY, {
  name: `organizationDetailsQuery`,
  options: props => {
    return {
      name: `organizationDetails`,
      variables: { id: props.organizationId },
      fetchPolicy: `cache-and-network`,
    }
  },
})(OrganizationDetails)
