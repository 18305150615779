import React from "react"

export default () => (
  <svg
    width="227"
    height="19"
    viewBox="0 0 227 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.25">
      <circle
        cx="5.37118"
        cy="9.08511"
        r="4.83406"
        stroke="#8954A8"
        strokeWidth="1.07424"
      />
      <circle
        cx="117.629"
        cy="9.08483"
        r="4.83406"
        stroke="#8954A8"
        strokeWidth="1.07424"
      />
      <circle
        cx="11.8166"
        cy="9.08511"
        r="4.83406"
        stroke="#8954A8"
        strokeWidth="1.07424"
      />
      <circle
        cx="35.9869"
        cy="9.08511"
        r="4.83406"
        stroke="#8954A8"
        strokeWidth="1.07424"
      />
      <circle
        cx="62.8428"
        cy="9.0851"
        r="4.29694"
        stroke="#8954A8"
        strokeWidth="1.07424"
      />
      <circle
        cx="76.2707"
        cy="8.59389"
        r="2.14847"
        stroke="#8954A8"
        strokeWidth="2.14847"
      />
      <circle
        cx="96.9498"
        cy="9.35365"
        r="4.43122"
        stroke="#8954A8"
        strokeWidth="0.805677"
      />
      <path
        d="M34.4507 6.34006V11.7139H35.4981V9.72391H36.1749C36.7953 9.72391 37.2384 9.54666 37.5365 9.24856C37.9474 8.83766 37.9796 8.25757 37.9796 8.0481C37.9796 7.66137 37.8668 7.13768 37.4317 6.75096C37.037 6.40452 36.618 6.34006 36.0138 6.34006H34.4507ZM35.4981 7.24242H35.8607C36.1104 7.24242 36.3844 7.25854 36.6019 7.41161C36.755 7.52441 36.9 7.72583 36.9 8.02393C36.9 8.25757 36.8033 8.49928 36.61 8.65236C36.3844 8.82155 36.1266 8.82155 35.9251 8.82155H35.4981V7.24242Z"
        fill="#8954A8"
      />
      <line
        x1="7.51976"
        y1="6.93676"
        x2="9.66823"
        y2="6.93676"
        stroke="#8954A8"
        strokeWidth="0.537118"
      />
      <line
        x1="6.98264"
        y1="8.01101"
        x2="10.2054"
        y2="8.01101"
        stroke="#8954A8"
        strokeWidth="0.537118"
      />
      <line
        x1="6.98264"
        y1="9.08523"
        x2="10.2054"
        y2="9.08523"
        stroke="#8954A8"
        strokeWidth="0.537118"
      />
      <line
        x1="6.98264"
        y1="10.1595"
        x2="10.2054"
        y2="10.1595"
        stroke="#8954A8"
        strokeWidth="0.537118"
      />
      <line
        x1="7.51976"
        y1="11.2337"
        x2="9.66823"
        y2="11.2337"
        stroke="#8954A8"
        strokeWidth="0.537118"
      />
      <circle cx="26.8559" cy="8.54798" r="2.14847" fill="#8954A8" />
      <circle cx="92.6528" cy="9.35366" r="1.34279" fill="#8954A8" />
      <circle cx="101.247" cy="9.35366" r="1.34279" fill="#8954A8" />
      <circle cx="96.9498" cy="5.05672" r="1.34279" fill="#8954A8" />
      <circle cx="96.9498" cy="13.6506" r="1.34279" fill="#8954A8" />
      <circle cx="88.3558" cy="9.35374" r="0.537118" fill="#8954A8" />
      <circle cx="96.9497" cy="9.35374" r="0.537118" fill="#8954A8" />
      <circle cx="92.3842" cy="5.05677" r="0.537118" fill="#8954A8" />
      <circle cx="92.3842" cy="13.6507" r="0.537118" fill="#8954A8" />
      <line
        x1="27.9301"
        y1="8.548"
        x2="31.1528"
        y2="8.548"
        stroke="#8954A8"
        strokeWidth="1.07424"
      />
      <line
        x1="67.1397"
        y1="8.81655"
        x2="73.5852"
        y2="8.81655"
        stroke="#8954A8"
        strokeWidth="1.07424"
      />
      <circle
        cx="43.1189"
        cy="3.03833"
        r="2.14847"
        transform="rotate(135 43.1189 3.03833)"
        fill="#8954A8"
      />
      <circle cx="62.8372" cy="9.08512" r="2.28275" fill="#8954A8" />
      <line
        x1="42.3593"
        y1="3.79817"
        x2="40.0805"
        y2="6.07696"
        stroke="#8954A8"
        strokeWidth="1.07424"
      />
      <circle
        cx="42.929"
        cy="15.5821"
        r="2.14847"
        transform="rotate(-135 42.929 15.5821)"
        fill="#8954A8"
      />
      <line
        x1="42.1694"
        y1="14.8225"
        x2="39.8906"
        y2="12.5437"
        stroke="#8954A8"
        strokeWidth="1.07424"
      />
      <path
        d="M120.852 9.1311C120.852 7.35125 119.409 5.9084 117.629 5.9084L117.629 9.1311L120.852 9.1311Z"
        fill="#663399"
      />
      <path
        d="M117.629 5.9084C115.849 5.9084 114.406 7.35125 114.406 9.1311L117.629 9.1311L117.629 5.9084Z"
        fill="#8954A8"
      />
      <path
        d="M114.406 9.1311C114.406 10.911 115.849 12.3538 117.629 12.3538L117.629 9.1311L114.406 9.1311Z"
        fill="#B17ACC"
      />
      <circle
        cx="140.188"
        cy="6.98259"
        r="2.68559"
        stroke="#8954A8"
        strokeWidth="1.07424"
      />
      <circle cx="132.131" cy="8.59396" r="1.61135" fill="#8954A8" />
      <circle cx="143.948" cy="11.2795" r="1.07424" fill="#8954A8" />
      <circle cx="138.577" cy="13.9651" r="0.537118" fill="#8954A8" />
      <circle
        cx="135.891"
        cy="11.8167"
        r="1.34279"
        stroke="#8954A8"
        strokeWidth="1.61135"
      />
      <circle cx="158.987" cy="9.08517" r="5.37118" fill="#8954A8" />
      <circle cx="160.598" cy="8.05682" r="1.61135" fill="white" />
      <circle cx="157.51" cy="6.57975" r="0.671397" fill="white" />
      <circle
        cx="156.406"
        cy="12.3666"
        r="3.22271"
        transform="rotate(135 156.406 12.3666)"
        fill="white"
      />
      <circle
        cx="158.987"
        cy="9.08517"
        r="4.83406"
        stroke="#8954A8"
        strokeWidth="1.07424"
      />
      <circle
        cx="175.571"
        cy="5.22731"
        r="1.61135"
        transform="rotate(16.6025 175.571 5.22731)"
        fill="#8954A8"
      />
      <circle
        cx="182.153"
        cy="7.96728"
        r="1.07424"
        transform="rotate(16.6025 182.153 7.96728)"
        fill="#8954A8"
      />
      <circle
        cx="176.632"
        cy="12.0627"
        r="2.14847"
        transform="rotate(16.6025 176.632 12.0627)"
        fill="#8954A8"
      />
      <line
        x1="175.741"
        y1="5.12335"
        x2="182.186"
        y2="7.80894"
        stroke="#8954A8"
        strokeWidth="0.537118"
      />
      <line
        x1="176.158"
        y1="10.9178"
        x2="175.372"
        y2="5.40924"
        stroke="#8954A8"
        strokeWidth="0.537118"
      />
      <line
        x1="176.544"
        y1="12.1441"
        x2="181.915"
        y2="7.84711"
        stroke="#8954A8"
        strokeWidth="0.537118"
      />
      <circle
        cx="201.755"
        cy="9.35398"
        r="1.34279"
        transform="rotate(-180 201.755 9.35398)"
        fill="#8954A8"
      />
      <circle
        cx="195.31"
        cy="13.6509"
        r="1.34279"
        transform="rotate(-180 195.31 13.6509)"
        fill="#8954A8"
      />
      <circle
        cx="196.921"
        cy="9.30809"
        r="4.83406"
        transform="rotate(-180 196.921 9.30809)"
        stroke="#8954A8"
        strokeWidth="0.537118"
      />
      <circle
        cx="195.31"
        cy="5.05705"
        r="1.34279"
        transform="rotate(-180 195.31 5.05705)"
        fill="#8954A8"
      />
      <circle
        cx="192.02"
        cy="9.08541"
        r="0.805677"
        transform="rotate(-180 192.02 9.08541)"
        fill="#8954A8"
      />
      <circle
        cx="199.607"
        cy="13.3824"
        r="0.805677"
        transform="rotate(-180 199.607 13.3824)"
        fill="#8954A8"
      />
      <circle
        cx="199.607"
        cy="5.32558"
        r="0.805677"
        transform="rotate(-180 199.607 5.32558)"
        fill="#8954A8"
      />
      <path
        d="M199.002 8.6659C199.36 8.87264 199.36 9.38948 199.002 9.59622L196.182 11.2243C195.824 11.431 195.377 11.1726 195.377 10.7591L195.377 7.50301C195.377 7.08953 195.824 6.83111 196.182 7.03785L199.002 8.6659Z"
        fill="#8954A8"
      />
      <circle
        cx="220.755"
        cy="9.08465"
        r="4.83406"
        stroke="#8954A8"
        strokeWidth="1.07424"
      />
      <line
        x1="212.967"
        y1="6.71377"
        x2="215.116"
        y2="6.71377"
        stroke="#8954A8"
        strokeWidth="0.537118"
        strokeLinecap="round"
      />
      <line
        x1="211.893"
        y1="8.32513"
        x2="214.579"
        y2="8.32513"
        stroke="#8954A8"
        strokeWidth="0.537118"
        strokeLinecap="round"
      />
      <line
        x1="211.893"
        y1="9.93649"
        x2="214.579"
        y2="9.93649"
        stroke="#8954A8"
        strokeWidth="0.537118"
        strokeLinecap="round"
      />
      <line
        x1="212.967"
        y1="11.5478"
        x2="215.116"
        y2="11.5478"
        stroke="#8954A8"
        strokeWidth="0.537118"
        strokeLinecap="round"
      />
      <line
        x1="216.123"
        y1="8.39227"
        x2="225.388"
        y2="8.39227"
        stroke="#8954A8"
        strokeWidth="0.402838"
        strokeLinecap="round"
      />
      <line
        x1="216.123"
        y1="10.0036"
        x2="218.943"
        y2="10.0036"
        stroke="#8954A8"
        strokeWidth="0.402838"
        strokeLinecap="round"
      />
      <path
        d="M218.875 10.0036H221.561L222.635 8.92938"
        stroke="#8954A8"
        strokeWidth="0.402838"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M218.875 6.78093H221.561L222.635 7.85516"
        stroke="#8954A8"
        strokeWidth="0.402838"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="216.66"
        y1="11.615"
        x2="224.851"
        y2="11.615"
        stroke="#8954A8"
        strokeWidth="0.402838"
        strokeLinecap="round"
      />
      <circle cx="222.635" cy="8.45942" r="0.805677" fill="#8954A8" />
      <circle cx="221.561" cy="11.6821" r="0.805677" fill="#8954A8" />
      <circle cx="218.338" cy="10.0708" r="0.805677" fill="#8954A8" />
      <line
        x1="216.66"
        y1="6.78091"
        x2="221.091"
        y2="6.78091"
        stroke="#8954A8"
        strokeWidth="0.402838"
        strokeLinecap="round"
      />
      <circle cx="220.487" cy="6.84807" r="0.805677" fill="#8954A8" />
    </g>
  </svg>
)
