import React from "react"
import styled from "react-emotion"
import PropTypes from "prop-types"
import LazyImage from "react-lazy-progressive-image"

import { breakpoints, spaces } from "../../../utils/presets"
import AvatarFallback from "../../../assets/AvatarFallback.png"
import SmallAvatarFallback from "../../../assets/SmallAvatarFallback.png"

const Avatar = styled(`img`)`
  border-radius: 50%;
  cursor: pointer;
  height: 32px;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  max-width: 32px;
  @media (min-width: ${breakpoints.desktop}px) {
    margin-right: ${spaces.m};
  }
`

const ContributorAvatar = ({ contributor }) => (
  <LazyImage
    src={contributor.avatarUrl ? contributor.avatarUrl : AvatarFallback}
    placeholder={
      contributor.avatarUrl
        ? `${contributor.avatarUrl}&s=12`
        : SmallAvatarFallback
    }
  >
    {src => (
      <Avatar
        src={src}
        alt={
          contributor.avatarUrl
            ? `${contributor.name} avatar`
            : `Default user avatar`
        }
      />
    )}
  </LazyImage>
)

Avatar.propTypes = {
  contributor: PropTypes.object,
}

export default ContributorAvatar
