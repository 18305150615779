import React from "react"
import styled from "react-emotion"

import { MdClose } from "react-icons/md"

import { palette, spaces } from "../../utils/presets"

const CloseButtonRoot = styled(`button`)`
  aling-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: ${spaces[`3xl`]};
  justify-content: center;
  position: absolute;
  top: ${spaces.m};
  right: ${spaces.m};
  width: ${spaces[`3xl`]}};

  svg {
    color: ${palette.grey[500]};
    height: ${spaces.xl};
    width: ${spaces.xl};
  }
`

export const CloseButton = props => (
  <CloseButtonRoot {...props}>
    <MdClose />
  </CloseButtonRoot>
)
