import React, { Fragment, useContext } from "react"
import PropTypes from "prop-types"
import { graphql } from "react-apollo"

import { ModalContext } from "../../Modal"
import { ToastContext } from "../../shared/Toast"
import { DefaultError } from "../../shared/ErrorModal"
import { PaymentProvider, CheckoutForm } from "../../Payment"
import { paymentInformation as text } from "../../../locales/default.json"
import { UPDATE_PAYMENT_INFO } from "../../Payment/mutations"

let CheckoutFormWithMutation = CheckoutForm

if (graphql) {
  CheckoutFormWithMutation = graphql(UPDATE_PAYMENT_INFO)(
    CheckoutFormWithMutation
  )
}

export const UpdatePaymentForm = ({
  name,
  organizationId,
  id,
  setResponse,
  resourceType = `UPDATE`,
  cancelCallback,
  className,
}) => {
  const { showModal } = useContext(ModalContext)
  const { showToast } = useContext(ToastContext)

  return (
    <Fragment>
      <PaymentProvider>
        <CheckoutFormWithMutation
          checkoutType="UPDATE_PAYMENT"
          cancelCallback={cancelCallback}
          submitButtonText={text.updatePayment}
          cancelButtonText={text.cancelText}
          planId={id}
          organizationId={organizationId}
          className={className}
          showSuccess={creditCard => {
            setResponse({
              success: true,
              creditCard,
            })
            showToast(`<strong>${text.paymentInformation}</strong>
                ${text.updatedSuccessfully}
              `)
            cancelCallback()
          }}
          showError={err => {
            showModal({
              Component: DefaultError,
              props: { errMsg: err },
            })
          }}
        />
      </PaymentProvider>
    </Fragment>
  )
}

UpdatePaymentForm.propTypes = {
  name: PropTypes.string,
  organizationId: PropTypes.string,
  planId: PropTypes.string,
  setMessage: PropTypes.func,
  cancelCallback: PropTypes.func,
}
