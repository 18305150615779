import React from "react"
import styled from "react-emotion"
import PropTypes from "prop-types"

import { breakpoints, fontSizes, palette } from "../../../utils/presets"
import { Text } from "../Typography"
import ContributorManage from "../ContributorManage"
import { orgMembers as text } from "../../../locales/default.json"
import ContributorAvatar from "./ContributorAvatar"

const ContributorRow = styled(`li`)`
  display: flex;
  align-items: center;
  margin-bottom: 0.375rem;
`

const ContributorRoot = styled(Text)`
  margin: 0;
  overflow: hidden;
  padding-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ContributorName = styled(ContributorRoot)`
  color: ${palette.black};
  display: block;
  width: 50%;
  @media (min-width: ${breakpoints.desktop}px) {
    padding-left: 0;
    width: 25%;
  }
`

const ContributorEmail = styled(ContributorRoot)`
  color: ${palette.grey[500]};
  display: none;
  font-size: ${fontSizes.xs};
  width: 25%;
  @media (min-width: ${breakpoints.desktop}px) {
    display: block;
  }
`

const ContributorRole = styled(ContributorRoot)`
  color: ${palette.grey[500]};
  display: flex;
  font-size: ${fontSizes[`2xs`]};
  justify-content: flex-end;
  width: 25%;
  @media (min-width: ${breakpoints.desktop}px) {
    justify-content: flex-end;
    width: 25%;
  }
`

const ContributorAction = styled(`div`)`
  display: flex;
  justify-content: flex-end;
  visibility: visible;
  width: 25%;
  &.hidden {
    visibility: hidden;
  }
  @media (min-width: ${breakpoints.tablet}px) {
    width: 25%;
  }
  button {
    background: none;
    border: none;
  }
`

const ContributorList = styled(`ul`)`
  margin: 0;
  padding: 0;
`

const ContributorDetails = ({
  contributors,
  showModal,
  resourceId,
  resourceType,
  permissionToDelete,
  permissionToInvite,
  showToast,
}) => (
  <ContributorList>
    {contributors.map(contributor => (
      <ContributorRow key={contributor.email}>
        <ContributorAvatar contributor={contributor} />
        <ContributorName data-cy={`contributor-` + contributor.email + `-name`}>
          {contributor.name ? (
            contributor.name
          ) : (
            <i>{text.pendingInvitation}</i>
          )}
        </ContributorName>
        <ContributorEmail
          data-cy={`contributor-` + contributor.email + `-email`}
        >
          {contributor.email}
        </ContributorEmail>
        <ContributorRole data-cy={`contributor-` + contributor.email + `-role`}>
          {contributor.contributorRole}
        </ContributorRole>
        {(permissionToDelete || permissionToInvite) && (
          <ContributorAction
            className={contributor.contributorRole === `OWNER` ? `hidden` : ``}
          >
            <ContributorManage
              contributor={contributor}
              showModal={showModal}
              resourceId={resourceId}
              resourceType={resourceType}
              permissionToInvite={permissionToInvite}
              permissionToDelete={permissionToDelete}
              showToast={showToast}
            />
          </ContributorAction>
        )}
      </ContributorRow>
    ))}
  </ContributorList>
)

ContributorDetails.propTypes = {
  contributors: PropTypes.array.isRequired,
  permissionToInvite: PropTypes.bool,
  permissionToDelete: PropTypes.bool,
}

export default ContributorDetails
