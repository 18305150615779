export const PERMISSIONS_SITE_FRAGMENT = `
    fragment sitePermissionsFragment on PermissionsDigest {
        role
        sites {
            read
            create
            edit
            delete
        }
        contributor {
            read
            invite
            delete
            deleteSelf
        }
    }
`

export const SITES_WITH_PERMISSIONS_FRAGMENT = `
    fragment sitesWithPermissions on Site {
        id
        permissions {
            ...sitePermissionsFragment
        }
    }
    ${PERMISSIONS_SITE_FRAGMENT}
`

export const PERMISSIONS_ORGANIZATION_FRAGMENT = `
fragment orgPermissionsFragment on PermissionsDigest {
    role
    billing {
        read
        create
        edit
      }
    organization {
        create
        read
        delete
        edit
    }
    sites {
        edit
        read
        delete
        create
    }
    contributor {
        read
        invite
        delete
        deleteSelf
    }
}
`

export const ORGANIZATION_WITH_PERMISSIONS_FRAGMENT = `
    fragment organizationWithPermissions on Organization {
        id
        permissions {
            ...orgPermissionsFragment
        }
    }
    ${PERMISSIONS_ORGANIZATION_FRAGMENT}
`
