import { Link } from "gatsby"
import styled from "react-emotion"
import {
  breakpoints,
  palette,
  spaces,
  fontSizes,
  fontFamilies,
  colors,
} from "../../../utils/presets"
import { Heading } from "../Typography"

export const OrgType = styled(`header`)`
  color: ${palette.grey[600]};
  font-size: ${fontSizes[`xs`]};
  letter-spacing: 0.5px;
  position: relative;

  :before {
    border-top: solid 1px ${palette.grey[400]};
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 0.6rem;
    width: 100%;
  }

  span {
    background: ${colors.secondaryBackground};
    padding: 0 10px 0 0;
    position: relative;
  }
`

export const OrgSection = styled(`section`)`
  margin-top: ${spaces[`2xl`]};
  :first-of-type {
    margin-top: 0;
  }
`

export const OrgInfo = styled(`div`)`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${spaces.xl};

  &:first-of-type {
    margin-top: ${spaces.l};
  }
`
export const OrgName = styled(Heading)`
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${fontSizes.l};
  line-height: normal;
  margin: ${spaces[`xs`]} ${spaces[`xs`]} ${spaces.m} 0;
  width: 100%;

  @media (min-width: ${breakpoints.desktop}px) {
    width: auto;
    margin-bottom: ${spaces.xs};
  }
`

export const OrgDetailsWrapper = styled(`div`)`
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
`

export const OrgSettings = styled(Link)`
  align-items: center;
  color: ${palette.grey[600]};
  display: flex;
  flex-grow: 1;
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${fontSizes.s};
  font-weight: normal;
  padding-right: ${spaces.s};
  text-decoration: none;

  :hover {
    color: ${palette.grey[400]};
    text-decoration: underline;
  }

  > svg {
    margin: 0 0 0 ${spaces[`2xs`]};
  }
`
