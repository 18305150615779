import React from "react"

export default ({ className }) => (
  <svg
    className={className}
    width="174"
    height="108"
    viewBox="0 0 174 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <circle
        cx="110.667"
        cy="63.3334"
        r="7.33398"
        transform="rotate(-180 110.667 63.3334)"
        fill="#FFDF37"
      />
      <ellipse
        cx="91.9993"
        cy="81.9996"
        rx="7.33398"
        ry="7.33398"
        transform="rotate(-180 91.9993 81.9996)"
        fill="#BC027F"
      />
      <circle
        cx="166.667"
        cy="82.0004"
        r="7.33321"
        transform="rotate(-180 166.667 82.0004)"
        fill="#159BF3"
      />
      <ellipse
        cx="110.668"
        cy="100.667"
        rx="7.33321"
        ry="7.33321"
        transform="rotate(-180 110.668 100.667)"
        fill="#B17ACC"
      />
      <ellipse
        cx="92.0007"
        cy="25.9994"
        rx="7.33398"
        ry="7.33398"
        transform="rotate(-180 92.0007 25.9994)"
        fill="#59C156"
      />
      <ellipse
        cx="110.667"
        cy="44.667"
        rx="7.33398"
        ry="7.33397"
        transform="rotate(-180 110.667 44.667)"
        fill="#FB8400"
      />
      <ellipse
        cx="92.0007"
        cy="44.667"
        rx="7.33398"
        ry="7.33397"
        transform="rotate(-180 92.0007 44.667)"
        fill="#663399"
      />
      <ellipse
        cx="148.001"
        cy="44.6678"
        rx="7.33321"
        ry="7.3332"
        transform="rotate(-180 148.001 44.6678)"
        fill="#05F7F4"
      />
      <circle
        cx="128.667"
        cy="63.3326"
        r="7.33398"
        transform="rotate(-180 128.667 63.3326)"
        fill="#FFD280"
      />
      <circle
        r="7.33398"
        transform="matrix(-1 0 0 1 17.3337 44.6666)"
        fill="#FFDF37"
      />
      <ellipse
        rx="7.33397"
        ry="7.33397"
        transform="matrix(-1 0 0 1 -1.33275 26.0004)"
        fill="#00BDB6"
      />
      <ellipse
        rx="7.33321"
        ry="7.33321"
        transform="matrix(-1 0 0 1 73.3335 25.9996)"
        fill="#159BF3"
      />
      <ellipse
        rx="7.33321"
        ry="7.33321"
        transform="matrix(-1 0 0 1 17.3345 7.33321)"
        fill="#F2C4E3"
      />
      <ellipse
        rx="7.33397"
        ry="7.33398"
        transform="matrix(-1 0 0 1 -1.33275 81.9994)"
        fill="#FF5A54"
      />
      <circle
        r="7.33398"
        transform="matrix(-1 0 0 1 35.9993 63.333)"
        fill="#A1DA9E"
      />
      <ellipse
        rx="7.33397"
        ry="7.33398"
        transform="matrix(-1 0 0 1 -1.33275 63.333)"
        fill="#CCFFFC"
      />
      <ellipse
        rx="7.3332"
        ry="7.33321"
        transform="matrix(-1 0 0 1 54.667 63.3322)"
        fill="#159BF3"
      />
      <circle
        r="7.33398"
        transform="matrix(-1 0 0 1 35.9993 44.6666)"
        fill="#FB8400"
      />
    </g>
  </svg>
)
