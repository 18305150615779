import gql from "graphql-tag"
import { CREDIT_CARD_FRAGMENT, BILLING_PLAN_FRAGMENT } from "./fragments"

// type SubscriptionCreateInput { token: ID!, planId:
// UUID!, organizationID: UUID!, companyName: String }
export const SUBSCRIBE_TO_PLAN = gql`
  mutation subscribeToPlan($input: SubscriptionCreateInput!) {
    subscribeToPlan(input: $input) {
      token
      errors {
        message
      }
      subscription {
        id
        creditCard {
          ...creditCardFragment
        }
        plan {
          ...billingPlanFragment
        }
        nextBillingDate
      }
    }
  }
  ${BILLING_PLAN_FRAGMENT}
  ${CREDIT_CARD_FRAGMENT}
`

export const UPDATE_PAYMENT_INFO = gql`
  mutation updatePaymentInfo(
    $organizationId: UUID!
    $token: String!
    $customerName: String!
    $billingEmail: String
  ) {
    updatePaymentInformation(
      organizationId: $organizationId
      token: $token
      customerName: $customerName
      billingEmail: $billingEmail
    ) {
      errors {
        errorType
        code
        message
        declineCode
      }
      creditCard {
        ...creditCardFragment
      }
    }
  }

  ${CREDIT_CARD_FRAGMENT}
`
