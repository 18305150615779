import { ApolloClient } from "apollo-client"
import { InMemoryCache } from "apollo-cache-inmemory"
import {
  fragmentCacheRedirect,
  fragmentLinkState,
} from "apollo-link-state-fragment"
import apolloLogger from "apollo-link-logger"
import { BatchHttpLink } from "apollo-link-batch-http"
import { onError } from "apollo-link-error"
import { ApolloLink, split } from "apollo-link"
import { WebSocketLink } from "apollo-link-ws"
import { getMainDefinition } from "apollo-utilities"

/* set up apollo graphql client */
const graphqlEndpoint = (useWebSocket = false) => {
  let httpProtocol
  let wssProtocol

  if (process.env.GATSBY_ENV === `development`) {
    httpProtocol = `http`
    wssProtocol = `ws`
  } else if (process.env.NODE_ENV === `production`) {
    httpProtocol = `https`
    wssProtocol = `wss`
  } else {
    httpProtocol = `http`
    wssProtocol = `ws`
  }

  return useWebSocket
    ? `${wssProtocol}://${
        process.env.GATSBY_DASHBOARD_GRAPHQL_SUBSCRIPTIONS_URL
      }/subscriptions`
    : `${httpProtocol}://${process.env.GATSBY_DASHBOARD_GRAPHQL_URL}`
}

const cache = new InMemoryCache({
  cacheRedirects: {
    Query: {
      ...fragmentCacheRedirect(),
    },
  },
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

/* create apollo client */
export default function createApolloClient() {
  let headers = {}
  const authToken = localStorage.getItem(`gatsby:token`)

  if (authToken) {
    headers = {
      Authorization: `Bearer ${authToken}`,
    }
  }

  const batchHttpLink = new BatchHttpLink({
    uri: graphqlEndpoint(),
    headers,
  })

  const websocketLink = new WebSocketLink({
    uri: graphqlEndpoint(true),
    options: {
      reconnect: !process.env.GATSBY_MOCK_MODE,
    },
  })

  let links = [errorLink, fragmentLinkState(cache)]

  if (process.env.NODE_ENV === `development`) {
    links = [apolloLogger, ...links]
  }

  let transportLink = ApolloLink.from(links)

  if (process.env.GATSBY_ENABLE_SUBSCRIPTIONS) {
    transportLink = split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query)
        return kind === `OperationDefinition` && operation === `subscription`
      },
      websocketLink,
      batchHttpLink
    )
  } else {
    transportLink = batchHttpLink
  }

  const link = ApolloLink.from([...links, transportLink])

  return new ApolloClient({
    cache,
    link,
  })
}

export const apolloCache = cache
