import styled, { keyframes } from "react-emotion"

import { palette, spaces, fontSizes } from "../../utils/presets"

const varEntry = keyframes`
   to {
     opacity: 1;
     transform: translateY(0);
   }
 `

export const Table = styled(`table`)`
  animation: ${varEntry} 0.5s ease forwards;
  margin: ${spaces.m} 0 0 0;
  opacity: 0;
  transform: translateY(${spaces.s});
  width: 100%;
`

export const TableBody = styled(`tbody`)``

export const TableRow = styled(`tr`)``

export const TableHeader = styled(`th`)`
  color: ${palette.grey[700]};
  font-size: ${fontSizes[`2xs`]};
  font-weight: normal;
  padding: ${spaces.xs} 0 ${spaces.xs} ${spaces.xs} !important;
`
