import React from "react"

import { colors } from "../utils/presets"

export default () => (
  <svg preserveAspectRatio="xMinYMin meet" viewBox="0 0 28 28">
    <g>
      <path
        className="st0"
        fill="transparent"
        d="M25 14h-7v2h4.8a9 9 0 0 1-5.8 6.5L5.5 11A9 9 0 0 1 14 5c3 0 5.7 1.5 7.4 3.8l1.5-1.3a11 11 0 0 0-19.6 4.1l13.2 13.2C21.3 23.6 25 19.2 25 14zM3 14.1a10.9 10.9 0 0 0 10.8 10.8L3 14.1z"
      />
      <path
        className="st1"
        fill={colors.gatsby}
        d="M14 0C6.3 0 0 6.3 0 14s6.3 14 14 14 14-6.3 14-14S21.7 0 14 0zM6.2 21.8A10.9 10.9 0 0 1 3 14.2L13.9 25c-2.8-.1-5.6-1.1-7.7-3.2zm10.2 2.9L3.3 11.6a11 11 0 0 1 19.6-4.1l-1.5 1.3A9.2 9.2 0 0 0 14 5a9.1 9.1 0 0 0-8.5 6L17 22.5a9 9 0 0 0 5.8-6.5H18v-2h7a11 11 0 0 1-8.6 10.7z"
      />
    </g>
  </svg>
)
