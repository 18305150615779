import React from "react"
import styled from "react-emotion"
import { PropTypes } from "prop-types"
import { MdCheck } from "react-icons/md"

import { palette, fontSizes } from "../../../utils/presets"

const PlanRadioRoot = styled(`div`)`
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
`

const Checkmark = styled(`label`)`
  align-items: center;
  background: ${palette.grey[100]};
  border: 2px solid ${palette.grey[100]};
  border-radius: 50%;
  cursor: ${props =>
    props.checked || props.enableCursor ? `pointer` : `not-allowed`};
  display: flex;
  height: ${fontSizes.xl};
  left: 0;
  position: absolute;
  width: ${fontSizes.xl};
`

const Option = styled(`input`)`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  :checked + label {
    background: ${palette.purple[500]};
    border: 1px solid ${palette.purple[500]};
  }

  :focus + label {
    border-color: ${palette.purple[400]};
    box-shadow: 0 0 0 3px ${palette.purple[200]};
  }
`

const Icon = styled(MdCheck)`
  stroke: ${palette.white};
  margin-left: 4px;
  position: absolute;
  stroke-width: 2px;
`

const PlanRadio = ({
  disabled,
  checked,
  readOnly = true,
  id = `plan options`,
  enableCursor = false,
}) => (
  <PlanRadioRoot>
    <Option
      type="radio"
      readOnly={readOnly}
      checked={checked}
      disabled={disabled}
      id={id}
      aria-checked={checked}
      aria-label={id}
    />
    <Checkmark
      checked={checked}
      htmlFor={id}
      aria-label={id}
      enableCursor={enableCursor}
    >
      {/* Adding below for accessibility, labels cannot be empty. Is there another way around this? */}
      <p style={{ visibility: `hidden` }}>hide</p>
      {checked && <Icon />}
    </Checkmark>
  </PlanRadioRoot>
)

PlanRadio.propTypes = {
  checked: PropTypes.bool,
}

export default PlanRadio
