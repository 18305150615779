import React from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"

import { MdArrowDropDown, MdDone } from "react-icons/md"
import {
  Menu,
  MenuList,
  MenuButton as BaseMenuButton,
  MenuItem,
} from "@reach/menu-button"
import { fontSizes, palette, radius, spaces } from "../../../utils/presets"
import { branchList as text } from "../../../locales/default.json"
import { Text } from "../Typography"
import { Search } from "../Forms"
import { useTracker } from "../../../analytics"

const MenuButton = styled(BaseMenuButton)`
  align-items: center;
  background: ${palette.white};
  border: 1px solid ${palette.grey[300]};
  border-radius: ${radius.default};
  color: ${palette.grey[900]};
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-size: ${fontSizes.s};
  height: 2.25rem;
  justify-content: space-between;
  list-style: none;
  margin-left: 0;
  position: relative;
  text-decoration: none;
  width: 14rem;

  :focus {
    border-color: ${palette.purple[400]};
    box-shadow: 0 0 0 3px ${palette.purple[200]};
    outline: 0;
    transition: box-shadow 0.15s ease-in-out;
  }

  :disabled {
    cursor: not-allowed;
  }
`

const MenuSearch = styled(`div`)`
  padding: ${spaces.s} ${spaces.m};
`

const MenuLoading = styled(MenuSearch)`
  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
`

const NoResults = styled(Text)`
  margin: 0;
  padding: ${spaces.s} ${spaces.m};
`

const BranchName = styled(Text)`
  margin: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SVGContainer = styled(`div`)`
  width: 1rem;
  height: 1rem;
`

const BranchList = ({
  selectedBranch,
  branches,
  input,
  loadingCondition,
  loadingComponent,
  setInput,
  onScroll,
  updateBranch,
  disabled,
  siteId,
  uiSource,
}) => {
  const { trackButtonClicked } = useTracker()
  const listItems = loadingCondition ? (
    [<MenuLoading key="loading">{loadingComponent}</MenuLoading>]
  ) : branches.length === 0 ? (
    <NoResults>{text.zeroResults}</NoResults>
  ) : (
    branches.map(branch =>
      selectedBranch !== branch ? (
        <MenuItem
          data-cy="branch-list-item"
          style={{ width: `100%` }}
          key={branch}
          onSelect={() => {
            trackButtonClicked(`Branch list`, {
              siteId,
              uiSource,
            })
            updateBranch(branch)
          }}
        >
          {branch}
        </MenuItem>
      ) : (
        <MenuItem key={branch} className="active" onSelect={() => {}}>
          <MdDone /> {branch}
        </MenuItem>
      )
    )
  )

  return (
    <Menu>
      <MenuButton disabled={disabled} data-cy="branch-list-button">
        <BranchName>{selectedBranch}</BranchName>
        <SVGContainer>
          <MdArrowDropDown />
        </SVGContainer>
      </MenuButton>
      <MenuList
        className="branchListMenu"
        onScroll={onScroll}
        data-cy="branch-list"
      >
        {[
          <MenuSearch key="search">
            <Search
              data-cy="branch-list-search"
              aria-label="Find a branch..."
              value={input}
              placeholder="Find a branch..."
              onChange={e => {
                setInput(e.target.value)
              }}
            />
          </MenuSearch>,
          ...listItems,
        ]}
      </MenuList>
    </Menu>
  )
}

BranchList.propTypes = {
  siteId: PropTypes.string,
  siteBranch: PropTypes.string,
  selectedBranch: PropTypes.string,
  branches: PropTypes.array,
  input: PropTypes.string,
  loadingCondition: PropTypes.bool,
  setInput: PropTypes.func,
  onScroll: PropTypes.func,
  updateBranch: PropTypes.func,
}

export default BranchList
