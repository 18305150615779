import React from "react"
import { StripeProvider, Elements } from "react-stripe-elements"
import PropTypes from "prop-types"

export const PaymentProvider = ({ children }) => (
  <StripeProvider apiKey={process.env.GATSBY_STRIPE_KEY}>
    <Elements>{children}</Elements>
  </StripeProvider>
)

PaymentProvider.propTypes = {
  children: PropTypes.node,
}
