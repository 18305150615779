import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "react-emotion"
import Monogram from "../../assets/Monogram"
import { SiteHeading } from "../shared/Typography"
import UserMenu from "./UserMenu"
import OrgsMenu from "./OrgsMenu"
import { StandardSingleColumn } from "../shared/Containers"
import {
  colors,
  dimensions,
  palette,
  fontSizes,
  spaces,
  breakpoints,
} from "../../utils/presets"
import { header as text } from "../../locales/default.json"
import VisuallyHidden from "../shared/VisuallyHidden"

const HeaderRoot = styled(`header`)`
  background: ${colors.primaryBackground};
  height: calc(${dimensions.siteHeader.height} * 2);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;

  .dashboardGreyBg & {
    background: ${colors.secondaryBackground};
  }

  @media (min-width: ${breakpoints.tablet}px) {
    height: ${dimensions.siteHeader.height};
  }
`

const HeaderNav = styled(StandardSingleColumn.withComponent(`nav`))`
  align-items: center;
  display: flex;
  height: ${dimensions.siteHeader.height};
  justify-content: flex-start;
  margin-bottom: ${spaces.l};
`

const HeaderTitle = styled(SiteHeading)`
  color: ${palette.black};
  font-size: ${fontSizes.l};
  margin-left: ${spaces[`m`]};
`

export const HeaderLogoLink = styled(Link)`
  align-items: center;
  display: flex;
  text-decoration: none;

  svg {
    height: ${fontSizes.xl};
    width: ${fontSizes.xl};
  }
`

export const Header = ({ pathname }) => {
  const welcomeFormScreen = pathname.includes(`dashboard/welcome`)

  return (
    <HeaderRoot>
      <HeaderNav aria-label="Header navigation">
        <HeaderLogoLink to="/dashboard/sites" data-cy="header-logo-link">
          <Monogram alt="Gatsby" />
          <HeaderTitle>
            <VisuallyHidden>Gatsby</VisuallyHidden> {text.title}
          </HeaderTitle>
        </HeaderLogoLink>
        {!welcomeFormScreen && <OrgsMenu path={pathname} />}
        <UserMenu />
      </HeaderNav>
    </HeaderRoot>
  )
}

Header.propTypes = {
  pathname: PropTypes.string.isRequired,
}
