import React from "react"
import PropTypes from "prop-types"

export default function NoSites({ className }) {
  return (
    <svg
      width="270"
      height="193"
      viewBox="0 0 270 193"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity="0.25"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.9978 25c-1.106 0-2.0022-.8952-2.0022-2s.8962-2 2.0022-2c1.106 0 2.0021.8952 2.0021 2s-.8961 2-2.0021 2z"
        fill="#3FA9F5"
      />
      <circle cx="135" cy="84" r="84" fill="#F6EDFA" />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.0002 64c-1.1049 0-2.0007.8952-2.0014 2-.0006 1.1048.8942 2 1.9991 2 1.1049 0 2.0007-.8952 2.0014-2 .0006-1.1048-.8942-2-1.9991-2z"
        fill="#FFB238"
      />
      <path d="M94.9998 32H163v4H94.9998v-4z" fill="#F1DEFA" />
      <path d="M94.9998 110V32H163l12 12v66" fill="#fff" />
      <path
        d="M94.9998 110V32H163l12 12v66"
        stroke="#8954A8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163 32v8c0 4 4 4 4 4h8"
        stroke="#8954A8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.9998 104h-6s-4 0-4-4v-8s0-4 4-4h6v16zM187 88h6s4 0 4 4v8s0 4-4 4h-6V88z"
        fill="#F7FFFF"
        stroke="#8954A8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.9998 92h-6"
        stroke="#8954A8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="106" y="40" width="36" height="4" rx="2" fill="#A1DA9E" />
      <rect
        opacity="0.25"
        x="106"
        y="48"
        width="52"
        height="4"
        rx="2"
        fill="#FFDF37"
      />
      <rect
        opacity="0.25"
        x="106"
        y="80"
        width="52"
        height="4"
        rx="2"
        fill="#FFB238"
      />
      <rect
        opacity="0.25"
        x="106"
        y="88"
        width="36"
        height="4"
        rx="2"
        fill="#BC027F"
      />
      <rect x="106" y="96" width="36" height="4" rx="2" fill="#F1DEFA" />
      <rect x="106" y="104" width="36" height="4" rx="2" fill="#F6EDFA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.011 96c1.106 0 2.002.8952 2.002 2s-.896 2-2.002 2c-1.106 0-2.002-.8952-2.002-2s.896-2 2.002-2z"
        fill="#EC1818"
      />
      <path
        d="M82.9998 96h-6M82.9998 100h-6M193 92h-6M193 96h-6M193 100h-6"
        stroke="#8954A8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.615 80c0 17.9202 14.54 27.571 30.753 27.571C152.58 107.571 165 95.7859 165 80h20c3.854 0 4 4 4 4v32H80.9998V84s0-4 4-4H105"
        fill="#fff"
      />
      <path
        d="M105.615 80c0 17.9202 14.54 27.571 30.753 27.571C152.58 107.571 165 95.7859 165 80h20c3.854 0 4 4 4 4v32H80.9998V84s0-4 4-4H105"
        stroke="#8954A8"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M189 116v45.999C189 176 177 176 177 176H94.9998s-14 0-14-14.001V116H189z"
        stroke="#8954A8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.9998 132c2.2091 0 4-1.791 4-4s-1.7909-4-4-4c-2.2092 0-4 1.791-4 4s1.7908 4 4 4z"
        fill="#fff"
        stroke="#3FA9F5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105 132c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4zM117 132c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4zM113 168c-2.209 0-4-1.791-4-4s1.791-4 4-4h44c2.209 0 4 1.791 4 4s-1.791 4-4 4h-44z"
        fill="#fff"
        stroke="#8954A8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129 132c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4z"
        fill="#FFDF37"
        stroke="#FFDF37"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M141 132c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4zM153 132c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4z"
        fill="#fff"
        stroke="#8954A8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165 132c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4z"
        fill="#fff"
        stroke="#BC027F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177 132c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4zM98.9998 144c2.2092 0 4.0002-1.791 4.0002-4s-1.791-4-4.0002-4c-2.2092 0-4 1.791-4 4s1.7908 4 4 4z"
        fill="#fff"
        stroke="#8954A8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111 144c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4z"
        fill="#FFB238"
        stroke="#FFB238"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123 144c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4z"
        fill="#BC027F"
        stroke="#BC027F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135 144c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4z"
        fill="#fff"
        stroke="#8954A8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M147 144c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4z"
        fill="#73FFF7"
        stroke="#73FFF7"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159 144c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4z"
        fill="#fff"
        stroke="#8954A8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M171 144c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4z"
        fill="#fff"
        stroke="#37B635"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105 156c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4z"
        fill="#fff"
        stroke="#8954A8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117 156c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4z"
        fill="#37B635"
        stroke="#37B635"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129 156c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4z"
        fill="#fff"
        stroke="#8954A8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M141 156c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4z"
        fill="#fff"
        stroke="#FFB238"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153 156c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4z"
        fill="#fff"
        stroke="#8954A8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165 156c2.209 0 4-1.791 4-4s-1.791-4-4-4-4 1.791-4 4 1.791 4 4 4z"
        fill="#FFE6F6"
        stroke="#FFE6F6"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112 68c0-3.866 3.134-7 7-7s7 3.134 7 7M146 68c0-3.866 3.134-7 7-7s7 3.134 7 7"
        stroke="#8954A8"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62 50c-2.2096 0-4-1.7904-4-4s1.7904-4 4-4 4 1.7904 4 4-1.7904 4-4 4z"
        fill="#73FFF7"
      />
      <path
        opacity="0.125"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.019 28c1.106 0 2.002.8952 2.002 2s-.896 2-2.002 2c-1.106 0-2.002-.8952-2.002-2s.896-2 2.002-2z"
        fill="#BC027F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M252 125c-2.21 0-3.999-1.79-3.998-4 .002-2.21 1.793-4 4.003-4 2.209 0 3.999 1.79 3.997 4-.001 2.21-1.792 4-4.002 4"
        fill="#FFDF37"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M267.998 146c1.106 0 2.002-.895 2.002-2s-.896-2-2.002-2c-1.106 0-2.002.895-2.002 2s.896 2 2.002 2z"
        fill="#FFB238"
      />
      <path
        opacity="0.5"
        d="M199 167c0 11.046-8.954 20-20 20"
        stroke="#FFDF37"
        strokeWidth="12"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

NoSites.propTypes = {
  className: PropTypes.string || PropTypes.object,
}
