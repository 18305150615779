import React, { useEffect } from "react"
import gql from "graphql-tag"
import styled from "react-emotion"
import { graphql } from "react-apollo"
import { format } from "date-fns"
import { CloseButton } from "../../Modal"
import Loading from "../../shared/Loading"
import useSubscription from "../../../graphql/subscribe"
import ErrorModal from "../../shared/ErrorModal"
import {
  fontFamilies,
  fontSizes,
  palette,
  spaces,
} from "../../../utils/presets"
import { siteDetails as text } from "../../../locales/default.json"

export const LOGS_QUERY = gql`
  query logsForBuild($siteId: UUID!, $buildId: UUID!) {
    logsForBuild(siteId: $siteId, buildId: $buildId) {
      id
      message
      timestamp
      sourceStream
      command
    }
  }
`

const LOG_SUBSCRIPTION = gql`
  subscription buildLogCreated($buildId: UUID!) {
    buildLogCreated(buildId: $buildId) {
      id
      message
      timestamp
      sourceStream
      command
    }
  }
`

const LogContainer = styled(`section`)`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const LogHeader = styled(`header`)`
  align-items: center;
  display: flex;
  font-family: ${fontFamilies.headerFontFamily};
  font-size: ${fontSizes.xl};
  font-weight: bold;
  justify-content: space-between;
  padding: ${spaces.l};
  padding-right: ${spaces.xl};
`

const LogListRoot = styled(`div`)`
  background-color: ${palette.grey[900]};
  color: ${palette.white};
  padding: ${spaces.l};
  font-family: ${fontFamilies.monospaceFontFamily};
  flex-grow: 1;
  overflow-y: auto;
`

const LogItem = styled(`p`)`
  color: ${props => (props.sourceStream === `STDERR` ? `red` : `white`)};
  font-size: ${fontSizes.xs};
  line-height: 1.5;
  margin-bottom: ${spaces.xs};
`

function LogDisplay({ id, message, timestamp, sourceStream, command }) {
  return (
    <LogItem sourceStream={sourceStream}>{`[${format(
      new Date(timestamp),
      `MM/dd/yyyy - HH:mm:ss aa`
    )}] [${command}] ${message}`}</LogItem>
  )
}

function LogsList({ client, siteId, logs, buildId }) {
  let listRef

  useEffect(() => {
    listRef.scrollTop = listRef.scrollHeight
  }, [])

  useSubscription(client, LOG_SUBSCRIPTION, {
    options: {
      variables: {
        buildId,
      },
    },
    deps: [buildId],
    onResult: payload => {
      const logsPayload = (payload && payload.data.buildLogCreated) || []

      try {
        const currentLogs = client.readQuery({
          query: LOGS_QUERY,
          variables: {
            buildId,
            siteId,
          },
        })

        const currentLogsForBuild =
          (currentLogs && currentLogs.logsForBuild) || []

        currentLogs.logsForBuild = [...currentLogsForBuild, ...logsPayload]

        client.writeQuery({
          query: LOGS_QUERY,
          variables: {
            buildId,
            siteId,
          },
          data: currentLogs,
        })
      } catch (e) {
        console.error(e)
      }
    },
  })

  return (
    <LogListRoot
      innerRef={list => {
        listRef = list
      }}
    >
      {logs.map(log => (
        <LogDisplay key={log.id} {...log} />
      ))}
    </LogListRoot>
  )
}

const Logs = ({
  id,
  siteId,
  client,
  header,
  closeModal,
  logsQuery,
  className,
}) => {
  const { loading, error } = logsQuery
  if (loading) {
    return <Loading delay={1000} />
  }
  if (error) return <ErrorModal errMsg={error.message} />
  const logs = (logsQuery && logsQuery.logsForBuild) || []

  return (
    <LogContainer className={className}>
      <LogHeader>
        {header || text.previewLogs}
        {` `}
        {closeModal && (
          <CloseButton aria-label="Close logs" onClick={closeModal} />
        )}
      </LogHeader>
      <LogsList client={client} siteId={siteId} buildId={id} logs={logs} />
    </LogContainer>
  )
}

export default graphql(LOGS_QUERY, {
  name: `logsQuery`,
  options: props => {
    return {
      name: `logsDetails`,
      variables: { buildId: props.id, siteId: props.siteId },
      fetchPolicy: `cache-and-network`,
    }
  },
})(Logs)
