import React, { Fragment } from "react"
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik"
// import { Debug } from "../../../../utils/debug/Formik"
import { MdErrorOutline } from "react-icons/md"
import * as Yup from "yup"
import styled from "react-emotion"

import { breakpoints, spaces } from "../../../../utils/presets"
import {
  ModalActions as BaseModalActions,
  ModalForm as BaseModalForm,
} from "../../../Modal"
import { PrimaryDeleteButton, CancelButton } from "../../../shared/Buttons"
import { Input, BigLabel, InputError } from "../../../shared/Forms"

import { sites as text } from "../../../../locales/default"

const Form = BaseModalForm.withComponent(FormikForm)

function createSchema(siteName) {
  return Yup.object().shape({
    siteNameInput: Yup.string()
      .required(`Required`)
      .test(
        `nameEquality`,
        `Please make sure your input matches the site name.`,
        function(value) {
          return value === siteName
        }
      ),
  })
}

const ModalActions = styled(BaseModalActions)`
  flex-wrap: wrap;
  flex-direction: column-reverse;

  ${CancelButton} {
    margin-top: ${spaces.xs};
    width: 100%;
  }

  ${PrimaryDeleteButton} {
    width: 100%;
  }

  @media (min-width: ${breakpoints.phablet}px) {
    flex-direction: row;

    ${CancelButton} {
      margin-top: 0;
      width: auto;
    }

    ${PrimaryDeleteButton} {
      width: auto;
    }
  }
`

const DeleteSiteForm = ({
  submitCallback,
  cancelCallback,
  siteId,
  siteName,
}) => (
  <Formik
    initialValues={{ siteNameInput: ``, siteName, siteId }}
    validationSchema={createSchema(siteName)}
    onSubmit={values => {
      submitCallback(values.siteId)
    }}
  >
    {() => (
      <Form>
        <Field name={`siteNameInput`} type="text" aria-label={`Site name`}>
          {({ field }) => (
            <Fragment>
              <BigLabel htmlFor="site name">{text.enterSiteName}:</BigLabel>
              <Input
                {...field}
                placeholder={text.siteName}
                id="site name"
                data-cy="delete-site-name-input"
              />
            </Fragment>
          )}
        </Field>
        <ErrorMessage name={`siteNameInput`}>
          {msg => (
            <InputError data-cy="delete-site-input-error-message">
              <MdErrorOutline /> {msg}
            </InputError>
          )}
        </ErrorMessage>
        <ModalActions>
          <CancelButton
            type="button"
            onClick={cancelCallback}
            data-cy="delete-site-modal-cancel-button"
          >
            {text.cancel}
          </CancelButton>
          <PrimaryDeleteButton
            type="submit"
            data-cy="delete-site-modal-submit-button"
          >
            {text.yesDeleteSite}
          </PrimaryDeleteButton>
        </ModalActions>

        {/* Uncomment to see Formik state (copied from envvarsform) */}
        {/* <Debug /> */}
      </Form>
    )}
  </Formik>
)

DeleteSiteForm.propTypes = {}

export default DeleteSiteForm
