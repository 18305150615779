import gql from "graphql-tag"
import {
  CREDIT_CARD_FRAGMENT,
  BILLING_PLAN_FRAGMENT,
  BILLING_FIELDS_FRAGMENT,
} from "../../Payment/fragments"

export const ORG_CONTRIBUTORS_QUERY = gql`
  query contributorsByOrgQuery($id: UUID!) {
    contributorsByOrganization(id: $id) {
      id
      name
      email
      avatarUrl
      contributorRole
    }
  }
`

export const PAYMENT_FRAGMENT = `
  fragment paymentFields on Organization {
    id
    name
    billing {
      ...billingFieldsFragment
      creditCard {
        ...creditCardFragment
      }
      plan {
        ...billingPlanFragment
      }
    }
  }
  ${BILLING_FIELDS_FRAGMENT}
  ${BILLING_PLAN_FRAGMENT}
  ${CREDIT_CARD_FRAGMENT}
`

export const PLAN_FRAGMENT = `
  fragment planFields on Organization {
    id
    name
    billing {
      ...billingFieldsFragment
      creditCard {
        ...creditCardFragment
      }
      plan {
        ...billingPlanFragment
      }
    }
  }
  ${BILLING_FIELDS_FRAGMENT}
  ${BILLING_PLAN_FRAGMENT}
  ${CREDIT_CARD_FRAGMENT}
`
