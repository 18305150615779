import React from "react"
import styled from "react-emotion"
import PropTypes from "prop-types"
import { MdArrowForward } from "react-icons/md"

import { createSite as text } from "../../locales/default.json"
import { NarrowSingleColumn } from "../shared/Containers"
import { breakpoints, palette, spaces, fontSizes } from "../../utils/presets"
import { CancelButton, PrimaryButton } from "../shared/Buttons"
import { Text } from "../shared/Typography"

const FooterRoot = styled(`footer`)`
  left: 0;
  margin-top: ${spaces.xl};
  position: absolute;
  width: 100%;

  :before {
    border-top: 1px solid ${palette.grey[200]};
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
`

const ButtonSet = styled(`div`)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`

const Cancel = styled(CancelButton)`
  margin-right: ${spaces.xl};
`

const MessageContainer = styled(`div`)`
  display: flex;
  justify-content: flex-end;
`

const Message = styled(Text)`
  color: ${palette.grey[500]};
  font-size: ${fontSizes.xs};
  text-align: right;
  width: 100%;
  @media (min-width: ${breakpoints.tablet}px) {
    width: 40%;
  }
`

const Footer = ({
  cancel,
  submitForm,
  buttonText,
  showMessage = false,
  isSubmitting,
  type = `button`,
}) => (
  <FooterRoot>
    <NarrowSingleColumn>
      <ButtonSet>
        <Cancel
          type="button"
          onClick={cancel}
          size={`L`}
          data-cy="footer-cancel-button"
        >
          {text.cancel}
        </Cancel>
        <PrimaryButton
          type={type}
          disabled={isSubmitting || false}
          loading={isSubmitting}
          onClick={submitForm && submitForm}
          data-cy="create-site-button"
        >
          {buttonText} <MdArrowForward />
        </PrimaryButton>
      </ButtonSet>
      {showMessage && (
        <MessageContainer>
          <Message>{text.manageIntegrations}</Message>
        </MessageContainer>
      )}
    </NarrowSingleColumn>
  </FooterRoot>
)

Footer.propTypes = {
  cancel: PropTypes.func.isRequired,
  submitForm: PropTypes.func,
}

export default Footer
