import React, { Fragment, useContext } from "react"
import PropTypes from "prop-types"
import gql from "graphql-tag"
import { graphql } from "react-apollo"

import { SITE_CONTRIBUTORS_QUERY } from "../../../SiteDetails/Settings/queries"
import { ORG_CONTRIBUTORS_QUERY } from "../../../OrganizationDetails/Settings/queries"
import { useTracker } from "../../../../analytics"

import { Heading } from "../../Typography"
import {
  ModalContext,
  ModalSecondaryHeader,
  ModalBody,
  ModalActions,
  ModalMessage,
  CloseButton,
  ModalForm,
} from "../../../Modal"
import { DefaultError } from "../../../shared/ErrorModal"
import { Text } from "../../Typography"
import { CancelButton, PrimaryDeleteButton } from "../../../shared/Buttons"
import { toastNotifications as toastText } from "../../../../locales/default.json"
import { manageContributors as text } from "../../../../locales/default.json"

const DELETE_SITE_CONTRIBUTOR_MUTATION = gql`
  mutation deleteContributorFromSite($siteId: UUID!, $contributorId: UUID!) {
    deleteContributorFromSite(siteId: $siteId, contributorId: $contributorId) {
      success
      message
    }
  }
`

const DELETE_ORG_CONTRIBUTOR_MUTATION = gql`
  mutation deleteContributorFromOrganization(
    $organizationId: UUID!
    $contributorId: UUID!
  ) {
    deleteContributorFromOrganization(
      organizationId: $organizationId
      contributorId: $contributorId
    ) {
      success
      message
    }
  }
`

let RemoveSiteContributorModal = ({
  closeModal,
  email,
  siteId,
  contributorId,
  showToast,
  deleteSiteContributorMutation,
}) => {
  const { showModal } = useContext(ModalContext)
  const { trackButtonClicked } = useTracker()

  return (
    <Fragment>
      <ModalSecondaryHeader>
        <Heading>{text.removeContributor}?</Heading>
        <CloseButton onClick={closeModal} />
      </ModalSecondaryHeader>
      <ModalBody>
        <ModalMessage>
          <Text>
            {text.removeContributorConf}
            {` `}
            <strong>{`${email}`}</strong>?
          </Text>
          <Text>{text.removeSiteContributorEffects}</Text>
        </ModalMessage>
        <ModalForm
          onSubmit={e => {
            e.preventDefault()
            trackButtonClicked(text.removeContributor, {
              siteId,
              uiSource: `Site settings`,
            })
            deleteSiteContributorMutation({
              variables: {
                siteId,
                contributorId,
              },
              update: cache => {
                const contributorsFromCache = cache.readQuery({
                  query: SITE_CONTRIBUTORS_QUERY,
                  variables: {
                    id: siteId,
                  },
                })

                contributorsFromCache.contributorsBySite = contributorsFromCache.contributorsBySite.filter(
                  ({ id }) => id !== contributorId
                )

                cache.writeQuery({
                  query: SITE_CONTRIBUTORS_QUERY,
                  variables: {
                    id: siteId,
                  },
                  data: contributorsFromCache,
                })
              },
            })
              .then(() => {
                closeModal()
                showToast(
                  `<strong>${toastText.contributorRemoved}</strong> ${
                    toastText.successfully
                  }`
                )
              })
              .catch(err =>
                showModal({
                  Component: DefaultError,
                  props: { errMsg: err.message },
                })
              )
          }}
        >
          <ModalActions>
            <CancelButton type="button" onClick={closeModal}>
              {text.cancel}
            </CancelButton>
            <PrimaryDeleteButton type="submit">
              {text.remove}
            </PrimaryDeleteButton>
          </ModalActions>
        </ModalForm>
      </ModalBody>
    </Fragment>
  )
}

RemoveSiteContributorModal.propTypes = {
  closeModal: PropTypes.func,
  email: PropTypes.string,
  siteId: PropTypes.string,
  contributorId: PropTypes.string,
  emails: PropTypes.array,
}

RemoveSiteContributorModal = graphql(DELETE_SITE_CONTRIBUTOR_MUTATION, {
  name: `deleteSiteContributorMutation`,
})(RemoveSiteContributorModal)

let RemoveOrgContributorModal = ({
  closeModal,
  email,
  organizationId,
  contributorId,
  showToast,
  deleteOrgContributorMutation,
}) => {
  const { showModal } = useContext(ModalContext)
  const { trackButtonClicked } = useTracker()

  return (
    <Fragment>
      <ModalSecondaryHeader>
        <Heading>{text.removeContributor}?</Heading>
        <CloseButton onClick={closeModal} />
      </ModalSecondaryHeader>
      <ModalBody>
        <ModalMessage>
          <Text>
            {text.removeContributorConf}
            {` `}
            <strong>{`${email}`}</strong>?
          </Text>
          <Text>{text.removeOrgContributorEffects}</Text>
        </ModalMessage>
        <ModalForm
          onSubmit={e => {
            e.preventDefault()
            trackButtonClicked(text.removeContributor, {
              organizationId,
              uiSource: `Organization settings`,
            })
            deleteOrgContributorMutation({
              variables: {
                organizationId,
                contributorId,
              },
              update: cache => {
                const contributorsFromCache = cache.readQuery({
                  query: ORG_CONTRIBUTORS_QUERY,
                  variables: {
                    id: organizationId,
                  },
                })

                contributorsFromCache.contributorsByOrganization = contributorsFromCache.contributorsByOrganization.filter(
                  ({ id }) => id !== contributorId
                )

                cache.writeQuery({
                  query: ORG_CONTRIBUTORS_QUERY,
                  variables: {
                    id: organizationId,
                  },
                  data: contributorsFromCache,
                })
              },
            })
              .then(() => {
                closeModal()
                showToast(
                  `<strong>${toastText.contributorRemoved}</strong> ${
                    toastText.successfully
                  }`
                )
              })
              .catch(err =>
                showModal({
                  Component: DefaultError,
                  props: { errMsg: err.message },
                })
              )
          }}
        >
          <ModalActions>
            <CancelButton type="button" onClick={closeModal}>
              {text.cancel}
            </CancelButton>
            <PrimaryDeleteButton type="submit">
              {text.remove}
            </PrimaryDeleteButton>
          </ModalActions>
        </ModalForm>
      </ModalBody>
    </Fragment>
  )
}

RemoveOrgContributorModal.propTypes = {
  closeModal: PropTypes.func,
  email: PropTypes.string,
  organizationId: PropTypes.string,
  contributorId: PropTypes.string,
  emails: PropTypes.array,
}

RemoveOrgContributorModal = graphql(DELETE_ORG_CONTRIBUTOR_MUTATION, {
  name: `deleteOrgContributorMutation`,
})(RemoveOrgContributorModal)

export { RemoveSiteContributorModal, RemoveOrgContributorModal }
