import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { withApolloFragment } from "apollo-fragment-react"
import { ModalConsumer } from "../../Modal"
import { Resubscribe as ResubscribeModal } from "./Modals"

const PAYWALL_FRAGMENT = `
  fragment paywallFragment on Organization {
    id
    name
    billing {
      id
      status
    }
    permissions {
      billing {
        edit
      }
    }
  }
`

const Paywall = ({ organization, showModal, children, onSuccess }) => {
  const showResubscribe = () => {
    showModal({
      Component: ResubscribeModal,
      props: {
        name: organization.name,
        organizationId: organization.id,
        canResubscribe: organization.permissions.billing.edit,
        mode: `action`,
      },
    })
  }

  const onClick = () => {
    if (organization.billing && organization.billing.status === `CANCELED`) {
      return showResubscribe()
    }

    return onSuccess()
  }

  return <Fragment>{children({ onClick })}</Fragment>
}

Paywall.propTypes = {
  organization: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

const PaywallWrapper = ({ id, data, children, ...rest }) => (
  <ModalConsumer>
    {({ showModal }) => (
      <Paywall showModal={showModal} organization={data} {...rest}>
        {children}
      </Paywall>
    )}
  </ModalConsumer>
)

export default withApolloFragment(PAYWALL_FRAGMENT)(PaywallWrapper)
