import React, { Fragment } from "react"
import styled from "react-emotion"

import { planInformation as planText } from "../../locales/default.json"
import { useTracker } from "../../analytics/index.js"

const ContactLink = styled(`a`)`
  text-decoration: none;
`

export default function ContactUsLink() {
  const { trackButtonClicked } = useTracker()

  return (
    <Fragment>
      {planText.contactUs}
      {` `}
      <ContactLink
        href="mailto:sales@gatsbyjs.com"
        onClick={() => trackButtonClicked(planText.contactUs)}
      >
        sales@gatsbyjs.com
      </ContactLink>
    </Fragment>
  )
}
