import React from "react"
import { Router } from "@reach/router"
import Billing from "./components/Billing"
import Sites from "./components/Sites"
import Login from "./components/Login"
import PreviewLogin from "./components/PreviewLogin"
import Logout from "./components/Logout"
import CreateSite from "./components/CreateSite"
import SiteDetails from "./components/SiteDetails/SiteDetails"
import OrganizationDetails from "./components/OrganizationDetails"
import ProtectedRoute from "./components/ProtectedRoute"
import InvitationRedirect from "./components/InvitationRedirect"
import AuthRedirect from "./components/AuthRedirect"
import ContentfulRedirect from "./components/Redirects/ContentfulRedirect"
import NotFound from "./components/NotFound"
import LeadCapture from "./components/LeadCapture"
import BuildLogs from "./components/BuildLogs"
import { ToastProvider } from "./components/shared/Toast"

const Dashboard = () => (
  <ToastProvider>
    <Router>
      <Login path="/dashboard/" />
      <Login path="/dashboard/login" />
      <PreviewLogin path="/dashboard/previews/login" />
      <AuthRedirect path="/dashboard/redirect" />
      <ProtectedRoute path="/dashboard/welcome" component={LeadCapture} />
      <ProtectedRoute
        component={ContentfulRedirect}
        path="/dashboard/contentful/redirect"
      />
      <ProtectedRoute
        component={InvitationRedirect}
        path="/dashboard/invitations/:invitationId"
      />
      <Logout path="/dashboard/logout" />
      <ProtectedRoute
        component={BuildLogs}
        path="/dashboard/builds/:buildId/logs"
      />
      <ProtectedRoute component={Sites} path="/dashboard/sites" />
      <ProtectedRoute component={CreateSite} path="/dashboard/sites/create" />
      <ProtectedRoute
        component={CreateSite}
        path="/dashboard/sites/create/:siteId/integrations"
      />
      <ProtectedRoute
        component={CreateSite}
        path="/dashboard/sites/create/:siteId/setup"
      />
      <ProtectedRoute
        component={CreateSite}
        path="/dashboard/sites/create/:siteId"
      />
      <ProtectedRoute
        component={SiteDetails}
        path="/dashboard/:organizationId/sites/settings/:siteId"
      />
      <ProtectedRoute
        component={SiteDetails}
        path="/dashboard/:organizationId/sites/overview/:siteId"
      />
      <ProtectedRoute
        component={SiteDetails}
        path="/dashboard/:organizationId/sites/integrations/:siteId"
      />
      <ProtectedRoute
        component={Billing}
        path="/dashboard/pricing/:organizationId"
      />
      <ProtectedRoute
        component={OrganizationDetails}
        path="/dashboard/organization/settings/:organizationId"
      />
      <ProtectedRoute
        component={OrganizationDetails}
        path="/dashboard/organization/sites/:organizationId"
      />
      <NotFound path="/dashboard/*" />
    </Router>
  </ToastProvider>
)

export default Dashboard
