import React from "react"

export default ({ className }) => (
  <svg
    className={className}
    width="273"
    height="89"
    viewBox="0 0 273 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M99.7822 27.3425H111.003V67.343H99.7822V62.1703C95.7334 67.9903 90.3994 68.6357 87.5478 68.6357C75.3157 68.6357 67.22 59.0298 67.22 47.2976C67.22 35.4733 75.2239 26.0498 86.9971 26.0498C90.0322 26.0498 96.0107 26.605 99.7822 32.5172V27.3425ZM78.8098 47.2976C78.8098 53.6708 83.4092 58.659 89.8467 58.659C96.2861 58.659 100.886 53.6708 100.886 47.2976C100.886 40.9225 96.2861 36.0265 89.8467 36.0265C83.4092 36.0265 78.8098 40.9225 78.8098 47.2976Z"
      fill="black"
    />
    <path
      d="M132.882 36.9503V67.343H121.66V36.9503H117.246V27.3425H121.66V13.6724H132.882V27.3425H140.609V36.9503H132.882Z"
      fill="black"
    />
    <path
      d="M166.739 36.8582C164.071 34.365 161.403 34.1786 160.208 34.1786C157.54 34.1786 155.884 35.4733 155.884 37.4133C155.884 38.4293 156.344 39.9987 159.471 41.0148L162.139 41.8464C165.266 42.8625 169.958 44.4337 172.256 47.5742C173.452 49.2377 174.28 51.6387 174.28 54.2259C174.28 57.8274 173.084 61.4308 169.866 64.387C166.645 67.343 162.784 68.6357 157.909 68.6357C149.63 68.6357 144.938 64.6634 142.456 61.986L148.343 55.1499C150.55 57.7352 153.86 59.7674 157.173 59.7674C160.3 59.7674 162.69 58.1981 162.69 55.4264C162.69 52.9313 160.667 51.9152 159.196 51.3621L156.62 50.4381C153.768 49.422 150.458 48.1274 148.067 45.6343C146.228 43.6942 145.03 41.1991 145.03 37.9664C145.03 34.0864 146.87 30.8538 149.171 28.9138C152.298 26.4186 156.344 26.0498 159.563 26.0498C162.507 26.0498 167.198 26.4186 172.256 30.2986L166.739 36.8582Z"
      fill="black"
    />
    <path
      d="M191.046 32.5171C195.001 26.9736 200.613 26.0497 204.015 26.0497C215.788 26.0497 223.606 35.1946 223.606 47.2975C223.606 59.4907 215.513 68.6356 203.739 68.6356C201.164 68.6356 195.369 68.1745 191.046 62.1702V67.3429H179.824V0H191.046V32.5171ZM189.943 47.2975C189.943 53.6706 194.541 58.6589 200.98 58.6589C207.419 58.6589 212.017 53.6706 212.017 47.2975C212.017 40.9224 207.419 36.0264 200.98 36.0264C194.541 36.0264 189.943 40.9224 189.943 47.2975Z"
      fill="black"
    />
    <path
      d="M241.833 61.3384L222.885 27.3423H235.947L248.271 50.0671L259.308 27.3423H272.001L239.902 88.4964H227.209L241.833 61.3384Z"
      fill="black"
    />
    <path
      d="M64.1006 34.0496H53.0618C53.0618 34.0498 36.0703 34.05 36.0703 34.05V45.135H50.6865C48.4697 52.5815 42.8313 57.8054 32.1282 57.8054C20.498 57.8054 12.2942 48.3037 12.2942 36.623C12.2942 24.9424 20.498 15.4407 32.1282 15.4407C38.6865 15.4407 44.7976 18.5195 48.7844 23.6931L58.1714 17.8008C52.1321 9.38232 42.4892 4.35547 32.1282 4.35547C14.4116 4.35547 0 18.8296 0 36.623C0 54.4165 13.4028 68.8906 32.1282 68.8906C50.8535 68.8906 64.2563 54.4165 64.2563 36.623C64.2563 35.7519 64.168 34.9036 64.1006 34.0496Z"
      fill="black"
    />
  </svg>
)
