import React, { Fragment } from "react"
import PropTypes from "prop-types"
import styled from "react-emotion"
import { palette, spaces, fontSizes } from "../../utils/presets"
import hex2rgba from "hex2rgba"

const PreviewStatusPill = styled(`span`)`
  border-radius: ${spaces[`3xs`]};
  font-size: ${fontSizes[`2xs`]};
  margin: 0 ${spaces[`xs`]} 0 0;
  padding: 4px 8px;
`

const DefaultState = styled(PreviewStatusPill)`
  background: ${hex2rgba(palette.grey[400], 0.1)};
  color: ${palette.grey[800]};
`

const ReadyState = styled(PreviewStatusPill)`
  background: ${hex2rgba(palette.green[200], 0.1)};
  color: ${palette.green[800]};
`

const PendingState = styled(PreviewStatusPill)`
  background: ${hex2rgba(palette.yellow[400], 0.1)};
  color: ${palette.yellow[900]};
`

const BuildingState = styled(PreviewStatusPill)`
  background: ${hex2rgba(palette.purple[400], 0.1)};
  color: ${palette.purple[800]};
`

const StoppedState = styled(PreviewStatusPill)`
  background: ${hex2rgba(palette.red[200], 0.1)};
  color: ${palette.red[800]};
`

const RebuildingState = styled(PreviewStatusPill)`
  background: ${hex2rgba(palette.blue[200], 0.1)};
  color: ${palette.blue[800]};
`

const PreviewState = ({ status }) => (
  <Fragment>
    {{
      CLOUD_AUTH: <PendingState>LOG IN REQUIRED</PendingState>,
      PASSWORD: <StoppedState>PASSWORD REQUIRED</StoppedState>,
      PUBLIC: <ReadyState>{status}</ReadyState>,
      UNAUTHORIZED: <StoppedState>{status}</StoppedState>,
      READY: <ReadyState>{status}</ReadyState>,
      PENDING: <PendingState>{status}</PendingState>,
      BUILDING: <BuildingState>STARTING</BuildingState>,
      STOPPED: <StoppedState>{status}</StoppedState>,
      REBUILDING: <RebuildingState>STARTING</RebuildingState>,
      BUILD_ERROR: <StoppedState>BUILD ERROR</StoppedState>,
      SYSTEM_ERROR: <StoppedState>SYSTEM ERROR</StoppedState>,
    }[status] || <DefaultState>{status}</DefaultState>}
  </Fragment>
)

PreviewState.propTypes = {
  status: PropTypes.string.isRequired,
}

export default PreviewState
