import { useCallback } from "react"
import * as uuid from "uuid"

function setSessionId(id) {
  return window.localStorage.setItem(`gatsby:sessionId`, id)
}

function findOrCreateSessionId() {
  const sessionId = window.localStorage.getItem(`gatsby:sessionId`)

  if (!sessionId) {
    const id = uuid()
    setSessionId(id)
    return id
  }

  return sessionId
}

export function useTracker() {
  const userId =
    typeof window !== `undefined`
      ? window.localStorage.getItem(`gatsby:userid`)
      : undefined

  const hostname =
    typeof window !== `undefined` ? window.location.hostname : undefined

  function track(event) {
    if (process.env.GATSBY_MOCK_MODE || process.env.GATSBY_LOCAL) {
      return console.log(
        `Tracking events`,
        event,
        userId ? `for user ${userId}` : ``
      )
    }

    const headers = {
      "content-type": `application/json`,
    }
    if (userId) {
      headers[`x-cloud-user-id`] = userId
    }

    fetch(process.env.GATSBY_ANALYTICS_ENDPOINT, {
      method: `POST`,
      headers,
      body: JSON.stringify({
        ...event,
        componentId: hostname,
        sessionId: findOrCreateSessionId(),
        pathname:
          typeof window !== `undefined` ? window.location.pathname : undefined,
      }),
    }).catch(e => {
      console.error(e)
    })
  }

  return {
    track: useCallback(track, [userId]),
    trackPageViewed: useCallback(
      name => {
        track({
          eventType: `PAGE_VIEWED`,
          uiSource: name,
        })
      },
      [userId]
    ),
    trackButtonClicked: useCallback(
      (buttonName, params) =>
        track({
          eventType: `BUTTON_CLICKED`,
          buttonName,
          ...params,
        }),
      [userId]
    ),
  }
}
