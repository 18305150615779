import React from "react"
import styled, { keyframes } from "react-emotion"
import PropTypes from "prop-types"
import { Field } from "formik"

import { useTracker } from "../../../analytics"
import { BranchListContainer } from "../../shared/BranchList"

import {
  FieldBlock,
  FieldLabel,
  Radio,
  TextInputBlock,
} from "../../shared/Forms"

import { palette, spaces } from "../../../utils/presets"

const RepositoryListRoot = styled(`fieldset`)`
  border: none;
  margin: ${spaces.l} 0 0 0;
  max-height: 25rem;
  overflow: hidden;
  overflow-y: scroll;
`

const RepositoryListItem = styled(Radio)`
  align-items: flex-start;
  flex-direction: column;

  & > label {
    display: flex;
    flex-wrap: wrap;

    span:first-of-type {
      color: ${palette.grey[600]};
    }
    span:nth-of-type(2) {
      margin: 0 ${spaces[`3xs`]};
      color: ${palette.grey[400]};
    }
  }
`

const nameWithOwnerHtml = name => {
  const parts = name.split(`/`)

  return `<span>${parts[0]}</span> <span>/</span> <span>${parts[1]}</span>`
}

const entry = keyframes`
  50% {
    opacity: 0;
    max-height: 10rem;
  }
  100% {
    opacity: 1;
    max-height: 10rem;
  }
`

const FieldsetInner = styled(`div`)`
  display: flex;
`

const BranchAndDirectoryRoot = styled(`fieldset`)`
  animation: ${entry} 0.5s ease-out forwards;
  border: none;
  margin: 0;
  opacity: 0;
  padding: ${spaces[`2xs`]} ${spaces.l} ${spaces.m} ${spaces[`3xl`]};
  max-height: 0rem;
  width: 100%;

  ${FieldBlock} {
    display: flex;
    flex-direction: column;
    margin: 0;

    &:first-of-type {
      width: 35%;

      button {
        width: 100%;
      }
    }

    &:nth-of-type(2) {
      width: 65%;
      padding-left: ${spaces.m};
    }
  }
`

const BranchAndDirectory = ({ nameWithOwner, setBranch, branch }) => (
  <BranchAndDirectoryRoot>
    <FieldsetInner>
      <FieldBlock>
        <FieldLabel htmlFor="branchList">Branch</FieldLabel>
        <BranchListContainer
          id="branchList"
          updateBranchState={branch => {
            setBranch(branch)
          }}
          nameWithOwner={nameWithOwner}
          siteBranch={branch}
          uiSource="Create and setup site"
        />
      </FieldBlock>
      <TextInputBlock
        label="Directory path"
        fieldTip="The default is set to the root directory"
        placeholder="/"
        fieldName="directoryPath"
      />
    </FieldsetInner>
  </BranchAndDirectoryRoot>
)

const RepositoryList = ({
  repositories,
  loadRepositories,
  setBranch,
  branch,
}) => {
  const { trackButtonClicked } = useTracker()
  const onScroll = e => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom) loadRepositories()
  }

  return (
    <RepositoryListRoot
      className={`repos-list`}
      onScroll={onScroll}
      role="list"
      data-cy="repository-list"
    >
      {repositories.map((repo, index) => (
        <Field name={`nameWithOwner`} key={index}>
          {({ field, form, ...formikProps }) => (
            <RepositoryListItem
              fieldName={`nameWithOwner`}
              type={`colourful`}
              optionValue={repo.nameWithOwner}
              htmlLabel={nameWithOwnerHtml(repo.nameWithOwner)}
              id={`nameWithOwner${index}`}
              SubForm={BranchAndDirectory}
              subFormProps={{
                nameWithOwner: repo.nameWithOwner,
                branch: `master`,
              }}
              onClick={() => {
                trackButtonClicked(`Repo List Item`, {
                  repositoryId: repo.id,
                  name: repo.nameWithOwner,
                })
              }}
              {...field}
              {...formikProps}
              {...form}
            >
              {repo.nameWithOwner === form.values.nameWithOwner && (
                <BranchAndDirectory
                  nameWithOwner={repo.nameWithOwner}
                  setBranch={setBranch}
                  branch={branch}
                />
              )}
            </RepositoryListItem>
          )}
        </Field>
      ))}
    </RepositoryListRoot>
  )
}

RepositoryList.propTypes = {
  repositories: PropTypes.array,
  loadRepositories: PropTypes.func,
  setBranch: PropTypes.func,
  branch: PropTypes.string,
}

export default RepositoryList
