import React from "react"
import styled from "react-emotion"
import PropTypes from "prop-types"

import Notification from "./Notification"
import { palette, radius, spaces } from "../../../utils/presets"
import { notificationModes } from "./Notification"

export const NotificationCardRoot = styled(`div`)`
  background: ${palette.white};
  width: 100%;
  border-radius: ${radius.default} 0 0 ${radius.default};
  border-left: 10px solid ${props => notificationModes[props.mode].brdCol};
  box-shadow: 0px 1px 2px rgba(46, 41, 51, 0.08),
    0px 2px 4px rgba(71, 63, 79, 0.08);
  padding: ${spaces.m} ${spaces.l} ${spaces.m} ${spaces.m};
`

const NotificationCard = props => {
  const { className, mode = `default` } = props

  return (
    <NotificationCardRoot className={className} mode={mode}>
      <Notification {...props} />
    </NotificationCardRoot>
  )
}

NotificationCard.propTypes = {
  message: PropTypes.any.isRequired,
  mode: PropTypes.oneOf([`default`, `success`, `warning`, `error`]),
  icon: PropTypes.func,
  showLink: PropTypes.bool,
  linkLabel: PropTypes.string,
  linkTo: PropTypes.string,
  linkOnClick: PropTypes.func,
  className: PropTypes.string,
}

export default NotificationCard
