import React, { Fragment } from "react"
import styled from "react-emotion"
import { format } from "date-fns"

import { MdCreditCard } from "react-icons/md"

import { breakpoints, spaces, palette, fontSizes } from "../../../utils/presets"
import GreyCalendar from "../../../assets/GreyCalendar"
import GreyCash from "../../../assets/GreyCash"
import { billing as billingText } from "../../../locales/default.json"
import { Text } from "../Typography"

const CreditCard = styled(MdCreditCard)`
  fill: ${palette.grey[400]};
`

const Content = styled(`section`)`
  color: ${palette.grey[500]};
  font-size: ${fontSizes.xs};
  line-height: 1.3125rem;
  letter-spacing: 0.015rem;
  margin-top: ${spaces.m};

  @media (min-width: ${breakpoints.desktop}px) {
    margin-top: ${spaces.l};
  }
`

const Row = styled(`div`)`
  align-items: center;
  display: flex;
  width: 100%;

  svg {
    margin-right: ${spaces.s};
    width: 16px;
    height: 16px;
  }
`

const Key = styled(`span`)`
  color: ${palette.grey[900]};
  font-size: ${fontSizes.xs};
  padding-right: ${spaces.xs};
  width: auto;

  @media (min-width: ${breakpoints.tablet}px) {
    padding-right: 0;
    min-width: 250px;
  }
`

const Details = styled(Text)`
  color: ${palette.grey[900]};
  font-size: ${fontSizes.xs};
  font-weight: bold;
  margin: 0;
`

export const CardInformation = ({
  cardBrand,
  cardNumber,
  interval,
  formattedAmount,
  amount,
  nextBillingDate,
}) => (
  <Content>
    <Row>
      {!!cardBrand && !!cardNumber && <CreditCard />}

      {!!cardBrand && (
        <Key>
          {`${cardBrand.charAt(0).toUpperCase()}${cardBrand
            .slice(1)
            .toLowerCase()}`}
        </Key>
      )}
      {!!cardNumber && (
        <Fragment>
          {` `}
          <Details>**** **** **** {cardNumber}</Details>
        </Fragment>
      )}
    </Row>
    <Row>
      <GreyCalendar />
      <Key>
        {billingText.billed} {` `}
        {`${interval}`.toLowerCase()}
      </Key>
      {` `}
      <Details>{`${formattedAmount}`.replace(/\.00$/, ``)}</Details>
    </Row>
    {amount > 0 && (
      <Row>
        <GreyCash />
        <Key>{billingText.nextPaymentReceived}</Key>
        {` `}
        <Details>{format(new Date(nextBillingDate), `yyyy-MM-dd`)}</Details>
      </Row>
    )}
  </Content>
)

export default CardInformation
