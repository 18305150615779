import React from "react"
import PropTypes from "prop-types"
import { Redirect } from "@reach/router"
import { graphql } from "react-apollo"
import withFlagProvider from "ld-react/lib/withFlagProvider"
import gql from "graphql-tag"
import { logoutBannedUser } from "../auth"
import Loading from "../components/shared/Loading"
import { setIdentify } from "../utils/helpers/fullstory"

export const USER_INFO = gql`
  query UserInfo {
    currentUser {
      id
      avatarUrl
      email
      name
      firstName
      lastName
      banned
    }
  }
`

function ProtectedRoute({
  component: Component,
  userInfoQuery,
  location,
  ...rest
}) {
  if (!localStorage.getItem(`gatsby:token`)) {
    // If we’re not logged in, redirect to the home page.
    return (
      <Redirect to={`/dashboard/login?loginRedirectUrl=${rest.uri}`} noThrow />
    )
  }
  const { currentUser, loading, error } = userInfoQuery

  if (loading) return <Loading delay={1000} message="authenticating ..." />
  if (error) return <p>Error: {error.message}</p>

  if (!currentUser) {
    return <Redirect to="/dashboard/logout" noThrow />
  }

  // Call identify API from fullstory
  if (location.search.includes(`loggedin=1`)) {
    setIdentify(currentUser)
  }

  if (currentUser.banned) {
    logoutBannedUser()
    return null
  }

  const Comp = withFlagProvider(Component, {
    clientSideId: process.env.GATSBY_LAUNCH_DARKLY_CLIENT_ID,
    user: {
      key: currentUser.id,
      firstName: currentUser.name.split(` `)[0],
      lastName: currentUser.name.split(` `)[1],
      email: currentUser.email,
    },
  })

  return <Comp {...rest} location={location} user={currentUser} />
}

ProtectedRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default graphql(USER_INFO, {
  name: `userInfoQuery`,
})(ProtectedRoute)
