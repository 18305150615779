import React from "react"

export default () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 8.66669H11V10.5467L12.6267 11.4867L12.1267 12.3534L10 11.1267V8.66669ZM12.6667 5.33335H3.33333V12.6667H6.44667C6.16 12.06 6 11.38 6 10.6667C6 8.08669 8.08667 6.00002 10.6667 6.00002C11.38 6.00002 12.06 6.16002 12.6667 6.44669V5.33335ZM3.33333 14C2.59333 14 2 13.4 2 12.6667V3.33335C2 2.59335 2.59333 2.00002 3.33333 2.00002H4V0.666687H5.33333V2.00002H10.6667V0.666687H12V2.00002H12.6667C13.4 2.00002 14 2.60002 14 3.33335V7.40002C14.8267 8.24002 15.3333 9.39335 15.3333 10.6667C15.3333 13.2467 13.2467 15.3334 10.6667 15.3334C9.39333 15.3334 8.24 14.8267 7.4 14H3.33333ZM10.6667 7.43335C8.88 7.43335 7.43333 8.88002 7.43333 10.6667C7.43333 12.4534 8.88 13.9 10.6667 13.9C12.4533 13.9 13.9 12.4534 13.9 10.6667C13.9 8.88002 12.4533 7.43335 10.6667 7.43335Z"
      fill="#B7B5BD"
    />
  </svg>
)
