import React, { useState, useContext, Fragment } from "react"
import styled from "react-emotion"
import { compose, graphql } from "react-apollo"
import gql from "graphql-tag"
import PropTypes from "prop-types"
import { MdEdit, MdHelpOutline } from "react-icons/md"

import { spaces, breakpoints, colors } from "../../../utils/presets"
import { envVars as text } from "../../../locales/default.json"
import Loading from "../Loading"
import EnvVarsData from "./EnvVarsData"
import EnvVarsForm from "../EnvVarsForm"
import EnvVarsFormSkeleton from "../EnvVarsForm/EnvVarsFormSkeleton"
import { SecondaryButton as SecondaryButtonBase } from "../Buttons"
import { SettingsCard, Header, Title } from "../Settings/SettingsCard"
import VisuallyHidden from "../VisuallyHidden"
import { SitePermissions } from "../../../permissions"
import Paywall from "../PaymentInformation/Paywall"
import { DefaultError } from "../ErrorModal"
import { ModalContext } from "../../Modal"
import { Text } from "../Typography"

const UPDATE_ENV_VARS_MUTATION = gql`
  mutation updateEnvironmentVariablesForSite(
    $id: UUID!
    $environmentVariables: [TagInput!]!
  ) {
    updateEnvironmentVariablesForSite(
      id: $id
      environmentVariables: $environmentVariables
    ) {
      success
      message
    }
  }
`

const Link = styled(`a`)`
  text-decoration: none;

  svg {
    margin-top: 8px;
  }
`

const DataContainer = styled(`section`)`
  display: flex;
  flex-direction: column;
  letter-spacing: 0.25px;
  overflow-x: auto;
  margin: ${spaces.m} ${spaces.m} 0 0;

  @media (min-width: ${breakpoints.desktop}px) {
    margin: ${spaces.l} 0 0;
  }
`

const HelpLink = styled(`a`)`
  display: flex;

  svg {
    fill: ${colors.lilac};
    height: 1rem;
    margin-left: 0.375rem;
    width: 1rem;
  }
`

const SecondaryButton = styled(SecondaryButtonBase)`
  :before {
    content: "${text.edit}";
  }

  @media (min-width: ${breakpoints.tablet}px) {
    :before {
      content: "${text.editVariables}";
    }
  }
`
const EditButton = ({ onClick }) => (
  <SecondaryButton
    type="button"
    onClick={onClick}
    data-cy="edit-variables-button"
  >
    <VisuallyHidden>{text.editVariables}</VisuallyHidden>
    <MdEdit />
  </SecondaryButton>
)

const Description = ({ environmentVariablesForSite }) => (
  <Text>
    {environmentVariablesForSite.length > 0 && (
      <Fragment>{text.autoAddEnvVars}</Fragment>
    )}
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href={`${window.location.origin}/docs/`}
      aria-label="Environment variables docs"
    >
      {` `}
      {text.documentation} <MdHelpOutline />
    </Link>
    {` `}
    {text.learnMore}
    {` `}
  </Text>
)

const EnvVars = ({
  siteId,
  organizationId,
  updateEnvVarsMutation,
  loading,
  error,
  envVarsQuery,
  origin = `settings`,
  values,
}) => {
  const [isEditing, toggleIsEditing] = useState(false)
  const { showModal } = useContext(ModalContext)
  if (loading)
    return (
      <Loading
        delay={1000}
        message="loading your environment variables list..."
        fullPageHeight={false}
      />
    )
  if (error) return <p>Error: {error.message}</p>
  const { environmentVariablesForSite } = envVarsQuery
  return (
    <SettingsCard>
      <Header>
        <Title>
          {text.environmentVariables}
          {/* TODO: Update link when we have docs on environment variables not cms specific */}
          <HelpLink
            target="_blank"
            href={`${window.location.origin}/docs`}
            aria-label="Environment variable docs"
          >
            <MdHelpOutline />
          </HelpLink>
        </Title>
        {origin === `settings` ? (
          <SitePermissions id={siteId} resource="sites" action="edit">
            <Paywall
              id={organizationId}
              onSuccess={() => toggleIsEditing(!isEditing)}
            >
              {({ onClick }) => <EditButton onClick={onClick} />}
            </Paywall>
          </SitePermissions>
        ) : (
          environmentVariablesForSite.length > 0 && (
            <EditButton onClick={() => toggleIsEditing(!isEditing)} />
          )
        )}
      </Header>
      {origin === `setup` && (
        <Description
          environmentVariablesForSite={environmentVariablesForSite}
        />
      )}
      <DataContainer>
        {origin === `setup` && environmentVariablesForSite.length === 0 && (
          <EnvVarsFormSkeleton
            siteId={siteId}
            uiSource="Setup site"
            values={values}
          />
        )}
        {isEditing ? (
          origin === `settings` ? (
            <EnvVarsForm
              siteId={siteId}
              uiSource="Site settings"
              envVars={environmentVariablesForSite}
              submitCallback={envVars => {
                updateEnvVarsMutation({
                  variables: {
                    id: siteId,
                    environmentVariables: envVars,
                  },
                })
                  .then(({ data }) => {
                    if (data.updateEnvironmentVariablesForSite.success) {
                      toggleIsEditing(false)
                      envVarsQuery.refetch()
                    }
                  })
                  .catch(err => {
                    showModal({
                      Component: DefaultError,
                      props: { errMsg: err.message },
                    })
                  })
              }}
              submitButtonLabel={text.save}
              cancelCallback={() => toggleIsEditing(false)}
            />
          ) : (
            <EnvVarsFormSkeleton
              siteId={siteId}
              uiSource="Setup site"
              values={values}
            />
          )
        ) : (
          <EnvVarsData envVars={environmentVariablesForSite} origin={origin} />
        )}
      </DataContainer>
    </SettingsCard>
  )
}

EnvVars.propTypes = {
  siteId: PropTypes.string.isRequired,
}

export default compose(
  graphql(UPDATE_ENV_VARS_MUTATION, {
    name: `updateEnvVarsMutation`,
  })
)(EnvVars)
