import styled from "react-emotion"
import {
  breakpoints,
  colors,
  dimensions,
  palette,
  spaces,
} from "../../utils/presets"

export const WhiteBackground = styled(`div`)`
  background: ${palette.white};
  width: 100%;
`

export const StandardSingleColumn = styled(`div`)`
  margin: 0 auto;
  max-width: 64rem;
  padding: 0 ${dimensions.pagePadding.mobile};

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 0 ${dimensions.pagePadding.tablet};
  }
`

export const NarrowSingleColumn = styled(StandardSingleColumn)`
  margin: ${spaces.xl} auto;
  max-width: 46rem;
`

export const PageWithTabsNavSection = styled(`div`)`
  align-items: flex-end;
  border-bottom: 1px solid ${palette.grey[200]};
  display: flex;
  min-height: ${dimensions.pageNav.minHeight};
`

export const StandardPageContentSection = styled(`main`)`
  border-bottom: 1px solid ${palette.grey[200]};
  min-height: calc(100vh - ${dimensions.siteHeader.height});
  padding: ${spaces[`2xl`]} 0 ${spaces[`3xl`]};
`

export const PageWithTabsContentSection = styled(StandardPageContentSection)`
  background: ${colors.secondaryBackground};
  min-height: calc(
    100vh - (${dimensions.siteHeader.height} + ${dimensions.pageNav.minHeight})
  );
`
